/**
 * Entry application component used to compose providers and render Routes.
 * */

// import React from "react";
// import { Provider } from "react-redux";
// import { BrowserRouter, withRouter } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
// import { Routes } from "../app/Routes";
// import { I18nProvider } from "../_metronic/i18n";
// import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SessionTimeout from "../Global/SessionTimeout";

// const App = (({ history, props, store, persistor, basename }) => (
//   /* Provide Redux store */
  
//   <Provider store={store}>
     
//     {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
//     <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
//       {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
//       <React.Suspense fallback={<LayoutSplashScreen />}>
//         {/* Override `basename` (e.g: `homepage` in `package.json`) */}
//         <BrowserRouter basename={basename}>
//           {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
//           <MaterialThemeProvider>
//             {/* Provide `react-intl` context synchronized with Redux state.  */}
//             <I18nProvider>
//               {/* Render routes with provided `Layout`. */}
//               <Routes />
//             </I18nProvider>
//           </MaterialThemeProvider>
//         </BrowserRouter>
//       </React.Suspense>
//     </PersistGate>
//     <ToastContainer />
   
//     <SessionTimeout />
//   </Provider>
// ));

// export default App;


import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionTimeout from "../Global/SessionTimeout";

const App = ({ store, persistor, basename }) => {
  //added by efaj
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem();
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <MaterialThemeProvider>
            <I18nProvider>
              <Routes />
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </PersistGate>
      <ToastContainer />
      <SessionTimeout />
    </Provider>
  );
};

export default App;
