import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Dropdown, Card } from "react-bootstrap";
import Pdf from "react-to-pdf";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getPromotionalListDetails } from "../../_redux/actions/BreadBurnRemoteSales/PromotionalAction";
import DownloadDropdown from "../../Master/DownloadDropdown";



const GiftListDetails = ({ handleClose, itemID, itemDetailnIfo }) => {

  const ItemDetails = useSelector((state) => state.promotional.promotionalListDetails);
  console.log('now ItemDetails :>> ', ItemDetails);

  const totalQuantity = useSelector(
    (state) => state.remoteSales.totalDetQuantity
  );
  const amount = useSelector(
    (state) => state.remoteSales.totalSalesDetailsAmount
  );

  const componentRef = useRef();
  let ref = React.createRef();
  const inputField = React.useRef(null);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPromotionalListDetails(itemID));
  }, []);

  const tdStyle = {
    fontSize: 40,

  }

  const styles = {
    color: 'purple',
    fontSize: '15px',
  }

  const tbodies = ItemDetails?.map((state, index) => {
    const cityValues = Object.values(state);
    console.log('state :>> ', state);
    return (
      <tbody key={index} className={state.tadaDate}>
        <tr style={{ height: '40px' }}>
          <td style={styles}  >{state.productName}</td>
          <td style={styles} >{state.totalQty}</td>
        </tr>
      </tbody>
    )
  })

  return (
    <>
      {
        ItemDetails?.length > 0
        && <div className=" p-5 mb-3 " >
          <div className="row">
            <div className="col-md-9"></div>
            <div className="col-md-3">
              <DownloadDropdown excelname='Tada Details' refvalue={componentRef} />
            </div>

          </div>
          <div ref={componentRef}>
            <div className="row">
              <div className="col-lg-12">
                {/* <h2 className="mb-0 pb-0 text-center text-bold">{ItemDetails[0]?.custName}</h2> */}
                <h2 className="mb-0 pb-0 text-center">Invoice ID: <b> {ItemDetails[0]?.invID}</b>  </h2>
                <h4 className="text-center">Date:<b>{ItemDetails[0]?.giftDate}</b></h4>
                <h4 className="text-center mb-3">Remarks: <b>{ItemDetails[0]?.remarks}</b></h4>
              </div>
            </div>
            <table className="table table-head-custom borderTableims" id="table-to-xls">
              <thead>
                <tr style={{ height: '40px' }}>
                  <th style={styles} className="text-center">Name</th>
                  <th className="text-center">Quantity</th>
                </tr>
              </thead>
              {tbodies}
            </table>

            <div className="row mt-30">
              <div className="col-lg-8">
              </div>
              <div className="col-lg-4 text-right">
              <h4>Signature</h4>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="invoice-price-right-text">
                <span mt-10>Signature</span>
              </div>
            </div> */}
          </div>
        </div>
      }
    </>
  );
};

export default GiftListDetails
