import React from "react";
import NewDamageEntry from "../../../information/components/RemoteSales/NewDamageEntry";



const DamageEntryContainer = () => {
  return (
    <div className="container-fluid">
        <NewDamageEntry />
      </div>
  );
};

export default DamageEntryContainer;