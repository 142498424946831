import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPreviousMonthWorkingDayList } from './DashboardCartAction';

const ModalCalender = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const lastWorkingDay = useSelector((state) => state.dashboardcart.lastWorkingDay);

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = formatDate(selectedDate);
      dispatch(GetPreviousMonthWorkingDayList(formattedDate));
    }
  }, [dispatch, selectedDate]);


  if (!selectedDate) return null;

  const day = selectedDate.getDate();
  const month = selectedDate.toLocaleString('default', { month: 'long' });
  const year = selectedDate.getFullYear();
  const weekday = selectedDate.toLocaleString('default', { weekday: 'long' });

  
  const styles = {
    container: {
      padding: '20px',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    detail: {
      fontSize: '18px',
      margin: '5px 0',
      color: '#555',
    },
    strong: {
      fontWeight: 'bold',
      color: '#000',
    },
  };

  // Function to format date to YYYY-MM-DD
  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <>
      <span>Current Month :</span>
      <div style={styles.container}>
        <p style={styles.detail}><span style={styles.strong}>Current Date:</span> {day}</p>
        <p style={styles.detail}><span style={styles.strong}>Current Month:</span> {month}</p>
        <p style={styles.detail}><span style={styles.strong}>Current Year:</span> {year}</p>
        <p style={styles.detail}><span style={styles.strong}>Current Weekday:</span> {weekday}</p>
      </div>

      <span>Previous Month :</span>
      <div style={styles.container}>
        <p style={styles.detail}><span style={styles.strong}>Previous Date:</span> {lastWorkingDay?.day}</p>
        <p style={styles.detail}><span style={styles.strong}>Previous Month:</span> {lastWorkingDay?.monthName}</p>
        <p style={styles.detail}><span style={styles.strong}>Previous Year:</span> {lastWorkingDay?.year}</p>
        <p style={styles.detail}><span style={styles.strong}>Previous Weekday:</span> {lastWorkingDay?.weekdayName}</p>
      </div>
    </>
  );
};

export default ModalCalender;
