import { indexOf } from "lodash";
import * as Types from "../../types/SecondarySalesTypes";
const initialState = {
  stockLoading: false,
  DisPointLoading: false,
  cumalative: [],
  totalQuantityCum: 0,
  totalPriceCum: 0,
  biscuteQty: 0,
  biscutePrice: 0,
  cakeQty: 0,
  cakePrice: 0,
  cookisQty: 0,
  cookisPrice: 0,
  dateWiseReport: [],
  subLedgerData: [],

  soWiseProduct: [],
  salessoWiseProduct: [],
  stockReport: [],
  PDemandStatus: [],
  skuTotal: 0,
  salesskuTotal: 0,
  skuQuantity: 0,
  salesskuQuantity: 0,
  salesTotalValueDel: 0,
  skuLoading: false,
  salesskuLoading: false,
  isProductLoading: false,
  tDemandApQty: 0,
  tDemandAprvValue: 0,
  tDemandQty: 0,
  tDemandValue: 0,
  aprvQty: 0,
  aprvValue: 0,
  demandQty: 0,
  demandValue: 0,
  deliveryStatusInput: {
    delivery_code: "",
    intDistributorId: ""
  },
  stockReportModified: [],
  stockModifiedLoading: false,
  totals: {},
  TotalDeliveryQty: 0,
  TotalDeliveryValue: 0 ,
  salesTotalDeliveryQty: 0,
  salesTotalDeliveryValue: 0 ,

};
const DeliveryReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case Types.GET_DELIVERY_REPORT_BY_DISTRIBUTOR:
      let sumSoPrice = 0;
      let soquantity = 0;
      let sobiscuteQty = 0;
      let sobiscutePrice = 0;
      let socakeQty = 0;
      let socakePrice = 0;
      let socookisQty = 0;
      let socookisPrice = 0;

      for (let i = 0; i < action.payload.data.length; i++) {
        soquantity += action.payload.data[i].totalQuantity;
        sumSoPrice += action.payload.data[i].totalPrice;
        sobiscuteQty += action.payload.data[i].biscuteQty;
        sobiscutePrice += action.payload.data[i].biscutePrice;
        socakeQty += action.payload.data[i].cakeQty;
        socakePrice += action.payload.data[i].cakePrice;
        socookisQty += action.payload.data[i].cookisQty;
        socookisPrice += action.payload.data[i].cookisPrice;
      }
      return {
        ...state,
        cumalative: action.payload.data,
        totalQuantityCum: soquantity,
        totalPriceCum: sumSoPrice,
        biscuteQty: sobiscuteQty,
        biscutePrice: sobiscutePrice,
        cakeQty: socakeQty,
        cakePrice: socakePrice,
        cookisQty: socookisQty,
        cookisPrice: socookisPrice,
        pldpcLoading: action.payload.isProductLoading,
      };
    case Types.GET_DELIVERY_SKU_REPORT:
      console.log('action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        soWiseProduct: action.payload.data.allReport,
        skuLoading: action.payload.isProductLoading,
        skuTotal: action.payload.data.totalPrice,
        skuQuantity: action.payload.data.totalQuentity,
        TotalValueDel: action.payload.data.totalValue,
        TotalDeliveryQty: action.payload.data.totalDeliveryQuentity,
        TotalDeliveryValue: action.payload.data.totalDeliveryValue 
      };

      case Types.GET_SALES_SKU_REPORT_ALL_ZONE:
        console.log('action.payload.data :>> ', action.payload.data);
        return {
          ...state,
          salessoWiseProduct: action.payload.data.allReport,
          salesskuLoading: action.payload.isProductLoading,
          salesskuTotal: action.payload.data.totalPrice,
          salesskuQuantity: action.payload.data.totalQuentity,
          salesTotalValueDel: action.payload.data.totalValue,
          salesTotalDeliveryQty: action.payload.data.totalDeliveryQuentity,
          salesTotalDeliveryValue: action.payload.data.totalDeliveryValue 
        };

     
    case Types.GET_PRIMARY_DEMAND_STATUS:
      console.log('action.payload.data', action.payload.data);
      return {
        ...state,
        PDemandStatus: action.payload.data?.allReport,
        skuLoading: action.payload.isProductLoading,
        aprvQty: action.payload.data.aprvQty,
        aprvValue: action.payload.data.aprvValue,
        demandQty: action.payload.data.demandQty,
        demandValue: action.payload.data.demandValue
      };
    case Types.GET_CUSTOEMR_LEDGER:

      return {
        ...state,
        subLedgerData: action.payload.data

      };
    case Types.CHANGE_DELIVERY_INPUT:
      const deliveryStatusInput = { ...state.deliveryStatusInput };
      deliveryStatusInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        deliveryStatusInput,
      };

    case Types.GET_STOCK_REPORT_NEW:
      return {
        ...state,
        stockReport: action.payload.data,
        stockLoading: action.payload.isProductLoading
      };

    case Types.GET_STOCK_REPORT_MODIFIED:
      
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      const distributorSums = {};
      const modifiedStockReports = [];

      action.payload.data.forEach(item => {
        const disPointID = item.disPointID;

        const totalSums = {
          productIMSPrice: 0,
          productIMS_CTQty: 0,
          productIMS_PCQty: 0,
          productLiftingCTQty: 0,
          productLiftingPCQty: 0,
          productLiftingPrice: 0,
          productOpeningCTQty: 0,
          productOpeningPCQty: 0,
          productOpeningPrice: 0,
          productClosingCTQty: 0,
          productClosingPCQty: 0,
          productClosingPrice: 0,
          productNet_PCQty:0,
          productNet_CTQty:0,
          productNetPrice:0,
          productDMG_PCQty:0,
          productDMG_CTQty:0,
          productDMGPrice:0,

        };

        if (!distributorSums[disPointID]) {
          distributorSums[disPointID] = {
            productIMSPrice: 0,
            productIMS_CTQty: 0,
            productIMS_PCQty: 0,
            productLiftingCTQty: 0,
            productLiftingPCQty: 0,
            productLiftingPrice: 0,
            productOpeningCTQty: 0,
            productOpeningPCQty: 0,
            productOpeningPrice: 0,
            productClosingCTQty: 0,
            productClosingPCQty: 0,
            productClosingPrice: 0,
            productNet_PCQty:0,
            productNet_CTQty:0,
            productNetPrice:0,
            productDMG_PCQty:0,
            productDMG_CTQty:0,
            productDMGPrice:0,

          };
        }

        // Update the sums for the distributor
        distributorSums[disPointID].productIMSPrice += item.productIMSPrice;
        distributorSums[disPointID].productIMS_CTQty += item.productIMS_CTQty;
        distributorSums[disPointID].productIMS_PCQty += item.productIMS_PCQty;
        distributorSums[disPointID].productLiftingCTQty += item.productLiftingCTQty;
        distributorSums[disPointID].productLiftingPCQty += item.productLiftingPCQty;
        distributorSums[disPointID].productLiftingPrice += item.productLiftingPrice;
        distributorSums[disPointID].productOpeningCTQty += item.productOpeningCTQty;
        distributorSums[disPointID].productOpeningPCQty += item.productOpeningPCQty;
        distributorSums[disPointID].productOpeningPrice += item.productOpeningPrice;
        distributorSums[disPointID].productClosingCTQty += item.productClosingCTQty;
        distributorSums[disPointID].productClosingPCQty += item.productClosingPCQty;
        distributorSums[disPointID].productClosingPrice += item.productClosingPrice;
        distributorSums[disPointID].productNet_PCQty += item.productNet_PCQty;
        distributorSums[disPointID].productNet_CTQty += item.productNet_CTQty;
        distributorSums[disPointID].productNetPrice += item.productNetPrice;
        distributorSums[disPointID].productDMG_PCQty += item.productDMG_PCQty;
        distributorSums[disPointID].productDMG_CTQty += item.productDMG_CTQty;
        distributorSums[disPointID].productDMGPrice += item.productDMGPrice;

  
        let stockReport = modifiedStockReports.find(report => report.disPointID === disPointID);
        if (!stockReport) {
          stockReport = {
            zoneName: item.zoneName,
            divisionName: item.divisionName,
            regionName: item.regionName,
            territoryName: item.territoryName,
            disPointID: item.disPointID,
            disPointName: item.disPointName,
            distributorSums: distributorSums[disPointID],
            Details: [],
          };
          modifiedStockReports.push(stockReport);
        }

        // Add the Details to the existing StockReport
        stockReport.Details.push({
          zoneName: item.zoneName,
          divisionName: item.divisionName,
          regionName: item.regionName,
          territoryName: item.territoryName,
          disPointID: item.disPointID,
          disPointName: item.disPointName,
          brandName: item.brandName,
          divisionID: item.divisionID,
          packTypeName: item.packTypeName,
          productID: item.productID,
          prodCatName: item.prodCatName,
          prodGroup:item.salesGroup,
          productName: item.productName,
          productUom: item.productUom,
          regionId: item.regionId,
          zoneID: item.zoneID,
          productIMSPrice: item.productIMSPrice,
          productIMS_CTQty: item.productIMS_CTQty,
          productIMS_PCQty: item.productIMS_PCQty,
          productLiftingCTQty: item.productLiftingCTQty,
          productLiftingPCQty: item.productLiftingPCQty,
          productLiftingPrice: item.productLiftingPrice,
          productOpeningCTQty: item.productOpeningCTQty,
          productOpeningPCQty: item.productOpeningPCQty,
          productOpeningPrice: item.productOpeningPrice,
          productClosingCTQty: item.productClosingCTQty,
          productClosingPCQty: item.productClosingPCQty,
          productClosingPrice: item.productClosingPrice,
          productNet_PCQty: item.productNet_PCQty,
          productNet_CTQty: item.productNet_CTQty,
          productNetPrice: item.productNetPrice,
          productDMG_PCQty: item.productDMG_PCQty,
          productDMG_CTQty: item.productDMG_CTQty,
          productDMGPrice: item.productDMGPrice,
          productPrice: item.productPrice,
          productUom: item.productUom,
        });
      });

      const totalProductOpeningPCQty = Object.values(distributorSums).reduce((sum, distributorSum) => {
        return sum + distributorSum.productOpeningPCQty;
      }, 0);

      const totalproductLiftingPCQty = Object.values(distributorSums).reduce((sum, distributorSum) => {
        return sum + distributorSum.productLiftingPCQty;
      }, 0);

      const totalproductClosingPCQty = Object.values(distributorSums).reduce((sum, distributorSum) => {
        return sum + distributorSum.productClosingPCQty;
      }, 0);

      const totalproductIMS_PCQty = Object.values(distributorSums).reduce((sum, distributorSum) => {
        return sum + distributorSum.productIMS_PCQty;
      }, 0);

      const totalproductNet_PCQty = Object.values(distributorSums).reduce((sum, distributorSum) => {
        return sum + distributorSum.productNet_PCQty;
      }, 0);
      console.log('action.payload.isStockLoading :>> ', action.payload.isStockLoading);

      return {
        ...state,
        stockReportModified: modifiedStockReports,
        stockModifiedLoading: action.payload.isStockLoading,
        totals: {
          totalProductOpeningPCQty,
          totalproductLiftingPCQty,
          totalproductClosingPCQty,
          totalproductIMS_PCQty,
          totalproductNet_PCQty
        },
      };


    // case Types.GET_DELIVERY_SKU_REPORT_PRODUCT_WISE:
    //   return {
    //     ...state,
    //     soWiseProduct: action.payload.data.allReport,
    //     skuLoading: action.payload.isProductLoading,
    //     skuTotal: action.payload.data.totalPrice,
    //     skuQuantity: action.payload.data.totalQuentity,
    //     TotalValueDel: action.payload.data.totalValue
    //   };



    case Types.GET_DELIVERY_SKU_REPORT_PRODUCT_WISE:
      console.log('action.payload', action.payload);

      // Extract item reports from the payload
      const allItemReports = action.payload.data.allReport;
      const demandItemReports = action.payload.data?.demandReport;
      const targetItemReports = action.payload.data?.targetReport;

      const aggregatedReports = {};

      // Check if allItemReports is an array and has elements
      if (Array.isArray(allItemReports) && allItemReports.length > 0) {
        // Loop through all items in the reports
        allItemReports.forEach((item) => {
          const { productID, productQty, productTPrice, productName, productCategoryName } = item;
          // Include items with quantity 0
          if (aggregatedReports[productID]) {
            aggregatedReports[productID].productQty += productQty;
            aggregatedReports[productID].productTPrice += productTPrice;
          } else {
            aggregatedReports[productID] = {
              productID,
              productQty,
              productTPrice,
              demandQty: 0,
              targetQty: 0,
              targetPrice: 0,
              productCategoryName,
              productName
            };
          }
        });
      }


      // Add demandQty from demandItemReports
      if (demandItemReports && Array.isArray(demandItemReports)) {
        demandItemReports.forEach((demandItem) => {
          const { productID, prodQty } = demandItem;
          if (aggregatedReports[productID]) {
            aggregatedReports[productID].demandQty += prodQty;
          } else {
            aggregatedReports[productID] = {
              prodID: productID,
              demandQty: prodQty,
            };
          }
        });
      }


      // Add targetQty from targetItemReports
      if (targetItemReports && Array.isArray(targetItemReports)) {
        targetItemReports.forEach((taritem) => {
          const { prodID, prodQty, prodPrice,prodCat, prodName } = taritem;
          if (aggregatedReports[prodID]) {
            aggregatedReports[prodID].targetQty += prodQty;
            aggregatedReports[prodID].targetPrice += prodPrice;
          } else {
            aggregatedReports[prodID] = {
              prodID: prodID,
              targetQty: prodQty,
              targetPrice: prodPrice,
              ProductCat: prodCat,
              ProductName: prodName
            };
          }
        });
      }

      // Convert the aggregatedReports object to an array of values
      const aggregatedReportsArray = Object.values(aggregatedReports);

      // Log the final aggregatedReportsArray for debugging
      console.log('Final aggregatedReportsArray:', aggregatedReportsArray);

      return {
        ...state,
        DispointTarget: action.payload.data,
        DisPointLoading: action.payload.isProductLoading,
        allItemReports: aggregatedReportsArray,
      };


    default:
      break;
  }
  return newState;
};

export default DeliveryReducer;

