import React from "react";
import DamageList from "../../../information/components/RemoteSales/DamageList";

const DamageListContainer = () => {
  return (
    <div className="container-fluid">
      <DamageList />
    </div>
  );
};

export default DamageListContainer;
