import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { GetAllProduct } from "../../_redux/actions/Dashboard/DashboardAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const PieChart = () => {
  const dispatch = useDispatch();
const PieCategory = useSelector((state) => state.dashboard.PieCategory);
  const totalCategory = useSelector((state) => state.dashboard.totalCategory);

  const isLoading = useSelector((state) => state.dashboard.piechartLoading);

  useEffect(() => {
    dispatch(GetAllProduct());
  }, []);
  const options = {
    chart: {
      width: 200,
      type: "pie",
    },
    
    labels: ["Cookies", "Biscuit", "Cake", "Wafer"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
          
        },
      },
    ],
  };

  const seriesData = PieCategory;

  return (
    <div className="pie_chart_card">
      <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
      
        <>
          {!isLoading && (
            <>
              <h3 className="font-weight-bolder">
                Secondary Sales:{" "}
                <span className="totalCategorytext"> {totalCategory !=undefined?totalCategory?.toLocaleString("en-IN"):0}</span>{" "}
              </h3>
              <div id="chart">
                <ReactApexChart
                  options={options}
                  series={seriesData}
                  type="pie"
                  height="210"
                />
              </div>
            </>
          )}
        </>
      
    </div>
  );
};


export default PieChart;
