import React from "react";
import SmsSystem from "./SmsSystem";


const SmsSystemContainer = () => {
  return (
    <div className="container-fluid">
        <SmsSystem />
    </div>
  );
};
export default SmsSystemContainer;
