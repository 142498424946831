import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetIMSMenuTrackingList } from '../../../information/_redux/actions/Report/ReportAction';
import { format } from 'date-fns';
import { Form, Button, Col } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import DotLoading from '../../../../master/spinner/DotLoading';
import { Bar } from 'react-chartjs-2';  // Import Bar chart component
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Registering Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const IMSActiveUser = () => {
  const dispatch = useDispatch();

  // Get data from Redux
  const hourWiseUser = useSelector((state) => state.target.hourWiseUser) || [];  // Default to an empty array if undefined
  const menuTrackLoading = useSelector((state) => state.target.menuTrackLoading);

  const [empEnroll, setEmpEnroll] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [userType, setUserType] = useState('SO'); // Default userType is 'SO'

  // Initialize the start date on component mount
  useEffect(() => {
    const d = new Date();
    const currentDay = format(d, 'yyyy-MM-dd');
    setStartDate(currentDay); // Set today's date as the default start date

    // Fetch SO data initially
    dispatch(GetIMSMenuTrackingList(currentDay, '', 'SO'));
  }, [dispatch]);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value); // Update the start date when the user changes it
  };

  const showReport = () => {
    dispatch(GetIMSMenuTrackingList(startDate, empEnroll, userType)); // Fetch data for the selected date and user type
  };

  // Chart.js Data
  const chartData = {
    labels: hourWiseUser.length > 0 ? hourWiseUser.map(item => item.hourString) : [],  // Hours as labels, empty if no data
    datasets: [
      {
        label: 'User Activity by Hour', // Dataset label
        data: hourWiseUser.length > 0 ? hourWiseUser.map(item => item.count) : [],  // User count as data points, empty if no data
        backgroundColor: 'rgba(75, 192, 192, 0.7)',  // Bar color (with opacity)
        borderColor: 'rgba(75, 192, 192, 1)',  // Bar border color
        borderWidth: 1,
      }
    ]
  };

  return (
    <div className="card fullcardbg m-3">
      {/* Form and Inputs */}
      <div className="p-3 single-sms">
        <div className="row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridDate">
              <label className="form-label">Date : </label>
              <Form.Control
                type="date"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          {/* <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridEmployeeEnroll">
              <label className="form-label">Enroll : </label>
              <Form.Control
                type="number"
                placeholder="Enter Employee Enroll"
                name="empEnroll"
                value={empEnroll}
                style={{ height: '31px' }}
                onChange={(e) => setEmpEnroll(e.target.value)}
              />
            </Form.Group>
          </div> */}

          <div className="col-md-2">
            <Button
              size="sm"
              variant="primary"
              onClick={showReport}
              className="btn-sm btn mt-6"
              style={{ height: '31px' }}
            >
              <FaSearch /> Show Report
            </Button>
          </div>
        </div>
      </div>

      {/* Activity View */}
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className='row'>
            <div className='col-lg-2'>
              <div className="table-responsive pl-5 pr-5 pb-5 text-center">
                {menuTrackLoading ? (
                  <DotLoading />
                ) : (
                  <table id="table-to-xls" className="printtablenew1">
                    <thead>
                      <tr className="backcolor">
                        <th className="text-center">SL</th>
                        <th className="text-center">Tracking Time</th>
                        <th className="text-center">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hourWiseUser.length > 0 ? (
                        hourWiseUser.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.hourString}</td>
                            <td className="text-center">{item.count}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <div className='col-lg-10'>
              <div className="table-responsive pl-5 pr-5 pb-5">
                {menuTrackLoading ? (
                  <DotLoading />
                ) : (
                  <Bar
                    data={chartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                          display: false,
                        }
                      },
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: 'Hour of Day',
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: 'User Count',
                          },
                          beginAtZero: true,
                        }
                      },
                      maintainAspectRatio: false,
                    }}
                    style={{ height: '400px', width: '100%' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IMSActiveUser;
