import axios from "axios";
import * as Types from "../../types/DashboardTypes";

export const GetTopCard = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_TOPCARD_DATA, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetIMSTARGETAChivement`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOPCARD_DATA, payload: responseList });
};

export const GetAllProduct = () => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_ALL_PRODUCT_DATA, payload: responseList });
    try {
      let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSecondarySalesProductCategory`;
    await axios.get(url).then((res) => {
      responseList.data = res.data.data;
      responseList.status = false;
    });

    } catch (error) {
      // responseList.status = false;
    }
    dispatch({ type: Types.GET_ALL_PRODUCT_DATA, payload: responseList });
    
  };
