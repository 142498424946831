import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  delteProductList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getSubUpdateProductList, subsalesUpdate, SuperDepoproductUpdateInputQuantity } from "../../_redux/actions/RemoteSales/SuperDepoAction";

const SubSalesEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();


  const { id, name, code, salesGroup, superDepo, superdist } = useParams();
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const location = useLocation();
  const productUpdateStatus = useSelector((state) => state.subDistri.productUpdateStatus);
  console.log('productUpdateStatus :>> ', productUpdateStatus);
  const [btndisabled, setbtnDisabled] = useState(null);
  const salesUpdateData = useSelector(
    (state) => state.subDistri.salesUpdateData
  );

  console.log('salesUpdateData', salesUpdateData);

  // Redirect List Page after submitting data
  useEffect(() => {
    if (productUpdateStatus) {

      setbtnDisabled(true)
      // setTimeout(() => { history.go(0) }, 3000);

    } else {
      setbtnDisabled(false)
    }
  }, [productUpdateStatus]);


  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    // dispatch(getDistributorList());
    dispatch(getSubUpdateProductList(id, salesGroup,superDepo,superdist));
    // dispatch(getCustomerBalance(id));
    // dispatch(getItemCategory())
  }, []);

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (item, name, value) => {
    dispatch(SuperDepoproductUpdateInputQuantity(item, name, value));
  };

  const onSubmit = async (e) => {
    // setDisabled(true);
    dispatch(subsalesUpdate(id,superDepo, superdist, salesUpdateData));
    // setDisabled(false);
  };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];
  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border p-3">
          <div className="mt-5">
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">{name}</strong>
                    <br />
                    {code}
                    <br />

                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row"></div>
        </div>



        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Stock Qty</th>
                  <th>Quantity</th>
                  <th>Free Qty</th>
                  <th>Damage Qty</th>

                </thead>
                <tbody>
                  {salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.stockQty}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.itemQuentity}
                            onChange={(e) =>
                              changeProductQuantity(item, 'itemQuentity', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="bonusQty"
                            value={item.bonusQty}
                            onChange={(e) =>
                              changeProductQuantity(item, 'bonusQty', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="damageQty"
                            value={item.damageQty}
                            onChange={(e) =>
                              changeProductQuantity(item, 'damageQty', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                   disabled={btndisabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/remoteSales/list";
                  }}
                  type="button"
                
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SubSalesEdit;
