import React from "react";
import SecondaryCumalative from "../../../information/components/SecodarySales/SecondaryCumalative";


const SecondaryCumalativeContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondaryCumalative/>
      </div>
    </div>
  );
};

export default SecondaryCumalativeContainer;
