import React, { useEffect, useState, useRef } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { AnualSalesSinceInception } from "../Chart/AnualSalesSinceInception";
import { DamageChart } from "../Chart/DamageChart";
import DoughnutChartLocationWIseSales from "../Chart/DoughnutChartLocationWIseSales";
import { SecondarySalesTrending } from "../Chart/SecondarySalesTrending";
import { SecondarySalesTrendingPercentageWise } from "../Chart/SecondarySalesTrendingPercentageWise";
import { CumulativeRevenueLast12Months } from "./CumulativeRevenueLast12Months";
import DashboardInput from "./DashboardInput";
import Money from './Money'
import Money2 from './Money2'
import { PerDayTargetDashboard } from './PerDayTargetDashboard'
import VerticalProgress from './VerticalProgress'
import VerticalProgress2 from './VerticalProgress2'
import { FaAngleDoubleDown } from 'react-icons/fa';
import Axios from "axios";
import DotLoading from "../../../../master/spinner/DotLoading";
import NetworkError from "../../../../../_metronic/_partials/dashboards/NetworkError";
import ProductIMG from "../../../../../_metronic/_partials/dashboards/ProductIMG";
import TopSellingSku from "../../../../../_metronic/_partials/dashboards/TopSellingSku";
import ImsFocusProduct from "../../../../../_metronic/_partials/dashboards/ImsFocusProduct";


const DashboardDemo = () => {
    const reportData = useSelector((state) => state.dashboardcart.reportData);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [itemDetailShow3, setItemDetailShow3] = useState(false);
    const [apiData, setApiData] = useState({ status: true });
    const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);

    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const previousPrice = () => {
        setItemDetailShow3(true);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (apiData && apiData.status === true) {
            setItemDetailShow3(true);
        }
    }, [apiData]);

    const fetchData = async () => {
        try {
            const response = await Axios.get("/api/data");
            setApiData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const menuList = useSelector((state) => state.menu.menuList);
    const [showNetworkError, setShowNetworkError] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (menuList.length <= 0) {
                setShowNetworkError(true);
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [menuList.length]);


    return (
        <>
            <div className="container-fluid">
                {showNetworkError && (
                    <NetworkError
                        isVisible={true}
                        onClose={() => setShowNetworkError(false)}
                        title="Request Error"
                    >
                        <p>Menu list not found...! Login again....</p>
                    </NetworkError>
                )}
                
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                        <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                            <div className="top_card3 d-flex justify-content-center align-items-center text-center">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey="1"
                                        className="arrayButton"
                                        onClick={handleAccordionToggle}
                                    >
                                        <div>
                                            <div className="row justify-content-center">
                                                <div className="col">
                                                    <div className="dailysales dailysalesmargin dailysalesfont">
                                                        Sales Performance Dashboard
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <FaAngleDoubleDown className="float-right toparrow blink-text" />
                                                <span className="clickHere float-right">
                                                    {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                                                </span>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="1">
                                        <div className="col-lg-12 col-md-12 col-sm-12 paddingremove text-left">
                                            <div className="row text-left">
                                                <div className="col text-left">
                                                    <DashboardInput />
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                        <PerDayTargetDashboard />
                    </div>
                    <div className="col-lg-1 col-md-12 col-sm-12 paddingremove">
                        <div className="vertical_bar" style={{ height: isLoading ? '280px' : 'auto' }}>
                            <span className="spineercolor mt-5 mb-5">{isLoading && <DotLoading />}</span>
                            <>
                                {!isLoading && (
                                    <>
                                        <VerticalProgress />
                                        <div className="vertical_bar_bottom1 vertical_bar_bottom_color_1">
                                            MTD-Primary Sales
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12 primary paddingremove ">
                        <Money />
                    </div>
                    <div className="col-lg-1 paddingremove">
                        <div className="vertical_bar2" style={{ height: isLoading ? '280px' : 'auto' }}>
                            <span className="spineercolor mt-5 mb-5">{isLoading && <DotLoading />}</span>
                            <>
                                {!isLoading && (
                                    <>
                                        <VerticalProgress2 />
                                        <div className="vertical_bar_bottom2 vertical_bar_bottom_color_2" >
                                            MTD-Secondary Sales
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12 primary paddingremove">
                        <Money2 />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                        <div className="top_card2"></div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    <SecondarySalesTrending />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    <SecondarySalesTrendingPercentageWise />
                </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    <CumulativeRevenueLast12Months />
                </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    < PolarAreaChart />
                </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    <DamageChart />
                </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    <AnualSalesSinceInception />
                </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove marginremove">
                    < DoughnutChartLocationWIseSales />
                </div> */}


                </div>
            </div>
            {/* <div className="container-fluid mb-6 "><ProductIMG /></div> */}
            <div className="container-fluid"><TopSellingSku /></div>
            <div className="container-fluid" ><ImsFocusProduct /></div>
            

            {/* <DashboardModal
        size="xl"
        show={itemDetailShow3}
        handleClose={() => setItemDetailShow3(false)}
        handleShow={() => setItemDetailShow3(true)}
      >
        <ModalIMS handleClose={() => setItemDetailShow3(false)} />
      </DashboardModal> */}
        </>
    );
};

export default DashboardDemo;
