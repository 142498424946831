import React from "react";
import UserEdit from "../components/UserEdit";

export default function EditUserContainer() {
  return (
    <div>
      <UserEdit />
    </div>
  );
}
