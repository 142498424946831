import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
    getAreaListByDivisionEntry,
    getDivisionListByZoneEntry,
    getdropdownproductList,
    getTerritoryEntry,
    getZoneListEntry,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
    cleanSecondarySalesData,
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getPrimaryDemandStatus } from "../../_redux/actions/Delivery/DeliveryAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const PrimaryDemandStatus = () => {

    const isProductLoading = useSelector((state) => state.delivery.skuLoading);
    const productReport = useSelector((state) => state.delivery.PDemandStatus);

    const ApQty = useSelector((state) => state.delivery.aprvQty);
    const AprvValue = useSelector((state) => state.delivery.aprvValue);
    const DemandQty = useSelector((state) => state.delivery.demandQty);
    const demandValue = useSelector((state) => state.delivery.demandValue);

    const [dropdownProduct, setdropdownProduct] = useState([]);
    const [zone, Setzone] = useState([]);

    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(1);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [ProductId, setProductId] = useState(0);
    const componentRef = useRef();

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    const { register, setValue } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        initialData();
    }, []);


    const initialData = async () => {
       
        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);


        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);

        dispatch(cleanSecondarySalesData());
        let productData = await getdropdownproductList(user.employeeType);
        setdropdownProduct(productData.data);

    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const inputField = React.useRef(null);



    const getSalesValueType = async (name, value) => {

        setSalesType(value);
        let productData = await getdropdownproductList(value);
        setdropdownProduct(productData?.data);
    }

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleListAction = () => {
        dispatch(getPrimaryDemandStatus(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, ProductId));
    }

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);

    const [selectTerritory, setSelectTerritory] = useState(null);

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const productIDDropdownData = () => {
        let options = [];
        if (dropdownProduct?.length > 0) {
            dropdownProduct.forEach((item) => {
                let itemData = {
                    value: item.itemID,
                    label: item.itemName + " " + item.itemID,
                };

                options.push(itemData);
            });
            console.log('options', options);
        }
        return options;
    };

    return (
        <>
            <div className="card card-custom pl-5 pr-5 mb-5 card-top-border">

                <div className="mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <span className="button-010">Primary Demand Status</span>
                                {/* <h3 className="mb-0 pb-0">Primary Demand Status</h3> */}
                            </div>
                        </div>
                        <SimpleSalesType
                            distribType={distribType}
                            salesType={salesType}
                            getSalesValue={getSalesValueType}
                        />
                    </div>
                    <div className="float-right ">
                        <DownloadDropdown excelname='Primary Demand Status' />
                    </div>
                </div>


                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option);
                                    setdefultDivision(null);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option);
                                    setdefultTerritory(null);
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0 ml-4">
                        <label className="form-label">Select Territory</label>
                        <Select
                            options={territoryDropdownData()}
                            value={defultTerritory}
                            onChange={(option) => {
                                setSelectTerritory(option?.value);
                                setdefultTerritory(option)
                            }}
                            defaultValue={defultTerritory}
                        />
                    </div>

                    <div className="col-md">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Product Id</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={productIDDropdownData()} />}
                                rules={{ required: false }}
                                name="productData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setProductId(option?.value)

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>



                    <div className="col-md mt-6 mb-5">
                        <button className="button-621" onClick={() => handleListAction()} >  Show Report</button>
                        {/* <Button size="sm" onClick={() => handleListAction()}>Show Report</Button> */}
                    </div>

                </div>
                <div>
                    {productReport?.length > 0 &&

                        <div className="container-fluid mb-3 mt-3 containerFixed">
                            <div className="row">

                                <div className="col-xl-3 col-lg-3 col-md-3">
                                    <div className="paid__widget two">
                                        <div className="widget__left">


                                            <p className="balanceleft">Demand Quantity</p>
                                        </div>
                                        <div className="widget__right">

                                            <div className="overlay-layer bg-transparent">
                                            </div>
                                            <div>
                                                <h4 className="balanceleft">{parseInt(DemandQty)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3">
                                    <div className="paid__widget one">
                                        <div className="widget__left" >
                                            <p className="balanceleft">Approved Quantity</p>
                                        </div>
                                        <div className="widget__right">
                                            <div>
                                                <h4 className="balanceleft">{parseInt(ApQty)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3">
                                    <div className="paid__widget two">
                                        <div className="widget__left">


                                            <p className="balanceleft">Demand Value</p>
                                        </div>
                                        <div className="widget__right">

                                            <div className="overlay-layer bg-transparent">
                                            </div>
                                            <div>
                                                <h4 className="balanceleft">৳ {parseFloat(demandValue).toFixed(2)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3">
                                    <div className="paid__widget one">
                                        <div className="widget__left" >
                                            <p className="balanceleft">Approved Value</p>
                                        </div>
                                        <div className="widget__right">
                                            <div>
                                                <h4 className="balanceleft">৳ {parseFloat(AprvValue).toFixed(2)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <div className="row" id="id" ref={inputField}>
                        {/* <table className="table mt-5 voyageTable table-responsive"> */}
                        {isProductLoading && <LoadingSpinner text="Loading Items List..." />}

                        <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
                            <div
                                className="stickyTable"
                                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
                            >

                                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                                    <thead className="bg-white" >

                                        <tr >
                                            <th scope="col">ZONE</th>
                                            <th scope="col">DIVISION</th>
                                            <th scope="col">REGION</th>
                                            <th scope="col">TERRITORY</th>
                                            <th scope="col">PRODUCT ID</th>
                                            <th scope="col">PRODUCT NAME</th>
                                            <th scope="col">PRODUCT PIC PRICE</th>
                                            <th scope="col">DEMAND QTY</th>
                                            <th scope="col">DEMAND PRICE</th>
                                            <th scope="col">APPROVED QTY</th>
                                            <th scope="col">APPROVED PRICE</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productReport?.length > 0 &&
                                            productReport?.map((item, index) => (
                                                <>
                                                    <tr className="alert bg-secondary">

                                                        <td>{item.zonename}</td>
                                                        <td>{item.divisionname}</td>
                                                        <td>{item.areaname}</td>
                                                        <td>{item.territoryName}</td>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td>{item.totalDemandQty}</td>
                                                        <td>{parseFloat(item.totalDemandValue).toFixed(2)}</td>
                                                        <td>{item.totalDemandApQty}</td>
                                                        <td>{parseFloat(item.totalDemandAprvValue).toFixed(2)}</td>
                                                    </tr>
                                                    {
                                                        item?.itemReport.map((product, subindex) => (
                                                            <tr>
                                                                <td>{item.zonename}</td>
                                                                <td>{item.divisionname}</td>
                                                                <td>{item.areaname}</td>
                                                                <td>{item.territoryName}</td>
                                                                <td>{product.productID}</td>
                                                                <td>{product.productName}</td>
                                                                <td>{parseFloat(product.productPicPrice).toFixed(2)}</td>
                                                                <td>{product.demandQty}</td>
                                                                <td>{parseFloat(product.demandTPrice).toFixed(2)}</td>
                                                                <td>{product.approvedQty}</td>
                                                                <td>{parseFloat(product.approvedTPrice).toFixed(2)}</td>

                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            ))}


                                    </tbody>
                                </table>

                            </div>
                            {!isProductLoading && productReport.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! List Not Found.
                                </div>
                            )}

                        </div>
                       

                    </div>

                </div>




         
            </div>
        </>
    );
};

export default PrimaryDemandStatus;
