import React from "react";
import AreaAdd from "../information/components/AreaAdd";

const AreaAddContainer = () => {
  return (
    <div className="container">
      <div className="">
        <AreaAdd />
      </div>
    </div>
  );
};

export default AreaAddContainer;
