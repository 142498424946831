import React from "react";
import GiftList from "../../../information/components/RemoteSales/GiftList";




const GiftListContainer = () => {
  return (
    <div className="container-fluid">
      <GiftList />
    </div>
  );
};

export default GiftListContainer;