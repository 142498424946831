import React from "react";
import SecondarySalesCumalitiveBYSo from "../../../information/components/SecodarySales/SecondarySalesCumalitiveBYSo";



const SecondarySalesCumaSoWiseContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySalesCumalitiveBYSo/>
      </div>
    </div>
  );
};

export default SecondarySalesCumaSoWiseContainer;
