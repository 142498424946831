import React from "react";
import RegionList from "../../../information/components/Region/RegionList";


const RegionListContainer = () => {
  return (
    <div className="container">

     <RegionList/>

    </div>
  );
};

export default RegionListContainer;
