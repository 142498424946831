import React from "react";
import SecondarySalesPivotList from "../../../../information/components/RemoteSales/secondarySalesPivot/SecondarySalesPivotList";

const SecondarySalesPivotContainer = () => {
  return (
    <div className="container-fluid">
      <SecondarySalesPivotList />
    </div>
  );
};

export default SecondarySalesPivotContainer;
