import React from "react";
import CorporateSalesAddForMan from "../../../information/components/RemoteSales/CorporateSalesAddForMan";

const CorporateSalesContainerForMan = () => {
  return (
    <div className="container-fluid">
      <div className="">
        < CorporateSalesAddForMan/>
      </div>
    </div>
  );
};
export default CorporateSalesContainerForMan;
