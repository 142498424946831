import * as Types from "../../types/Types";

const initialState = {
    isProductLoading: false,
    promotionalListDetails:[] ,
    primaryLoading:false
};
const PromotionalReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    
    case Types.GET_PROMOTIONAL_LIST_DETAILS:
        return {
          ...state,
          promotionalListDetails: action.payload.data,
        //   approvedLoading: action.payload.approvedLoading,
        };
  

    default:
      break;
  }
  return newState;
};

export default PromotionalReducer;
