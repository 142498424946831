import React from "react";

import UserList from "../components/UserList";

export default function UserListContainer() {
  return (
    <div>
      <UserList />
      {/* <BasicInfoAdd /> */}
    </div>
  );
}
