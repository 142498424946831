import React from "react";
import ZoneAdd from "../../../information/components/Zone/ZoneAdd";


const ZoneAddContainer = () => {
  return (
    <div className="container">
      <div className="">
        <ZoneAdd />
      </div>
    </div>
  );
};

export default ZoneAddContainer;
