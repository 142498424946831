import React from "react";
import RolePermissionList from "../components/RolePermissionList";

export default function RolePermissionListContainer() {
  return (
    <div>
      <RolePermissionList />
    </div>
  );
}
