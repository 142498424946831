import React from "react";
import SummerySheetReport from "./SummerySheetReport";

const SummerySheetReportContainer = () => {
  return (
    <div className="container-fluid">
      <SummerySheetReport />
    </div>
  );
};

export default SummerySheetReportContainer;
