import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { setBottom3DSMReports } from '../../../information/_redux/actions/Report/ReportAction';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};


const Bottom3DSM = () => {

    const reportDataLoading = useSelector((state) => state.target.zoneReportsLoading);
    const reportData = useSelector((state) => state.target.zoneReports);
    const bottom3DSMReports = useSelector((state) => state.target.bottom3DSMReports);
    const divisionReportsLoading = useSelector((state) => state.target.divisionReportsLoading);
    const areaReportsLoading = useSelector((state) => state.target.areaReportsLoading);
    const territoryReportsLoading = useSelector((state) => state.target.territoryReportsLoading);
    const TotalTarget = useSelector((state) => state.target.TotalTarget);

    const totalDays = useSelector((state) => state.target.totalDays);
    const workingLeft = useSelector((state) => state.target.workingLeft);

    const componentRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBottom3DSMReports(3));
    }, [])

    const needIms = ((TotalTarget / totalDays) * (workingLeft / TotalTarget)) * 100;

    return (
        <div className='card fullcardbg m-3' style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
        }}>

            <div className="row mt-7" ref={componentRef}>

                <div className='col-lg-12'>
                    {reportDataLoading && <NewLoader2 />}
                    {divisionReportsLoading && <NewLoader2 />}
                    {areaReportsLoading && <NewLoader2 />}
                    {territoryReportsLoading && <NewLoader2 />}

                    <div className="table-responsive pl-5 pr-5 pb-5 " ref={componentRef}>
                        <table id="table-to-xls" className="printtablenew1">

                            {reportData?.length > 0 &&
                                <thead >
                                    <tr className="backcolor1">
                                        <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>BOTTOM 3 DSM</th>
                                    </tr>
                                    <tr >
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS:&nbsp;&nbsp;{totalDays}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS PASS:&nbsp;&nbsp;{workingLeft}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>NEED IMS:&nbsp;&nbsp;{parseFloat(needIms).toFixed(2)}% </th>
                                    </tr>

                                </thead>
                            }
                            <tbody>
                                <>


                                    {bottom3DSMReports?.length > 0 && <>

                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th>Division</th>
                                            <th>DSM Name</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">Previous Month IMS</th>
                                            <th className="text-center">Current Month IMS</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr></>}


                                    {bottom3DSMReports?.length > 0 &&
                                        bottom3DSMReports
                                            .sort((a, b) => b.rank - a.rank)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{item.zone_name}</td>
                                                        <td>{item.nsmName} ({item.nsmID})</td>
                                                        <td>{isNaN(parseFloat(item.target)) ? '0.00' : parseFloat(item.target).toFixed(2)}</td>
                                                        <td>{parseFloat(item.beforeMonth).toFixed(2)}</td>
                                                        <td>{parseFloat(item.sales).toFixed(2)}</td>
                                                        <td className="text-center">
                                                            <span style={{
                                                                color: parseFloat(item.performance) >= needIms ?
                                                                    parseFloat(item.performance).toFixed(2) === '0.00' ? '#f1420d' : '#1c8b5d' :
                                                                    '#f1420d'
                                                            }}>
                                                                {parseFloat(item.performance).toFixed(2)}%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {parseFloat((item.performance * totalDays) / workingLeft).toFixed(2)}%
                                                        </td>
                                                        <td className="text-center">
                                                            {item.soQty}
                                                        </td>
                                                        <td className="text-center">
                                                            <span style={{
                                                                color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                                    parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) === '0.00' ? '#f1420d' : '#1c8b5d' :
                                                                    '#f1420d'
                                                            }}>
                                                                {parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)}%
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {parseFloat((item.sales / item.soQty) / workingLeft).toFixed(2)}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.rank}
                                                        </td>
                                                    </tr>
                                                );
                                            })}


                                </>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bottom3DSM

