import React from 'react'

const NewLoadind = () => {
    return (
        <div className='loadposition'>
            <div class="newloading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default NewLoadind