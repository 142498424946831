import React from "react";
import PromotionalItem from "./PromotionalItem";



const PromotionalItemContainer = () => {
  return (
    <div className="container-fluid">
        < PromotionalItem/>
    </div>
  );
};
export default PromotionalItemContainer;
