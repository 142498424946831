export const GET_USER_ROLE_LIST="GET_USER_ROLE_LIST";
export const USER_ROLE_CHECKED="USER_ROLE_CHECKED";
export const USER_ROLE_ALL_CHECKED="USER_ROLE_ALL_CHECKED";
export const USER_ROLE_CHECKED_GROUP="USER_ROLE_CHECKED_GROUP";
export const GET_USER_ROLE_POST="GET_USER_ROLE_POST";
export const USER_ROLE_HANDLE_CHANGE="USER_ROLE_HANDLE_CHANGE";
export const GET_USER_PERMISSION_GROUPS="GET_USER_PERMISSION_GROUPS";
export const GET_USER_ROLE_LIST_PAGINATED="GET_USER_ROLE_LIST_PAGINATED";
export const CHANGE_ROLE_INPUT="CHANGE_ROLE_INPUT";
export const CREATE_ROLE="CREATE_ROLE";
export const CREATE_MULTIPLE_ROLE="CREATE_MULTIPLE_ROLE";
export const UPDATE_MULTIPLE_ROLE="UPDATE_MULTIPLE_ROLE";
export const EMPTY_ROLE_STATUS="EMPTY_ROLE_STATUS";
export const GET_ROLE_DETAILS_DATA="GET_ROLE_DETAILS_DATA";
export const GET_PERMISSION_USER_LIST="GET_PERMISSION_USER_LIST";
export const GET_USER_ROLE_LIST_DATA="GET_USER_ROLE_LIST_DATA";
export const GET_USER_ROLE_INPUT_DATA="GET_USER_ROLE_INPUT_DATA";
export const GET_USER_CREATED="GET_USER_CREATED";
export const GET_USER_DETAILS="GET_USER_DETAILS";
export const GET_USER_ROLE_TYPE="GET_USER_ROLE_TYPE";
