import React from "react";
import RemoteSalesEdit from "../../../information/components/RemoteSales/RemoteSalesEdit";




const RemoteSalesEditContainer = () => {
  return (
    <div className="container">
      <RemoteSalesEdit />
    </div>
  );
};

export default RemoteSalesEditContainer;
