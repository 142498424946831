import React from "react";
import TradeDemandEntry from "../../../information/components/RemoteSales/TradeDemandEntry";

const TradeDemandEntryContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TradeDemandEntry />
      </div>
    </div>
  );
};

export default TradeDemandEntryContainer;