import { indexOf } from "lodash";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

const initialState = {
  isLoading: false,
  zoneSubmitStatus: false,
  productUpdateStatus: false,
  productSubmitStatus: false,
  submitsecondaryStatus: false,
  restBalance: 0,
  salesDetails: [],
  productQuantity: 0,
  totalSalesDetailsAmount: null,
  totalDetQuantity: null,
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  salesUpdateData: [],
  submitData: {
    intCustomer: 0,
    monCustBalance: 0,
    coaid: 0,
    intsalesofficeid: 0,
    DistribPointID: 0,
    DelPointID: 0,
    supperDistribId: 0,
  },
  balanceLoader: false,
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
  productCost: 0,
  productList: [],
  filterProduct: [],
  isLoggedIn: false,
  selectedProduct: [],
  availableBalance: 0,
  creditLimit: 0,
  demandlist: [],
  productDelivery: [],
  customerDeliveryProd: [],
  isPProductLoading: false,
  isDeDetails: false,
  salesEditInfo: null,
  submitLoading: false,
  salesApprovedList: [],
  salesApprovedDetails: [],
  approvedLoading: false,
  subDistributor: [],
  superdistributor: [],
};
const SubDepoReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_PRODUCT_INPUT:
      const itemDataInput = { ...state.itemDataInput };
      itemDataInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInput,
      };
    case Types.GET_DISTRIBUTOR_LIST:
      return {
        ...state,
        distributorList: getDistributor(action.payload.data),
      };
    case Types.ZONE_SAVE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        zoneSubmitStatus: true,
      };
    case Types.GET_SUB_SALES_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload.data == null ? [] : action.payload.data,
      };
    case Types.GET_SALES_APPROVED_SALES_REPORT:
      return {
        ...state,
        salesApprovedList: action.payload.data == null ? [] : action.payload.data,
      };
    case Types.GET_SALES_APPROVED_DETAILS:
      return {
        ...state,
        salesApprovedDetails: action.payload.data,
        approvedLoading: action.payload.approvedLoading,
      };
    case Types.GET_SUB_SALES_PRODUCT_DEMAND_LIST:
      return {
        ...state,
        demandlist: action.payload.data,
      };
    case Types.GET_CUSTOMER_PRODUCT_DELIVERY:
      return {
        ...state,
        productDelivery: action.payload.data,
        isPProductLoading: action.payload.isPProductLoading,
      };
    case Types.GET_PRODUCT_SELECTED:
      const productInput = { ...state.itemDataInput };
      productInput.itemName = "";
      const fetchProduct = state.selectedProduct;
      fetchProduct.push(action.payload);
      return {
        ...state,
        selectedProduct: fetchProduct,
        filterProduct: [],
        itemDataInput: productInput,
      };
    case Types.GET_PRODUCT_DELETE:
      const delteData = state.selectedProduct;
      delteData.splice(action.payload, 1);
      return {
        ...state,
        selectedProduct: delteData,
      };
    case Types.SALES_PRODUCT_SUBMIT:
      return {
        ...state,
        submitLoading: false,
        productSubmitStatus: action.payload.status,
        availableBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        submitsecondaryStatus: action.payload.status,
      };
    case Types.SALES_PRODUCT_UPDATE:
      return {
        ...state,
        productUpdateStatus: action.payload.status,
      };
    case Types.GET_SALES_LIST_EDIT_DATA:
      return {
        ...state,
        salesEditInfo: action.payload,
      };
    case Types.GET_SUB_SUPER_DEPO:

      return {
        ...state,
        subDistributor: getSubdistributor(action.payload?.data),

      };
    case Types.GET_SUPER_DISTRIBUTOR:

      return {
        ...state,
        superdistributor: getSuperdistributor(action.payload?.data),

      };
    case Types.PRODUCT_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        productSubmitStatus: false,
      };
    case Types.GET_SUB_SALES_ORDER_DETAILS:
      let totalSum = 0;
      let totalQuantity = 0;

      for (let i = 0; i < action.payload.data.length; i++) {
        totalSum += action.payload.data[i].totalPrice;
        totalQuantity += action.payload.data[i].itemQuentity;
      }
      return {
        ...state,
        salesDetails: action.payload.data,
        totalSalesDetailsAmount: totalSum,
        totalDetQuantity: totalQuantity,
      };
    case Types.GET_CUSTOMER_PROD_DETAILS:
      // let totalSum = 0;
      // let totalQuantity = 0;

      // for (let i = 0; i < action.payload.data.length; i++) {
      //   totalSum += action.payload.data[i].totalPrice;
      //   totalQuantity += action.payload.data[i].itemQuentity;
      // }
      return {
        ...state,
        customerDeliveryProd: action.payload.data,
        isDeDetails: action.payload.isDeDetails,
        // totalSalesDetailsAmount: totalSum,
        // totalDetQuantity: totalQuantity,
      };
    case Types.GET_SUB_SALES_ORDER_UPDATE_DETAILS:
      return {
        ...state,
        salesUpdateData: action.payload.data,
      };
    case Types.CLEAR_REMOTE_SALES_DATA:
      return {
        ...state,
        availableBalance: 0,
        restBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        salesApprovedList: [],
        demandlist: [],
        // productSubmitStatus:false

      };
    case Types.DELETE_SALES_TITEM:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.GET_CUSTOMER_BALANCE:
      let submitData = { ...state.submitData };
      submitData.intCustomer = action.payload.data.cusid;
      submitData.monCustBalance = action.payload.data.availableBalance;
      submitData.coaid = action.payload.data.intcoaid;
      submitData.intsalesofficeid = action.payload.data.intsalesoffid;
      submitData.disPointID = action.payload.data.disPointID;
      submitData.delPointID = action.payload.data.delPointID;
      submitData.distribCat = action.payload.data.distribCat;
      submitData.supperDistribId = action.payload.data.distribCat;

      return {
        ...state,
        availableBalance: action.payload.data.availableBalance,
        creditLimit: action.payload.data.moncreditlimit,
        balanceLoader: action.payload.isLoading,
        restBalance: action.payload.data.availableBalance,
        submitData,
      };
    case Types.CHANGE_SUB_PRODUCT_QUANTITY:

      let quantityvalue = parseInt(action.payload.value);

      console.log('quantityvalue', quantityvalue);

      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const product = state.productList.slice();
      const avaiblebalance = state.availableBalance;
      let sumItem = 0;
      let restBalance = 0;
      let rest;
      let productQuantity = 0;
      for (let i = 0; i < product.length; i++) {
        if (product[i].itemID === action.payload.item.itemID) {
          product[i][action.payload.name] = quantityvalue;
          product[i].salesGroupId = action.payload.item.salesGroupId;
          product[i].totalPrice =
            product[i].itemQuentity * product[i].itemPrice;

          if ((product[i].itemQuentity + product[i].bonusQty + product[i].damageQty) > product[i].stockQty) {
            showToast("error", "Item quantity is grater than stock quantity");
          }
        }
        sumItem += product[i].itemQuentity * product[i].itemPrice;
        restBalance = avaiblebalance - sumItem;
        productQuantity += product[i].itemQuentity;
      }
      return {
        ...state,
        productList: product,
        productCost: sumItem,
        productQuantity: productQuantity,
        restBalance,
        // salesUpdateData:product
        // itemDataInput:productClone
      };
    case Types.CHANGE_PRODUCT_UPDATE_QUANTITY:

      let quantityUpdatevalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const productUpdate = state.salesUpdateData.slice();

console.log('productUpdate :>> ', productUpdate);
      let sumItemUpdate = 0;
      for (let i = 0; i < productUpdate.length; i++) {
        if (productUpdate[i].itemID === action.payload.item.itemID) {
          productUpdate[i][action.payload.name] = quantityUpdatevalue;
          productUpdate[i].totalPrice =
            productUpdate[i].itemQuentity * productUpdate[i].itemPrice;
            if ((productUpdate[i].itemQuentity + productUpdate[i].bonusQty + productUpdate[i].damageQty) > productUpdate[i].stockQty) {
              showToast("error", "Item quantity is grater than stock quantity");
            }
        }
       
        sumItemUpdate +=
          productUpdate[i].itemQuentity * productUpdate[i].itemPrice;
      }
      return {
        ...state,
        salesUpdateData: productUpdate,
      };
    default:
      break;
  }
  return newState;
};

export default SubDepoReducer;

const getDistributor = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.intcusid,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getSubdistributor = (data) => {
  console.log('data dropdown', data);
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item?.distribId,
        label: item?.distribName,
        superDis: item?.superDistribId,
        salesGroupId: item?.salesGroupId,
        distibPointId: item?.distibPointId,
        superDistibId: item?.superDistibId

      };
      options.push(itemData);
    });
  }
  return options;
};

const getSuperdistributor = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item?.distribId,
        label: item?.distribName,
        distibPointId: item?.distibPointId,
        distribCat: item?.distribCat

      };
      options.push(itemData);
    });
  }
  return options;
};
