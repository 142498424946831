import React from "react";
import DamageDemandApproveList from "../../information/components/RemoteSales/SalesApprovedReport/DamageDemandApproveList";




const DamageDemandApproveListContainer = () => {
  return (
    <div className="container-fluid">
      <DamageDemandApproveList />
    </div>
  );
};

export default DamageDemandApproveListContainer;
