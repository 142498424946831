import React from "react";
import Profile from "./Profile";


const ProfileContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <Profile />
      </div>
    </div>
  );
};
export default ProfileContainer;
