import React from "react";
import RolePermissionEdit from "../components/RolePermissionEdit";

export default function RolePermissionEditContainer() {
  return (
    <div>
      <RolePermissionEdit />
    </div>
  );
}
