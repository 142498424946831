import React from "react";
import SampleSalesOrderAdd from "../../../information/components/RemoteSales/SampleSalesOrderAdd";



const SampleSalesContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SampleSalesOrderAdd/>
      </div>
    </div>
  );
};
export default SampleSalesContainer;
