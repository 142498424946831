import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const DistributorReportsList = () => {
  const [show, setShow] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const modalEditStatus = useSelector(
    (state) => state.CertificateListReducer.editStatus
  );


  const dispatch = useDispatch();
  const certificateMasterData = useSelector((state) => state.CertificateListReducer.certificateMasterList);
  const certificateMasterPaginatedData = useSelector((state) => state.CertificateListReducer.certificateMasterPaginatedData);
  const areaInfo = useSelector(
    (state) => state.disReport.selectArea
  );
  const distributorReportList = useSelector(
    (state) => state.disReport.distributorReport
  );
  const isLoading = useSelector(
    (state) => state.disReport.isLoading
  );
  const disBalance = useSelector(
    (state) => state.disReport.disBalance
  );
  const disTotalBalance = useSelector(
    (state) => state.disReport.disTotalBalance
  );

 

  

  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (editItem) => {
    setEditItem(editItem);
    setShow(true);
  };


  return (
    <>
  
     
        <>
        {isLoading && (
        <div className="mt-5">
          <LoadingSpinner text="Loading Blance " />
        </div>
      )}
      {!isLoading && distributorReportList.length === 0 && (
        <div className="alert alert-warning mt-5">
          Sorry ! No Balance Found.
        </div>
      )}
        {!isLoading && distributorReportList.length > 0 && (
          <>
          <div className="react-bootstrap-table table-responsive">
            <table className="table table table-head-custom table-vertical-center" id="table-to-xls">
              <thead>
                <tr>
                  <th scope="col">Distributor ID</th>
                  <th scope="col">Distributor Name</th>
                  <th scope="col">Opening Balance</th>
                  <th scope="col"> Daily Balance</th>
                  <th scope="col"> Total Balance</th>
                </tr>
              </thead>
              <tbody>

              {distributorReportList && distributorReportList.map((item, index) => (
                    <tr>
                      <td>{item.distributorID}</td>
                      <td>{item.distributorName}</td>
                      <td>{item.distribOpeningBalance}</td>
                      <td>{item.distrDailyBalance}</td>
                      <td>{item.disTotalBalance}</td>
                    </tr>
                 ))}
                   
                
              </tbody>
            </table>
          </div>
          <div className="alert alert-success mt-5 font-size-h6 text-right">
            <p>Daily Balance is  {disBalance}.</p>
            <p>Total Balance is  {disTotalBalance}.</p>
             
            </div>
        </>
           
            
        
           )}
          
        </>
     
    </>
  );
};

export default DistributorReportsList;
