import React from "react";
import DamageDemandlist from "../../information/components/RemoteSales/DamageDemandlist";



const DamageDemandlistContainer = () => {
  return (
    <div className="container-fluid">
      <DamageDemandlist />
    </div>
  );
};

export default DamageDemandlistContainer;
