import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams  } from "react-router-dom";
import {getFebUpdateProductList,salesFebUpdate,productFebUpdateInputQuantity} from "../../_redux/actions/SecondarySales/SecondaryListAction";

const SecondarySalesFebEdit = () => {
  const dispatch = useDispatch();
  const { id,name,code } = useParams();
  const { handleSubmit } = useForm();
  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled,setDisabled ]=useState(false);
  const salesUpdateData = useSelector((state) => state.secondaryFeb.salesUpdateData);
  
  useEffect(() => {
    if (productUpdateStatus) {
    }

  }, [productUpdateStatus]);

  useEffect(() => {
    dispatch(getFebUpdateProductList(id,name));
  }, []);

  const changeProductQuantity = (name, value) => {
    dispatch(productFebUpdateInputQuantity(name, value));
  };

  const onSubmit = async (e) => {
    setDisabled(true);
    dispatch(salesFebUpdate(id,name, salesUpdateData));
  
  };

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border p-3">
          <div className="mt-5">
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">{name}</strong>
                    <br />
                   {code}
                    <br />
                   
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row"></div>
        </div>
       

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.itemQuentityPic}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/remoteSales/list";
                  }}
                  type="button"
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SecondarySalesFebEdit;
