import React from "react";
import NewUser from "../components/NewUser";

export default function NewUserContainer() {
  return (
    <div>
      <NewUser />
    </div>
  );
}
