import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";

import {
    getAreaListByDivisionEntry,
    getDivisionListByZoneEntry,
    getTerritoryEntry,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { cleanTargetSalesData } from "../../_redux/actions/Report/ReportAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { getPrimaryLiftingAllZoneProductWise } from './../../_redux/actions/Delivery/DeliveryAction';
import NewLoader2 from './../../../../master/spinner/NewLoader2';

const TargetVsAchProductWise = () => {

    const isLoading = useSelector((state) => state.delivery.DisPointLoading);
    const allItemReports = useSelector((state) => state.delivery.allItemReports);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const componentRef = useRef();
    const [skuDate, setSkuDate] = useState({
        fromDate: null,
        fromTime: "00:00",
        todate: null,
        toTime: "23:59"
    })

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    const {setValue } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanTargetSalesData());
        initialData();
    }, []);

    const initialData = async () => {
    
        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }



    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };
                options.push(itemData);
            });
        }
        // setValue("divisionData", "");
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);

    const inputField = React.useRef(null);

    const handleChange = (e, name) => {
        const dateDetails = { ...skuDate };
        dateDetails[name] = e.target.value;
        setSkuDate(dateDetails);
    };


    const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
        let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
        let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    
        dispatch(getPrimaryLiftingAllZoneProductWise(conFromDate, conToDate, idZone, idDivision, idArea, idTerritory));
    }


    const style = {
        container: {
            'background': 'f7f7f7',
            // 'height':670,
            'overflowy': 'scroll'
        },
    }

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState('prodName');

    //For Sorting
    const handleSort = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortedColumn(column);
            setSortOrder("asc");
            setSearchTerm("");
        }
    };

    // Sorting logic
    const sortedList = useMemo(() => {
        if (!Array.isArray(allItemReports)) {
            return [];
        }

        const filteredList = allItemReports.filter((item) => {
            const itemNameLower = String(item.productName).toLowerCase();
            const searchTermLower = searchTerm.toLowerCase();
            return !searchTermLower || itemNameLower.includes(searchTermLower);
        });

        const sorted = filteredList.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
            } else {
                return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
            }
        });

        return sorted;
    }, [allItemReports, searchTerm, sortOrder, sortedColumn]);

    const customOrder = ['Snacks', 'Biscuit', 'Cookies', 'Cake', 'Wafer'];

    const sortedData = sortedList.sort((a, b) =>
        customOrder.indexOf(a.productCategoryName ? a.productCategoryName : a.ProductCat) - customOrder.indexOf(b.productCategoryName ? b.productCategoryName : b.ProductCat)
    );



    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-md-9">
                        <div className="mt-2 p-2">
                            <h3 className="mb-0 pb-0">PRIMARY TARGET VS ACHIVEMENT PRODUCT WISE</h3>
                        </div>
                    </div>


                    <div className="col-md-3">
                        {/* <Button size="sm" onClick={() => hanleExcelClick()}>
                            Download
                        </Button> */}
                        <DownloadDropdown excelname='Primary Lifting vs Achievement Confirm Date' />
                    </div>

                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={(e) => handleChange(e, 'fromDate')}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Time</label>
                            <Form.Control
                                type="time"
                                placeholder="Enter Deadweight"
                                name="fromTime"
                                value={`${skuDate.fromTime}`}
                                className="fromStyle"
                                onChange={(e) => handleChange(e, 'fromTime')}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={
                                    (e) => {
                                        handleChange(e, 'todate');
                                    }
                                }
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Time</label>
                            <Form.Control
                                type="time"
                                placeholder="Enter Todate"
                                name="toTime"
                                className="fromStyle"
                                value={`${skuDate.toTime}`}
                                onChange={(e) => handleChange(e, 'toTime')}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option);
                                    setdefultDivision(null);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option);
                                    setdefultTerritory(null);
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>


                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Territory</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={territoryDropdownData()}
                                value={defultTerritory}
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);
                                    setdefultTerritory(option)
                                }}
                                defaultValue={defultTerritory}
                            />
                        </Form.Group>
                    </div>


                </div>
                <div className="col-lg-12 ">
                    <button type="button" className="btn btn-success btn-sm mt-3 float-left" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}>
                        Show Report
                    </button>
                </div>
                {/* {Object.keys(targetDateList).length > 0 &&
                    <div className="container-fluid mb-3 mt-3 containerFixed">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="paid__widget one">
                                    <div className="widget__left">
                                        <p className="widgetTitle">Target</p>
                                        <div className="achiveLayer">
                                            <span>Biscuit Target Value:  {numberFormat(parseInt(targetDateList?.biscuitTargetPrice))}</span>
                                            <span>Cookies  Target Value:  {numberFormat(parseInt(targetDateList?.cookisTargetPrice))}</span>
                                            <span>Cake Target Value:  {numberFormat(parseInt(targetDateList?.cakeTargetPrice))}</span>
                                            <span>Wafer Target Value:  {numberFormat(parseInt(targetDateList?.waferTargetPrice))}</span>
                                            <span>Total Target Value:  {numberFormat(parseInt(targetDateList?.totalTargetPrice))}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="paid__widget two">
                                    <div className="widget__left">
                                        <p className="widgetTitle">Primary Lifting</p>
                                        <div className="achiveLayer">
                                            <span>Biscuit Lifting Value:  {numberFormat(parseInt(targetDateList?.biscuitLiftingPrice))}</span>
                                            <span>Cookies  Lifting Value:  {numberFormat(parseInt(targetDateList?.cookisLiftingPrice))}</span>
                                            <span>Cake Lifting Value:  {numberFormat(parseInt(targetDateList?.cakeLiftingPrice))}</span>
                                            <span>Wafer Lifting Value:  {numberFormat(parseInt(targetDateList?.waferLiftingPrice))}</span>
                                            <span>Total Lifting Value:  {numberFormat(parseInt(targetDateList?.totalLiftingPrice))}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="paid__widget three">
                                    <div className="widget__left">
                                        <p className="widgetTitle">Achivement</p>
                                        <div className="achiveLayer">
                                            <span>Biscuit Ach (%):  {parseInt(((targetDateList?.biscuitLiftingPrice) / targetDateList?.biscuitTargetPrice) * 100)}</span>
                                            <span>Cookies Ach (%):  {parseInt(((targetDateList?.cookisLiftingPrice) / targetDateList?.cookisTargetPrice) * 100)}</span>
                                            <span>Cake Ach (%):  {parseInt(((targetDateList?.cakeLiftingPrice) / targetDateList?.cakeTargetPrice) * 100)}</span>
                                            <span>Wafer Ach (%):  {parseInt(((targetDateList?.waferLiftingPrice) / targetDateList?.waferTargetPrice) * 100)}</span>
                                            <span>Total Ach (%):  {parseInt(((targetDateList?.totalLiftingPrice) / targetDateList?.totalTargetPrice) * 100)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                } */}

                <div className=" pl-5 pr-5 mb-5">
                    {allItemReports?.length > 0 ? (
                        <div className="searchBox" >
                            <form className="form mt-2" style={{ backgroundColor: 'skyblue', float: 'right' }}>
                                <span>
                                    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <input
                                    className="input"
                                    placeholder="Search Product Name"
                                    required=""
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </form>

                        </div>
                    ) : null}

                </div>

                <div className="row" id="id" ref={inputField}>
                    {/* <table className="table mt-5 voyageTable table-responsive"> */}
                    {isLoading &&
                        <div className="col-lg-12 loadingPosition">
                            <NewLoader2 />
                        </div>
                    }
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>

                        <div
                            className="stickyTable"
                            style={{ height: allItemReports?.length > 0 ? 800 + "px" : 0 }}
                        >
                            {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} > */}
                            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "60px" }} scope="col">PRODUCT ID</th>
                                        <th style={{ width: "60px" }} scope="col">PRODUCT CATEGORIES</th>
                                        <th style={{ width: "400px" }} scope="col">PRODUCT NAME</th>
                                        <th style={{ width: "60px" }} scope="col">TARGET QUANTITY(CTN)</th>
                                        <th style={{ width: "60px" }} scope="col">TARGET VALUE</th>
                                        <th style={{ width: "60px" }} scope="col">LIFTING QTY</th>
                                        <th style={{ width: "60px" }} scope="col">LIFTING VALUE</th>
                                        <th style={{ width: "60px" }} scope="col">TODAY DEMAND QUANTITY</th>
                                        <th style={{ width: "60px" }} scope="col">ACV%</th>



                                    </tr>
                                </thead>

                                {/* 17-12-2023 Changed Parvez Bhai */}
                                <tbody>
                                    {sortedData?.length > 0 &&
                                        sortedData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.productID ? item.productID : item.prodID}
                                                </td>
                                                <td>
                                                    {item.productCategoryName ? item.productCategoryName : item.ProductCat}
                                                </td>
                                                <td>
                                                    {item.productName ? item.productName : item.ProductName}</td>
                                                <td>{parseInt(item.targetQty)}</td>
                                                <td>{parseFloat(item.targetPrice).toFixed(2)}</td>
                                                <td>{item.productQty}</td>
                                                <td>{isNaN(parseFloat(item.productTPrice)) ? '0' : parseFloat(item.productTPrice).toFixed(2)}</td>
                                                <td>{item.demandQty}</td>
                                                <td>
                                                    {isNaN(parseInt(((item.productTPrice) / (item.targetPrice)) * 100))
                                                        ? 0
                                                        : parseInt(((item.productTPrice) / (item.targetPrice)) * 100)}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </table>
                        </div>

                        {!isLoading && sortedData?.length === 0 && (
                            <div className="alert alert-warning mt-5">
                                Sorry ! Sales List Not Found.
                            </div>
                        )}
                        {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
                    </div>
                </div>




                <SimpleModal
                    size="xl"
                    show={itemDetailShow}
                    handleClose={() => setItemDetailShow(false)}
                    handleShow={() => setItemDetailShow(true)}
                    modalTitle={"Item Details"}
                >
                    <RemoteSalesDetails
                        handleClose={() => setItemDetailShow(false)}
                        itemID={itemID}
                        itemDetailnIfo={itemifo}
                    />
                </SimpleModal>

                {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default TargetVsAchProductWise;
