import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { cleanRemoteSalesData, getProductList, getSoInformation } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getStatusList } from "../../_redux/actions/SecondarySales/SecondarySalesAction";
import { InputHandle } from "../../_redux/actions/Delivery/DeliveryAction";
import { StockStatusUpdate } from "../../_redux/actions/RemoteSales/RemoteSalesAction";


const SODeliveryConfirmStatus = () => {
    const dispatch = useDispatch();

    const { handleSubmit } = useForm();
    const deliveryStatusInput = useSelector((state) => state.delivery.deliveryStatusInput);
    const [soInfo, setSoInfo] = useState({
        zoneName: null,
        divisionName: null,
        areaName: null,
        soName: null,
        soPointID: null,
        soid: null,
        distribName: null,
        soPointName: null
    })
    const [status, Setstatus] = useState([]);

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        dispatch(getProductList(user.employeeType));
        dispatch(cleanRemoteSalesData());


    }, [])

   
    useEffect(() => {
        initialData();

    }, []);

    const initialData = async () => {
        
        let soDetails = await getSoInformation();
        let statusData = await getStatusList();
        Setstatus(statusData.data);
      
        if (soDetails.data != null) {
          const { zoneName, divisionName, areaName, soName, soPointID, soid, distribName, soPointName, territoryName, distribID } = soDetails.data;
          const soInfomation = { ...soInfo };
          soInfomation.zoneName = zoneName;
          soInfomation.divisionName = divisionName;
          soInfomation.areaName = areaName;
          soInfomation.territoryName = territoryName;
          soInfomation.soName = soName;
          soInfomation.soPointID = soPointID;
          soInfomation.soid = soid;
          soInfomation.distribName = distribName;
          soInfomation.soPointName = soPointName;
          setSoInfo(soInfomation);
    
          inputChangeHandle("intDistributorId", distribID);
        }
        
      };
      
  

    const onSubmit = async (e) => {
        if (deliveryStatusInput.delivery_code == 0) {
            alert("Please Type Delivery Code");
            return false;
        }
       else if (deliveryStatusInput.intDistributorId == 0) {
            alert("There Is No Distributor");
            return false;
        }
        else {
            dispatch(StockStatusUpdate(deliveryStatusInput?.delivery_code, deliveryStatusInput?.intDistributorId));
        }
    };

    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };



    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2 titleposition">
                                <span className="button-010">SO Delivery Confirm Status</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="form-group row">


                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Name</p>
                                    <h6>{soInfo.soName + '[' + soInfo.soPointName + ']'}</h6>
                                </div>
                            </Form.Group>

                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Zone</p>
                                    <h6>{soInfo.zoneName}</h6>
                                </div>
                            </Form.Group>

                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Division</p>
                                    <h6>{soInfo.divisionName}</h6>
                                </div>
                            </Form.Group>

                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Region</p>
                                    <h6>{soInfo.areaName}</h6>
                                </div>
                            </Form.Group>

                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Territory</p>
                                    <h6>{soInfo.territoryName}</h6>
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-lg-4">
                            <Form.Group as={Col} controlId="formGridState">
                                <div className="top_card_so">
                                    <p className="Secondary_so_information text-primary">Distributor</p>
                                    <h6>{soInfo.distribName}</h6>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="row ml-1">
                            <div className="col-lg-4">
                                <Form.Group controlId="formGridState">
                                    <label className="form-label">CHALLAN NO</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss "
                                        type="text"
                                        placeholder="Type DO Code"
                                        name="delivery_code"
                                        // value={deliveryStatusInput?.delivery_code}
                                        onChange={(e) => {
                                            inputChangeHandle("delivery_code", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-1 mt-7 mb-5">
                                <button
                                    type="submit"
                                    className="btn btn-primary 
                                    btn-sm text-center 
                                    custome-addnew-btn item-add-save mb-5"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </>
    );
};

export default SODeliveryConfirmStatus;
