import React from "react";
import SalesApprovedReportList from "../../../../information/components/RemoteSales/SalesApprovedReport/SalesApprovedReportList";




const SalesApprovedReportListContainer = () => {
  return (
    <div className="container-fluid">
      <SalesApprovedReportList />
    </div>
  );
};

export default SalesApprovedReportListContainer;
