import React from "react";
import CorporateSalesAdd from "../../../information/components/RemoteSales/CorporateSalesAdd";



const CorporateSalesContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        < CorporateSalesAdd/>
      </div>
    </div>
  );
};
export default CorporateSalesContainer;
