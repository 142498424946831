import React from 'react'

const NewLoader2 = () => {
    return (
        <div className='custom-loader-new-position'>
            <div class="column-container">
                <div class="custom-loader-new ml-8 mb-3"></div>
            </div>
        </div>

    )
}

export default NewLoader2