import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { handleSubmit, itemAddInput, submitStatusRemove } from "../../_redux/actions/Zone/ZoneAction";
const ZoneAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const itemDataInput = useSelector((state) => state.zone.itemDataInput);
  const loading = useSelector((state) => state.zone.isLoading);
  const zoneSubmitStatus = useSelector((state) => state.zone.zoneSubmitStatus);

  useEffect(() => {
    if (zoneSubmitStatus) {
        dispatch(submitStatusRemove())
      history.push("/zone/list");
    }
  }, [zoneSubmitStatus]);

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };

  const onSubmit = () => {
    dispatch(handleSubmit(itemDataInput));
  }; 

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        <div className="mt-5">
          <h3 className="mb-0 pb-0">Add Zone</h3>
        </div>
        <hr></hr>
        <div className="form-group row"></div>
        <div className="form-group row">
          <div className="col-xl-10 col-lg-10 col-md-10 ">
            <Form.Group>
              <Form.Label className="formFont pl-1">Zone Name</Form.Label>
              <Form.Control
                className="formHeight"
                type="text"
                placeholder="Enter Zone Name"
                name="strPartNo"
                value={itemDataInput.zoneName}
                onChange={(e) => changeText("zoneName", e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mt-5">
            {/* <button className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-add-save mb-5" onClick={()=>onSubmit()}>
              save
            </button> */}

            <button
            id="kt_login_signin_submit"
            onClick={()=>onSubmit()}
            disabled={loading}
            className={`btn btn-primary loginButton overlay__caption`}
          >
            <span className="loginText">Sign in</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZoneAdd;
