import React from "react";
import RegionAdd from "../../../information/components/Region/RegionAdd";


const RegionAddContainer = () => {
  return (
    <div className="container">
      <div className="">
        <RegionAdd />
      </div>
    </div>
  );
};

export default RegionAddContainer;
