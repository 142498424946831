import React, { useState, useEffect } from 'react'


const defaulImageSrc = '/media/users/blank1.png'
const initialFieldValues = {
    employeeId: 0,
    employeeName: '',
    occupation: '',
    imageName: '',
    imageSrc: defaulImageSrc,
    imageFile: null
}

export default function Profile(props) {
    const { addOrEdit } = props


    const [values, setValues] = useState(initialFieldValues)
    const [errors, setErrors] = useState({})

    const handleInputChange = e => {


        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
        validate();

    }

    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues(
                    {
                        ...values,
                        imageFile,
                        imageSrc: x.target.result
                    }
                )
            }
            reader.readAsDataURL(imageFile)

        }
        else {
            setValues(
                {
                    ...values,
                    imageFile: null,
                    imageSrc: defaulImageSrc

                }
            )
        }

    }

    const validate = () => {
        let temp = {}
        temp.employeeName = values.employeeName == "" ? false : true;
        temp.imageSrc = values.imageSrc == defaulImageSrc ? false : true;
        setErrors(temp)
        return Object.values(temp).every(x => x == true)
    }

    const resetForm = () => {
        setValues(initialFieldValues)
        document.getElementById('image-uploader').value = null;
        setErrors({})
    }

    const handleFromSubmit = e => {
        e.preventDefault()
        if (validate()) {
            const formData = new FormData()
            formData.append('imageName', values.imageName)
            formData.append('imageFile', values.imageFile)
            addOrEdit(formData.resetForm)

        }
    }

    const applyErrorClass = field => ((field in errors && errors[field] == false) ? ' invalid-field' : '')

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-3">
                        < form autoComplete='off' noValidate onSubmit={handleFromSubmit} >
                            <div className='card'>
                                <img src={values.imageSrc} className='card-img-top' />
                                <div className='card-body'>
                                    <div className='form-group'>
                                        <input type='file' accept='image/*'
                                            className={'form-control-file' + applyErrorClass('imageSrc')}
                                            onChange={showPreview}
                                            id='image-uploader'
                                        />
                                    </div>

                                    <div className='form-group text-right'>
                                        <button type='submit' className='btn btn-sm btn-primary'>Updated</button>
                                    </div>

                                </div>
                            </div>
                        </form></div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </>

    )
}
