import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  delteProductList,
  getDistributorList,
  getProductList,
  productSelecteditem,
  itemAddInput,
  productQuantity,
  InputHandle,
  getCustomerBalance,
  cleanRemoteSalesData,
  stockSubmit,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";

const RemoteSalesAdd = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const productList = useSelector((state) => state.remoteSales.productList);
  const availableBalance = useSelector(
    (state) => state.remoteSales.availableBalance
  );
  const restBalance = useSelector((state) => state.remoteSales.restBalance);
  const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);
  const submitData = useSelector((state) => state.remoteSales.submitData);
  const productSubmitStatus = useSelector(
    (state) => state.remoteSales.productSubmitStatus
  );
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [distribType, setDistribType] = useState(null);




  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    
    dispatch(getProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());
  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {

    // if (productSubmitStatus) {
    //   setTimeout(() => { history.go(0) }, 3000);
    // }
  }, [productSubmitStatus]);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneListEntry();
    Setzone(zoneData.data);
  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
    dispatch(productQuantity(name, value));
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
    dispatch(getDistributorList(selectArea, value));
    dispatch(getProductList(value));
  }

  const onSubmit = async (e) => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (itemDataInput.intDistributorId == "") {
      alert("Please select Distribution");
      return false;
    }
    setDisabled(true);
    dispatch(stockSubmit(submitData, productList, selectZone, selectDivision, selectArea));
    // dispatch(MainCertificateCreateAction(certificateInfoInput));
  };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];


  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };













  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3 bg-light">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <h3 className="mb-0 pb-0">Stock Entry</h3>
              </div>
            </div>
            {/* Check sales type  0 for all 1 for Biscuite and 3 for Bread */}

            {/* <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            /> */}

            {/* {
                distribType == 0?
                 <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1?true:false}    onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3  ?true:false}  onChange={(e)=>getSalesValue('bread',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div>: <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1?true:false} disabled={distribType==1 || 0?false:true }   onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3  ?true:false} disabled={distribType==3 || 0?false:true } onChange={(e)=>getSalesValue('bread',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div>
              
              } */}



          </div>

          <hr></hr>
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Zones</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={zoneDropdownData()} />}
                  rules={{ required: false }}
                  name="zoneData"
                  register={register}
                  // value={selectZone}
                  value=""
                  onChange={(option) => {

                    divisionList(option.value);
                    setSelectZone(option.value);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("intDistributorId", "");

                  }}
                  setValue={setValue}
                />

              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Division</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={divisionDropdownData()} />}
                  rules={{ required: false }}
                  name="divisionData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)
                    getAreaData(option.value);
                    setSelectDivision(option.value);

                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Region</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={areaDropdownData()} />}
                  rules={{ required: false }}
                  name="areaData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)

                    getTerritoryData(option.value)
                    setSelectArea(option.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


            <div className="col-lg-3">

              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    dispatch(getDistributorList(option.value,salesType));
                    
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


            <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Distributor</label>
              <RHFInput
                as={<Select options={distributorList} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                  inputChangeHandle("distributorName", option.label);
                  inputChangeHandle("intDistributorId", option.value);
                  dispatch(getCustomerBalance(option.value));

                }}
                setValue={setValue}
              />
               </Form.Group>
              {/* <div className="inputError margin-minus-8">
                {errors.intDistributorId &&
                  errors.intDistributorId.ref.value === undefined &&
                  "Distributor can't be blank"}
              </div> */}
            </div>

            {/* <div className="col-xl-6 col-lg-6 col-md-6 ">
              <Form.Group>
                <Form.Label className="formFont pl-1">
                  Search Product
                </Form.Label>
                <Form.Control
                  rules={{ required: true }}
                  className="formHeight"
                  type="text"
                  placeholder="Enter product Name"
                  name="itemName"
                  value={itemDataInput.itemName}
                  onChange={(e) => changeText("itemName", e.target.value)}
                 
                />
              
              </Form.Group>
              <div className="serchproduct">
                {fillterProductList.length > 0
                  ? fillterProductList.map((item, index) => (
                      <p onClick={() => productSelected(item)}>
                        {item.zoneName}
                      </p>
                    ))
                  : null}
              </div>
            </div> */}

            {/* <div className="col-lg-2 mt-5">
            <button className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-add-save mb-5">
              ADD
            </button>
          </div> */}
          </div>
        </div>
        <div className="container-fluid mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoader ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {availableBalance}</h4>
                      <h4 className="balanceleft">৳ {restBalance.toFixed(2)}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Credit Limit</p>
                </div>
                <div className="widget__right">
                  {balanceLoader ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {creditLimit}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Product Value</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Quantity(Pieces)</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.quantity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                {/* {
                  submitLoading?
                  <button
                 
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit .....
                </button>:  */}
                <button
                  type="submit"
                  disabled={disabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>
                {/* } */}

              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default RemoteSalesAdd;
