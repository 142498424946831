import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useForm } from "react-hook-form";

import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

import { getUnapprovedList } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

const SecondarySalesUnapprovedList = () => {
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const unapprovedList = useSelector((state) => state.rollback.unapprovedList);
  const allReportList = useSelector((state) => state.rollback.allReport);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  const { register, setValue } = useForm();

  const dispatch = useDispatch();
  useEffect(() => {

    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea,idTerritory) => {

    dispatch(getUnapprovedList(fromDate, tDate, idZone, idDivision, idArea,idTerritory));
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const showReport =()=>{
    handleListAction(startDate, toDate, selectZone, selectDivision,selectArea,selectTerritory);
   
  }

  const getSalesValueType = (name, value) => {
    setSelectGeoType(value);
  }


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Secondary Sales Unapproved List</h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              <DownloadDropdown excelname='Secondary Sales Unapproved List' />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
            <SimpleSgeoType
             getSalesValue={getSalesValueType}
             sGeoType={selectGeoType}
             />
            </div>
          </div>

        </div>
        <hr></hr>



        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>

              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  //handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  //handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  
                  setValue("territory", "");
                  //handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
          </div>


          <div className="col mt-5">
            <Button size="sm" onClick={()=>showReport()}>Show Report</Button>
          </div>

        </div>
        <div>
          {allReportList?.length > 0 &&
           
           <div className="container-fluid mb-3 mt-3 containerFixed">
           <div className="row">
            
             <div className="col-xl-6 col-lg-6 col-md-6">
               <div className="paid__widget two">
                 <div className="widget__left">
                   {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                   <p className="balanceleft">Product Qty</p>
                 </div>
                 <div className="widget__right">
                  
                     <div className="overlay-layer bg-transparent">
                       {/* <div className="spinner spinner-lg spinner-warning" /> */}
                     </div>
                     <div>
                       <h4 className="balanceleft"> {parseInt(unapprovedList.productQty)}</h4>
                     </div>
                 </div>
               </div>
             </div>
             <div className="col-xl-6 col-lg-6 col-md-6">
               <div className="paid__widget one">
                 <div className="widget__left" >
                   {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                   <p className="balanceleft">Product Value</p>
                 
                 </div>
                 <div className="widget__right">
                 
                     <div className="overlay-layer bg-transparent">
                       {/* <div className="spinner spinner-lg spinner-warning" /> */}
                     </div>
                 
                     <div>
                       <h4 className="balanceleft">{parseInt(unapprovedList.productPrice)}</h4>
                     </div>

                  
                 </div>
               </div>
             </div>
             {/* <div className="col-xl-3 col-lg-3 col-md-6">
               <div className="paid__widget three">
                 <div className="widget__left">
                  
                   <p className="balanceleft">Cookies Quantity</p>
                   <p className="balanceleft">Cookies Price</p>
                 </div>
                 <div>
                   <h4 className="balanceleft">{parseFloat(amount.cookisQty)}</h4>
                   <h4 className="balanceleft">৳ {parseFloat(amount.cookisPrice).toFixed(2)}</h4>
                 </div>
               </div>
             </div> */}
             
           </div>
         </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="shortstickyTable"
                style={{ height: allReportList?.length > 0 ? 800 + "px" : 0 }}
              >
            {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                  <th scope="col">ZONE NAME</th>
                  <th scope="col">DIVISION NAME</th>
                  <th scope="col">REGION NAME</th>
                  <th scope="col">TERRITORY NAME</th>
                  <th scope="col">SO POINT NAME</th>
                  <th scope="col">SO ENROLL</th>
                  <th scope="col">SO NAME</th>
                  <th scope="col">ENTRY DATE</th>
                  <th scope="col">SALES DATE</th>
                  <th scope="col">PRODUCT PIC PRICE</th>
                  <th scope="col">PRODUCT Qty</th>
                  <th scope="col">PRODUCT TOTAL Value</th>
                </tr>
              </thead>
              <tbody>
                {allReportList?.length > 0 &&
                  allReportList.map((item, index) => (
                    <tr>
                         <td>{item.zonename}</td>
                         <td>{item.divisionname}</td>
                         <td>{item.areaname}</td>
                         <td>{item.territoryName}</td>
                         <td>{item.soPointName}</td>
                         <td>{item.soEnroll}</td>
                         <td>{item.soName}</td>
                         <td>{item.entryDate}</td>
                         <td>{item.salesDate}</td>
                         <td>{item.productPicPrice}</td>
                         <td>{item.productQty}</td>
                         <td>{item.productTPrice}</td>
                    </tr>
                  ))}


              </tbody>
            </table>
            </div>
            {!isLoading && allReportList?.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SecondarySalesUnapprovedList;
