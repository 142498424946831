import React from "react";
import EventList from "../../../information/components/RemoteSales/EventList";

const EventListContainer = () => {
  return (
    <div className="container-fluid">
      <EventList />
    </div>
  );
};

export default EventListContainer;