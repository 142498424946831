import React from "react";
import BreadBurnProductLifting from "../../../information/components/SecodarySales/BreadBurnProductLifting";




const DeliveryCumaDistributorWiseBreadBurnContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <BreadBurnProductLifting/>
      </div>
    </div>
  );
};

export default DeliveryCumaDistributorWiseBreadBurnContainer;
