import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const TopLevelDashboard = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const tableDataItems = [
    { id: 1, name: 'Chocolate Cake', calories: 450, fat: 25, carbs: 60, protein: 5 },
    { id: 2, name: 'Apple Pie', calories: 300, fat: 12, carbs: 45, protein: 3 },
  ];

  const columns = [
    { name: 'ID', selector: 'id', sortable: true },
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Calories', selector: 'calories', sortable: true },
  ];

  const smsTemplates = [
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 2, title: "Employee Issue", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 2, title: "Employee Issue", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 2, title: "Employee Issue", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 2, title: "Employee Issue", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
    { id: 2, title: "Employee Issue", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
  ];
  const templateColumns = [
    { name: 'Title', selector: 'title', sortable: true },
  ];

  const handleRowExpand = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);
    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((id) => id !== row.id));
    } else {
      setExpandedRows([...expandedRows, row.id]);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <div className='bg-secondary'>
      <p className='p-3'>{data.description}</p>
    </div>
  );

  return (
    <div className='container-fluid'>
      <div className=''>
        <div className='row'>
          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 card1">
              <div className="circle-card">
                <img src="/media/bg/audit.png" alt="Audit Logo" className="logo" />
              </div>
              <div className="text-center">
                <h3>Audit</h3>
                <p>This is the Audit Section</p>
              </div>
              <div className='col-lg-12 col-md-12 p-4'>
                <DataTable
                  columns={columns}
                  data={tableDataItems}
                  selectableRows
                  selectAllRows={isAllSelected}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 imgcard">
              <div className="circle-card">
                <img src="/media/bg/marketing.png" alt="Marketing Logo" className="logo" />
              </div>
              <div className="text-center">
                <h3>Marketing</h3>
                <p>This is the Marketing Section</p>
              </div>
              <div className='col-lg-12 col-md-12 p-4'>
                <DataTable
                  columns={columns}
                  data={tableDataItems}
                  selectableRows
                  selectAllRows={isAllSelected}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 factoryimgcard">
              <div className="circle-card">
                <img src="/media/bg/factory.png" alt="Factory Logo" className="logo" />
              </div>
              <div className="text-center">
                <h3>Factory</h3>
                <p>This is the Factory Section</p>
              </div>
              <div className='col-lg-12 col-md-12 p-4'>
                <DataTable
                  columns={columns}
                  data={tableDataItems}
                  selectableRows
                  selectAllRows={isAllSelected}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 hrimgcard">
              <div className="circle-card">
                <img src="/media/bg/hr.png" alt="HR Logo" className="logo" />
              </div>
              <div className="text-center">
                <h3>HR</h3>
                <p>This is the HR Section</p>
              </div>
              <div className='col-lg-12 col-md-12 p-4'>
                <DataTable
                  columns={templateColumns}
                  data={tableDataItems}
                  selectableRows
                  expandableRows
                  expandOnRowClicked
                  onRowExpand={handleRowExpand}
                  expandableRowsComponent={expandableRowsComponent}
                  customStyles={{
                    rows: {
                      style: {
                        "&:hover": {
                          backgroundColor: "#F0F0F0",
                          cursor: "pointer",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 hrimgcard">
              <div class="container_Premium">
                <div class="card_box">
                  <span></span>
                </div>
                <DataTable
                  columns={templateColumns}
                  data={smsTemplates}
                  selectableRows
                  expandableRows
                  expandOnRowClicked
                  onRowExpand={handleRowExpand}
                  expandableRowsComponent={expandableRowsComponent}
                  customStyles={{
                    rows: {
                      style: {
                        "&:hover": {
                          backgroundColor: "#F0F0F0",
                          cursor: "pointer",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-2'>
            <div className="cardtop col-lg-12 col-md-12 card1">
              <div class="container_Premium">
                <div class="card_box_Audit">
                  <span></span>
                </div>
                <DataTable
                  columns={templateColumns}
                  data={smsTemplates}
                  selectableRows
                  expandableRows
                  expandOnRowClicked
                  onRowExpand={handleRowExpand}
                  expandableRowsComponent={expandableRowsComponent}
                  customStyles={{
                    rows: {
                      style: {
                        "&:hover": {
                          backgroundColor: "#F0F0F0",
                          cursor: "pointer",
                        },
                      },
                    },
                  }}
                />
                
              </div>
            </div>
          </div>

          <div className='col-lg-12'>
            <button className='button-621 float-right'>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopLevelDashboard;