import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getPrimaryDeliverySkuWiseReport, getSKUWiseIMScumalativeReport } from "../../_redux/actions/Delivery/DeliveryAction";

const SkuWisePrimaryCumalativeReport = () => {
  const history = useHistory();
  const isProductLoading = useSelector((state) => state.primary.primaryLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const productReport = useSelector((state) => state.primary.productReports);
  const skuTotal = useSelector((state) => state.delivery.skuTotal);
  const skuQuantity = useSelector((state) => state.delivery.skuQuantity);
  const productTotal = useSelector((state) => state.primary.productTotal);
  const productQuantity = useSelector((state) => state.primary.productQuantity);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState(1);
  const componentRef = useRef();
  
  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  });

  const [reportList,setReportList] = useState([
    {
      id:1,
      type:'Delivery Date'
    },
    {
      id:2,
      type:'Demand Confirm Date'
    }
  ]);


  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
   
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();

  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const reportTypeDdl = () => {
    let options = [];
    if (reportList?.length > 0) {
      reportList.forEach((item) => {
        let itemData = {
          value: item.id,
          label: item.type,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  // const handleChange = ({ currentTarget: input }) => {
  //   setStartDate(input.value);
  // };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  const handleListAction = () => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    dispatch(getPrimaryDeliverySkuWiseReport(conFromDate, conToDate, selectZone, selectDivision, selectArea,selectTerritory,type));
  }

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };





  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
            <span className="button-010">Sku Wise Primary Cumalative Report</span>
              {/* <h3 className="mb-0 pb-0">Sku Wise Primary Cumalative Report</h3> */}
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Sku Wise Primary Cumalative Report' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={`${skuDate.fromDate}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Deadweight"
                name="fromTime"
                 value={`${skuDate.fromTime}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                value={`${skuDate.todate}`}
                onChange={(e) => handleChange(e, 'todate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">To Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Todate"
                name="toTime"
                className="fromStyle"
                value={`${skuDate.toTime}`}
                onChange={(e) => handleChange(e, 'toTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md">
          <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Filter By</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={reportTypeDdl()} />}
                rules={{ required: false }}
                name="typeData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                 setType(option?.value)
                 
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
               
                onChange={(option) => {
               
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


     
          <div className="col-md mt-6 mb-5">
          <button className="button-621" onClick={() => handleListAction()} >  Show Report</button>
          {/* <Button size="sm" onClick={() => handleListAction()}>Show Report</Button> */}
          </div>
         
        </div>
        <div>
         
          {productReport.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left" >
                    
                      <p className="balanceleft">Total Quantity</p>
                    </div>
                    <div className="widget__right">

            

                      <div>
                        <h4 className="balanceleft">{parseInt(productQuantity)}</h4>
                      </div>

                    </div>
                  </div>
                </div>
               
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget four">
                    <div className="widget__left">
                     
                      <p className="balanceleft">Total Value</p>
                    </div>
                  <div className="widget__right">
                    <div>
                      <h4 className="balanceleft">৳ {parseFloat(productTotal).toFixed(2)}</h4>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            <div
                className="shortstickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
            {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
              <thead>
               
                <tr>
                  <th scope="col">PRODUCT ID</th>
                  <th scope="col">PRODUCT NAME</th>
                  <th scope="col">CATEGORY</th>
                  <th scope="col">BRAND NAME</th>
                  <th scope="col">PACK NAME</th>
                  <th scope="col">PRODUCT PRICE(CTN)</th>
                  <th scope="col">TOTAL QUANTITY</th>
                  {/* <th scope="col">TOTAL CTN(QTY)</th> */}
                  <th scope="col">TOTAL VALUE</th>
                </tr>
              </thead>
              <tbody>
                {productReport.length > 0 &&
                  productReport.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.productID}</td>
                      <td>{item.productName}</td>
                      <td>{item.productCategory}</td>
                      <td>{item.brandName}</td>
                      <td>{item.packName}</td>
                      <td>{parseFloat(item.productPicPrice).toFixed(2)}</td>
                      <td>{item.productQty}</td>
                      {/* <td>{Math.round(item.productQtyCtn)}</td> */}
                      <td>{`${parseFloat(item.productTPrice).toFixed(2)}`}</td>
                      {/* <td>{item.productUom}</td> */}

                      {/* <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                      </td> */}
                    </tr>
                  ))}


              </tbody>
            </table>
            </div>
            
            {!isProductLoading && productReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>
       
      </div>
    </>
  );
};

export default SkuWisePrimaryCumalativeReport;
