import React, { useEffect, useState } from "react";
import { Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getItemCategory,
  getItemList,
  getItemSubCategory,
} from "../../_redux/actions/ItemAction";

const TerritoryFilter = ({ currentPage, setCurrentPage }) => {
  const { register, setValue } = useForm();
  const dispatch = useDispatch();
  const itemCategoryOptionData = useSelector(
    (state) => state.itemList.itemCategoryOptionData
  );
  const itemSubCategoryOptionData = useSelector(
    (state) => state.itemList.itemSubCategoryOptionData
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(getItemCategory());
    dispatch(getItemSubCategory());
    dispatch(getItemList(currentPage));
  }, []);
  useEffect(() => {
    dispatch(getItemList(currentPage));
  }, [dispatch, currentPage]);

  const categorySelecte = (itemCategory) => {
    dispatch(getItemList(currentPage, searchText, itemCategory, null, null));
  };
  const subCategorySelecte = (itemSubCategory) => {
    dispatch(getItemList(currentPage, searchText, null, itemSubCategory, null));
  };
  const itemDepartment = (department) => {
    dispatch(getItemList(currentPage, searchText, null, null, department));
  };

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getItemList(currentPage));
    } else {
      dispatch(getItemList(currentPage, searchText));
    }
  };
  const department = [
    {
      value: 1,
      label: "Store",
    },
    {
      value: 2,
      label: "Engine",
    },
  ];
  return (
    <>
      <h1 className="tableheading ml-5 pt-5">Territory List</h1>
      <div className="row m-4">
     
        
     
        <div className="col-lg-10 col-md-10 ">
          <Form.Group as={Col} controlId="formGridState">
            <input
              type="search"
              value={searchText}
              className="form-control product-search-input formHeight"
              placeholder="Search by Zone"
              onChange={searchItems}
            />
          </Form.Group>
        </div>
        <div className="col-lg-2 col-md-2">
          <div className="certificate-filter">
            <i className="fas fa-filter tableFilter mt-1 mr-2"></i>
            <i className="far fa-filter"></i>
            <Link
              to="/territory/add"
              className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn"
            >
              Add New
          </Link>
          </div>
        </div>

      </div>
    </>
  );
};

export default TerritoryFilter;
