import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import {getZoneList} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getStrikeRateOfSecondaryForSo } from "../../_redux/actions/SecondarySales/SecondarySalesAction";

const StrikeRateOfSecondaryForSo = () => {

    const isLoading = useSelector((state) => state.secondarySales.sostrikeLoading);
    const sostrikedata = useSelector((state) => state.secondarySales.sostrikedata);

    const [zone, Setzone] = useState([]);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const componentRef = useRef();

    const {  } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";
        dispatch(getStrikeRateOfSecondaryForSo(firstD, currentD));
    }, []);
    
    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    };
   
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = (input) => {
        setToDate(input);
    };

    const handleDateFilter = (option) => {
        handleChangeTodate(option);
    }

    const showReport = () => {
        dispatch(getStrikeRateOfSecondaryForSo(startDate, toDate));
    }

    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                            <h3 className="mb-0 pb-0">SO LPC. ST Rate. Non EX % Report</h3>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right ">
                            <DownloadDropdown excelname='SO LPC. ST Rate. Non EX % Report' />
                        </div>
                    </div>
                </div>

                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={(event) => {
                                    handleDateFilter(event.target.value)
                                }}
                            />
                        </Form.Group>
                    </div>
                   
                    <div className="col mt-5">
                        <Button size="sm" onClick={() => showReport()}>Show Report</Button>
                    </div>
                </div>
                <div>
                </div>

                <div className="row mt-5" id="id" ref={inputField}>
                    {/* <table className="table mt-5 voyageTable table-responsive"> */}
                    {isLoading && <LoadingSpinner text="Loading Data List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5" >


                        {/* <div
                            className="stickyTablecus"
                            style={{ height: sostrikedata?.length > 0 ? 800 + "px" : 0 }}
                        > */}
                        <div className="stickyTablecus"
                            style={{ height: sostrikedata?.length > 0 ? 800 + "px" : 0 }}
                        >
                            <table className="table  table-vertical-center demotable text-center" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    {sostrikedata.length > 0 &&
                                        <tr>
                                            <th >ZONE NAME</th>
                                            <th >DIVISION NAME</th>
                                            <th >REGION</th>
                                            <th >TERRITORY</th>
                                            <th >LPC</th>
                                            <th >STRIKE RATE</th>
                                            <th >NON EXECUTION</th>
                                            <th >MEMOLINE</th>
                                            <th >MEMO QTY</th>
                                            <th >VISITED OUTLET</th>
                                            <th  >ORDER VALUE</th>
                                            <th >TOTAL QTY</th>
                                            <th >TOTAL QTY (CTN)</th>
                                            <th >NON EXECUTION VALUE</th>
                                            <th >TOTAL PRICE</th>
                                            
                                        </tr>
                                    }


                                </thead>
                                <tbody >
                                    {sostrikedata.length > 0 &&
                                        sostrikedata.map((item, index) => (

                                            <tr key={index}>
                                                {/* <td>{item.orderId}</td> */}
                                                <td>{item.zoneName}</td>
                                                <td>{item.divisionName}</td>
                                                <td>{item.areaName}</td>
                                                <td>{item.territoryName}</td>
                                                <td>{(item.lpc).toFixed(2)}</td>
                                                <td>{(item.strikeRate).toFixed(2)}%</td>
                                                <td>{(item.nonExecution).toFixed(2)}%</td>
                                                <td>{item.memoLine}</td>
                                                <td>{item.memoQty}</td>
                                                <td>{item.visitedOutlet}</td>
                                                <td>{item.orderValue}</td>
                                                <td>{item.totalQuantity}</td>
                                                <td>{(item.totalQuantityCtn).toFixed(2)}</td>
                                                <td>{(item.nonExeCutionValue).toFixed(2)}</td>
                                                <td>{(item.totalPrice).toFixed(2)}</td>
                                                
                                                {/* <td>
                                                {" "}
                                                <Link onClick={() => itemDetail(item)} title="Details">
                                                <i className="far fa-eye editIcon item-list-icon"></i>
                                                </Link>
                                                
                                                <Link onClick={() => gotoEditPage(item)} title="Edit">
                                                <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                                                </Link>

                                                <a
                                                href
                                                onClick={(id) => handleDeleteItem(item.orderId)}
                                                title="Delete"
                                                >
                                                <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                                                </a>
                                            </td> */}
                                            </tr>
                                        ))}

                                </tbody>
                            </table>


                            {/* </div> */}
                            
                            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
                        </div>
                        {!isLoading && sostrikedata.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! Data List Not Found.
                                </div>
                            )}
                    </div>
                </div>
                {/* <div>
                    <table className="table table-head-custom demotable">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Roll</th>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Roll</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}


                {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default StrikeRateOfSecondaryForSo;
