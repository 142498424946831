import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form, Button, Col, Row } from "react-bootstrap";
import { FaAngry, FaEdit, FaSearch } from "react-icons/fa";
import { GetEmployeeInformation } from "../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";


const RemoteAppUser = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm();

    const [Enroll, setEnroll] = useState(null);

    const employeeInformation = useSelector(
        (state) => state.menu.employeeInformation
    );

    const showEmployee = () => {
        dispatch(GetEmployeeInformation(Enroll));
    };

    const handleDeleteItem = (item) => {
        //dispatch(GetEmployeeInformation(Enroll))
    };


    return (

        <>
            <div className="bg-white p-5 card">
                <div className="row bg-light card p-3 mb-4 text-left">
                    <div className="col-lg-12">
                        <h5 >Employee Information</h5>
                    </div>
                </div>

                <div className="form-group mt-5 mb-5">
                    <div className="row pr-4">
                        <div className="col-lg-6">
                            <Form.Group as={Row} controlId="formGridState" className="row">
                                <label className="form-label col-4 text-right">ENROLL :</label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="number"
                                    placeholder="TYPE ENROLL"
                                    onChange={(e) => { setEnroll(e.target.value) }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-4">
                                <Button size="sm" className="button-audit float-right col-5" onClick={() => showEmployee()}>
                                    <FaSearch /> SEARCH
                                </Button> 
                        </div>
                    </div>

                </div>

                <div className="">

                    <div className="form-group mt-5 mb-5 ">
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ENROLL :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empEnroll}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    NAME :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empName}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    USER NAME :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empUserName}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    PASSWORD :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empPassword}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ROLE :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empRole}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ROLE NAME:
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empRoleName}
                                    disabled

                                />
                            </Form.Group>
                        </div>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mt-5">
                                <div className="row mb-5">
                                    <div className="col-6">
                                        {/* <button onClick={() => showSingleReport()} className="btn btn-success btn-block">
                                            <FaEdit /> Update User Information
                                        </button> */}
                                    </div>
                                    <div className="col-6">
                                        {/* <button
                                            onClick={() => handleDeleteItem(employeeInformation?.bilL_ID)}
                                            className="btn btn-danger btn-block">
                                            <FaAngry /> Delete User Information
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>


                </div>
            </div>


        </>
    );
};

export default RemoteAppUser;