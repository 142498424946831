import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {getAreaListByDivision,getDivisionListByZone,getTerritory,getZoneList} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {cleanSecondarySalesData} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import { getPrimaryDeliveryDistributorwithProductVerticlZone } from "../../_redux/actions/RemoteSales/RemoteSalesAction";

const PriDisWiseVirticleReport = () => {
    const isLoading = useSelector((state) => state.remoteSales.verticlePridistributorReportLoading);
    const verticlePridistributorReport = useSelector((state) => state.remoteSales.verticlePridistributorReport);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const componentRef = useRef();
    const [salesType, setSalesType] = useState(0);

    const { register, setValue } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    };

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZone(id, selectGeoType);
        SetDivision(divisionData.data);
        setValue("divisionData", "");
    };
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivision(id, selectGeoType);
        setArea(areaData.data);
    };

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    let ref = React.createRef();
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleListAction = (selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType) => {
        dispatch(getPrimaryDeliveryDistributorwithProductVerticlZone(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType));
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritory(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const getSalesValueType = (name, value) => {
        setSelectGeoType(value);
    }

    const areaIDs = [...new Set(verticlePridistributorReport.flatMap(item => item.areaQuantities.map(area => area.areaID)))];

    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                            <h3 className="mb-0 pb-0">Primary Delivery Distributor with Product</h3>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right">
                            <DownloadDropdown excelname='Primary Delivery Distributor with Product' refvalue={componentRef} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 ml-4">
                            <SimpleSgeoType
                                getSalesValue={getSalesValueType}
                                sGeoType={selectGeoType}
                            />
                        </div>
                    </div>

                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Zone</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={zoneDropdownData()} />}
                                rules={{ required: false }}
                                name="zoneData"
                                register={register}
                                isClearable
                                onChange={(option) => {
                                    setSelectZone(option?.value);
                                    divisionList(option?.value);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Division</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={divisionDropdownData()} />}
                                rules={{ required: false }}
                                name="divisionData"
                                register={register}
                                isClearable
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Region</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={areaDropdownData()} />}
                                rules={{ required: false }}
                                name="areaData"
                                register={register}
                                isClearable
                                onChange={(option) => {
                                    getTerritoryData(option?.value)
                                    setSelectArea(option?.value);
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Territory</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={territoryDropdownData()} />}
                                rules={{ required: false }}
                                name="territory"
                                register={register}
                                isClearable
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 mt-5 mb-5">
                        <Button size="sm" onClick={() => handleListAction(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, salesType)}>Show Report</Button>
                    </div>

                </div>
                <div>
                    <div className="row" id="id" ref={inputField}>
                        {isLoading && <LoadingSpinner text="Loading Items List..." />}
                        <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
                            <div className="stickyTable"
                                style={{ height: verticlePridistributorReport?.length > 0 ? 600 + "px" : 0 }}
                            >
                                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            {areaIDs.map(areaID => {
                                                const area = verticlePridistributorReport[0].areaQuantities.find(a => a.areaID === areaID);
                                                return <th key={areaID}>{area ? area.areaName : '-'}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {verticlePridistributorReport.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                {areaIDs.map(areaID => {
                                                    const area = item.areaQuantities.find(a => a.areaID === areaID);
                                                    return <td key={areaID}>{area ? area.quantity : '-'}</td>;
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {!isLoading && verticlePridistributorReport.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! List Not Found.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PriDisWiseVirticleReport;
