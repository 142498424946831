import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  getSalesList,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";

import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

import {
  cleanSecondarySalesData,
  getDistributorSkuProduct,
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";

import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const PrimarySalesDistributorProduct = () => {
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const productReport = useSelector((state) => state.secondarySales.soWiseProduct);
  const skuTotal = useSelector((state) => state.secondarySales.skuTotal);
  const skuQuantity = useSelector((state) => state.secondarySales.skuQuantity);
  
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();
  const [selectGeoType,setSelectGeoType] = useState(2);

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea) => {
    dispatch(getDistributorSkuProduct(fromDate, tDate, idZone, idDivision, idArea));
  }


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Primary Sales Distributor Wise Sku Report  </h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  setSelectZone(option.value);
                  divisionList(option.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("intDistributorId", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                  setValue("areaData", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Area</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  setSelectArea(option.value);
                  dispatch(getSalesList(option.value, salesType));
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 mt-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea)}>Show Report</Button>
          </div>
          
        </div>
        <div>
          {productReport?.length > 0 &&

            <div className="container mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-6 col-lg-4 col-md-6">
                  <div className="paid__widget two">
                    <div className="widget__left">


                      <p className="balanceleft">Total Quantity</p>
                    </div>
                    <div className="widget__right">

                      <div className="overlay-layer bg-transparent">
                      </div>
                      <div>
                        <h4 className="balanceleft">{parseInt(skuQuantity)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left" >
                      <p className="balanceleft">Total Value</p>
                    </div>
                    <div className="widget__right">
                      <div>
                        <h4 className="balanceleft">৳ {parseFloat(skuTotal).toFixed(2)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
              <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
                <thead>

                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">AREA</th>
                    <th scope="col">DISTRIBUTOR ID</th>
                    <th scope="col">DISTRIBUTOR NAME</th>
                    <th scope="col">DISTRIBUTOR POINT</th>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>

                    <th scope="col">PRODUCT VALUE</th>
                    <th scope="col">PRODUCT QUANTITY</th>
                    <th scope="col">Total Value</th>

                  </tr>
                </thead>
                <tbody>
                  {productReport?.length > 0 &&
                    productReport?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.distribID}</td>
                          <td>{item.distribName}</td>
                          <td>{item.disPointName}</td>
                          <td />
                          <td />
                          <td />
                          <td>{item.totalQuentity}</td>

                          <td>{item.totalPrice}</td>
                        </tr>
                        {
                          item?.itemReport.map((product, subindex) => (
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{product.productID}</td>
                              <td>{product.productName}</td>
                              <td>{product.productPrice}</td>
                              <td>{product.productQty}</td>
                              <td>{product.productTotalPrice}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}


                </tbody>
              </table>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default PrimarySalesDistributorProduct;
