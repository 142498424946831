import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form} from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  itemAddInput,
  multipleItemAddInput,
  getItemCategory,
  getUOM,
  getItemType,
  getItemSubCategory,
} from "../../_redux/actions/ItemAction";
import { useHistory } from "react-router-dom";
import { getCatalougList } from "../../../../master/DropDownData/Catalogue/_redux/CatalogueAction/CatalogueAction";
const TerritoryAdd = () => {
  const { register, setValue } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const itemDataInput = useSelector((state) => state.itemList.itemDataInput);
  const multipleItemList = useSelector(
    (state) => state.itemList.multipleItemAdd
  );
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  // Redirect List Page after submitting data
  useEffect(() => {
    if (itemSUbmit.status) {
      history.push("/items/list");
    }
  }, [itemSUbmit]);

  const vesselListOptions = useSelector(
    (state) => state.vesselInfo.vesselListOptions
  );
  const voyageInput = useSelector((state) => state.voyageInfo.voyageInput);
  const handleChangeTextInput = (name, value) => {
   
  };


  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const multipleItemAdd = () => {
    dispatch(multipleItemAddInput(itemDataInput));
  };
 


  useEffect(() => {
    if (multipleItemList.length > 0) {
      setValue("intDepartmentID", "");
      setValue("intUoMID", "");
      setValue("intItemTypeID", "");
      setValue("intItemCategoryID", "");
    }
  }, [multipleItemList, setValue]);

  useEffect(() => {
    dispatch(getUOM());
    dispatch(getItemType());
    dispatch(getItemCategory());
    dispatch(getItemSubCategory());
    dispatch(getCatalougList());
    // dispatch(getItemCategory())
  }, []);

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        <div className="mt-5">
          <h3 className="mb-0 pb-0">Add Territory</h3>
        </div>
        <hr></hr>
        <div className="form-group row"></div>
        <div className="form-group row">
        <div className="col-lg-6">
            <label className="form-label">Select Zone</label>
            <RHFInput
              as={<Select options={vesselListOptions} />}
              rules={{ required: false }}
              name="intVesselID"
              register={register}
              value={voyageInput.intVesselID}
              onChange={(option) => {
                handleChangeTextInput("strVesselName", option.label);
                handleChangeTextInput("intVesselID", option.value);
              }}
              setValue={setValue}
            />
          </div>
          <div className="col-lg-6">
            <label className="form-label">Select Region</label>
            <RHFInput
              as={<Select options={vesselListOptions} />}
              rules={{ required: false }}
              name="intVesselID"
              register={register}
              value={voyageInput.intVesselID}
              onChange={(option) => {
                handleChangeTextInput("strVesselName", option.label);
                handleChangeTextInput("intVesselID", option.value);
              }}
              setValue={setValue}
            />
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 ">
            <Form.Group>
              <Form.Label className="formFont pl-1">Territory Name</Form.Label>
              <Form.Control
                className="formHeight"
                type="text"
                placeholder="Enter Zone Name"
                name="strPartNo"
                value={itemDataInput.strPartNo}
                onChange={(e) => changeText("strPartNo", e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mt-5">
            <button className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-add-save mb-5">
              save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TerritoryAdd;
