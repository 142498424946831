import React from "react";
import DamageEdit from "./DamageEdit";

const DamageEditContainer = () => {
  return (
    <div className="container-fluid">
      <DamageEdit />
    </div>
  );
};

export default DamageEditContainer;
