import Axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const damagedemandSubmit = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,salesGroupId) => async (dispatch) => {

  let newList = [];
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
 
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/InsertDamageSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.damagE_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&DistribCat=${submitData?.distribCat}&salesGroupId=${submitData?.disSalesGroupId}`;
    await Axios
      .post(postUrl, newList)
      .then(function (response) {
    
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.DAMAGE_DEMAND_SUBMIT, payload: responseList });
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.DAMAGE_DEMAND_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.DAMAGE_DEMAND_SUBMIT, payload: responseList });
      });
  } catch (error) {
   
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.DAMAGE_DEMAND_SUBMIT, payload: responseList });
  }
};

export const getProductListForDamage = (salesType,option) => async (dispatch) => {
  console.log('option :>> ', option);
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageProductItem?unitId=98&DistribCat=${salesType}&salesGroup=${option.value?.salesGroupId}`;
  console.log('damage - url', url);
  await Axios.get(url).then((res) => {
    console.log('damage - res', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGED_PRODUCT_LIST, payload: responseList });
};

export const productDemageQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_DAMAGE_PRODUCT_QUANTITY, payload: formData });
};

export const getCustomerBalanceForDamage = (customerId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDamageBalance?CustomerID=${customerId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};

export const getCustomerBalanceForTrade = (customerId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDamageBalanceForTrade?CustomerID=${customerId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};
export const showDamageApprovedReport = (fromDate, todate, areaId,territoryId, salesTypeId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DAMAGE_APPROVED_SALES_REPORT, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDamageDemandConfirmReport?FromDate=${fromDate}&ToDate=${todate}&AreaID=${areaId}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;

  await Axios.get(url).then((res) => {
  console.log('ef-res', res);
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_DAMAGE_APPROVED_SALES_REPORT, payload: responseList });
};
export const getDamageSalesList = (areaid,territoryId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderReport?AreaID=${areaid}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;

  await Axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const getDamageList = (areaid,territoryId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDamageReportReport?AreaID=${areaid}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;

  await Axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_PRODUCT_DEMAND_LIST, payload: responseList });
};

