import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pdf from "react-to-pdf";
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { divisionSalesReport, territorySalesReport, ZoneSalesReport } from '../../../information/_redux/actions/Report/ReportAction';
import Print from '../../../../master/PrintDwopdown/Print';
import LoadingSpinner from '../../../../master/spinner/LoadingSpinner';
import { areaSalesReport } from '../../../information/_redux/actions/Report/ReportAction';
import { useState } from 'react';
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FaSearch, FaSave, FaCalculator, FaFileExcel, FaClock } from 'react-icons/fa';
import PrintNew from '../../../../master/PrintDwopdown/PrintNew';

import { format, subDays } from 'date-fns';
import DatePicker from "react-datepicker";
import { dateTimeConvertLongStringForDate } from '../../../../../domains/CCO/utils/DateHelper';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};


const DivisionWiseDashboard = () => {

    const { register, control, setValue } = useForm();

    const divisionReports = useSelector((state) => state.target.divisionReports);
    const divisionReportsLoading = useSelector((state) => state.target.divisionReportsLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        const d = new Date();
        const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
        const previousDay = new Date(d);
        previousDay.setDate(d.getDate() - 1);

        const firstD = formatDate(firstDayOfMonth);
        const currentD = formatDate(previousDay);

        dispatch(ZoneSalesReport(firstD, currentD, 1));
        dispatch(divisionSalesReport(firstD, currentD, 1));
        dispatch(areaSalesReport(firstD, currentD, 1));
        dispatch(territorySalesReport(firstD, currentD, 1));
    }, [])

    
    return (
        <div className='card fullcardbg m-3' style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
        }}>
            <div>
                <div className='col-lg-12'>
                    {divisionReportsLoading && <NewLoader2 />}
                    <div className="table-responsive pl-5 pr-5 pb-5  ">
                        <table id="table-to-xls" className="printtablenew1 text-center">
                            <tbody>
                                {divisionReports?.length > 0 && <>
                                    <tr className="backcolor1">
                                    <th className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>POSITION</th>
                                        <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>DIVISION</th>
                                    </tr>
                                </>
                                }
                                {divisionReports?.length > 0 &&
                                    divisionReports?.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td className='text-center'>{index+1}</td>
                                                <td>{item.zone_name}</td>

                                            </tr>
                                        );
                                    })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DivisionWiseDashboard

