import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment,
  } from 'react';
  import moment from 'moment';

  
  const SessionTimeout = () => {
 
    const [events, setEvents] = useState(['click', 'load', 'scroll']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [tokenStatus, setTokenStatus] = useState(false);

  
    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();
    // start inactive check
    let timeChecker = () => {
     
      startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
        warningInactive(storedTimeStamp);
      }, 900000);
     
    };



    const logout =()=>{
        const data = localStorage.removeItem("userData");
        const removeToken = localStorage.removeItem("access_token");
        const removeshowCard = localStorage.removeItem("showCard");
        setTokenStatus(false);
        window.location.href = "/auth/login";
    }
  
    // warning timer
    let warningInactive = (timeString) => {
      clearTimeout(startTimerInterval.current);
      warningInactiveInterval.current = setInterval(() => {
        const maxTime = 15;
        const popTime = 1;
  
        const diff = moment.duration(moment().diff(moment(timeString)));
       
        const minPast = diff.minutes();
        const leftSecond = 900 - diff.seconds();
  
        if (minPast === popTime) {
          setSecond(leftSecond);
          setOpen(true);
        }
  
        if (minPast === maxTime) {
          clearInterval(warningInactiveInterval.current);
          setOpen(false);
          sessionStorage.removeItem('lastTimeStamp');
         logout();
        }
      }, 1000);
    };
  
    // reset interval timer
    let resetTimer = useCallback(() => {
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
      timeStamp = moment();
    const data = localStorage.getItem("userData");
    if(data !== null){
        setTokenStatus(true);
        timeStamp = moment();
        
       sessionStorage.setItem('lastTimeStamp', timeStamp);
    }else{
        setTokenStatus(false);
        clearInterval(warningInactiveInterval.current);
       sessionStorage.removeItem('lastTimeStamp');
    }
      timeChecker();
      setOpen(false);
    }, [tokenStatus]);
  
    // handle close popup
    const handleClose = () => {
      setOpen(false);
  
      resetTimer();
    };
  
    useEffect(() => {
     
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
  
      timeChecker();
  
      return () => {
        clearTimeout(startTimerInterval.current);
        //   resetTimer();
      };
    }, [resetTimer, events, timeChecker]);

   
  
    if (!isOpen) {
      return null;
    }

  
    // change fragment to modal and handleclose func to close
    return <Fragment />;
  };
  
  export default SessionTimeout;