import React from "react";
import ReactToPrint from "react-to-print-advanced";
import { FaPrint } from "react-icons/fa";

const PrintNew = (props) => {
  const componentRef = props.refvalue;
 

  return (
    <>
      {
        <ReactToPrint  
          trigger={() => <button style={{ 
            display: 'flex', 
            alignItems: 'center', 
            background: 'orange', 
            color: 'white', 
            padding: '5px 10px', 
            borderRadius: '5px', 
            cursor: 'pointer',
            border: '2px solid orange' // Add border with 1px width and orange color
        }}>
            <FaPrint style={{ marginRight: '5px' }} />
            PDF
        </button>
        
        }
          content={() => componentRef.current}
         
        />
      }
    </>
  );
};
export default PrintNew;
