import React from 'react'
import { GetImsMenuTrackingSystem } from '../../../modules/Sales/information/_redux/actions/SecondarySales/SecondaryAction';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const WelCome = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        getLocation();
      }, [])
    
      const getLocation = () => {
        if ("geolocation" in navigator) {
    
          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
    
          
    
            const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;
    
            fetch(url)
              .then(response => {
                if (!response.ok) {
    
                  dispatch(GetImsMenuTrackingSystem("Welcome Dashboard", "Login", "mac", "0", "0", "0", "Location Not Allowed"));
                  throw new Error('Network response was not ok');
    
                }
                return response.json();
              })
              .then(data => {
                console.log('Location Data:', data?.display_name);
    
                dispatch(GetImsMenuTrackingSystem("Welcome Dashboard", "Login", "mac", "0", latitude, longitude, data?.display_name));
              })
              .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
              });
          }, (error) => {
            console.error("Error getting geolocation:", error);
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      };


    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>WELCOME</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>TO</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>AKIJ</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>BAKERS</strong>
                </h1>
            </div>
            {/* <div className='mb-5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ textAlign: 'center' }}>
                    <Link onClick={() => gotoDashboardPage()} >
                        <Button size="lg" className="btn btn-success">
                            <FaTable style={{ marginRight: '5px' }} />Click Here To Show Dashboard
                        </Button>
                    </Link>
                </span>
            </div> */}
            {/* <marquee className="card-title font-weight-bolder text-red" behavior="scroll" direction="left">IMS থেকে Dashbord অংশটি সাময়িক সময়ের জন্য বন্ধ রাখা হয়েছে।</marquee> */}
            {/* <h3 className="card-title font-weight-bolder text-danger text-center" behavior="scroll" direction="left">***IMS থেকে Dashboard অংশটি সাময়িক সময়ের জন্য বন্ধ রাখা হয়েছে***</h3> */}
            {/* <div className='text-center mt-4'>
                <img
                    src="/media/bg/notice.jpg"
                    alt="Notice"
                    width="350"
                    height="370"
                    style={{
                        border: '1px solid yellow',
                        borderRadius: '10px', // Optional: Add border-radius for rounded corners
                        boxShadow: '0 0 8px 3px red', // Box shadow for the lighting-blowing effect
                        transition: 'box-shadow 0.3s ease-in-out', // Optional: Add transition for a smooth effect
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 20px 10px red'; // Increase the box shadow on hover
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 10px 5px red'; // Reset box shadow when not hovering
                    }}
                />
            </div> */}



        </>
    )
}

export default WelCome