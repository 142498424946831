import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetBradeBurnTopCard } from "../../_redux/actions/Dashboard/BradeBurnDashboardAction";
import { FaUserAlt, FaMapMarkerAlt, FaMapMarkedAlt, FaTruck, FaChartLine, FaChartArea } from "react-icons/fa";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { getSoInformation } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { Col, Row } from "react-bootstrap";
import LotiAnimation from "../Dashboard/LotiAnimation";
import { GiFlyingTarget } from "react-icons/gi";
import SOMobileDashboard from "./SOMobileDashboard";

export function CircleChartBreadBurn() {
  const topCard = useSelector((state) => state.breadburn.topcardValue);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetBradeBurnTopCard("BRA"));
  }, [dispatch]);

  const achievement = topCard?.achivement ? parseFloat(topCard.achivement) : 0;
  const [animatedAchievement, setAnimatedAchievement] = useState(0);
  const [animatedDemand, setAnimatedDemand] = useState(0);
  const [animatedDelivery, setAnimatedDelivery] = useState(0);

  const [soInfo, setSoInfo] = useState({
    zoneName: null,
    divisionName: null,
    areaName: null,
    soName: null,
    soPointID: null,
    soid: null,
    distribName: null,
    soPointName: null,
    territoryName: null,
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialData = async () => {
    let soDetails = await getSoInformation();

    if (soDetails.data != null) {
      const {
        zoneName,
        divisionName,
        areaName,
        soName,
        soPointID,
        soid,
        distribName,
        soPointName,
        territoryName,
      } = soDetails.data;
      const soInfomation = { ...soInfo };
      soInfomation.zoneName = zoneName;
      soInfomation.divisionName = divisionName;
      soInfomation.areaName = areaName;
      soInfomation.territoryName = territoryName;
      soInfomation.soName = soName;
      soInfomation.soPointID = soPointID;
      soInfomation.soid = soid;
      soInfomation.distribName = distribName;
      soInfomation.soPointName = soPointName;
      setSoInfo(soInfomation);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  // Animate achievement value slowly
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedAchievement((prev) => {
        if (prev < achievement) {
          return prev + 0.2; // Slower increment for smoother animation
        } else {
          clearInterval(interval);
          return achievement;
        }
      });
    }, 50); // Increased interval time for slower animation
    return () => clearInterval(interval);
  }, [achievement]);

  // Animation for Demand
  useEffect(() => {
    const demandValue = topCard?.demand || 0;
    const interval = setInterval(() => {
      setAnimatedDemand((prev) => {
        if (prev < demandValue) {
          return Math.min(prev + (demandValue / 100), demandValue); // Slower increment for Demand animation
        } else {
          clearInterval(interval);
          return demandValue;
        }
      });
    }, 50); // Slower animation speed
    return () => clearInterval(interval);
  }, [topCard?.demand]);

  // Animation for Delivery
  useEffect(() => {
    const deliveryValue = topCard?.delivery || 0;
    const interval = setInterval(() => {
      setAnimatedDelivery((prev) => {
        if (prev < deliveryValue) {
          return Math.min(prev + (deliveryValue / 100), deliveryValue); // Slower animation speed
        } else {
          clearInterval(interval);
          return deliveryValue;
        }
      });
    }, 50); // Slower animation speed
    return () => clearInterval(interval);
  }, [topCard?.delivery]);

  const data = [
    { name: "Achieved", value: animatedAchievement },
    { name: "Remaining", value: 100 - animatedAchievement },
  ];

  const COLORS = ["#ffbc00", "#dcdcdc"];

  return (
    <>
      {/* Check for screen size and conditionally render */}
      {windowWidth < 768 ? (
        <SOMobileDashboard />
      ) : (
        <div className="container-fluid">
          {/* Name and Distributor Section (Row 1) */}
          <Row className="mb-4">
            {/* Name Card */}
            <Col lg={6} md={6} className="mb-3">
              <div className="card shadow-sm p-3 rounded" style={{ backgroundColor: '#66CDAA' }}>
                <div className="d-flex align-items-center">
                  <FaUserAlt size={30} className="mr-3" />
                  <div>
                    <h6 className="text-light">Name</h6>
                    <h5 className="text-truncate">{soInfo.soName + " [" + soInfo.soPointName + "]"}</h5>
                  </div>
                </div>
              </div>
            </Col>

            {/* Distributor Card */}
            <Col lg={6} md={6} className="mb-3">
              <div className="card shadow-sm p-3 rounded" style={{ backgroundColor: '#E5DD80' }}>
                <div className="d-flex align-items-center">
                  <FaMapMarkedAlt size={30} className="mr-3" />
                  <div style={{ maxWidth: windowWidth < 768 ? '300px' : 'none' }}>
                    <h6 className="text-light">Distributor</h6>
                    <h5 className="text-truncate">{soInfo.distribName}</h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Achievement and Metrics Section (Row 2) */}
          <Row className="mb-4">
            {/* Left side: Achievement Chart */}
            <Col lg={6} md={6} className="mb-3">
              <div className="card shadow-lg p-4 text-center rounded bg-light position-relative">
                <ResponsiveContainer width="100%" height={175}>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius="60%"
                      outerRadius="90%"
                      dataKey="value"
                      stroke="none"
                      isAnimationActive={true}
                      animationDuration={2000}
                      animationEasing="ease-out"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Legend verticalAlign="bottom" height={16} />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>

                <div className="achievement-text mt-3">
                  <h5 className="text-muted">Achievement</h5>
                  <h2>{animatedAchievement < 100 ? animatedAchievement.toFixed(1) + "%" : Math.floor(animatedAchievement) + "%"}</h2>
                  <p>{animatedAchievement >= 100 ? "Congratulations!" : "Keep going!"}</p>
                </div>

                {/* Celebration Component */}
                {animatedAchievement >= 100 && (
                  <div className="position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10 }}>
                    <LotiAnimation />
                  </div>
                )}
              </div>
            </Col>

            {/* Right side: Zone, Division, Region, Territory */}
            <Col lg={6} md={6}>
              <Row className="mb-3">
                {/* Zone Card */}
                <Col sm={6} lg={6}>
                  <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#64B5F6', border: 'none' }}>
                    <div className="d-flex align-items-center">
                      <FaMapMarkerAlt size={30} className="mr-3" />
                      <div>
                        <h6 className="text-light">Zone</h6>
                        <h5>{soInfo.zoneName}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* Division Card */}
                <Col sm={6} lg={6}>
                  <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#B7B7E5', border: 'none' }}>
                    <div className="d-flex align-items-center">
                      <FaMapMarkerAlt size={30} className="mr-3" />
                      <div>
                        <h6 className="text-light">Division</h6>
                        <h5>{soInfo.divisionName}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* Region Card */}
                <Col sm={6} lg={6}>
                  <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#FF8A80', border: 'none' }}>
                    <div className="d-flex align-items-center">
                      <FaMapMarkerAlt size={30} className="mr-3" />
                      <div>
                        <h6 className="text-light">Region</h6>
                        <h5>{soInfo.areaName}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* Territory Card */}
                <Col sm={6} lg={6}>
                  <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#FFC3A0', border: 'none' }}>
                    <div className="d-flex align-items-center">
                      <FaMapMarkerAlt size={30} className="mr-3" />
                      <div>
                        <h6 className="text-light">Territory</h6>
                        <h5>{soInfo.territoryName}</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* Demand Card with Animation */}
              <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#FFBC00', border: "none" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FaChartLine size={30} className="mr-3 text-light" />
                    <h5 className="text-light">IMS</h5>
                  </div>
                  <h4 className="text-bold">{numberFormatDecemal(Math.floor(animatedDemand))}</h4>
                </div>
              </div>

              {/* Delivery Card with Animation */}
              <div className="card shadow-lg p-4 mb-4" style={{ backgroundColor: '#FF8A80', border: "none" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <GiFlyingTarget size={30} className="mr-3 text-light" />
                    <h5 className="text-light">Target</h5>
                  </div>
                  <h4 className="text-bold">{numberFormatDecemal(Math.floor(animatedDelivery))}</h4>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default CircleChartBreadBurn;
