import React from "react";
import BradBurnCumalitiveByAsm from "../../../information/components/RemoteSales/BradBurnReports/BradBurnCumalitiveByAsm";


const PrimarySalesCumalitiveContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        < BradBurnCumalitiveByAsm/>
      </div>
    </div>
  );
};
export default PrimarySalesCumalitiveContainer;
