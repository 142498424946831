import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import {
  getItemCategory,
  getItemList,
  getItemSubCategory,
} from "../../../_redux/actions/ItemAction";

const SecondarySalesApprovedFilter = ({ currentPage}) => {

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");

  const ref = React.createRef();

  useEffect(() => {
    dispatch(getItemCategory());
    dispatch(getItemSubCategory());
    dispatch(getItemList(currentPage));
  }, []);
  useEffect(() => {
    dispatch(getItemList(currentPage));
  }, [dispatch, currentPage]);

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getItemList(currentPage));
    } else {
      dispatch(getItemList(currentPage, searchText));
    }
  };

  const handleChangeTextInput = () => {};
  const department = [
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Approved",
    },
  ];
  return (
    <>
        <div className="mt-2 p-2">
        <span className="button-010">Secondary Sales For Approve</span>
          {/* <h3 className="mb-0 pb-0">Secondary Sales For Approve</h3> */}
        </div>
      
      <div className="row">
        {/* <div className="col-lg-8 col-md-8">
          <Form.Group as={Col} controlId="formGridState">
            <input
              type="search"
              value={searchText}
              className="form-control product-search-input formHeight"
              placeholder="Search by order No"
              onChange={searchItems}
            />
          </Form.Group>
        </div> */}
        <div className="col-lg-10 col-md-10">
          <div className="certificate-filter">
            {/* <i className="fas fa-filter tableFilter">
            </i> */}

            {/* <div className="export text-right">
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  className="btn btn-sm"
                  variant="light text-primary"
                  id="dropdown-basic"
                >
                  Export
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Pdf
                    targetRef={ref}
                    filename="Certificate Type.pdf"
                    x={0.5}
                    y={0.5}
                    scale={0.93}
                  >
                    {({ toPdf }) => (
                      <Dropdown.Item onClick={toPdf}>
                        <i class="far fa-file-pdf"></i>
                        <span className="ml-3">Pdf</span>
                      </Dropdown.Item>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item href="#/action-1" onClick={window.print}>
                        <i class="fas fa-print"></i>
                        <span className="ml-2">Print</span>
                      </Dropdown.Item>
                    )}
                    content={() => ref.current}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
         
        </div>
        {/* <div className="col-md-2">
          <Link
            to="/remoteSales/add"
            className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn"
          >
            Add News
          </Link>
        </div>    */}
      </div>
      
    </>
  );
};

export default SecondarySalesApprovedFilter;
