import { indexOf } from "lodash";
import * as Types from "../../types/SecondarySalesTypes";

const initialState = {
    rollbacklist: [],
    rollbackData:[],
    allCheckbox: false,
    unapprovedList:[],
    allReport:[]
    

};
const SecondaryRollbackReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_ROLL_BACK_LIST:
            let newData = action.payload.data.slice();
            if(newData.length>0){
                 for (let i = 0; i < newData.length; i++) {
                newData[i].isPresent = false
            } 
            }
            return {
                ...state,
                rollbacklist: newData,
            };
        case Types.REMOTE_CHECKBOX_STATUS:
            let checkboxEm = state.rollbacklist.slice();
            let allCheck = false;
            for (let i = 0; i < checkboxEm.length; i++) {
                if (checkboxEm[i].salesID == action.payload.data.item.salesID) {
                    checkboxEm[i].isPresent = !checkboxEm[i].isPresent

                }
            }
            return {
                ...state,
                rollbacklist: checkboxEm,
                allCheckbox: allCheck
            };
        case Types.REMOTE_CHECKBOX_ALL:
           
            let chekboxData = state.rollbacklist.slice();
            for (let i = 0; i < chekboxData.length; i++) {
                if (state.allCheckbox == true) {
                    chekboxData[i].isPresent = false
                } else {
                    chekboxData[i].isPresent = true
                }
            }
            return {
                ...state,
                allCheckbox: !state.allCheckbox,
                remoteEmployee: chekboxData,
            };
        case Types.GET_SECONDARY_UNAPPROVED_LIST:
            return {
                ...state,
                unapprovedList: action.payload.data,
                allReport:action.payload.data.allReport
            };
        default:
            break;
    }
    return newState;
};

export default SecondaryRollbackReducer;
