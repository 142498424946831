import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { getCumalativeReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import jsPDF from "jspdf";
import "jspdf-autotable";

const PER_PAGE = 10;
const DemoTablePDF = () => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const componentRef = useRef();
  const isLoading = useSelector((state) => state.secondarySales.distributorwiseLoading);
  const demandlist = useSelector((state) => state.secondarySales.cumalative);
  console.log('demandlist', demandlist)
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(demandlist.length / PER_PAGE);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState("distributorPointId");
  const [searchTerm, setSearchTerm] = useState("");


  const downloadAsPDF = () => {
    const doc = new jsPDF();
    const tableConfig = {
      margin: { top: 18, right: 8, bottom: 10, left: 8 },
      styles: { fontSize: 4, cellPadding: 1 },
      headStyles: { valign: "middle", fillColor: [108, 143, 192] },
    };

    // Add logo
    const logoUrl = "/media/logos/abl_logo.png";
    const logoWidth = 16;
    const logoHeight = 16;
    const logoX = 3;
    const logoY = 2;
    doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);

    // Add title
    doc.setFontSize(8);
    const titleText = "Product Wise Target";
    const titleWidth = doc.getTextDimensions(titleText).w;
    const titleX = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
    doc.text(titleText, titleX, 10);

    // Add sub text
    doc.setFontSize(6);
    const text = "This is the PDF report for product-wise targets.";
    const textWidth = doc.getTextDimensions(text).w;
    const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
    doc.text(text, textX, 13);

    const columns = [
      { header: "ZONE", dataKey: "zone" },
      { header: "DIVISION", dataKey: "division" },
      { header: "REGION", dataKey: "region" },
      { header: "TERRITORY", dataKey: "territory" },
      { header: "DISTRIBUTION ID", dataKey: "distributionId" },
      { header: "DISTRIBUTOR NAME", dataKey: "distributionName" },
      { header: "DISTRIBUTOR POINT ID", dataKey: "distributorPointId" },
      { header: "DISTRIBUTOR POINT NAME", dataKey: "distributorPointName" },
      { header: "NUMBER OF MEMO LINE", dataKey: "memoLine" },
      { header: "TOTAL MEMO QTY", dataKey: "memoQty" },
      { header: "TOTAL VISITED OUTLET", dataKey: "visitedOutlet" },
      { header: "TOTAL ORDER VALUE", dataKey: "orderValue" },
      { header: "BISCUIT QUANTITY", dataKey: "biscuitQty" },
      { header: "BISCUIT VALUE", dataKey: "biscuitValue" },
      { header: "CAKE QUANTITY", dataKey: "cakeQty" },
      { header: "CAKE VALUE", dataKey: "cakeValue" },
      { header: "COOKIES QUANTITY", dataKey: "cookiesQty" },
      { header: "COOKIES VALUE", dataKey: "cookiesValue" },
      { header: "WAFER QUANTITY", dataKey: "waferQty" },
      { header: "WAFER VALUE", dataKey: "waferValue" },
      { header: "TOTAL QUANTITY", dataKey: "totalQty" },
      { header: "TOTAL VALUE", dataKey: "totalValue" },
    ];

    const tableData = demandlist.map((item) => ({
      zone: item.zoneName,
      division: item.divisionName,
      region: item.areaName,
      territory: item.territoryName,
      distributionId: item.distributionId,
      distributionName: item.distributionName,
      distributorPointId: item.distributorPointId,
      distributorPointName: item.distributorPointName,
      memoLine: parseInt(item.memoLine),
      memoQty: parseInt(item.memoQty),
      visitedOutlet: parseInt(item.visitedOutlet),
      orderValue: parseInt(item.orderValue),
      biscuitQty: item.biscuteQty,
      biscuitValue: parseFloat(item.biscutePrice).toFixed(2),
      cakeQty: item.cakeQty,
      cakeValue: parseFloat(item.cakePrice).toFixed(2),
      cookiesQty: item.cookisQty,
      cookiesValue: parseFloat(item.cookisPrice).toFixed(2),
      waferQty: parseFloat(item.wiferQty),
      waferValue: parseFloat(item.wiferPrice).toFixed(2),
      totalQty: parseInt(item.totalQuantity),
      totalValue: parseFloat(item.totalPrice).toFixed(2),
    }));

    doc.autoTable(columns, tableData, tableConfig);
    doc.save("product_wise_target.pdf");
  };

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType) => {
    dispatch(getCumalativeReport(fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType));
  };

  useEffect(() => {
    handleListAction("2023-07-01", "2023-07-18", 2, 0, 0, 0, 2);
  }, []);

  //For Sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
      setSearchTerm(""); // Reset search term
    }
  };

  // Sorting logic
  const sortedList = useMemo(() => {
    if (!Array.isArray(demandlist)) {
      return [];
    }

    const filteredList = demandlist.filter(
      (item) =>
        !searchTerm ||
        String(item.distributorPointId).includes(searchTerm)
    );

    return filteredList.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
      } else {
        return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
      }
    });
  }, [demandlist, searchTerm, sortOrder, sortedColumn]);

  //Pagination
  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <span className="button-010">Demo Table</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="col mt-5 mb-5">
          {!isLoading && demandlist.length > 0 && (
            <button className="button-pdf ml-2" onClick={downloadAsPDF}>Download as PDF</button>
          )}
        </div>
        <div>
          {isLoading && <LoadingSpinner />}
          {!isLoading && demandlist.length === 0 && (
            <div className="text-center mt-5 mb-5">
              <h5 className="bg-warning p-2">No Data Found</h5>
            </div>
          )}
          {!isLoading && demandlist.length > 0 && (
            <div>
              <div className="card-custom card-stretch gutter-b">

                <div className="searchBox pl-5">
                  <form className="form float-right">
                    <button>
                      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </button>
                    <input
                      className="input"
                      placeholder="Search Dis Point ID"
                      required=""
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </form>
                </div>

                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="table-responsive">
                      <div className="table-container">
                        <div>
                          <table className="table table-bordered table-hover" id="table-to-xls">
                            <thead>
                              <tr>
                                <th scope="col">ZONE</th>
                                <th scope="col">DIVISION</th>
                                <th scope="col">REGION</th>
                                <th scope="col">TERRITORY</th>
                                <th scope="col">DISTRIBUTION ID</th>
                                <th scope="col">DISTRIBUTOR NAME</th>
                                <th scope="col" onClick={() => handleSort("distributorPointId")}>
                                  DISTRIBUTOR POINT ID{" "}
                                  {sortedColumn === "distributorPointId" && (
                                    <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                                  )}


                                </th>
                                {/* Remaining table headers (unchanged) */}
                              </tr>
                            </thead>
                            <tbody>
                              {sortedList
                                .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
                                .map((item, index) => (

                                  <tr key={index}>
                                    <td>{item.zoneName}</td>
                                    <td>{item.divisionName}</td>
                                    <td>{item.areaName}</td>
                                    <td>{item.territoryName}</td>
                                    <td>{item.distributionId}</td>
                                    <td>{item.distributionName}</td>
                                    <td>{item.distributorPointId}</td>
                                    {/* Remaining table data cells (unchanged) */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
      
                          <div className="pagination">
                            {currentPage > 1 && (
                              <>
                                <a href="#" onClick={(e) => handleClick(e, currentPage - 1)}>
                                  &lt; {/* Left arrow */}
                                </a>
                                <a href="#" onClick={(e) => handleClick(e, 1)}>1</a>
                                {currentPage > 3 && <span>...</span>}
                              </>
                            )}

                            {Array.from({ length: pageCount }, (_, i) => {
                              if (
                                i + 1 === 1 ||
                                i + 1 === pageCount ||
                                (i + 1 >= currentPage - 1 && i + 1 <= currentPage + 1)
                              ) {
                                return (
                                  <a
                                    href="#"
                                    key={i}
                                    onClick={(e) => handleClick(e, i + 1)}
                                    className={currentPage === i + 1 ? "active" : ""}
                                  >
                                    {i + 1}
                                  </a>
                                );
                              }
                              return null;
                            })}

                            {currentPage < pageCount && (
                              <>
                                {currentPage < pageCount - 2 && <span>.....</span>}
                                <a href="#" onClick={(e) => handleClick(e, pageCount)}>
                                  {pageCount}
                                </a>
                                <a href="#" onClick={(e) => handleClick(e, currentPage + 1)}>
                                  &gt; {/* Right arrow */}
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DemoTablePDF;
