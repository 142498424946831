import Axios from "axios";
import * as Types from "../../types/SecondarySalesTypes";

export const getBreadBurnReportByAsm = (fromDate,toDate,zoneId,divisionId,areaId) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let url = "";
       url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSalesDeliveryReportPivit?Fdate=2022-03-01&TDate=2022-03-28`;

    // if(zoneId !== null){
    
    //     url +=`&ZoneID=${zoneId}`
    // }
    // if(divisionId !== null){
        
    //     url +=`&DivisionID=${divisionId}`
    // }
    // if(areaId !== null){
    //     url +=`&AreaID=${areaId}`
    // }
    // if(fromDate !== null){
    
    //     url +=`&FDate=${fromDate}`
    // }
    // if(toDate !== null){
    
    //     url +=`&TDate=${toDate}`
    // }
   

    await Axios.get(url).then((res) => {

      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_BRAED_BURN_CUMALATIVE, payload: responseList });
  };