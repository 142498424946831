import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

import * as XLSX from "xlsx/xlsx.mjs";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import {  GetApprovedSummeryDamageReportSkuWise, GetApprovedSummeryDamageReportSkuWiseExcel } from "../../_redux/actions/RemoteSales/DamageAction";
import { FaDownload, FaSearch } from "react-icons/fa";

const ApprovedSummaryDamageReportSKUwise = () => {
  const damageData = useSelector((state) => state.damage.damageApprovedDataSKUWise);
  const damageSummaryLoading = useSelector((state) => state.damage.damageSummaryLoading);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectCategoryLable, setSelectCategoryLable] = useState(null);
  const componentRef = useRef();

  const { register, setValue } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hanleExcelClick = async () => {
    setLoading(true);
    setError(null);

    try {


      let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
      let conToDate = skuDate.todate + 'T' + skuDate.toTime;
      let excelClick = await GetApprovedSummeryDamageReportSkuWiseExcel(
        conFromDate,
        conToDate,
        0,
        0,
        0,
        0
      );
      const current = currentdate();
      let Heading = [[
        "ZoneID",
        "ZoneName",
        "DivisionID",
        "DivisionName",
        "RegionId",
        "RegionName",
        "TerritoryId",
        "TerritoryName",
        "DisPointID",
        "DisPointName",
        "DistibID",
        "DistibName",
        "ProductID",
        "ProductName",
        "ProdCatID",
        "ProdCatName",
        "SalesGroup",
        "BrandName",
        "PackTypeName",
        "ProductUom",
        "DamageName",
        "ProductDMG_PCQty",
        "ProductDMGPrice",
        "ProductLiftingPCQty",
        "ProductLiftingPrice",
      ]];

      //Had to create a new workbook and then add the header
      const wb = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
      const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
      XLSX.writeFile(wb, 'Damage vs Lifting' + " " + current + '.xlsx');

    } catch (error) {
      console.error("Error:", error);
      setError("Network Error");
    } finally {
      setLoading(false);
    }

  };

  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectCategory) => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    dispatch(GetApprovedSummeryDamageReportSkuWise(conFromDate, conToDate, idZone, idDivision, idArea, idTerritory, selectCategory));
  }

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const catdata = [
    { value: "0", label: "All" },
    { value: "1", label: "Cake" },
    { value: "2", label: "Biscuit" },
  ];


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              {/* <span className="button-010">Damage Summery Report(SKU Wise) - Approved Data</span> */}
              <h4>Damage Summery Report(SKU Wise)</h4>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mt-2 p-2 float-right">
            <DownloadDropdown excelname='Damage Summery Report(SKU Wise)' refvalue={componentRef} />
              <Button
                size="sm"
                onClick={hanleExcelClick}
                disabled={loading}
                className={loading ? 'bg-danger' : ''}
              >
                <FaDownload /> {loading ? 'Loading...' : 'Download'}
              </Button>
              {error && <div>Error: {error}</div>}
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div> */}
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={`${skuDate.fromDate}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                value={`${skuDate.todate}`}
                onChange={(e) => handleChange(e, 'todate')}
              />
            </Form.Group>
          </div>
          
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Category</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={catdata} />}
                rules={{ required: false }}
                name="category"
                register={register}
                value=""
                onChange={(option) => {
                  setSelectCategory(option?.value);
                  setSelectCategoryLable(option?.label);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 mt-5 mb-5">
            <Button  size="sm"  onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectCategory)} > <FaSearch /> Show Report</Button>
            {/* <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectCategory)}>Show Report</Button> */}
          </div>

        </div>


        <div className="row" id="id" ref={inputField}>
          {damageSummaryLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

            <div
              className="stickyTable"
              style={{ height: damageData?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>

                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">DISPOINT ID</th>
                    <th scope="col">DISPOINT NAME</th>
                    <th scope="col">DISTRIBUTOR ID</th>
                    <th scope="col">DISTRIBUTOR Name</th>
                    <th scope="col">SKU CODE</th>
                    <th scope="col">SKU NAME</th>
                    <th scope="col">Category</th>
                    <th scope="col">DAMAGE CATEGORY </th>
                    {/* <th scope="col">DAMAGE Price</th> */}
                    <th scope="col">DAMAGE PCS</th>
                    <th scope="col">DAMAGE VALUE</th>
                    <th scope="col">Lifting CTN</th>
                    <th scope="col">Lifting VALUE</th>
                    {/* <th scope="col">ACV %</th> */}
                  </tr>
                </thead>
                <tbody>
                  {damageData?.length > 0 &&
                    damageData?.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.regionName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.disPointID}</td>
                          <td>{item.disPointName}</td>
                          <td>{item.distibID}</td>
                          <td>{item.distibName}</td>
                          <td>{item.productID}</td>
                          <td>{item.productName}</td>
                          <td>{item.prodCatName}</td>
                          <td>{item.damageName}</td>
                          <td>{item.productDMG_PCQty}</td>
                          <td>{item.productDMGPrice}</td>
                          <td>{item.productLiftingPCQty}</td>
                          <td>{item.productLiftingPrice}</td>
                          {/* <td>{item.productPrice_Delivery !== 0 ? parseFloat((item.damageTPrice / item.productPrice_Delivery) * 100).toFixed(2) : 0}%</td> */}

                        </tr>

                      </>
                    ))}


                </tbody>
              </table>
            </div>

          </div>
        </div>





      </div>
    </>
  );
};

export default ApprovedSummaryDamageReportSKUwise;
