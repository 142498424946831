import React from "react";
import SecondarySalesDateWiseReport from "../../../information/components/SecodarySales/SecondarySalesDateWiseReport";


const SecondaryDateWiseContainer = () => {
  return (
    <div className="container">
      <div className="">
        <SecondarySalesDateWiseReport/>
      </div>
    </div>
  );
};

export default SecondaryDateWiseContainer;
