import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DistributorReportsList from "../../../information/components/DistributorReports/DistributorReportsList";
import DistributorReportsFilter from "../../../information/components/DistributorReports/DistributorReportsFilter";


const DistributorReportsContainer = () => {

  const ref = React.createRef();
  const modalAddStatus = useSelector(
    (state) => state.CertificateListReducer.addStatus
  );


  useEffect(() => {
    if (modalAddStatus) {
      
    }
  }, [modalAddStatus]);

  return (
    <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
      
          <div className="container-fluid">
            {/* <h1 className="tableheading">Distributor Bank Receive Daily </h1> <hr /> */}
            <div className="row">
              <DistributorReportsFilter />
              {/* <div className="col-lg-3 export text-right">
                <Dropdown className="d-inline mr-2">
                  <Dropdown.Toggle
                    className="btn btn-sm"
                    variant="light text-primary"
                    id="dropdown-basic"
                  >
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Pdf
                      targetRef={ref}
                      filename="certificate_master_list.pdf"
                      x={0.5}
                      y={0.5}
                      scale={0.93}
                    >
                      {({ toPdf }) => (
                        <Dropdown.Item onClick={toPdf}>
                          <i class="far fa-file-pdf"></i>
                          <span className="ml-3">Pdf</span>
                        </Dropdown.Item>
                      )}
                    </Pdf>

                    <Dropdown.Item href="#/action-2">
                      <i class="far fa-file-excel mt-1"></i>
                      {
                        <ReactHTMLTableToExcel
                          className="excelBtn ml-1"
                          table="table-to-xls"
                          filename="certificate_master_list"
                          sheet="tablexls"
                          buttonText="Excel"
                        />
                      }
                    </Dropdown.Item>

                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item href="#/action-1" onClick={window.print}>
                          <i class="fas fa-print"></i>
                          <span className="ml-2">Print</span>
                        </Dropdown.Item>
                      )}
                      content={() => ref.current}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  className="btn btn-sm"
                  variant="primary"
                  onClick={handleShow}
                >
                  Add New
                </Button>
              </div> */}
              
            </div>
            <div id="id" ref={ref}>
              <DistributorReportsList />
            </div>
          </div>
       
    </div>
  );
};

export default DistributorReportsContainer;
