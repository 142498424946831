import React from "react";
import PrimarySalesCumalitiveBYAsm from "../../../information/components/SecodarySales/PrimarySalesCumalitiveBYAsm";




const PrimarySalesCumaAsmWiseContainer = () => {
  return (
    <div className="container">
      <div className="">
        <PrimarySalesCumalitiveBYAsm/>
      </div>
    </div>
  );
};

export default PrimarySalesCumaAsmWiseContainer;
