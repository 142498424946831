import React from "react";
import SODeliveryConfirmStatus from "../../../information/components/SecodarySales/SODeliveryConfirmStatus";

const SODeliveryConfirmStatusContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SODeliveryConfirmStatus />
      </div>
    </div>
  );
};
export default SODeliveryConfirmStatusContainer;