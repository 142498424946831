import React from 'react';
import { useSelector } from 'react-redux';
import { numberFormatDecemal } from '../../../domains/CCO/utils/DateHelper';

const TopSellingSku = () => {
    const topSellingSkuList = useSelector((state) => state.secondarySales.topSellingSkuList);
    const bottomSellingSkuList = useSelector((state) => state.secondarySales.bottomSellingSkuList);

    const isTopArray = Array.isArray(topSellingSkuList);
    const isBottomArray = Array.isArray(bottomSellingSkuList);

    const renderSkuCards = (skuList, isTop = true) => {
        return skuList.map((sku, index) => (
            <div 
                key={sku.productId} 
                style={{ 
                    backgroundColor: isTop ? '#e6f7e6' : '#f8d7da',
                    padding: '10px', 
                    margin: '5px', 
                    borderRadius: '10px', 
                    flex: '1 0 45%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: '100px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    position: 'relative'
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.03)';
                    e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
                }}
            >
                <div style={{ 
                    position: 'absolute', 
                    top: '-5px', 
                    right: '-5px', 
                    width: '30px', 
                    height: '30px', 
                    borderRadius: '50%', 
                    backgroundColor: isTop ? '#3cb371' : '#c0392b',
                    color: '#fff', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    fontWeight: 'bold' 
                }}>
                    {index + 1}
                </div>
                <div>
                    <strong>Product Name:</strong> {sku.productName} <br />
                    <strong>Total Value:</strong> <span style={{ color: isTop ? '#2e7d32' : '#c0392b' }}>{numberFormatDecemal(sku.totalValue.toFixed(2))}</span>
                </div>
            </div>
        ));
    };

    return (
        <div className="row paddingremove d-flex align-items-stretch" style={{ 
            backgroundColor: '#ffffff'
           
        }}>
            <div className='col-lg-6 paddingremove d-flex flex-column'>
                <div className='text-center' style={{ backgroundColor: '#c3e6cb', padding: '10px', margin: '5px', borderRadius: '5px', fontWeight: 'bold' }}>
                    TOP SIX SELLING SKU
                </div>
                {!isTopArray || topSellingSkuList.length === 0 ? (
                    <p className='text-center' style={{ color: '#666' }}>No data available.</p>
                ) : (
                    <div className="d-flex flex-wrap">
                        {renderSkuCards(topSellingSkuList, true)}
                    </div>
                )}
            </div>

            <div className='col-lg-6 paddingremove d-flex flex-column'>
                <div className='text-center' style={{ backgroundColor: '#f5c6cb', padding: '10px', margin: '5px', borderRadius: '5px', fontWeight: 'bold' }}>
                    BOTTOM SIX SELLING SKU
                </div>

                {!isBottomArray || bottomSellingSkuList.length === 0 ? (
                    <p className='text-center' style={{ color: '#666' }}>No data available.</p>
                ) : (
                    <div className="d-flex flex-wrap">
                        {renderSkuCards(bottomSellingSkuList, false)}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TopSellingSku;
