import React from "react";
import RemoteSalesAdd from "../../../information/components/RemoteSales/RemoteSalesAdd";



const RemoteSalesAddContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <RemoteSalesAdd/>
      </div>
    </div>
  );
};

export default RemoteSalesAddContainer;
