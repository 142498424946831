import React, { Component } from 'react';

const SimpleSalesType = (props) => {

    const {distribType,salesType}=props;
    return (
        <>
            {
                distribType == 0?
                 <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1||0? true:false}    onChange={(e)=>props.getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3  ?true:false}  onChange={(e)=>props.getSalesValue('bread',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div>: <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1?true:false} disabled={distribType==1 || 0?false:true }   onChange={(e)=>props.getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3  ?true:false} disabled={distribType==3 || 0?false:true } onChange={(e)=>props.getSalesValue('bread',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div>
              
              } 
        </>
          
    )
}
export default SimpleSalesType;