import React from "react";
import DistributorWiseDamageReport from "./DistributorWiseDamageReport";

const DistributorWiseDamageReportContainer = () => {
  return (
    <div className="container-fluid">
      <DistributorWiseDamageReport />
    </div>
  );
};

export default DistributorWiseDamageReportContainer;
