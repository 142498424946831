import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import faker from 'faker';
import LoadingSpinner from '../../../../master/spinner/LoadingSpinner';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

export function DamageChart() {

    const dispatch = useDispatch();
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const damagedata = useSelector((state) => state.dashboardcart.damagedata);
  const damageMonthData = useSelector((state) => state.dashboardcart.damageMonthData);
  const isLoading = useSelector((state) => state.dashboardcart.monthtomonthLoading);
  const TrendValue = useSelector((state) => state.dashboardcart.TrendValue);

  const verticleData = damagedata;
  const horizontalData = damageMonthData;

  let nf = new Intl.NumberFormat('en-BD');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: true
            },
            datalabels: {
                display: true,
                color: 'white',
            }
        },
        scales: {
            y: {
              title: {
                display: true,
                text: 'Millions',
                font: {
                  size: 12,
                },
              },
            },
          },
    };

    const labels = horizontalData;

    const data = {
        labels,
        datasets: [
            {
                type: 'bar',
                label: [reportData.lineName]+' Damage',
                backgroundColor: '#7d4b10',
                data: verticleData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'y',
                datalabels: {
                    display: true,
                    color: 'white',
                    align: 'bottom',
                    anchor: 'end',
                    rotation: 270,
                    formatter: function (value, context) {
                        return 'BDT ' + nf.format(value) ;
                    }
                }
            },
        ],

    };

    return (

        <div className="card mb-0 ">
            <span className="spineercolor mt-5 mb-5">{isLoading && <LoadingSpinner />}</span>
            <>
                {!isLoading && (
                    <>
                        <div className='text-center'><h5>Product Damage Cost Rolling 12 Month - At lac</h5></div>
                        <Chart options={options} data={data} height={137}  plugins={[ChartDataLabels]} />
                    </>
                )}
            </>
        </div>


    );
}
