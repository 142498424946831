import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Dropdown, Card } from "react-bootstrap";
import Pdf from "react-to-pdf";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { getDamageApprovedListDetails, getDamageProductListDetails } from "../../_redux/actions/RemoteSales/DamageAction";



const DamageApprovedListDetails = ({ handleClose, itemCode, itemDetailnIfo }) => {

    const ItemDetails = useSelector((state) => state.damage.damageApprovedListDetails);
    console.log('now damage ItemDetails', itemDetailnIfo);

    const totalQuantity = useSelector(
        (state) => state.remoteSales.totalDetQuantity
    );
    const amount = useSelector(
        (state) => state.remoteSales.totalSalesDetailsAmount
    );

    const componentRef = useRef();
    let ref = React.createRef();
    const inputField = React.useRef(null);

    console.log('details itemCode :>> ', itemCode);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDamageApprovedListDetails(itemCode));
    }, []);

    const tdStyle = {
        fontSize: 40,

    }

    const styles = {
        color: 'purple',
        fontSize: '15px',
    }

    const tbodies = ItemDetails?.map((state, index) => {
        const cityValues = Object.values(state.details);
        console.log('now state.details :>> ', state.details);
        const cityRows = cityValues.map((city, i) => {
            const stateName = i === 0 ? 
            <td rowSpan={cityValues.length + 1}>{state.productId}</td> : null
            const stateAbbreviation = i === 0 ? <td rowSpan={cityValues.length + 1}>{state.productName}</td> : null
            return (
                <tr key={i}>
                    {stateName}
                    {stateAbbreviation}
                    <td>{city.categoryName}</td>
                    <td>{city.quantity}</td>
                    <td>{city.price}</td>
                    <td>{city.totalPrice}</td>
                </tr>
            )
        })
        console.log('state :>> ', state);
        return (
            <tbody key={index}>
                {cityRows}

            </tbody>

        )
    })

    return (
        <>
            {
                ItemDetails?.length > 0
                && <div className=" p-5 mb-3 " >
                    <div className="row mb-10">
                        <div className="col-md-9"></div>
                        <div className="col-md-3 mb-3">
                            <DownloadDropdown excelname='Details' refvalue={componentRef} />
                        </div>

                    </div>


                    <div ref={componentRef}>
                        <div className="row">
                            {/* <div className="col-lg-12">
                                <h2 className="mb-0 pb-0 text-center text-bold">{ItemDetails?.custName}</h2>
                                <h2 className="mb-0 pb-0 text-center mb-4">Invoice ID: <b> {ItemDetails?.orderCode}</b>  </h2>
                            </div> */}
                        </div>
                        <table className="table table-head-custom borderTableims" id="table-to-xls">
                            <thead>
                                <tr style={{ height: '40px' }}>
                                    <th style={styles} className="text-center">Product ID</th>
                                    <th className="text-center">Product Name</th>
                                    <th className="text-center">Category Name</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-center">Value</th>
                                    <th className="text-center">Total Value</th>
                                </tr>
                            </thead>
                            {tbodies}
                        </table>

                        {/* <div className="col-lg-12">
              <div className="invoice-price-right-text">
                <span mt-10>Signature</span>
              </div>
            </div> */}
                    </div>

                </div>
            }
        </>
    );
};

export default DamageApprovedListDetails
