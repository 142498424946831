import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import {
  getSalesDrigData,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

const SalesDrillsReportList = () => {

  const distributorReportList = useSelector(
    (state) => state.disReport.distributorReport
  );
  const isLoading = useSelector((state) => state.disReport.isLoading);

  const [drilData, setDrillData] = useState([]);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const handleEdit = (editItem) => {
  //   setEditItem(editItem);
  //   setShow(true);
  // };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    // let changeData = [];
    let testData = await getSalesDrigData();
    setDrillData(testData.data);
  };

  return (
    <>
      <>
        {isLoading && (
          <div className="mt-5">
            <LoadingSpinner text="Loading Blance " />
          </div>
        )}
        {isLoading && distributorReportList.length === 0 && (
          <div className="alert alert-warning mt-5">
            Sorry ! No Balance Found.
          </div>
        )}
        {/* {isLoading && distributorReportList.length > 0 && ( */}
        <>
          <div className="react-bootstrap-table">
            <table
              className="table table-head-custom table-vertical-center  item-add-table table-bordered ditRitable"
              id="table-to-xls"
            >
              <thead>
                <tr>
                  <th>Zone</th>
                  <th>Division</th>
                 
               
                  
                   {/* <th colSpan="2">Distributor Information</th> */}
                  
                </tr>
              </thead>
              <tbody>
                {drilData.map((item, index) => (
                  <tr>
                    <td style={{ width: "10%" }}>{item.zoneName}</td>

                    {item.divisionData.map((division) => (
                      <tr>
                        <td style={{ width: "20%" }}>
                          {division.divisionName}
                        </td>
                        {division.areaData.map((area) => (
                          <tr>
                            <td style={{ width: "10%" }}>{area.areaName}</td>
                            {area.custData.map((cus) => (
                              <tr>
                                <td style={{ width: "25%" }}>{cus.custID}</td>
                                <td style={{ width: "50%" }}>{cus.custName}</td>
                                <td style={{ width: "20%" }}>
                                  {cus.totalAmount_C}
                                </td>
                              </tr>
                            ))}
                          </tr>
                        ))}
                      </tr>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
        {/* )} */}
      </>
    </>
  );
};

export default SalesDrillsReportList;
