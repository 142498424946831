import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import {
  currentdate
} from "../../../../../domains/CCO/utils/DateHelper";
import {
  getSecondaryStock,
  getSecondaryStockForExcel,
} from "../../_redux/actions/Delivery/DeliveryAction";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

const SecondarySaleStock = () => {

  const productReport = useSelector((state) => state.stock.stockReport);
  const skuTotal = useSelector((state) => state.stock.tReport);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [selectReportType, setSelectReportType] = useState(2);


  const [reportType, setReportType] = useState([
    {
      id: 1,
      name: "Distributor",
    },
    {
      id: 2,
      name: "Point Wise",
    },
  ]);
  const componentRef = useRef();

  const { register,setValue } = useForm();

  let pageLimit = 5;

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
   
  };


  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };
  const reportTypeDropdownData = () => {
    let options = [];
    if (reportType?.length > 0) {
      reportType.forEach((item) => {
        let itemData = {
          value: item.id,
          label: item.name,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleListAction = (
    fromDate,
    idZone,
    idDivision,
    idArea,
    territoryId
  ) => {

   
    let pageDive = 5;
    let pageNo = Math.ceil(productReport.length/pageDive)+1;
 
    dispatch(
      getSecondaryStock(
        fromDate,
        idZone,
        idDivision,
        idArea,
        territoryId,
        selectReportType,
        selectGeoType,
        pageNo,
        pageLimit

      )
    );
  };

  const [selectGeoType, setSelectGeoType] = useState(2);

  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const hanleExcelClick = async () => {
    let excelClick = await getSecondaryStockForExcel(
      startDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory,
      3
    );
    const current = currentdate();
    // const worksheet = XLSX.utils.json_to_sheet(excelClick.data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "Product Stock(Secondary).xlsx");
    let Heading = [[
    'ZoneId',
    'ZoneName', 
    'Divisionid',
    'DivisionName',
    'RegionID',
    'RegionName',
    'TerritoryId',
    'TerritoryName',
    'DisPointID',
    'DisPointName',
    'ProductID',
    'ProductName',
    'CategoryID',
    'CategoryName',
    'BrandName',
    'PackName',
    'Uom',
    'Price',
    'OpeningQty',
    'OpeningCtnQty',
    'OpeningValue',
    'LiftingQty',
    'LiftingCtnQty',
    'LiftingValue',
    'IMSPcsQty',
    'IMSCtnQty',
    'IMSValue',
    'ClosingQty',
    'ClosingCtnQty',
    'ClosingValue'
    
  ]];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
    const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    XLSX.writeFile(wb, 'ProductStock' + " " +current+'.xlsx');
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
            <span className="button-010">Product Stock(secondary)</span>
              {/* <h3 className="mb-0 pb-0">Product Stock(secondary)</h3> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <Button size="sm" onClick={() => hanleExcelClick()}>
                Download
              </Button>
              <DownloadDropdown excelname='Product Stock(secondary)' />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Month</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                 
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Report Type</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={reportTypeDropdownData()} />}
                rules={{ required: false }}
                name="reportType"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectReportType(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 mt-5 mb-5 ">
          <button className="button-621" onClick={() =>
                handleListAction(
                  startDate,
                  selectZone,
                  selectDivision,
                  selectArea,
                  selectTerritory,
                  selectGeoType
                )} >  Show Report</button>
            {/* <Button
              size="sm"
              onClick={() =>
                handleListAction(
                  startDate,
                  selectZone,
                  selectDivision,
                  selectArea,
                  selectTerritory,
                  selectGeoType
                )
                // fetchData()
              }
            >
              Show Report
            </Button> */}
          </div>
          {/* <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Distribution   Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  setSelectArea(option.value);
                  dispatch(getSalesList(option.value, salesType));
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
        </div>
        <div>
          {productReport?.length > 0 && (
            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      <p className="balanceleft">Opening Qty Pcs</p>
                      <h4 className="balanceleft">
                        {Math.round(skuTotal.t_ProductOpeningPCQty)}
                      </h4>
                    </div>
                    <div className="widget__right">
                      <div>
                        {/* <h4 className="balanceleft">৳ {Math.round(skuTotal.t_ProductOpeningPCQty)}</h4> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="paid__widget three">
                    <div className="widget__left">
                      <p className="balanceleft">Lifting Pcs Qty</p>
                      <h4 className="balanceleft">
                        {Math.round(skuTotal.t_ProductLiftingPCQty)}
                      </h4>
                    </div>
                    <div className="widget__right">
                      <div>
                        {/* <h4 className="balanceleft">৳ {Math.round(skuTotal.t_ProductLiftingPCQty)}</h4> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      <p className="balanceleft">Ims PCS Qty</p>
                    </div>
                   
                      <div>
                        <h4 className="balanceleft">
                          {Math.round(skuTotal.t_ProductIMS_PCQty)}
                        </h4>
                      </div>
                    
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="paid__widget one">
                    <div className="widget__left">
                      <p className="balanceleft">Closing PCS Qty</p>
                      <h4 className="balanceleft">
                        {Math.round(skuTotal.t_ProductClosingPCQty)}
                      </h4>
                    </div>
                    <div className="widget__right">
                      <div className="overlay-layer bg-transparent"></div>
                      <div>
                        {/* <h4 className="balanceleft">৳ {Math.round(skuTotal.t_ProductClosingCTQty)}</h4> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
         
          

          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            <div className="react-bootstrap-table table-responsive border-0 pl-5">
              {/* {isProductLoading && (
                <LoadingSpinner text="Loading Items List..." />
              )} */}
              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
                {/* <table
                  className="table table-head-custom table-vertical-center  item-add-table"
                  id="table-to-xls"
                  ref={componentRef}
                > */}
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead>
                    <tr>
                      <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>

                      {selectGeoType == 1 ? (
                        <>
                          <th scope="col">AREA</th>
                        </>
                      ) : (
                        <>
                          <th scope="col">REGION</th>
                          <th scope="col">TERRITORY</th>
                        </>
                      )}
                      <th scope="col">DISTRIBUTOR ID</th>
                      <th scope="col">DISTRIBUTOR NAME</th>
                      <th scope="col">DISTRIBUTOR POINT</th>
                      <th scope="col">DISTRIBUTOR POINT ID</th>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">PRODUCT CATEGORY</th>
                      <th scope="col">BRAND NAME</th>
                      <th scope="col">PACK NAME</th>
                      <th scope="col">PRODUCT UOM</th>
                      <th scope="col">OPENING(PCS)</th>
                      <th scope="col">TRANSFER(PCS)</th>
                      <th scope="col">OPENING(CTN)</th>
                      <th scope="col">OPENING(VALUE)</th>
                      <th scope="col">LIFING (PCS)</th>
                      <th scope="col">LIFING (CTN)</th>
                      <th scope="col">LIFING (VALUE)</th>
                      <th scope="col">IMS (PCS)</th>
                      <th scope="col">IMS (CTN)</th>
                      <th scope="col">IMS (VALUE)</th>
                      <th scope="col">CLOSING (PCS)</th>
                      <th scope="col">CLOSING(CTN)</th>
                      <th scope="col">CLOSING(VALUE)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr className="alert bg-secondary">
                            {/* <td>{item.orderId}</td> */}
                            <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>
                            {selectGeoType == 1 ? (
                              <td>{item.areaName}</td>
                            ) : (
                              <>
                                <td>{item.areaName}</td>
                                <td>{item.territoryName}</td>
                              </>
                            )}
                            <td>{item.distribID}</td>
                            <td>{item.distribName}</td>
                            <td>{item.disPointName}</td>
                            <td>{item.disPointID}</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductOpeningPCQty
                              )}
                            </td>
                            <td />
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductOpeningCTQty
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductOpeningPrice
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductLiftingPCQty
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductLiftingCTQty
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductLiftingPrice
                              )}
                            </td>
                            <td>
                              {Math.round(item?.reportTotal.t_ProductIMS_PCQty)}
                            </td>
                            <td>
                              {Math.round(item?.reportTotal.t_ProductIMS_CTQty)}
                            </td>
                            <td>
                              {Math.round(item?.reportTotal.t_ProductIMSPrice)}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductClosingPCQty
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductClosingCTQty
                              )}
                            </td>
                            <td>
                              {Math.round(
                                item?.reportTotal.t_ProductClosingPrice
                              )}
                            </td>
                          </tr>
                          {item?.itemReport.map((product, subindex) => (
                            <tr>
                              <td>{item.zoneName}</td>
                              <td>{item.divisionName}</td>
                              {selectGeoType == 1 ? (
                                <td>{item.areaName}</td>
                              ) : (
                                <>
                                  <td>{item.areaName}</td>
                                  <td>{item.territoryName}</td>
                                </>
                              )}
                              <td>{item.distribID}</td>
                              <td>{item.distribName}</td>
                              <td>{item.disPointName}</td>
                              <td>{item.disPointID}</td>

                              <td>{product.productID}</td>
                              <td wrap="nowrap" spacing={2}>
                                {product.productName}
                              </td>
                              <td>{product.prodCatName}</td>
                              <td>{product.brandName}</td>
                              <td>{product.packTypeName}</td>
                              <td>{product.productUom}</td>
                              <td>{Math.round(product.productOpeningPCQty)}</td>
                              <td>{Math.round(product.transferQty)}</td>
                              <td>{Math.round(product.productOpeningCTQty)}</td>
                              <td>{Math.round(product.productOpeningPrice)}</td>
                              <td>{Math.round(product.productLiftingPCQty)}</td>
                              <td>{Math.round(product.productLiftingCTQty)}</td>
                              <td>{Math.round(product.productLiftingPrice)}</td>
                              <td>{Math.round(product.productIMS_PCQty)}</td>
                              <td>{Math.round(product.productIMS_CTQty)}</td>
                              <td>{Math.round(product.productIMSPrice)}</td>
                              <td>{Math.round(product.productClosingPCQty)}</td>
                              <td>{Math.round(product.productClosingCTQty)}</td>
                              <td>{Math.round(product.productClosingPrice)}</td>
                            </tr>
                          ))}
                        </>
                      ))}
                  </tbody>
                </table>
                
              </div>
             
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default SecondarySaleStock;
