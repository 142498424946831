import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
    EditDateGet,
    salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
    InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
    getAreaListByDivision,
    getDivisionListByZone,
    getTerritory,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetDamageSkuWiseListForAccounts} from "../../_redux/actions/SecondarySales/SecondarySalesAction";

const DamageSkuWiseListForAccounts = () => {
    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    const history = useHistory();
    const isLoading = useSelector((state) => state.secondarySales.skuListLoading);
    const skuAcountsListdata = useSelector((state) => state.secondarySales.skuAcountsListdata);
    console.log('skuAcountsListdata', skuAcountsListdata?.orderData?.length);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(0);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const componentRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    };

    // delete issuing authority list
    const confirmDelete = (id) => {
        dispatch(salesDelete(id, selectArea));
        // setTimeout(() => {history.go(0)}, 3000);
        // history.go(0);
    };

    const handleDeleteItem = (id) => {
        confirmAlert({
            title: "Confirm To Delete",
            message: `Are you sure to delete..?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => confirmDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const zoneDropdownData = () => {

        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZone(id, selectGeoType);
        SetDivision(divisionData.data);
        setValue("divisionData", "");
    };
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivision(id, selectGeoType);
        setArea(areaData.data);
    };

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);
    const [paginateData, setPaginateData] = useState([]);

    const itemDetail = (item) => {
        setItemifo(item);
        setItemID(item.orderId);
        setItemDetailShow(true);
    };
    let ref = React.createRef();
    const inputField = React.useRef(null);

    const printFilter = (filter) => {
        inputField.current.value = filter;
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };
    const gotoEditPage = (item) => {
        dispatch(EditDateGet(item));
        history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
    }

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = (input) => {
        setToDate(input);
    };

    const handleDateFilter = (option) => {
        handleChangeTodate(option);
    }

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const getTerritoryData = async (id) => {
        let territoryData = await getTerritory(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };


    const showReport = () => {
        dispatch(GetDamageSkuWiseListForAccounts(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory));
    }

    const getSalesValueType = (name, value) => {
        setSalesType(value);
        setSelectGeoType(value);
    }


    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                        <span className="button-010">Damage Sku Wise List</span>
                            {/* <h3 className="mb-0 pb-0">Damage Sku Wise List</h3> */}
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right ">
                            <DownloadDropdown excelname='Damage Sku Wise List' />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 ml-4">
                            <SimpleSgeoType
                                getSalesValue={getSalesValueType}
                                sGeoType={selectGeoType}
                            />
                        </div>
                    </div>
                </div>

                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={(event) => {
                                    handleDateFilter(event.target.value)
                                }}

                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Zone</label>

                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={zoneDropdownData()} />}
                                rules={{ required: false }}
                                name="zoneData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectZone(option?.value);
                                    divisionList(option?.value);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Division</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={divisionDropdownData()} />}
                                rules={{ required: false }}
                                name="divisionData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    // setType(option.value)
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Region</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={areaDropdownData()} />}
                                rules={{ required: false }}
                                name="areaData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    getTerritoryData(option?.value)
                                    setSelectArea(option?.value);
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Territory</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={territoryDropdownData()} />}
                                rules={{ required: false }}
                                name="territory"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col mt-5">
                    <button className="button-621" onClick={() => showReport()} >  Show Report</button>
                        {/* <Button size="sm" onClick={() => showReport()}>Show Report</Button> */}
                    </div>
                </div>
                <div>
                </div>

                <div className="row mt-5" id="id" ref={inputField}>

                    {isLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5" >
                        {/* <div
                            className="stickyTablecus"
                            style={{ height: skuAcountsListdata?.length > 0 ? 800 + "px" : 0 }}
                        > */}
                        <div className="stickyTablecus"
                            style={{ height: skuAcountsListdata?.orderData?.length> 0 ? 800 + "px" : 0 }}
                        >
                            <table className="table  table-vertical-center demotable text-center" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    {skuAcountsListdata?.orderData?.length> 0 &&
                                        <tr>
                                            <th >PRODUCT ID</th>
                                            <th >PRODUCT NAME</th>
                                            <th >QUANTITY</th>
                                            <th >PRICE</th>
                                            <th >TOTAL</th>
                                        </tr>
                                    }
                                </thead>
                                <tbody >
                                    {skuAcountsListdata?.orderData?.length &&
                                       skuAcountsListdata?.orderData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.productID}</td>
                                                <td>{item.productName}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.price}</td>
                                                <td>{item.total}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                         
                        </div>
                        {!isLoading && skuAcountsListdata.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! Data List Not Found.
                                </div>
                            )}
                    </div>
                </div>
                {/* <div>
                    <table className="table table-head-custom demotable">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Roll</th>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Roll</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                                <td>1</td>
                                <td>Mohaimenur Rahman</td>
                                <td>CSE</td>
                                <td>Assistant Officer</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <SimpleModal
                    size="xl"
                    show={itemDetailShow}
                    handleClose={() => setItemDetailShow(false)}
                    handleShow={() => setItemDetailShow(true)}
                    modalTitle={"Item Details"}
                >
                    <RemoteSalesDetails
                        handleClose={() => setItemDetailShow(false)}
                        itemID={itemID}
                        itemDetailnIfo={itemifo}
                    />
                </SimpleModal>

                {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default DamageSkuWiseListForAccounts;
