import React from "react";
import SecondarySalesSoReport from "../../../information/components/SecodarySales/SecondarySalesSoReport";


const SecondarySalesSoReportContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySalesSoReport/>
      </div>
    </div>
  );
};

export default SecondarySalesSoReportContainer;