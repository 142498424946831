import React from "react";
import CorporateSalesEdit from "../../../information/components/RemoteSales/CorporateSalesEdit";

const CorporateSalesEditContainer = () => {
  return (
    <div className="container">
      <CorporateSalesEdit />
    </div>
  );
};

export default CorporateSalesEditContainer;
