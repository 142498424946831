
import React from "react";
import PieChart from "../../../modules/Sales/information/components/Chart/PieChart";
import CircleChart from "../../../modules/Sales/information/components/Chart/CircleChart";
import DashboardTopBar from "./DashboardTopBar";

const Demo1Dashboard=()=> {

    return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-12 col-xxl-12 text-center udevelopmentText_bg mb-3">
                <span className=" udevelopmentText">THE PAGE IS IN UNDER DEVELOPMENT</span> 
            </div>
            <div className="col-lg-12 col-xxl-12">
                <DashboardTopBar />
            </div>
         {/* <div className="col-lg-6 col-xxl-8">
                    <DashboardBarChart/>
                </div> */}
                 <div className="col-lg-6 col-md-6 col-sm-6 col-xxl-3">
                 {/* <GradientChart className="card-stretch gutter-b"/> */}
                 <CircleChart className="card-stretch"/>
                {/* <MixedWidget14 className="card-stretch gutter-b" mixValue={topCard.achivement}/> */}
                 </div>
                 {/* <div className="col-lg-6 col-xxl-4">
                <MixedWidget14 className="card-stretch gutter-b" />
                 </div> */}
                 {/* <div className="col-lg-6 col-xxl-4">
                <Acheivement className="card-stretch gutter-b"/>
                 </div> */}
                 {/* <div className="col-lg-6 col-xxl-4">
                <SalesCard className="card-stretch gutter-b" />
                 </div> */}
                 
             {/* <div className="col-lg-6 col-xxl-4"> */}
                 {/* Acheivement */}
                {/* <MixedWidget14 className="card-stretch gutter-b" /> */}
                {/* Weekly Sales */}
                {/* <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/> */}
                {/* New Users */}
                {/* <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/> */}
            {/* </div> */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xxl-4 mb-1">
                <PieChart className="card-stretch "/>
            </div>
            {/* <div className="col-lg-5">
                    <ParDayTargetChartForIMS />
                </div> */}
            {/* <div className="col-lg-6 col-xxl-5">
                <SalesCard className="card-stretch" />
            </div> */}
            {/* <div className="col-lg-6 col-sm-12 col-xxl-4">
                <BarChart4 className="card-stretch gutter-b"/>
            </div> */}
            
             {/* <div className="col-lg-6 col-xxl-4">
                <BarChart3 className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-8 col-xxl-8">
                <BarChart2 className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <FavouriteChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <GradientChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <BarChart1 className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <GradientChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <CircleChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <FavouriteChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <FavouriteChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
                <WeeklyChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-12 col-xxl-4">
                <NorthSouthChart className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-6 col-xxl-4">
            <SemiChart className="card-stretch card-stretch-half gutter-b"/>
            <SemiChart className="card-stretch card-stretch-half gutter-b"/>
            <SemiChart className="card-stretch card-stretch-half gutter-b"/>
            </div> */}
            {/* <div className="col-lg-2 col-xxl-2">
                <SemiChart className="card-stretch"/>
                <SemiChart className="card-stretch "/>
                <SemiChart className="card-stretch "/>
            </div> */}
            
           
            {/* Contact Info */}
            {/* <div className="col-xxl-8 order-2 order-xxl-1">
                <AdvanceTablesWidget2 className="card-stretch gutter-b" />
            </div> */}

            {/* Tasks Overview */}
            {/* <div className="col-lg-6 col-xxl-6 order-1 order-xxl-1">
                <ListsWidget1 className="card-stretch gutter-b" />
            </div> */}

            {/* Users */}
            {/* <div className="col-lg-6 col-xxl-6 order-1 order-xxl-2">
                <ListsWidget3 className="card-stretch gutter-b" />
            </div> */}

            {/* Todo */}
            {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div> */}
            {/* <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b"/>
                </div> */}
        </div>


        {/* <div className="row"> */}

            {/* Action Needed */}
            {/* <div className="col-lg-0">
                <MixedWidget14 className="card-stretch gutter-b" />
            </div> */}
            {/* Distributor */}
            {/* <div className="col-lg-12 col-xxl-12">
                <AdvanceTablesWidget4 className="card-stretch gutter-b" />
            </div> */}
        {/* </div> */}
    </div>
    );
}
export default Demo1Dashboard;
