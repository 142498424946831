import React from "react";
import TotalDistributorReportSummery from "./TotalDistributorReportSummery";

const TotalDamageReportContainer = () => {
  return (
    <div className="container-fluid">
      <TotalDistributorReportSummery />
    </div>
  );
};

export default TotalDamageReportContainer;
