import React from "react";
import { useSelector } from "react-redux";
import { Spring } from "react-spring";
import LotiAnimation from "./LotiAnimation";

const VerticalProgress = ({ progress }) => {
  const Primaryachivement = useSelector((state) => state.dashboardcart.Primaryachivement);
  var checkNan = (Primaryachivement === null || isNaN(Primaryachivement) || !isFinite(Primaryachivement)) ? 0 : Primaryachivement;


  console.log('Primaryachivement :>> ', Primaryachivement);
  console.log('checkNan', checkNan)
  return (
    <>
      <Spring from={{ percent: 0 }} to={{ percent: checkNan }}>
        {({ percent }) => (
          <><div> <span className="mr-2">100%</span></div>
            <div className="progress vertical">
              <div style={{ height: `${checkNan}%` }} className="progress-bar">
                <span>{`${checkNan}%`}</span>
              </div>
            </div>
          </>

        )}
      </Spring>

      {checkNan >= 100 && (
        <div style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
        }}>
          <LotiAnimation />
        </div>
      )}
    </>

  );
};

export default VerticalProgress;
