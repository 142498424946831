import { indexOf } from "lodash";
import * as Types from "../../types/SecondarySalesTypes";

const initialState = {
  strikeLoading: false,
  secondaryApprovedList: [],
  asmSecondaryList: [],
  asmapprovedDetails: [],
  secondaryApprovedDetails: [],
  submitData: [],
  cumalative: [],
  totalQuantityCum: 0,
  totalPriceCum: 0,
  biscuteQty: 0,
  biscutePrice: 0,
  cakeQty: 0,
  cakePrice: 0,
  cookisQty: 0,
  cookisPrice: 0,
  wiferPrice: 0,
  wiferQty: 0,
  snacksPrice: 0,
  snacksQty: 0,
  chanachurQty: 0,
  chanachurPrice: 0,
  sowiferPrice: 0,
  sowiferQty: 0,
  dateWiseReport: [],
  dateSys: [],
  areaInfo: [],
  distrinfo: [],
  soInfo: [],
  dateCount: 0,
  dateReportLoading: false,
  isLoading: false,
  cumalativePrice: 0,
  productReport: [],
  isProductLoading: false,
  productPrice: 0,
  productQuantity: 0,
  productTotal: 0,
  soWiseProduct: [],
  excelReport: [],
  skuTotal: 0,
  skuQuantity: 0,
  soWiseAchivementProduct: [],
  skuCumalative: null,
  secondaryStatus: [],
  salesGiven: 0,
  salesNotGiven: 0,
  flatReport: [],
  breadBurnReport: [],
  totalDisPrice: null,
  totalDisQty: null,
  dwisepskud: [],
  DtotalPrice: null,
  DtotalQuentity: null,
  DtotalQuentityCtn: null,
  SoReport: [],
  strikedata: [],
  skuAcountsListdata: [],
  skuListLoading: false,
  sostrikedata: [],
  soWiseAchivementProductdetails: [],
  salesPrimaryReport: [],
  sostrikeLoading: false,

  soWiseProductV2: [],
  soWiseProductV2OnlySo: [],
  skuLoadingV2: false,
  cumulative:[],
  topSellingSkuList: [],
  bottomSellingSkuList: [],
  imsFocusSkuList:[],
  DateWiseSoSales: [],
  DateWiseSoSalesLoading: false,
  MonthWiseSoSales: [],
  MonthWiseSoSalesLoading: false,
};
const SecondarySalesReducers = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_SECONDARY_SALES_APPROVED_REPORT:
      return {
        ...state,
        secondaryApprovedList: action.payload.data,
      };
    case Types.GET_ASM_APPROVED_LIST:
      return {
        ...state,
        asmSecondaryList: action.payload.data,
      };
    case Types.GET_ASM_APPROVED_LIST_DETAILS:
      return {
        ...state,
        asmapprovedDetails: action.payload.data,
      };
    case Types.GET_SECONDARY_SALES_APPROVED_DETAILS:
      return {
        ...state,
        secondaryApprovedDetails: action.payload.data,
      };
    case Types.GET_SO_REPORT:
      return {
        ...state,
        SoReport: action.payload.data,
      };

    case Types.GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR:
      return {
        ...state,
        breadBurnReport: action.payload.data.allReport,
        totalDisPrice: action.payload.data.productPrice,
        totalDisQty: action.payload.data.productQty,
        breadBurnReportLoading: action.payload.isProductLoading,

      };

    case Types.GET_AFML_PRIMARY_SALES_BY_DISTRIBUTOR:
      return {
        ...state,
        salesPrimaryReport: action.payload.data.allReport,
        totalDisPrice: action.payload.data.productPrice,
        totalDisQty: action.payload.data.productQty,
        breadBurnReportLoading: action.payload.isProductLoading,

      };
    case Types.GET_DISTRIBUTOR_WISE_PRODUCT_SKU_DETAILS:

      return {
        ...state,
        dwisepskud: action.payload.data.allReport,
        DtotalPrice: action.payload.data.totalPrice,
        DtotalQuentity: action.payload.data.totalQuentity,
        DtotalQuentityCtn: action.payload.data.totalQuentityCtn

      };
    case Types.GET_SALES_PRODUCT_CUMALATIVE_LIST:
      console.log('action.payload.data :>> ', action.payload.data);
      let sumPrice = 0;
      let quantity = 0;
      let biscuteQty = 0;
      let biscutePrice = 0;
      let cakeQty = 0;
      let cakePrice = 0;
      let cookisQty = 0;
      let cookisPrice = 0;
      let wiferPrice = 0;
      let wiferQty = 0;
      let snacksPrice = 0;
      let snacksQty = 0;

      for (let i = 0; i < action.payload.data.length; i++) {
        quantity += action.payload.data[i].totalQuantity;
        sumPrice += action.payload.data[i].totalPrice;
        biscuteQty += action.payload.data[i].biscuteQty;
        biscutePrice += action.payload.data[i].biscutePrice;
        cakeQty += action.payload.data[i].cakeQty;
        cakePrice += action.payload.data[i].cakePrice;
        cookisQty += action.payload.data[i].cookisQty;
        cookisPrice += action.payload.data[i].cookisPrice;
        wiferPrice += action.payload.data[i].wiferPrice;
        wiferQty += action.payload.data[i].wiferQty;
        snacksPrice += action.payload.data[i].snacksPrice;
        snacksQty += action.payload.data[i].snacksQty;
      }
      return {
        ...state,
        cumalative: action.payload.data,
        totalQuantityCum: quantity,
        totalPriceCum: sumPrice,
        biscuteQty,
        biscutePrice,
        cakeQty,
        cakePrice,
        cookisQty,
        cookisPrice,
        wiferPrice,
        wiferQty,
        snacksPrice,
        snacksQty,
        distributorwiseLoading: action.payload.isLoading,

      };

    case Types.GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO:
      console.log('action.payload.datanow :>> ', action.payload.data);
      let sumSoPrice = 0;
      let soquantity = 0;
      let sobiscuteQty = 0;
      let sobiscutePrice = 0;
      let socakeQty = 0;
      let socakePrice = 0;
      let socookisQty = 0;
      let socookisPrice = 0;
      let sowiferPrice = 0;
      let sowiferQty = 0;
      let soSnacksPrice = 0;
      let soSnacksQty = 0;


      for (let i = 0; i < action.payload.data.length; i++) {
        soquantity += action.payload.data[i].totalQuantity;
        sumSoPrice += action.payload.data[i].totalPrice;
        sobiscuteQty += action.payload.data[i].biscuteQty;
        sobiscutePrice += action.payload.data[i].biscutePrice;
        socakeQty += action.payload.data[i].cakeQty;
        socakePrice += action.payload.data[i].cakePrice;
        socookisQty += action.payload.data[i].cookisQty;
        socookisPrice += action.payload.data[i].cookisPrice;
        sowiferPrice += action.payload.data[i].wiferPrice;
        sowiferQty += action.payload.data[i].wiferQty;
        soSnacksPrice += action.payload.data[i].snacksPrice;
        soSnacksQty += action.payload.data[i].snacksQty;
      }
      return {
        ...state,
        cumalative: action.payload.data,
        totalQuantityCum: soquantity,
        totalPriceCum: sumSoPrice,
        biscuteQty: sobiscuteQty,
        biscutePrice: sobiscutePrice,
        cakeQty: socakeQty,
        cakePrice: socakePrice,
        cookisQty: socookisQty,
        cookisPrice: socookisPrice,
        wiferQty: sowiferQty,
        wiferPrice: sowiferPrice,
        snacksQty: soSnacksQty,
        snacksPrice: soSnacksPrice,
        sowiseimsLoading: action.payload.isLoading,

      };

    case Types.GET_SO_WISE_IMS_REPORT_VALUE:
      return {
        ...state,
        cumulative: action.payload.data?.cumalative,

        totalQuantityCum: action.payload.data?.totalQuantity,
        totalPriceCum: action.payload.data?.totalPrice,

        biscuteQty: action.payload.data?.totalBiscuteQty,
        biscutePrice: action.payload.data?.totalBiscutePrice,

        cakeQty: action.payload.data?.totalCakeQty,
        cakePrice: action.payload.data?.totalCakePrice,

        cookisQty: action.payload.data?.totalCookisQty,
        cookisPrice: action.payload.data?.totalCookisPrice,

        wiferQty: action.payload.data?.totalWiferQty,
        wiferPrice: action.payload.data?.totalWiferPrice,

        snacksQty: action.payload.data?.totalSnacksQty,
        snacksPrice: action.payload.data?.totalSnacksPrice,

        chanachurQty: action.payload.data?.totalChanachurQty,
        chanachurPrice: action.payload.data?.totalChanachurPrice,
        
        sowiseimsLoading: action.payload.isProductLoading,

      };


      case Types.GET_DATE_WISE_SO_IMS_REPORT_VALUE:
      return {
        ...state,
        DateWiseSoSales: action.payload.data,
        DateWiseSoSalesLoading: action.payload.isProductLoading,
        

      };
      case Types.GET_MONTH_WISE_SO_IMS_SKU_REPORT_VALUE:
      return {
        ...state,
        MonthWiseSoSales: action.payload.data,
        MonthWiseSoSalesLoading: action.payload.isProductLoading,

      };




      case Types.GET_TOP_SELLING_SKU:
      return {
        ...state,
        topSellingSkuList: action.payload.data?.topSellingSKUs,
        bottomSellingSkuList: action.payload.data?.bottomSellingSKUs,
      };

      case Types.GET_IMS_FOCUS_SKU:
      return {
        ...state,
        imsFocusSkuList: action.payload.data?.focusProduct,
      };

    case Types.GET_SKUWISE_REPORT:
      console.log('action.payload.data :>> ', action.payload.data);
      let productPrice = 0;
      let productQuantity = 0;
      let productTotal = 0;
      let TargetTotalPrice = 0;

      for (let i = 0; i < action.payload.data.length; i++) {
        productPrice += action.payload.data[i].productPicPrice;
        productQuantity += action.payload.data[i].productQty;
        productTotal += action.payload.data[i].productTPrice;
        TargetTotalPrice += action.payload.data[i].targetTotalPrice;
      }
      return {
        ...state,
        isProductLoading: action.payload.isProductLoading,
        productReport: action.payload.data,
        cumalativePrice: action.payload.data,
        productTotal,
        productQuantity,
        productPrice,
        TargetTotalPrice
      };

    case Types.GET_SO_SKU_REPORT:
      return {
        ...state,
        soWiseProduct: action.payload.data.allReport,
        skuLoading: action.payload.isProductLoading,
        skuTotal: action.payload.data.totalPrice,
        skuQuantity: action.payload.data.totalQuentity,
        excelReport: action.payload.data.excelReport
        ,
      };
    case Types.GET_SO_SKU_REPORT_V2:
      return {
        ...state,
        soWiseProductV2: action.payload.data?.productReportMSA,
        soWiseProductV2OnlySo: action.payload.data?.soEnrolReport,
        skuLoadingV2: action.payload.isProductLoading
        ,
      };

    case Types.GET_SO_SKU_REPORT_FLAT:
      return {
        ...state,
        flatReport: action.payload.data

      };
    case Types.GET_SO_ACHIVEMENT_REPORT:
      let itemReportArray = [];

      if (action.payload.data.detailsReport) {
        itemReportArray = action.payload.data.detailsReport[0]?.itemReport || [];
      }

      console.log('itemReportArray :>> ', itemReportArray);

      console.log('action.payload.data.detailsReport :>> ', action.payload.data.detailsReport);
      return {
        ...state,
        soWiseAchivementProduct: action.payload.data.detailsReport,
        skuLoading: action.payload.isProductLoading,
        skuCumalative: action.payload.data,
        soWiseAchivementProductdetails: itemReportArray,
      };

    case Types.GET_DISTRIBUTOR_SKU_REPORT:
      return {
        ...state,
        soWiseProduct: action.payload.data,
        skuLoading: action.payload.isProductLoading,
        skuTotal: action.payload.data.totalPrice,
        skuQuantity: action.payload.data.totalQuentity
      };
    case Types.GET_SECONDARY_SALES_HANDLE_INPUT:
      let parentIndex = action.payload.parentIndex;
      let childIndex = action.payload.childIndex;
      let approveData = state.secondaryApprovedDetails.slice();

      for (let i = 0; i < approveData.length; i++) {
        if (i == parentIndex) {
          let multipleData = approveData[i].p_SOItemquentities;
          for (let j = 0; j < multipleData.length; j++) {
            if (j == childIndex) {
              approveData[i].p_SOItemquentities[j].approvedQuentityPic = action.payload.value;
            }
          }
        }
      }
      return {
        ...state,
        secondaryApprovedDetails: approveData
      };
    case Types.GET_SECONDARY_SALES_DATE_WISE:
      let Paydata = action.payload.data;
      return {
        ...state,
        dateWiseReport: action.payload.data,
        dateSys: action.payload.dateDatasys,
        dateCount: action.payload.count,
        dateReportLoading: action.payload.isLoading
      };
    case Types.GET_SECONDARY_STATUS:
      var payLoadData = action.payload.data;
      var salesGiven = 0;
      var salesNotGiven = 0;

      for (let i = 0; i < action.payload.data.length; i++) {

        if (action.payload.data[i].status == true) {
          salesGiven += 1
        } else {
          salesNotGiven += 1
        }
      }
      return {
        ...state,
        secondaryStatus: action.payload.data,
        salesGiven,
        salesNotGiven

      };
    case Types.GET_SALES_CLEAN:
      return {
        ...state,
        cumalative: [],
        cumulative: [],
        totalQuantityCum: 0,
        totalPriceCum: 0,
        biscuteQty: 0,
        biscutePrice: 0,
        cakeQty: 0,
        cakePrice: 0,
        cookisQty: 0,
        cookisPrice: 0,
        wiferQty: 0,
        wiferPrice: 0,
        snacksQty: 0,
        snacksPrice: 0,
        sowiseimsLoading: false,
        soWiseProduct: []

      };
    case Types.GET_STRIKE_RATE_OF_SECONDARY:
      return {
        ...state,
        strikedata: action.payload.data,
        strikeLoading: action.payload.isLoading,
      };
    case Types.GET_STRIKE_RATE_OF_SECONDARY_FOR_SO:
      console.log('so action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        sostrikedata: action.payload.data,
        sostrikeLoading: action.payload.status,

      };
    case Types.GET_DAMAGE_SKU_WISE_LIST_FOR_ACCOUNTS:
      return {
        ...state,
        skuAcountsListdata: action.payload.data,
        skuListLoading: action.payload.isLoading,
      };

    default:
      break;
  }
  return newState;
};

export default SecondarySalesReducers;
