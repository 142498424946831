import React from "react";
import DeliveryReportDistributorSku from "../../../information/components/SecodarySales/DeliveryReportDistributorSku";

const DeliveryDistributorProductContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DeliveryReportDistributorSku/>
      </div>
    </div>
  );
};
export default DeliveryDistributorProductContainer;
