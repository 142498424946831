import React from "react";
import DamageSumCatWise from "./DamageSumCatWise";

const DamageSumCategoryWiseReportContainer = () => {
  return (
    <div className="container-fluid">
      <DamageSumCatWise />
    </div>
  );
};

export default DamageSumCategoryWiseReportContainer;
