import React from "react";
import DamageForApprove from "./DamageForApprove";

const DamageApproveContainer = () => {
  return (
    <div className="container-fluid">
      <DamageForApprove />
    </div>
  );
};

export default DamageApproveContainer;
