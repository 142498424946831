import React from "react";
import DamageDemandEntry from "../../../information/components/RemoteSales/DamageDemandEntry";



const DamageDemandEntryContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DamageDemandEntry />
      </div>
    </div>
  );
};

export default DamageDemandEntryContainer;