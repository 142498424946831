import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
  getSowiseAchivementReport
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";

import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const SecondarySoAchivemnt = () => {
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const productReport = useSelector((state) => state.secondarySales.soWiseAchivementProduct);
  const totalTarget = useSelector((state) => state.secondarySales.skuCumalative);
  
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  const { register, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
   
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea,idTerritory) => {
    dispatch(getSowiseAchivementReport(fromDate, tDate, idZone, idDivision, idArea,idTerritory));
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5 ">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Target Vs Achinement(SO Wise)</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Target Vs Achinement(SO Wise)' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");
                 
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                     
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


          <div className="col-md-3 mt-5 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea,selectTerritory)}>Show Report</Button>
          </div>
          {/* <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Distribution   Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  setSelectArea(option.value);
                  dispatch(getSalesList(option.value, salesType));
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
        </div>
        <div>
          {productReport?.length > 0 &&

<div className="container-fluid mb-3 mt-3 containerFixed">
<div className="row">
  <div className="col-xl-4 col-lg-4 col-md-6">
    <div className="paid__widget one">
      <div className="widget__left">
        <p className="widgetTitle">Cake</p>
        <div className="achiveLayer">
          <span>Target Quantity:  {parseFloat(totalTarget.cookisTargetQty)}</span>
          <span>Secondary Quantity:  {parseFloat(totalTarget.cookisLiftingQty)}</span>
          <span>Target value:  {parseFloat(totalTarget.cookisTargetPrice)}</span>
          <span>Secondary value:  {parseFloat(totalTarget.cookisLiftingPrice)}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-lg-4 col-md-6">
    <div className="paid__widget two">
      <div className="widget__left">
        <p className="widgetTitle">Cookies & Biscuit</p>
        <div className="achiveLayer">
          <span>Target Quantity:  {parseFloat(totalTarget.cookisandBiscuitTargetQty)}</span>
          <span>Secondary Quantity:  {parseFloat(totalTarget.cookisandBiscuitLiftingQty)}</span>
          <span>Target value:  {parseFloat(totalTarget.cookisandBiscuitTargetPrice)}</span>
          <span>Secondary value:  {parseFloat(totalTarget.cookisandBiscuitLiftingPrice)}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-lg-4 col-md-6">
    <div className="paid__widget three">
      <div className="widget__left">
        <p className="widgetTitle">Total</p>
        <div className="achiveLayer">
          <span>Target Quantity:  {parseFloat(totalTarget.totalTargetQty)}</span>
          <span>Secondary Quantity:  {parseFloat(totalTarget.totalLiftingQty)}</span>
          <span>Target value:  {parseFloat(totalTarget.totalTargetPrice)}</span>
          <span>Secondary value:  {parseFloat(totalTarget.totalLiftingPrice)}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

          }
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
             
              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
              <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
                <thead>

                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">SO ID</th>
                    <th scope="col">SO NAME</th>
                    <th scope="col">SO POINT</th>
                    <th scope="col">PRODUCT CATEGORIES</th>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">TARGET QUANTITY(PCS)</th>
                    <th scope="col">TARGET VALUE</th>
                    <th scope="col">IMS QTY</th>
                    <th scope="col">IMS VALUE</th>
                    <th scope="col">DIFFERECE QUANTITY</th>
                    <th scope="col">DIFFERECE VALUE</th>
                    <th scope="col">TARGET VS ACH(IN TK %)</th>
                   

                  </tr>
                </thead>
                <tbody>
                  {productReport?.length > 0 &&
                    productReport?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.soEnroll}</td>
                          <td>{item.soName}</td>
                          <td>{item.soPointName}</td>
                          <td>{item.prodCatagories}</td>
                          <td />
                          <td />
                          <td>{Math.round(item?.totalReport.targetQty)}</td>
                          <td>{Math.round(item?.totalReport.targetPrice)}</td>
                          <td>{Math.round(item?.totalReport.liftingQty)}</td>
                          <td>{Math.round(item?.totalReport.liftingPrice)}</td>
                          <td>{Math.round(item?.totalReport.targetQty)}</td>
                          <td>{Math.round(item?.totalReport.targetPrice)}</td>
                          <td>{Math.round(item?.totalReport.tarVsAccPers)}</td>
                    
                        </tr>
                        {
                          item?.itemReport.map((product, subindex) => (
                            <tr>
                              <td>{item.zoneName}</td>
                              <td>{item.divisionName}</td>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                              <td>{item.soEnroll}</td>
                              <td>{item.soName}</td>
                              <td>{item.soPointName}</td>
                              <td>{item.prodCatagories}</td>
                              <td>{product.prodID}</td>
                              <td>{product.prodName}</td>

                              <td>{Math.round(product.targetQty)}</td>
                              <td>{Math.round(product.targetPrice)}</td>
                              <td>{Math.round(product.liftingQty)}</td>
                              <td>{Math.round(product.liftingPrice)}</td>
                              <td>{Math.round(product.differentQty)}</td>
                              <td>{Math.round(product.differentPrice)}</td>
                              <td>{Math.round(product.tarVsAccPers)}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}


                </tbody>
              </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default SecondarySoAchivemnt;
