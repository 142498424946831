import React from "react";
import DamageSkuWiseListForAccounts from "./DamageSkuWiseListForAccounts";

const DamageSkuWiseListForAccountsContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DamageSkuWiseListForAccounts/>
      </div>
    </div>
  );
};

export default DamageSkuWiseListForAccountsContainer;
