import React from "react";
import TerritoryList from "../../../information/components/Territory/TerritoryList";


const TerritoryListContainer = () => {
  return (
    <div className="container">

      <TerritoryList />

    </div>
  );
};

export default TerritoryListContainer;
