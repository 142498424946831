import React from "react";
import DeliveryWiseSkuBYDeliveryDate from "../../../information/components/SecodarySales/DeliveryWiseSkuBYDeliveryDate";

const DeliveryWIseSkuByDelContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DeliveryWiseSkuBYDeliveryDate/>
      </div>
    </div>
  );
};
export default DeliveryWIseSkuByDelContainer;
