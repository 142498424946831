
import * as Types from "../../types/Types";

const initialState = {
    rollbacklist: [],
    rollbackData: [],
    allCheckbox: false,
    unapprovedList: [],
    allReport: [],
    fundList: [],
    workingdayList: [],
    areaListfund:[]
};
const FundReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_FUND_LIST:
            return {
                ...state,
                fundList: action.payload.data,
            };
        case Types.GET_AREA_LIST_FOR_FUND:
            return {
                ...state,
                areaListfund: action.payload.data,
            };
            case Types.GET_WORKING_DAY_LIST:
                return {
                    ...state,
                    workingdayList: action.payload.data,
                };

                case Types.DELETE_FUND_ITEM:
                    return {
                      ...state,
                    
                    };
              
        default:
            break;
    }
    return newState;
};

export default FundReducer;
