import React from "react";
import DamageApprovedList from "../../../information/components/RemoteSales/DamageApprovedList";

const DamageApprovedListContainer = () => {
  return (
    <div className="container-fluid">
      <DamageApprovedList />
    </div>
  );
};

export default DamageApprovedListContainer;
