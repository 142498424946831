export const CHANGE_ITEM_INPUT = "CHANGE_ITEM_INPUT";
export const MULTIPLE_ITEM_ADD_INPUT = "MULTIPLE_ITEM_ADD_INPUT";
export const DELETE_MULTIPLE_ITEM = "DELETE_MULTIPLE_ITEM";
export const GET_UOM = "GET_UOM";
export const GET_ITEM_TYPE = "GET_ITEM_TYPE";
export const GET_ITEM_CATEGORY = "GET_ITEM_CATEGORY";
export const GET_ITEM_SUB_CATEGORY = "GET_ITEM_SUB_CATEGORY";
export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const ITEM_SUBMIT = "ITEM_SUBMIT";
export const EMPTY_MULTIPLE_ITEM_LIST = "EMPTY_MULTIPLE_ITEM_LIST";
export const DELETE_TITEM = "DELETE_TITEM";
// export const AFTER_SUCCESSFULLY_INSERT = "AFTER_SUCCESSFULLY_INSERT";

//get item details
export const GET_ITEMS_DETAILS = "GET_ITEMS_DETAILS";

//edit items 
export const ITEM_EDIT = "ITEM_EDIT";
export const ITEM_EDITTING = "ITEM_EDITTING";

