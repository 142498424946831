import React from "react";
import TerritoryAdd from "../../../information/components/Territory/TerritoryAdd";


const TerritoryAddContainer = () => {
  return (
    <div className="container">
      <div className="">
        <TerritoryAdd />
      </div>
    </div>
  );
};

export default TerritoryAddContainer;
