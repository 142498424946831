import React from "react";
import CorporateSalesList from "../../../information/components/RemoteSales/CorporateSalesList";



const CorporateSalesListContainer = () => {
  return (
    <div className="container-fluid">
      <CorporateSalesList />
    </div>
  );
};

export default CorporateSalesListContainer;
