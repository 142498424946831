import * as Types from "../authTypes";

const initialState = {
  menuList: [],
  permissionMenu:[],
  isMenuLoading: false,
  authInformation:[],
  allMenuList: [],
  allMenuLoading: false
};

const authMenuPermissionReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_MENU_LIST:
      const menuArr=[];
      for (let i = 0; i < action.payload.menuList.length; i++) {
        const menuObj = {
          path: action.payload.menuList[i].path,
          name: action.payload.menuList[i].name,
          component: action.payload.menuList[i].component,
          exact: true,
        }
        menuArr.push(menuObj);
      }
      return {
        ...state,
        menuList: action.payload.menuList,
        permissionMenu:menuArr,
        isMenuLoading: action.payload.isMenuLoading,
      };

      case Types.GET_EMPLOYEE_INFORMATION:
     
      return {
        ...state,
        employeeInformation: action.payload.data[0],
       

      };

      case Types.GET_EMPLOYEE_INFORMATION_AUTHENTICATION:
     
        return {
          ...state,
          authInformation: action.payload.data,
         
  
        };

        case Types.GET_ALL_MENU_LIST:
     
      return {
        ...state,
        allMenuList: action.payload.data,
        allMenuLoading: action.payload.isMenuLoading
       

      };

      case Types.MENU_PERMISSION_SUMBIT:
     
        return {
          ...state,
  
        };




    default:
      break;
  }
  return newState;
};
export default authMenuPermissionReducer;
