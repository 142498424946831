import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  getProductList,
  productQuantity,
  cleanRemoteSalesData,
  getSOPointByArea,
  salesSubmitBySoPoint,
  getLockSystemData,
  getItemforStockProduct,
  getSoInformation,
  getItemValidationforStockProduct
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getStatusList } from "../../_redux/actions/SecondarySales/SecondarySalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { FaShoppingBag } from "react-icons/fa";

const SoSecondarySalesNewMobileView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {setValue } = useForm();
  const ProductStockSum = useSelector((state) => state.secondary.ProductStockSum);
  const productList = useSelector((state) => state.secondary.productList);
  const submitsecondaryStatus = useSelector((state) => state.secondary.submitsecondaryStatus);
  const countProduct = useSelector((state) => state.secondary.productQuantity);
  const productSubmitStatus = useSelector((state) => state.secondary.productSubmitStatus);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectArea, setSelectArea] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [lockStatus, setLockStatus] = useState(null);
  const [memoData, setMemoData] = useState({
    orderValue: 0,
    totalmemoQty: 0,
    totalSkuQty: 0,
    visitedOutlet: 0
  });

  const [soInfo, setSoInfo] = useState({
    zoneName: null,
    divisionName: null,
    areaName: null,
    soName: null,
    soPointID: null,
    soid: null,
    distribName: null,
    soPointName: null
  })
  const [status, Setstatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState(5);

  useEffect(() => {
    if (submitsecondaryStatus === true) {
      setTimeout(() => { history.go(0) }, 3000);
    }
  }, [submitsecondaryStatus]);



  useEffect(() => {
    dispatch(cleanRemoteSalesData());


  }, [])
  useEffect(() => {

    if (productSubmitStatus) {

      setTimeout(() => {
        setValue("intDistributorId", "");

        getSOListDate(selectArea, startDate);

        setDisabled(false);
      }, 3000);
      dispatch(getProductList());

    }
  }, [productSubmitStatus]);

  useEffect(() => {
    initialData();

  }, []);

  // SO Secondary Sales Entry
  const initialData = async () => {
    let lockData = await getLockSystemData();
    let soDetails = await getSoInformation();
    let statusData = await getStatusList();
    Setstatus(statusData.data);

    if (soDetails.data != null) {
      const { zoneName, divisionName, areaName, soName, soPointID, soid, distribName, soPointName, territoryName } = soDetails.data;
      const soInfomation = { ...soInfo };
      soInfomation.zoneName = zoneName;
      soInfomation.divisionName = divisionName;
      soInfomation.areaName = areaName;
      soInfomation.territoryName = territoryName;
      soInfomation.soName = soName;
      soInfomation.soPointID = soPointID;
      soInfomation.soid = soid;
      soInfomation.distribName = distribName;
      soInfomation.soPointName = soPointName;

      setSoInfo(soInfomation);
    }
    setLockStatus(lockData.data[0]);

  }


  const changeProductQuantity = (name, value) => {
    dispatch(getItemValidationforStockProduct(soInfo.soPointID, startDate, name));
    dispatch(productQuantity(name, value));
  };



  const getSOListDate = async (value, date) => {
    let solIst = await getSOPointByArea(value, date);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (memoData.visitedOutlet > 999) {
      alert("Visited Outlet must less than 1000");
      return false;
    }
    else if (memoData.visitedOutlet == 0) {
      alert("Please Input T.Visited Outlet");
      return false;
    } else if (memoData.orderValue == 0) {
      alert("Please Input T.Order value ");
      return false;
    }
    else if (memoData.totalmemoQty == 0) {
      alert("Please Input T.Memo Qty");
      return false;
    }
    else if (memoData.totalmemoQty > 999) {
      alert("T.Memo Qty must less than 1000 ");
      return false;
    } else if (memoData.totalSkuQty == 0) {
      alert("Please Input Number of T.Memo Line ");
      return false;
    }
    else if (memoData.totalSkuQty > 999) {
      alert("T.Memo Line must less than 1000");
      return false;
    }
    else {
      setDisabled(true);
      dispatch(salesSubmitBySoPoint(soInfo.soPointID, memoData, productList, startDate, selectStatus));
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    dispatch(getItemforStockProduct(soInfo.soPointID, input.value, user.employeeType));
    dispatch(getProductList(user.employeeType));

  };

  const handleMemoData = (value, name) => {

    const cloneObj = { ...memoData };
    cloneObj[name] = value;
    setMemoData(cloneObj);
  }


  const statusDropdownData = () => {
    let options = [];
    if (status?.length > 0) {
      status.forEach((item) => {
        let itemData = {
          value: item.stausId,
          label: item.statusName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState('itemName');

  //For Sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
      setSearchTerm("");
    }
  };

  // Sorting logic
  const sortedList = useMemo(() => {
    if (!Array.isArray(productList)) {
      return [];
    }
  
    const filteredList = productList.filter((item) => {
      const itemNameLower = String(item.itemName).toLowerCase();
      const itemIDLower = String(item.itemID).toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();
  
      // Check if searchTerm matches either itemName or itemID
      return !searchTermLower || 
        itemNameLower.includes(searchTermLower) || 
        itemIDLower.includes(searchTermLower);
    });
  
    const sorted = filteredList.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
      } else {
        return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
      }
    });
  
    return sorted;
  }, [productList, searchTerm, sortOrder, sortedColumn]);
  

  const valueStyle = {
    color: '#794044',
  };


  const [showStockAndDamage, setShowStockAndDamage] = useState(false);  // State for showing/hiding columns
  const handleToggleColumns = (event) => {
    event.preventDefault();  // Prevent the default form submission behavior
    setShowStockAndDamage(prevState => !prevState);  // Toggle visibility of columns
  };

  const [showLeadsModal, setShowLeadsModal] = useState(false);

  const handleShowLeadsModal = () => setShowLeadsModal(true);
  const handleCloseLeadsModal = () => setShowLeadsModal(false);

  const [scrollTop, setScrollTop] = useState(0);
  const [isSearchFixed, setIsSearchFixed] = useState(false);

  // Track scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    setIsSearchFixed(scrollPosition > 100); // You can adjust this threshold
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={onSubmit}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >

        <div style={{ paddingLeft: '14px', paddingRight: '14px', borderTop: '2px solid #ddd', backgroundColor: '#E6E6FA', padding: '14px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>

            {/* Select Date */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Sales Date</label>
                {lockStatus?.status === "Y" ? (
                  <input
                    style={{ width: '100%',   height: '35px',paddingLeft: '10px',  border: '1px solid #ccc', backgroundColor: '#F5F5DC' }}
                    type="date"
                    min={lockStatus?.lockTime}
                    max={lockStatus?.maxLock}
                    name="fromDate"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                ) : (
                  <input
                    style={{ width: '100%',   height: '35px',  border: '1px solid #ccc', backgroundColor: '#F5F5DC' }}
                    type="date"
                    name="fromDate"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              </div>
            </div>

            {/* T.Visited Outlet */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Total Visited Outlet</label>
                <input
                  style={{ width: '100%', padding: '8px',  border: '1px solid #ccc', backgroundColor: '#F5F5DC' }}
                  type="number"
                  placeholder="Enter Visited Outlet"
                  name="visitedOutlet"
                  onChange={(e) => handleMemoData(e.target.value, 'visitedOutlet')}
                />
              </div>
            </div>

            {/* T.Order Value */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Total Order value</label>
                <input
                  style={{ width: '100%', padding: '8px',  border: '1px solid #ccc',backgroundColor: '#F5F5DC' }}
                  type="number"
                  placeholder="Enter Order value"
                  name="orderValue"
                  onChange={(e) => handleMemoData(e.target.value, 'orderValue')}
                />
              </div>
            </div>

            {/* T.Memo Qty */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Total Memo Qty</label>
                <input
                  style={{ width: '100%', padding: '8px',  border: '1px solid #ccc',  backgroundColor: '#F5F5DC' }}
                  type="number"
                  placeholder="Enter Memo Qty"
                  name="totalmemoQty"
                  onChange={(e) => handleMemoData(e.target.value, 'totalmemoQty')}
                />
              </div>
            </div>

            {/* Number of T.Memo Line */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Number of T.Memo Line</label>
                <input
                  style={{ width: '100%', padding: '8px',  border: '1px solid #ccc',  backgroundColor: '#F5F5DC' }}
                  type="number"
                  placeholder="Enter Memo Line"
                  name="totalSkuQty"
                  onChange={(e) => handleMemoData(e.target.value, 'totalSkuQty')}
                />
              </div>
            </div>

            {/* Delivery Status */}
            <div style={{ flex: '0 0 50%', padding: '2px' }}>
              <div>
                <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0px' }}>Delivery Status</label>
                <select
                  style={{
                    width: '100%',
                    height: '35px',
                    
                    border: '1px solid #ccc',
                    backgroundColor: '#F5F5DC',
                    fontSize: '11px'
                  }}
                  name="statusData"
                  onChange={(e) => setSelectStatus(e.target.value)}
                  defaultValue="5"
                >
                  {statusDropdownData().map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

          </div>
        </div>

        <div className="so-quick-launcher">
          <div className="row d-flex justify-content-between align-items-center">
            {/* Left side: Total Quantity and Total Stock Value */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-8">
              <div>
                <div>
                  <span className="bottom-text-entry-so">Total Quantity (Pieces) : </span>
                  <span style={valueStyle}><strong>{numberFormatDecemal(countProduct)}</strong></span>
                </div>
                <div>
                  <span className="bottom-text-entry-so">Total Stock Value : </span>
                  <span style={valueStyle}><strong>{numberFormatDecemal(ProductStockSum.toFixed(2))}</strong></span>
                </div>
              </div>
            </div>

            {/* Right side: Shopping Cart Icon with Item Count */}
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-end so-entry-page-icon" onClick={handleShowLeadsModal}>
              <div className="cart-icon-container">
                <FaShoppingBag style={{ cursor: 'pointer' }} />
                {/* Show count in the top-right corner of the icon */}
                <span className="cart-count">{productList.filter(item => item.itemQuentityPic > 0).length}</span>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showLeadsModal} onHide={handleCloseLeadsModal} centered className="custom-leads-modal">
          <Modal.Body>
            <div className="custom-modal-body-content">
              <div className="custom-modal-content-title mt-3">Product List :</div>
              <div className="product-cards-container">
                {productList.filter(item => item.itemQuentityPic > 0).length === 0 ? (
                  <div className="no-data-message text-center">No data available</div>
                ) : (
                  productList.filter(item => item.itemQuentityPic > 0).map((product, index) => {
                    const productData = {
                      itemID: product.itemID,
                      itemName: product.itemName,
                      intUomId: product.intUomId,
                      prodTrdprice: product.prodTrdprice,
                      prodTrdpricebypic: product.prodTrdpricebypic,
                      itemUOMqty: parseInt(product.itemUOMqty),
                      salesStatusID: selectStatus,
                      itemQuentityPic: isNaN(parseInt(product.itemQuentityPic)) ? 0 : parseInt(product.itemQuentityPic),
                    };

                    return (
                      <div key={productData.itemID} className="product-card" style={{ backgroundColor: '#D6D6FF', marginBottom: '5px', padding: '10px', borderRadius: '8px' }}>
                        <div><strong>Product ID :</strong> {productData.itemID}</div>
                        <div><strong>Product Name :</strong> {productData.itemName}</div>
                        <div><strong>Product Pic Price :</strong> {numberFormatDecemal(parseFloat(productData.prodTrdpricebypic).toFixed(2))}</div>
                        <div><strong>Product Quantity :</strong> {productData.itemQuentityPic}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <Button variant="close" className="custom-close-btn" onClick={handleCloseLeadsModal} />
            <div className="">
              {productList.filter(item => item.itemQuentityPic > 0).length != 0 && (
                <div className=" mt-2">
                  <div>
                    <div >
                      <button
                        disabled={disabled}
                        type="submit"
                        className="btn btn-success btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>

        </Modal>

        <div className="card card-custom gutter-b pl-5 pr-5 ">
          {productList?.length > 0 ? (

            <>


              <div className="search-input-container mt-5">
                <input
                  type="text"
                  placeholder="Search By Item ID / Item Name "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                <span className="search-icon">🔍</span>
              </div>

              {
                sortedList.length > 0 && windowWidth < 768 &&
                <div className="text-center bg-info">
                  <span onClick={handleToggleColumns}>
                    <i className="italic-section">{showStockAndDamage ? "Click Here To Hide Stock & Damage" : "Click Here To Show Stock & Damage"}</i>
                  </span>
                </div>
              }

              {/* {productList[0]?.netStock > 0 ? ( */}
              <div className="react-bootstrap-table">
                <table className={windowWidth > 768 ? 'table table-head-custom table-vertical-center' : 'so-entry-page'}>
                  {sortedList.length > 0 && (
                    <thead>
                      <tr>
                        <th className="text-center">SL</th>
                        <th onClick={() => handleSort("itemID")}>Item ID
                          {sortedColumn === "itemID" && (
                            <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                          )}
                        </th>
                        <th >Item Name</th>
                        <th
                          className={showStockAndDamage ? '' : 'hide-column'}
                          onClick={handleToggleColumns}
                        >
                          Stock
                        </th>
                        <th
                          className={showStockAndDamage ? '' : 'hide-column'}
                          onClick={handleToggleColumns}
                        >
                          Damage
                        </th>
                        <th>Net Stock</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    {sortedList
                      .filter(item => item?.prodStock !== 0)
                      .sort((a, b) => parseInt(b.netStock, 10) - parseInt(a.netStock, 10))
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{++index}</td>
                          <td>{item.itemID}</td>
                          <td>{item.itemName}</td>
                          <td className={`${showStockAndDamage ? '' : 'hide-column'} ${item.prodStock < 0 ? 'redText' : ''}`}>
                            {parseInt(item.prodStock, 10)}
                          </td>
                          <td className={showStockAndDamage ? '' : 'hide-column'}>
                            {isNaN(parseInt(item.prodDamage)) ? 0 : parseInt(item.prodDamage)}
                          </td>
                          <td className={item.netStock < 0 ? 'redText' : ''}>
                            {isNaN(parseInt(item.netStock)) ? 0 : parseInt(item.netStock)}
                          </td>
                          <td>{parseFloat(item.prodTrdpricebypic).toFixed(2)}</td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                autoComplete="off"
                                className="formHeight"
                                type="number"
                                placeholder="Qty"
                                name="itemName"
                                value={`${item.itemQuentityPic}`}
                                disabled={item.prodStock <= 0}
                                onChange={(e) => {
                                  changeProductQuantity(item, e.target.value);
                                }}
                              />
                            </Form.Group>
                            <span>
                              <small className="validation-symbol">
                                {item.status === false ? "Sorry !! Stock is not available" : ""}
                              </small>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>

                </table>

                <div className="col-lg-12 mt-5">
                  {productList.filter(item => item.itemQuentityPic > 0).length != 0 && (
                    <div className="col-lg-12 mt-5">
                      <div className="mb-5">
                        <div className="col-lg-12">
                          <button
                            disabled={disabled}
                            type="submit"
                            className="btn btn-success btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
          {/* ) : null} */}
        </div>
      </form>
    </>
  );
};

export default SoSecondarySalesNewMobileView;
