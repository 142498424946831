import * as Types from "../../types/DashboardTypes";

const initialState = {
  isLoading: false,
  toCardLoading:false,
  piechartLoading:false,
  PieCategory:[],
  totalCategory: 0,
  topcardValue: {
    demand: 0,
    delivery: 0,
    ims: 0,
    target: 0,
    achivement:0,
  },
  allproductValue: {
    cookies: 0,
    biscuitValue: 0,
    cakeValue: 0,
    waferValue: 0,
    allproductTotal: 0

  }
};
const BreadBurnDashboardReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_TOPCARD_DATA_FOR_BREAD_BURN:
       const cloneTopCard = {...state.topcardValue};
       cloneTopCard.demand = parseFloat(action.payload.data.demandValue).toFixed(1)
       cloneTopCard.delivery = parseFloat(action.payload.data.deliveryValue).toFixed(1)
       cloneTopCard.ims = parseFloat(action.payload.data.secondaryValue).toFixed(1)
       cloneTopCard.target = parseFloat(action.payload.data.secondaryTarget).toFixed(1)
       cloneTopCard.achivement = parseFloat(action.payload.data.achivement).toFixed(1)
      return {
        ...state,
        topcardValue:cloneTopCard,
        toCardLoading:action.payload.status
        
      };
      case Types.GET_ALL_PRODUCT_DATA_FOR_BREAD_BURN:

       return {
         ...state,
         totalCategory:action.payload.data.total,
         piechartLoading:action.payload.status,
         PieCategory:[action.payload.data.cookiesValue,action.payload.data.biscuit,action.payload.data.cake,action.payload.data.wafer]
       };
   
    
    default:
      break;
  }
  return newState;
};

export default BreadBurnDashboardReducer;


