import React from "react";
import SecondarySoAchivemnt from "../../../information/components/SecodarySales/SecondarySoAchivemnt";

const SecondarySoAchivementContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySoAchivemnt/>
      </div>
    </div>
  );
};
export default SecondarySoAchivementContainer;
