import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
  getSOSkuProductv2
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { dateTimeConvertLongStringUpdated } from "../../../../../domains/CCO/utils/DateHelper";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SecondarySalesSoProductV2 = () => {

 
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoadingV2);
  const soWiseProductV2OnlySo = useSelector((state) => state.secondarySales.soWiseProductV2OnlySo);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const componentRef = useRef();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const { setValue } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
   

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    dispatch(getSOSkuProductv2(fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType));
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-9">
            <div className="mt-2 p-2">
              <span className="button-010">So SKU Wise Sku Report - V2</span>

            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <DownloadDropdown excelname='So SKU Wise Sku Report - V2' />
            </div>
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-md-3 mt-5 mb-5">
            <button className="button-621" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType)} >  Show Report</button>
            
          </div>

        </div>

        {/* <div>
          <div className="row" id="id" ref={inputField}>
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead className="bg-white" >

                    <tr>
                      <th scope="col">SALES DATE</th>
                      <th scope="col">ZONE ID</th>
                      <th scope="col">ZONE NAME</th>
                      <th scope="col">DIVISION ID</th>
                      <th scope="col">DIVISION NAME </th>
                      <th scope="col">REGION ID</th>
                      <th scope="col">REGION NAME </th>
                      <th scope="col">TERRITORY ID</th>
                      <th scope="col">TERRITORY NAME </th>
                      <th scope="col">SO FROM DATE</th>
                      <th scope="col">SO TO DATE</th>
                      <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th>
                      <th scope="col">SO POINT ID</th>
                      <th scope="col">SO POINT NAME</th>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">GROUP NAME</th>
                      <th scope="col">BRAND NAME</th>
                      <th scope="col">PACK NAME</th>
                      <th scope="col">CATEGORY</th>
                      <th scope="col">PRODUCT PRICE</th>
                      <th scope="col">PRODUCT QUANTITY(PCS)</th>
                      <th scope="col">PRODUCT QUANTITY(QTY)</th>
                      <th scope="col">Total Value</th>

                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr>
                            <td>{dateTimeConvertLongStringUpdated(item.salesDate)}</td>
                            <td>{item.zoneid}</td>
                            <td>{item.zonename}</td>
                            <td>{item.divisionid}</td>
                            <td>{item.divisionname}</td>
                            <td>{item.areaid}</td>
                            <td>{item.areaname}</td>
                            <td>{item.territoryId}</td>
                            <td>{item.terriToryName}</td>
                            <td>{dateTimeConvertLongStringUpdated(item.soFromDate)}</td>
                            <td>{dateTimeConvertLongStringUpdated(item.soToDate)}</td>
                            <td>{item.soEnroll}</td>
                            <td>{item.soName}</td>
                            <td>{item.soPointid}</td>
                            <td>{item.soPointName}</td>
                            <td>{item.productID}</td>
                            <td>{item.productName}</td>
                            <td>{item.salesGroupName}</td>
                            <td>{item.brandName}</td>
                            <td>{item.packTypeName}</td>
                            <td>{item.productCategoryName}</td>
                            <td>{item.productPicPrice}</td>
                            <td>{item.productQty}</td>
                            <td>{item.productQtyCtn}</td>
                            <td>{item.productTPrice}</td>
                            
                          </tr>
                          
                        </>
                      ))}


                  </tbody>
                </table>
              </div>
              
            </div>
          </div>

        </div> */}


        <div>
          <div className="row" id="id" ref={inputField}>
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              <div
                className="stickyTable"
                style={{ height: soWiseProductV2OnlySo?.length > 0 ? 800 + "px" : 0 }}
              >
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead className="bg-white" >

                    <tr>
                      
                      <th scope="col">ZONE ID</th>
                      <th scope="col">ZONE NAME</th>
                      <th scope="col">DIVISION ID</th>
                      <th scope="col">DIVISION NAME </th>
                      <th scope="col">REGION ID</th>
                      <th scope="col">REGION NAME </th>
                      <th scope="col">TERRITORY ID</th>
                      <th scope="col">TERRITORY NAME </th>
                      <th scope="col">SO FROM DATE</th>
                      <th scope="col">SO TO DATE</th>
                      <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th>
                      <th scope="col">SO POINT ID</th>
                      <th scope="col">SO POINT NAME</th>
                      <th scope="col">PRODUCT QUANTITY(PCS)</th>
                      <th scope="col">PRODUCT QUANTITY(QTY)</th>
                      <th scope="col">Total Value</th>

                    </tr>
                  </thead>
                  <tbody>
                    {soWiseProductV2OnlySo?.length > 0 &&
                      soWiseProductV2OnlySo?.map((item, index) => (
                        <>
                          <tr>
                            
                            <td>{item.zoneid}</td>
                            <td>{item.zonename}</td>
                            <td>{item.divisionid}</td>
                            <td>{item.divisionname}</td>
                            <td>{item.areaid}</td>
                            <td>{item.areaname}</td>
                            <td>{item.territoryId}</td>
                            <td>{item.terriToryName}</td>
                            <td>{dateTimeConvertLongStringUpdated(item.soFromDate)}</td>
                            <td>{dateTimeConvertLongStringUpdated(item.soToDate)}</td>
                            <td>{item.soEnroll}</td>
                            <td>{item.soName}</td>
                            <td>{item.soPointid}</td>
                            <td>{item.soPointName}</td>
                            <td>{item.productQty}</td>
                            <td>{item.productQtyCtn}</td>
                            <td>{item.productTPrice}</td>
                            
                          </tr>
                          
                        </>
                      ))}


                  </tbody>
                </table>
              </div>
              
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default SecondarySalesSoProductV2;
