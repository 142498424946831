import React from "react";
import AfmlProductLiftingByDistributor from "../../../information/components/SecodarySales/AfmlProductLiftingByDistributor";


const AfmlProductLiftingByDistributorContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <AfmlProductLiftingByDistributor />
      </div>
    </div>
  );
};

export default AfmlProductLiftingByDistributorContainer;
