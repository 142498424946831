import React from "react";
import DeliveryConfirmStatus from "../../../information/components/SecodarySales/DeliveryConfirmStatus";

const DeliveryConfirmStatusContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DeliveryConfirmStatus />
      </div>
    </div>
  );
};
export default DeliveryConfirmStatusContainer;