import * as Types from "../../types/Types";

const initialState = {
  isLoading: false,
  zoneSubmitStatus: false,
  itemDataInput: {
    zoneName: "",
  },
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
};
const ZoneReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_ZONE_INPUT:
      const itemDataInput = { ...state.itemDataInput };
      itemDataInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInput,
      };
    case Types.ZONE_SAVE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        zoneSubmitStatus: true,
      };
    case Types.ZONE_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        zoneSubmitStatus: false,
      };
    // case Types.ZONE_LIST_FOR_ENTRY:
    //   return {
    //     ...state,
    //     zoneListForEntry: action.payload.data,
    //   };

    default:
      break;
  }
  return newState;
};

export default ZoneReducer;


