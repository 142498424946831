import React from "react";
import SkuWiseDemandTarget from "../../../information/components/Reports/SkuWiseDemandTarget";



const SkuWiseDemandTargetContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SkuWiseDemandTarget/>
      </div>
    </div>
  );
};
export default SkuWiseDemandTargetContainer;
