import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  delteProductList,
  getDistributorList,
  productSelecteditem,
  itemAddInput,
  productQuantity,
  InputHandle,
  getCustomerBalance,
  cleanRemoteSalesData,
  getCorporateProductList,
  corporateSalesSubmit,
  GetNewCustomerBalanceByID,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDistributorAddress, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";

const CorporateSalesAdd = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const productList = useSelector((state) => state.remoteSales.productList);

  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const productSubmitStatus = useSelector(
    (state) => state.remoteSales.productSubmitStatus
  );

  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);
  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);

  console.log('NewCustomerBalance', NewCustomerBalance)


  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [distribAddress, setDistribAddress] = useState([]);
  const [startDate, setStartDate] = useState(null);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectDistribAddress, setSelectDistribAddress] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [distribType, setDistribType] = useState(null);
  const [poNo, setPoNo] = useState('');

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);

    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    // dispatch(getProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());
  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {
  }, [productSubmitStatus]);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneListEntry();
    Setzone(zoneData.data);
  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
    dispatch(productQuantity(name, value));
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");
    return options;
  };
  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id);
    setArea(areaData.data);
  }
  const getDistributorAddr = async (id) => {
    let distribData = await getDistributorAddress(id);
    setDistribAddress(distribData.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const distribAddressDropdownData = () => {
    let options = [];
    if (distribAddress?.length > 0) {
      distribAddress.forEach((item) => {
        let itemData = {
          value: item.distribAddressID,
          label: item.distribAddressName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
  }

  const onSubmit = async (e) => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (itemDataInput.intDistributorId == "") {
      alert("Please select Distribution");
      return false;
    }
    setDisabled(true);
    dispatch(corporateSalesSubmit(NewCustomerBalance, productList, selectZone, selectDivision, selectArea,selectTerritory, startDate, selectDistribAddress));
  };


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };



  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };







  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
              <span className="button-010">Corporate Sales Entry</span>
                {/* <h3 className="mb-0 pb-0">Corporate Sales Entry</h3> */}
              </div>
            </div>
            {/* Check sales type  0 for all 1 for Biscuite and 3 for Bread */}

            <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            />
          </div>

          <hr></hr>
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Deadweight"
                  name="fromDate"
                  value={startDate}
                  className="fromStyle"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Zones</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={zoneDropdownData()} />}
                  rules={{ required: false }}
                  name="zoneData"
                  register={register}
                  // value={selectZone}
                  value=""
                  onChange={(option) => {

                    divisionList(option.value);
                    setSelectZone(option.value);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");

                  }}
                  setValue={setValue}
                />

              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Division</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={divisionDropdownData()} />}
                  rules={{ required: false }}
                  name="divisionData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)
                    getAreaData(option.value);
                    setSelectDivision(option.value);
                    setValue("areaData", "");
                    setValue("territory", "");

                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Region</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={areaDropdownData()} />}
                  rules={{ required: false }}
                  name="areaData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                   getTerritoryData(option.value)
                    setSelectArea(option.value);
                    setValue("territory", "");
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3">

              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    dispatch(getDistributorList(option.value,salesType));
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">

            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Distributor</label>
              <RHFInput
                as={<Select options={distributorList} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                  inputChangeHandle("distributorName", option.label);
                  inputChangeHandle("intDistributorId", option.value);
                 // dispatch(getCustomerBalance(option.value));
                 dispatch(GetNewCustomerBalanceByID(option.value));
                  dispatch(getCorporateProductList(selectZone,startDate, option.value, salesType));
                  getDistributorAddr(option.value);
                }}
                setValue={setValue}
              />
              </Form.Group>
            </div>
            <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Distributor address</label>
              <RHFInput
                as={<Select options={distribAddressDropdownData()} />}
                rules={{ required: false }}
                name="distribAddressID"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                  inputChangeHandle("distribAddressName", option.label);
                  inputChangeHandle("distribAddressID", option.value);
                  setSelectDistribAddress(option.value);
                }}
                setValue={setValue}
              />
              </Form.Group>

            </div>

          

            {/* <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">PO NO</label>
                <Form.Control
                  type="text"
                  placeholder="Enter PO NO"
                  name="fromDate"
                  value={poNo}
                  className="fromStyle"
                  onChange={(e)=>{
                    setPoNo(e.target.value)
                  }}
                />
              </Form.Group>

            </div> */}
          </div>
        </div>

        

        <div className="container-fluid mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Credit Limit</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Product Cost</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Quantity</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.quantity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">

                <button
                  type="submit"
                  disabled={disabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>


              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default CorporateSalesAdd;
