import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/SecondarySalesTypes";

export const showSecondaryApprovedReport = (fromDate,zoneId=0,divisionId=0, areaId=0,territoryId=0) => async (
  dispatch
) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSSforApprovalASM?`;
  if (zoneId !== null) {
    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&SSDate=${fromDate}`
  }
 
  await axios.get(url).then((res) => {
    
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SECONDARY_SALES_APPROVED_REPORT,
    payload: responseList,
  });
};
export const showSecondaryApprovedReportShow = (fromDate,toDate,selectArea,selectTerritory) => async (
  dispatch
) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetApprovedSSbyArea?AreaID=${selectArea}&Fdate=${fromDate}&Tdate=${toDate}&TerritoryId=${selectTerritory}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_ASM_APPROVED_LIST,
    payload: responseList,
  });
};
export const getSecondarySalesApprovedShowDetails = (disId, fromDate,toDate) => async (
  dispatch
) => {



  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetApprovedSSDetailsbyDistrib?DistribID=${disId}&FDate=${fromDate.ssDate}&TDate=${fromDate.ssDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_ASM_APPROVED_LIST_DETAILS,
    payload: responseList,
  });
};
export const getSecondarySalesApprovedDetails = (disId, fromDate) => async (
  dispatch
) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSSDetailsforApprovalASM?DistribID=${disId}&SSDate=${fromDate}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SECONDARY_SALES_APPROVED_DETAILS,
    payload: responseList,
  });
};
export const getSecondarySalesApprovedInfo = (disId, fromDate,approvedData,selectTerritory) => async (
  dispatch
) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSSDetailsforApprovalASMSOwise?DistribID=${disId}&SSDate=${fromDate}`;
  await axios.get(url).then((res) => {
    
    responseList.data = res.data.data;
    responseList.status = false;
    dispatch(handleSubmitAction(res.data.data,approvedData,disId,selectTerritory));
  });
  dispatch({
    type: Types.GET_SECONDARY_SALES_APPROVED_DETAILS,
    payload: responseList,
  });
};
export const handleSeconsalesAproved = (
  name,
  value,
  itemList,
  parentIndex,
  childIndex
) => (dispatch) => {
  let data = {
    name: name,
    value: value,
    data: itemList,
    parentIndex: parentIndex,
    childIndex: childIndex,
  };
  dispatch({
    type: Types.GET_SECONDARY_SALES_HANDLE_INPUT,
    payload: data,
  });
};
export const handleSubmitAction = (ItemDetails, itemDetailnIfo,disId,selectTerritory) =>async(dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  const { zoneId, divisionId, areaId, fromDate } = itemDetailnIfo;
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSSDetailsforApprovalASMSOwise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&DistribID=${disId}&SSDate=${fromDate}`;


    axios
      .put(postUrl, ItemDetails)
      .then(function(response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(showSecondaryApprovedReport(fromDate,areaId,selectTerritory));
          

        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
  
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}
};

export const handleSubmitSoWiseApproved = (startDate,item,selectArea,selectTerritory,zone,division) =>async(dispatch)=> {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/secondarySalesSoWiseApproval?secondarySalesSoWiseApproval?DistribID=${item.distID}&SSDate=${startDate}&salesId=${item.salesId}&soId=${item.soId}`;
    axios
      .put(postUrl)
      .then(function(response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(showSecondaryApprovedReport(startDate,zone,division,selectArea,selectTerritory));
          
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
  
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {}


 
};
export const getStatusList = async (id, name) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSecondarySalesStatus`;
    await axios
      .get(geturl)
      .then(function(response) {
        responseList.data = response.data.data;
      })
      .catch(function(error) {
      });
  } catch (error) {
   
  }
  return responseList;
};
export const getStrikeRateOfSecondary = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId,SGeoType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_STRIKE_RATE_OF_SECONDARY, payload: responseList });

  let url = "";
     url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetStrikeRateOfSecondary?`;

  if(zoneId !== null){
 
      url +=`&ZoneID=${zoneId}`
  }
  if(divisionId !== null){
      
      url +=`&DivisionID=${divisionId}`
  }
  if(areaId !== null){
      url +=`&AreaID=${areaId}`
  }
  if(terrioryId !== null){
    url +=`&Territory=${terrioryId}`
  }
  if(fromDate !== null){
     
      url +=`&FDate=${fromDate}`
  }
  if(toDate !== null){
      
      url +=`&TDate=${toDate}`
  }
  if(SGeoType !== null){
     
    url +=`&SGeoType=${SGeoType}`
}
  await axios.get(url).then((res) => {
    console.log('res', res);
    responseList.data = res.data.data == null?[]:res.data.data;
    responseList.status = false;
    responseList.isLoading = false;

  });
  dispatch({ type: Types.GET_STRIKE_RATE_OF_SECONDARY, payload: responseList });
}
export const GetDamageSkuWiseListForAccounts = (fromDate,toDate,zoneId,divisionId,areaId,terrioryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DAMAGE_SKU_WISE_LIST_FOR_ACCOUNTS, payload: responseList });

  let url = "";
     url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageSkuWiseListForAccounts?`;

  if(zoneId !== null){
 
      url +=`&ZoneID=${zoneId}`
  }
  if(divisionId !== null){
      
      url +=`&DivisionID=${divisionId}`
  }
  if(areaId !== null){
      url +=`&AreaID=${areaId}`
  }
  if(terrioryId !== null){
    url +=`&Territory=${terrioryId}`
  }
  if(fromDate !== null){
     
      url +=`&Fdate=${fromDate}`
  }
  if(toDate !== null){
      
      url +=`&Tdate=${toDate}`
  }
  await axios.get(url).then((res) => {
    console.log('res', res);
    responseList.data = res.data.data == null?[]:res.data.data;
    responseList.status = false;
    responseList.isLoading = false;

  });
  dispatch({ type: Types.GET_DAMAGE_SKU_WISE_LIST_FOR_ACCOUNTS, payload: responseList });
}

export const getStrikeRateOfSecondaryForSo = (fromDate,toDate) => async (dispatch) => {

  let responseList = {
    isProductLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_STRIKE_RATE_OF_SECONDARY_FOR_SO, payload: responseList });
   let  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetStrikeRateOfSecondaryForSO?FDate=${fromDate}&TDate=${toDate}`;
  await axios.get(url).then((res) => {
    console.log('so res :>> ', res);
    console.log('so url', url)

    responseList.data = res.data.data == null?[]:res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_STRIKE_RATE_OF_SECONDARY_FOR_SO, payload: responseList });
};
