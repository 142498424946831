import React from "react";
import WorkingDaysList from "../../../information/components/SecodarySales/WorkingDaysList";





const WorkingDaysListContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <WorkingDaysList />
      </div>
    </div>
  );
};

export default WorkingDaysListContainer;