import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Form, Button, Row } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { FaSearch, FaSave } from "react-icons/fa";
import { GetEmployeeInformationWithoutAdmin } from "../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";
import { getRoleListData } from "../_redux/actions/RolePermissionManagementAction";
import { RemoteAppUpdate, RemoteAppUpdatePassword, RemoteAppUpdateUserName } from "../../../Sales/information/_redux/actions/RemoteSales/RemoteSalesAction";


const EmployeeInformation = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm();

    const [Enroll, setEnroll] = useState(null);
    const [role, setRole] = useState(null);
    const [username, setUserName] = useState(null);
    const [passrord, setPassword] = useState(null);
    const roleListOption = useSelector((state) => state.roleReducer.roleListOption);

    const employeeInformation = useSelector(
        (state) => state.menu.employeeInformation
    );
    useEffect(() => {
        dispatch(getRoleListData());
    }, []);

    const showEmployee = () => {
        dispatch(GetEmployeeInformationWithoutAdmin(Enroll));
    };
    const update = () => {
        dispatch(RemoteAppUpdate(Enroll, role));
    };

    const UpdateuserName = () => {
        dispatch(RemoteAppUpdateUserName(Enroll, username));
    };
    const UpdatePassword = () => {
        dispatch(RemoteAppUpdatePassword(Enroll, passrord));
    };


    return (

        <>
            <div className="bg-white p-5 card">
                <div className="row bg-primary card p-3 mb-4 text-left">
                    <div className="col-lg-12">
                        <h5 className="text-white" >Employee Information</h5>
                    </div>
                </div>

                <div className="form-group mt-5 mb-5">
                    <div className="row pr-4">
                        <div className="col-lg-6">
                            <Form.Group as={Row} controlId="formGridState" className="row">
                                <label className="form-label col-4 text-right">ENROLL :</label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="number"
                                    placeholder="TYPE ENROLL"
                                    onChange={(e) => { setEnroll(e.target.value) }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-4">
                            <Button size="sm" className="button-audit float-right col-5" onClick={() => showEmployee()}>
                                <FaSearch /> SEARCH
                            </Button>
                        </div>

                    </div>

                </div>

                <div className="">

                    <div className="form-group mt-5 mb-5 ">
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ENROLL :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empEnroll}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    NAME :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empName}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    USER NAME :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empUserName}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    PASSWORD :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empPassword}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ROLE :
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empRole}
                                    disabled

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    ROLE NAME:
                                </label>
                                <Form.Control
                                    className="form-control col-8"
                                    type="text"
                                    name="invoiceAmount"
                                    value={employeeInformation?.empRoleName}
                                    disabled

                                />
                            </Form.Group>
                        </div>


                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mt-5">
                                <div className="row mb-5">
                                    <div className="col-6">
                                        {/* <button onClick={() => showSingleReport()} className="btn btn-success btn-block">
                                            <FaEdit /> Update User Information
                                        </button> */}
                                    </div>
                                    <div className="col-6">
                                        {/* <button
                                            onClick={() => handleDeleteItem(employeeInformation?.bilL_ID)}
                                            className="btn btn-danger btn-block">
                                            <FaAngry /> Delete User Information
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                    <div className="row bg-info card p-3 mb-4 text-left">
                        <div className="col-lg-12">
                            <h5 className="text-white text-center" >Update User Role</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <Form.Label className="formFont pl-1">
                                Role
                            </Form.Label>
                            <RHFInput
                                as={<Select options={roleListOption} />}
                                rules={{ required: false }}
                                name="role_id"
                                register={register}
                                onChange={(option) => {
                                    setRole(option.value)
                                }}
                                setValue={setValue}
                            />
                        </div>
                        <div className="col-lg-4 mt-5 mb-10">
                            <Button size="sm" className="btn-info text-white" onClick={() => update()}>
                                <FaSave /> Update Role
                            </Button>
                        </div>

                    </div>


                    <div className="row bg-primary card p-3 mb-4 text-left">
                        <div className="col-lg-12">
                            <h5 className="text-white text-center" >Update User Name</h5>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    User Name:
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="userName"
                                    value={username}
                                    onChange={(e) => {
                                        setUserName(e.target.value)
                                    }}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mt-1 mb-10">
                            <Button size="sm" className="btn-primary text-white" onClick={() => UpdateuserName()}>
                                <FaSave /> Update UserName
                            </Button>
                        </div>

                    </div>
                    <div className="row bg-success card p-3 mb-4 text-left">
                        <div className="col-lg-12">
                            <h5 className="text-white text-center" >Update Password</h5>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-6 mb-1">
                            <Form.Group
                                as={Row}
                                controlId="formGridState"
                                className="row"
                            >
                                <label className="form-label col-4 text-right">
                                    Password:
                                </label>
                                <Form.Control
                                    className="form-control reffcss col-8"
                                    type="text"
                                    name="passrord"
                                    value={passrord}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mt-1 mb-10">
                            <Button size="sm" className="btn-success text-white" onClick={() => UpdatePassword()}>
                                <FaSave /> Update Password
                            </Button>
                        </div>

                    </div>




                </div>
            </div>


        </>
    );
};

export default EmployeeInformation;