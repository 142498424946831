import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

import getRolePermissionManagementRoutes from "../modules/role-permission-management/routes";
import getSalesRoutes from "../modules/Sales/routes";



const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/auth/login" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />

        {getSalesRoutes()}

        {getRolePermissionManagementRoutes()}
       
       

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
};

export default BasePage;
