import { indexOf } from "lodash";
import * as Types from "../../types/SecondarySalesTypes";
const initialState = {

    stockReport:[],
    tReport:null,
    skuLoading:false
};
const StockReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
        case Types.GET_DISTRIBUTOR_STOCK:
         
          return {
            ...state,
            // stockReport:[...state.stockReport,...action.payload.data.allReport],
            stockReport:action.payload.data?.allReport,
            tReport:action.payload.data.tReport,
            skuLoading:action.payload.isProductLoading
           
          };
    default:
      break;
  }
  return newState;
};

export default StockReducer;

