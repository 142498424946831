import React from "react";
import DashboardTopBar from "./DashboardTopBar";

export function Demo2Dashboard() {
    return(
        <div className="container-fluid">
             <div className="row">
             <div className="col-lg-12 col-xxl-12 text-center udevelopmentText_bg mb-3">
                <span className=" udevelopmentText">THE PAGE IS IN UNDER DEVELOPMENT</span> 
            </div>
             </div> 
             <div className="col-lg-12 col-xxl-12">
                <DashboardTopBar />
            </div>
        </div>
    );
}
