import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";


const DistributorReportBrDataTable = () => {

    const dispatch = useDispatch();
    const distributorBrReport = useSelector(
        (state) => state.disReport.distributorBrReport
    );

    const isLoading = useSelector((state) => state.disReport.isLoading);
    const disBrBalance = useSelector((state) => state.disReport.disBrBalance);
    const disBrTotalBalance = useSelector(
        (state) => state.disReport.disBrTotalBalance
    );

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1px',
    };

    const valueStyle = {
        color: 'yellow',
        fontSize: '13px',
    };


    let ref = React.createRef();
    const inputField = React.useRef(null);
    const componentRef = useRef();


    return (
        <>
            <div className="card card-custom pl-5 pr-5 p-1 ">
                <div className="container-fluid mb-3 containerFixed">
                    {


                        <div className="container-fluid mb-3 mt-3 containerFixed">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="paid__widget one">
                                        <div className="widget__left">
                                            <span className="paid__widget_New one text-center text-bold target-label-cumalative-bank">Total Bank Receive Balance</span>
                                            <div className="achiveLayer">
                                                <span style={containerStyle}>
                                                    <span style={valueStyle}>৳ {isNaN(parseFloat(disBrBalance)) ? '0.00' : numberFormatDecemal(parseFloat(disBrBalance).toFixed(2))}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="paid__widget two">
                                        <div className="widget__left">
                                            <span className="paid__widget_New two text-center text-bold target-label-cumalative-bank">Net Balance</span>
                                            <div className="achiveLayer">
                                                <span style={containerStyle}>
                                                    <span style={valueStyle}>৳ {numberFormatDecemal(parseFloat(disBrTotalBalance).toFixed(2))}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    }
                </div>

                <div className="row" id="id" ref={inputField}>
                    {isLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="table-responsive" >
                        <div className="stickyTablecus"
                            style={{ height: distributorBrReport?.length > 0 ? 800 + "px" : 0 }}
                        >
                            <table className="printtablenew1" id="table-to-xls" ref={componentRef}>
                                <thead className="banktablehead">
                                    {distributorBrReport?.length > 0 &&
                                      <tr>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center', width: '120px' }}>Distributor ID</th>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center', width: '390px' }}>Distributor NAME</th>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center' }}>Opening Balance</th>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center' }}>Bank Receive</th>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center' }}>Delivery Amount</th>
                                      <th style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase', textAlign: 'center' }}>Net Balance</th>
                                  </tr>
                                  
                                    }
                                </thead>
                                <tbody >
                                    {distributorBrReport?.length > 0 &&
                                        distributorBrReport.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{width: '120px', textAlign: 'center'}}>{item.distID}</td>
                                                <td style={{width: '390px'}}>{item.disName}</td>
                                                <td className="text-right">{item.openingBalance}</td>
                                                <td className="text-right">{item.bankReceive}</td>
                                                <td className="text-right">{item.deliveryAmount}</td>
                                                <td className="text-right">{item.netBalance}</td>
                                            </tr>
                                        ))}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DistributorReportBrDataTable;
