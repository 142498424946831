import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneList } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { getFundList, GetRegionListForFund, handleFundSubmit } from "../../_redux/actions/SecondarySales/FundAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaSave } from 'react-icons/fa';
import { showToast } from "../../../../master/utils/ToastHelper";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { dateTimeConvertLongStringForDate, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";



const FundCollection = () => {
    const dispatch = useDispatch();
    const {  handleSubmit, setValue } = useForm();
    const areaListfund = useSelector((state) => state.fund.areaListfund);
    const Fundlist = useSelector((state) => state.fund.fundList);
    const isLoading = useSelector((state) => state.itemList.isLoading);
    const [inputWarnings, setInputWarnings] = useState({});
    const [areaValues, setAreaValues] = useState([]);

    useEffect(() => {
       
        setAreaValues(
            areaListfund.map((area) => ({
                ...area,
                fundValue: area.fundValue || "0",  
                orderValue: area.orderValue || "0",
            }))
        );
    }, [areaListfund]);

    const handleInputChange = (e, index, field) => {
        let value = e.target.value;

        // If the user enters only zeros (e.g., "000" or "00"), treat it as "0"
        if (value === "" || value === "0") {
            value = "0"; // If the input is empty or just zero, set to "0"
        }

        // Remove leading zeros only if there's a numeric input
        if (!isNaN(value) && value !== "") {
            value = value.replace(/^0+/, ""); // Remove leading zeros
        }

        // Check if the input value is less than 5 digits
        let warning = "";
        if (value.length < 5) {
            warning = "Value must be at least 5 digits";
        }

        // Update the warning state for this field
        setInputWarnings({
            ...inputWarnings,
            [index]: {
                ...inputWarnings[index],
                [field]: warning,
            }
        });

        // Update the local state with the new value for the specific field
        const updatedValues = [...areaValues];
        updatedValues[index][field] = value;
        setAreaValues(updatedValues);
    };

    const productSubmitStatus = useSelector((state) => state.secondary.productSubmitStatus);


    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [selectTerritory, setSelectTerritory] = useState(null);
   

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    useEffect(() => {
        if (productSubmitStatus) {
            setTimeout(() => {

            }, 3000);
        }
    }, [productSubmitStatus]);


    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        var permissionwise = await GetPermissionDropDown()
            .then((res) => {

                var d = new Date();
                var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
                var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";




                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

                dispatch(GetRegionListForFund(zoneId, divisionId, araId, territoryId));
                dispatch(getFundList(zoneId, divisionId, araId, territoryId, currentD, currentD));

            });
        let zoneData = await getZoneList();

        Setzone(zoneData.data);
    };



    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };


    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };


    const onSubmit = async (e) => {
        
        if (dateTimeConvertLongStringForDate(cuDate) == null) {
            showToast("error", "Please select Collection Date");
            return false;
        }

        const filteredAreas = areaValues.filter(area => area.fundValue > 0 || area.orderValue > 0);
    
        for (let area of filteredAreas) {

            if (area.fundValue.length < 5 && area.orderValue === "0" ) {

                showToast("error", "Value must be at least 5 digits");
                return false;
            }

            if (area.orderValue.length < 5 && area.fundValue === "0" ) {

                showToast("error", "Value must be at least 5 digits");
                return false;
            }

            if (area.fundValue.length < 5 && area.orderValue === "" ) {

                showToast("error", "Value must be at least 5 digits");
                return false;
            }

            if (area.orderValue.length < 5 && area.fundValue === "" ) {

                showToast("error", "Value must be at least 5 digits");
                return false;
            }

           
        }
    
        dispatch(handleFundSubmit(dateTimeConvertLongStringForDate(cuDate), selectZone, selectDivision, selectArea, selectTerritory, areaValues));
        
    };

    const currentDate = new Date();

    const [cuDate, setCuDate] = useState(currentDate);
    const [istoOpen, setToIsOpen] = useState(false);

    const handleClickCuDate = (e) => {
        e.preventDefault();
        setToIsOpen(!istoOpen);
    };

    useEffect(() => {
       
        setCuDate(currentDate);

    }, []);

    const handleChangeCuDate = (date) => {
        setToIsOpen(!istoOpen);
        setCuDate(date);

    };


    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <h3 className="mb-0 pb-0">Fund Collection</h3>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="form-group row">


                        <div className="col-lg-3">
                            <label className="form-label mARBOTTOMREMOVE">Collection Date</label>
                            <button className="example-custom-input" onClick={handleClickCuDate}>
                                {format(cuDate, 'dd-MM-yyyy')}{' '}
                                <i className="mt-1 fa fa-calendar calendardesign" aria-hidden="true"></i>
                            </button>
                            {istoOpen && (
                                <DatePicker
                                    selected={cuDate}
                                    inline
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="datepickerstyle"
                                    onChange={handleChangeCuDate}
                                />
                            )}
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Zone</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={zoneDropdownData()}
                                    value={defultZone}
                                    onChange={(option) => {
                                        dispatch(GetRegionListForFund(option?.value, selectDivision, selectArea, selectTerritory));
                                        divisionList(option?.value);
                                        setSelectZone(option?.value);
                                        setDefaultZone(option);
                                        setdefultDivision(null);
                                        setdefaultArea(null);
                                        setdefultTerritory(null);
                                        setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("territory", "");

                                    }}
                                    defaultValue={defultZone}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Division</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={divisionDropdownData()}
                                    value={defultDivision}
                                    onChange={(option) => {
                                        dispatch(GetRegionListForFund(selectZone, option?.value, selectArea, selectTerritory));
                                        getAreaData(option?.value);
                                        setSelectDivision(option?.value);
                                        setdefultDivision(option);
                                        setdefaultArea(null);
                                        setdefultTerritory(null);
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                    }}
                                    defaultValue={defultDivision}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Region</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={areaDropdownData()}
                                    value={defaultArea}
                                    onChange={(option) => {
                                        dispatch(GetRegionListForFund(selectZone, selectDivision, option?.value, selectTerritory));
                                        getTerritoryData(option?.value);
                                        setSelectArea(option?.value);
                                        setdefaultArea(option);
                                        setdefultTerritory(null);
                                        setValue("territory", "");
                                    }}
                                    defaultValue={defaultArea}
                                />
                            </Form.Group>
                        </div>


                        {/* <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Fund Amount</label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Fund"
                                    name="fundAmount"
                                    className="fromStyle"
                                    onChange={(e) => {
                                        setFundAmount(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div> */}
                        {/* <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Order Value</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Fund"
                                    name="ordervalue"
                                    className="fromStyle"
                                    onChange={(e) => {
                                        setOrderValue(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div> */}
                        <div className="col-lg-3 mt-3">
                            <Button type="submit" size="md" ><FaSave /> Submit</Button>
                        </div>
                    </div>

                </div>

                <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div className="table-responsive text-center">
                        <table id="table-to-xls" className="printtablenew1" style={{ borderCollapse: 'collapse', margin: '0 auto' }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ padding: '12px', textAlign: 'left', backgroundColor: '#00C2C4' }}>Name of the Region</th>
                                    <th scope="col" style={{ padding: '12px', textAlign: 'left', backgroundColor: '#00C2C4' }}>Order Value</th>
                                    <th scope="col" style={{ padding: '12px', textAlign: 'left', backgroundColor: '#00C2C4' }}>Fund Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Fundlist?.length > 0 &&
                                    Fundlist?.map((item, index) => (
                                        <>
                                            {/* Render Areas */}
                                            {item.areas?.map((area, areaIndex) => (
                                                <tr key={`area-${areaIndex}`} style={{ borderBottom: '1px solid #ddd' }}>
                                                    <td style={{ padding: '12px' }}>{area.areaName}</td> {/* Name of the Area */}
                                                    <td style={{ padding: '12px' }}>{numberFormatDecemal(parseFloat(area.orderValue).toFixed(2))}</td> 
                                                    <td style={{ padding: '12px' }}>{numberFormatDecemal(parseFloat(area.fundAmount).toFixed(2))}</td>
                                                </tr>
                                            ))}


                                            <tr
                                                className="highlighted-row"
                                                key={`division-${index}`}

                                            >
                                                <td style={{ padding: '12px', color: 'black', backgroundColor: '#FFFACD' }}><strong>Division :</strong> {item.divisionName}</td> {/* divisionName */}
                                                <td style={{ padding: '12px', color: 'black', backgroundColor: '#FFFACD' }}>{numberFormatDecemal(parseFloat(item.orderValue).toFixed(2))}</td> {/* Division orderValue */}
                                                <td style={{ padding: '12px', color: 'black', backgroundColor: '#FFFACD' }}>{numberFormatDecemal(parseFloat(item.fundAmount).toFixed(2))}</td> {/* Division FundValue */}
                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </table>

                        {!isLoading && Fundlist.length === 0 && (
                            <div className="alert alert-warning mt-5" style={{ marginTop: '20px' }}>
                                Sorry! Fund List Not Found.
                            </div>
                        )}
                    </div>
                </div>


                <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
                    {areaListfund.length > 0 ? (
                        <div className="react-bootstrap-table table-responsive mt-8">
                            <table className="table table-head-custom table-vertical-center">
                                <thead>
                                    <tr>
                                        <th>Region Name</th>
                                        <th>Fund Value</th>
                                        <th>Order Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {areaValues.map((area, index) => (
                                        <tr key={index}>
                                            <td>{area.areaName}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className={`form-control ${inputWarnings[index]?.fundValue ? "is-invalid" : ""}`}
                                                    placeholder="Write here"
                                                    value={area.fundValue}
                                                    onChange={(e) => handleInputChange(e, index, "fundValue")}
                                                />
                                                {inputWarnings[index]?.fundValue && (
                                                    <div className="invalid-feedback" style={{ color: 'red' }}>
                                                        {inputWarnings[index].fundValue}
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className={`form-control ${inputWarnings[index]?.orderValue ? "is-invalid" : ""}`}
                                                    placeholder="Write here"
                                                    value={area.orderValue}
                                                    onChange={(e) => handleInputChange(e, index, "orderValue")}
                                                />
                                                {inputWarnings[index]?.orderValue && (
                                                    <div className="invalid-feedback" style={{ color: 'red' }}>
                                                        {inputWarnings[index].orderValue}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                </div>

            </form>
        </>
    );
};

export default FundCollection;
