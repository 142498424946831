import React from "react";
import PrimarySalesDistributorProduct from "../../../information/components/SecodarySales/PrimarySalesDistributorProduct";

const PrimarySalesDistributorProductContainer = () => {
  return (
    <div className="container">
      <div className="">
        <PrimarySalesDistributorProduct/>
      </div>
    </div>
  );
};
export default PrimarySalesDistributorProductContainer;
