import React from 'react';

const NetworkError = ({ isVisible, onClose, title, children }) => {

    const logoutButton = () => {
        localStorage.removeItem("userData");
        localStorage.removeItem("global");

        localStorage.removeItem("abl_scm_userData");
        localStorage.removeItem("abl_scm");

        localStorage.removeItem("appl_scm_userData");
        localStorage.removeItem("appl_scm");

        localStorage.removeItem("afml_scm_userData");
        localStorage.removeItem("afml_scm");

        localStorage.removeItem("activeButton");
        localStorage.removeItem("showCard");
        
        sessionStorage.removeItem("lastTimeStamp");
        localStorage.removeItem("encryptedData");
        window.location.href = "/auth/login";
    };

    const handleClose = () => {
        console.log('Close button clicked');
        onClose();
    };

    if (!isVisible) return null;

    return (
        <div style={modalOverlayStyle} onClick={onClose}>
            <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
                <div style={modalHeaderStyle}>
                    <h2 style={{ margin: 0 }}>{title}</h2>
                    
                </div>
                <div style={modalBodyStyle}>
                    {children}
                </div>
                <div style={modalFooterStyle}>
                    <button
                        style={signOutButtonStyle}
                        onClick={logoutButton}
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NetworkError;

const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1050,
};

const modalContentStyle = {
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
};

const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    backgroundColor: '#dc3545',
    color: '#fff',
    padding: '0.75rem 1.25rem',
    borderRadius: '0.5rem 0.5rem 0 0',
};

const closeButtonStyle = {
    background: 'transparent',
    border: 'none',
    fontSize: '1.5rem',
    color: '#fff',
    cursor: 'pointer',
};

const modalBodyStyle = {
    maxHeight: '400px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '1rem',
};

const modalFooterStyle = {
    padding: '1rem',
    borderTop: '1px solid #ddd',
    backgroundColor: '#f8f9fa',
    display: 'flex',
    justifyContent: 'flex-end',
};

const signOutButtonStyle = {
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    cursor: 'pointer',
};
