import React from "react";
import DeliveryReportCumalitiveBYDistributor from "../../../information/components/SecodarySales/DeliveryReportCumalitiveBYDistributor";




const DeliveryCumaDistributorWiseContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DeliveryReportCumalitiveBYDistributor/>
      </div>
    </div>
  );
};

export default DeliveryCumaDistributorWiseContainer;
