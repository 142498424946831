import React from "react";
import PrimaryDemandStatus from "../../../information/components/SecodarySales/PrimaryDemandStatus";

const PrimaryDemandStatusContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <PrimaryDemandStatus />
      </div>
    </div>
  );
};
export default PrimaryDemandStatusContainer;