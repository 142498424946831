import React from "react";
import RolePermissionCreate from "../components/RolePermissionCreate";

export default function RolePermissionCreateContainer() {
  return (
    <div>
      <RolePermissionCreate />
    </div>
  );
}
