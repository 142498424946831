import React from "react";
import PrimaryDamageDemandDistributorWise from "../../../information/components/SecodarySales/PrimaryDamageDemandDistributorWise";



const PrimaryDamageDemandDistributorWiseContainer = () => {
  return (
    <div className="container-fluid">
     <PrimaryDamageDemandDistributorWise />
    </div>
  );
};

export default PrimaryDamageDemandDistributorWiseContainer;
