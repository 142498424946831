import axios from "axios";
import * as Types from "../../types/SecondarySalesTypes";
import { getDateFromUtcDate } from "../../../../../../domains/CCO/utils/DateHelper";

export const secondarySalesReportDateWise = (fromDate, tDate, zoneId, divisionId, areaId) => async (
    dispatch
  ) => {
    let responseList = {
      isLoading: true,
      data: [],
      status: true,
      dateDatasys:[],      
      count:0
  
    };  

    dispatch({
      type: Types.GET_SECONDARY_SALES_DATE_WISE,
      payload: responseList,
    });
    
    let customeObj = {
      zone:'zone',
      division:'Division',
      Area:'Area',
      DistributionPoint:'Distribution point',
      Enroll:'Enroll',
      soName:'So Name',
      Email:'Email',
      product_code:'product_code',
      product_name:'product_name',
      unitName:'unit_name',
      price:'Price',
  }
 
    const start = new Date(fromDate);
    const end = new Date(tDate);
    const dateArr=[];


    let dateOne = new Date(start);
    let dateTwo = new Date(start);
     dateTwo.setDate(dateTwo.getDate()+31);

    for(var i = dateOne; i<= dateTwo; i.setDate(i.getDate()+1)){
      let data = getDateFromUtcDate(dateOne);
      dateArr.push(data);
    }

    for (let i = 0; i < dateArr.length; i++) {
      customeObj['field'+i]=dateArr[i]
    }

    responseList.count  = dateArr.length;
   responseList.dateDatasys.push(customeObj);
   let url = "";
      url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetMonthwithSSPivot?`;

 

       if(zoneId !== null){
       
        url +=`&ZoneID=${zoneId}`
    }
    if(divisionId !== null){
        
        url +=`&DivisionID=${divisionId}`
    }
    if(areaId !== null){
        url +=`&AreaID=${areaId}`
    }
    if(fromDate !== null){
        
        url +=`&FDate=${fromDate}`
    }
    if(tDate !== null){
       
        url +=`&TDate=${tDate}`
    }
  


    await axios.get(url).then((res) => {
      responseList.data = res.data.data;
      responseList.status = false;
      responseList.isLoading=false
      // responseList.dateDatasys = customeObj
    });

    dispatch({
      type: Types.GET_SECONDARY_SALES_DATE_WISE,
      payload: responseList,
    });
  };
  