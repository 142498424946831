import React from "react";
import SecondarySalesSoProduct from "../../../information/components/SecodarySales/SecondarySalesSoProduct";

const SecondarySalesSoProductContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySalesSoProduct/>
      </div>
    </div>
  );
};
export default SecondarySalesSoProductContainer;
