import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../../../styles/Gift1.json';

export default class LotiGift extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  componentDidMount() {
    if (!animationData) {
      console.error("Animation data is not defined");
    }
  }

  render() {
    const buttonStyle = {
      display: 'block',
      margin: '10px auto',
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
        />
      </div>
    );
  }
}
