import React from "react";
import SecondarySalesSoAchivement from "../../../information/components/SecodarySales/SecondarySalesSoAchivement";

const SeconSoWiseContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySalesSoAchivement/>
      </div>
    </div>
  );
};
export default SeconSoWiseContainer;
