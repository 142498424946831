import React from "react";
import SecondaryStatusSales from "../../../information/components/SecodarySales/SecondaryStatusSales";


const SecondarySalesStatusContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondaryStatusSales/>
      </div>
    </div>
  );
};

export default SecondarySalesStatusContainer;
