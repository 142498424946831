import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ZoneFilter from "./ZoneFilter";
import { GetAllZone } from "../../_redux/actions/Zone/ZoneAction";

const ZoneList = () => {
  const itemList = useSelector(state => state.itemList.itemList)
  const itemListPaginated = useSelector(state => state.itemList.itemListPaginated)
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(GetAllZone());
    
  }, [])



  

  const [itemDetailShow, setItemDetailShow] = useState(false)
  const [itemID, setItemID] = useState(null)
  const itemDetail = (item) => {
    setItemID(item.intItemID);
    setItemDetailShow(true)
  }
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        {/* <div className="container"> */}
        <ZoneFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <div className="row">
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  <th scope="col">SL</th>
                  {/* <th scope="col">Department</th> */}
                  <th scope="col">ZONE ID</th>
                  <th scope="col">ZONE CODE</th>
                  <th scope="col">ZONE NAME</th>
                  {/* <th scope="col">ITEM CATEGORY</th> */}
                  <th scope="col">COM ID</th>
                  {/* <th scope="col">MODEL</th> */}
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {itemList && itemList.map((item, index) => (
                  <tr>
                    <td>{itemListPaginated.from + index}</td>
                    {/* <td>{item.strDepartmentName}</td> */}
                    <td>{item.strItemName}</td>
                    <td>{item.strUoM}</td>
                    <td>{item.StrItemTypeName}</td>
                    {/* <td>{item.strItemCategoryName}</td> */}
                    <td>{item.strPartNo}</td>
                    {/* <td>{item.strModelNo}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ZoneList;
