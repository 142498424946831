
import * as Types from "../../types/SecondarySalesTypes";

const initialState = {
    isProductLoading: false,
    productReports:[] ,
    cumalativePrice: 0,
    productTotal: 0,
    productQuantity: 0,
    productPrice: 0,
    primaryLoading:false
};
const PrimaryReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    
      case Types.GET_PRIMARY_SKUWISE_REPORT:
        let productPrice = 0;
        let productQuantity = 0;
        let productTotal = 0;
  
        for (let i = 0; i < action.payload.data.length; i++) {
          productPrice += action.payload.data[i].productPicPrice;
          productQuantity += action.payload.data[i].productQty;
          productTotal += action.payload.data[i].productTPrice;
        }
        return {
          ...state,
          productReports: action.payload.data,
          cumalativePrice: action.payload.data,
          productTotal,
          productQuantity,
          productPrice,
          primaryLoading: action.payload.isProductLoading,
        };

    default:
      break;
  }
  return newState;
};

export default PrimaryReducer;
