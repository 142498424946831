import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";

import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Col, Button } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { currentdate, generateStringDateFromDate } from "../../../../../domains/CCO/utils/DateHelper";
import ReportFooter from "../../Master/ReportFooter";
import { GetTargetVsAchDispointWise, cleanTargetSalesData, getSkuDemandWiseTargetAction, GetTargetVsAchDispointWiseForDownload } from "../../_redux/actions/Report/ReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import * as XLSX from "xlsx/xlsx.mjs";
import { FaDownload } from "react-icons/fa";

const TargetVsAchDispointWise = () => {

  const targetDateList = useSelector((state) => state.target.DispointTarget);
  console.log('targetDateList', targetDateList);
  const totalTarget = useSelector((state) => state.target.totalData);

  const isLoading = useSelector((state) => state.target.DisPointLoading);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();
  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  console.log('targetDateList?.detailsReport :>> ', targetDateList?.detailsReport);

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanTargetSalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    // setValue("divisionData", "");
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  const [wordDate, setWordDate] = useState([]);

  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  // const handleChange = ({ currentTarget: input }) => {
  //   setStartDate(input.value);
  // };
  const handleChangeTodate = (input) => {
    let genData = generateStringDateFromDate(input);
    setWordDate(genData);
    setToDate(input);
  };
  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    //let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    //let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    let conFromDate = skuDate.fromDate;
    let conToDate = skuDate.todate;
    // dispatch(getSkuDemandWiseTargetAction(fromDate, tDate, idZone, idDivision, idArea));
    dispatch(GetTargetVsAchDispointWise(conFromDate, conToDate, idZone, idDivision, idArea, idTerritory));
  }


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hanleExcelClick = async () => {
    setLoading(true);
    setError(null);



    try {
      let excelClick = await GetTargetVsAchDispointWiseForDownload(
        skuDate.fromDate,
        skuDate.todate,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory
      );

      if (excelClick && excelClick.data.detailsReport && excelClick.data.detailsReport.length > 0) {
        const current = currentdate();
        const detailsReportData = excelClick.data.detailsReport;

        const flattenedData = [];

        detailsReportData.forEach((detailsRow) => {
          const itemReportData = detailsRow.itemReport;

          if (itemReportData && itemReportData.length > 0) {
            itemReportData.forEach((itemRow) => {
              const row = {
                "ZONE": detailsRow.zoneName,
                "DIVISION": detailsRow.divisionName,
                "REGION": detailsRow.areaName,
                "TERRITORY": detailsRow.territoryName,
                "DISPOINT ID": detailsRow.disPointID,
                "DISPOINT NAME": detailsRow.disName,
                "PRODUCT CATEGORIES": itemRow.prodCatagories || '',
                "PRODUCT ID": itemRow.prodID || '',
                "PRODUCT NAME": itemRow.prodName || '',
                "TARGET QUANTITY(CTN)": itemRow.targetQty || 0,
                "TARGET VALUE": itemRow.targetPrice || 0,
                "IMS QTY": itemRow.liftingQty || 0,
                "IMS VALUE": itemRow.liftingPrice || 0,
                "DIFFERENCE QUANTITY": itemRow.differentQty || 0,
                "DIFFERENCE VALUE": itemRow.differentPrice || 0,
                "TARGET VS ACH(IN TK %)": itemRow.tarVsAccPers || 0,
              };
              flattenedData.push(row);
            });
          } else {
            // If there's no itemReport data, include detailsReport data as a row
            const row = {
              "ZONE": detailsRow.zoneName,
              "DIVISION": detailsRow.divisionName,
              "REGION": detailsRow.areaName,
              "TERRITORY": detailsRow.territoryName,
              "DISPOINT ID": detailsRow.disPointID,
              "DISPOINT NAME": detailsRow.disName,
              "PRODUCT CATEGORIES": '',
              "PRODUCT ID": '',
              "PRODUCT NAME": '',
              "TARGET QUANTITY(CTN)": detailsRow.totalReport.targetQty || 0,
              "TARGET VALUE": detailsRow.totalReport.targetPrice || 0,
              "IMS QTY": detailsRow.totalReport.liftingQty || 0,
              "IMS VALUE": detailsRow.totalReport.liftingPrice || 0,
              "DIFFERENCE QUANTITY": detailsRow.totalReport.differentQty || 0,
              "DIFFERENCE VALUE": detailsRow.totalReport.differentPrice || 0,
              "TARGET VS ACH(IN TK %)": 0,
            };
            flattenedData.push(row);
          }
        });

        const Heading = [

        ];

        // Create a new workbook and add the header
        const wb = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: Heading });
        XLSX.utils.book_append_sheet(wb, worksheet, 'Details Report');
        XLSX.writeFile(wb, 'PRIMARY TARGET VS ACHIEVEMENT DISPOINT WISE ' + " " + current + '.xlsx');
      } else {
        console.error("No data to export to Excel");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Network Error");

    } finally {
      setLoading(false);
    }
  };









  const handleDateFilter = (option) => {
    handleChangeTodate(option);
    handleListAction(startDate, option, 0, selectDivision, selectArea);
  }

  const style = {
    container: {
      'background': 'f7f7f7',
      // 'height':670,
      'overflowy': 'scroll'
    },
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">PRIMARY TARGET VS ACHIVEMENT DISPOINT WISE</h3>
            </div>
          </div>


          <div className="col-md-3">
            <Button
              size="sm"
              onClick={hanleExcelClick}
              disabled={loading}
              className={loading ? 'bg-danger' : ''}
            >
              <FaDownload /> {loading ? 'Loading...' : 'Download'}
            </Button>
            {error && <div>Error: {error}</div>}
            <DownloadDropdown excelname='Primary Lifting vs Achievement Confirm Date' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Deadweight"
                name="fromTime"
                value={`${skuDate.fromTime}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Todate"
                name="toTime"
                className="fromStyle"
                value={`${skuDate.toTime}`}
                onChange={(e) => handleChange(e, 'toTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");


                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);

                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);


                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


        </div>
        <div className="col-lg-12 ">
          <button type="button" className="btn btn-success btn-sm mt-3 float-left" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}>
            Show Report
          </button>
        </div>
        {Object.keys(targetDateList).length > 0 &&
          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Target</p>
                    <div className="achiveLayer">
                      <span>Biscuit Target Value:  {parseInt(targetDateList?.biscuitTargetPrice)}</span>
                      <span>Cookies  Target Value:  {parseInt(targetDateList?.cookisTargetPrice)}</span>
                      <span>Cake Target Value:  {parseInt(targetDateList?.cakeTargetPrice)}</span>
                      <span>Wafer Target Value:  {parseInt(targetDateList?.waferTargetPrice)}</span>
                      <span>Snacks Target Value:  {parseInt(targetDateList?.snacksTargetPrice)}</span>
                      {/* <span>Total Target Value:  {parseInt(targetDateList?.totalTargetPrice)}</span> */}
                      <span>Total Target Value:  {parseInt(targetDateList?.biscuitTargetPrice)
                        + parseInt(targetDateList?.cookisTargetPrice) + parseInt(targetDateList?.cakeTargetPrice) +
                        parseInt(targetDateList?.waferTargetPrice) + parseInt(targetDateList?.snacksTargetPrice)
                      }</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Primary Lifting</p>
                    <div className="achiveLayer">
                      <span>Biscuit Lifting Value:  {parseInt(targetDateList?.biscuitLiftingPrice)}</span>
                      <span>Cookies  Lifting Value:  {parseInt(targetDateList?.cookisLiftingPrice)}</span>
                      <span>Cake Lifting Value:  {parseInt(targetDateList?.cakeLiftingPrice)}</span>
                      <span>Wafer Lifting Value:  {parseInt(targetDateList?.waferLiftingPrice)}</span>
                      <span>Snacks Lifting Value:  {parseInt(targetDateList?.snacksLiftingPrice)}</span>
                      {/* <span>Total Lifting Value:  {parseInt(targetDateList?.totalLiftingPrice)}</span> */}
                      <span>Total Lifting Value:  {

                        parseInt(targetDateList?.totalLiftingPrice)

                      }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Achivement</p>
                    <div className="achiveLayer">
                      <span>Biscuit Ach (%):  {parseInt(((targetDateList?.biscuitLiftingPrice) / targetDateList?.biscuitTargetPrice) * 100)}</span>
                      <span>Cookies Ach (%):  {parseInt(((targetDateList?.cookisLiftingPrice) / targetDateList?.cookisTargetPrice) * 100)}</span>
                      <span>Cake Ach (%):  {parseInt(((targetDateList?.cakeLiftingPrice) / targetDateList?.cakeTargetPrice) * 100)}</span>
                      <span>Wafer Ach (%):  {parseInt(((targetDateList?.waferLiftingPrice) / targetDateList?.waferTargetPrice) * 100)}</span>
                      <span>Snacks Ach (%):  {parseInt(((targetDateList?.snacksLiftingPrice) / targetDateList?.snacksTargetPrice) * 100)}</span>
                      <span>Total Ach (%):  {parseInt(((targetDateList?.totalLiftingPrice) /
                        (parseInt(targetDateList?.biscuitTargetPrice)
                          + parseInt(targetDateList?.cookisTargetPrice) + parseInt(targetDateList?.cakeTargetPrice) +
                          parseInt(targetDateList?.waferTargetPrice) + parseInt(targetDateList?.snacksTargetPrice))
                      ) * 100)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>

            <div
              className="stickyTable"
              style={{ height: Object.keys(targetDateList).length > 0 ? 800 + "px" : 0 }}
            >
              {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} > */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">DiSPOINT ID</th>
                    <th scope="col">DISPOINT NAME</th>
                    <th scope="col">PRODUCT CATEGORIES</th>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">TARGET QUANTITY(CTN)</th>
                    <th scope="col">TARGET VALUE</th>
                    <th scope="col">LIFTING QTY</th>
                    <th scope="col">LIFTING VALUE</th>
                    <th scope="col">DIFFERECE QUANTITY</th>
                    <th scope="col">DIFFERECE VALUE</th>
                    <th scope="col">TARGET VS ACH(IN TK %)</th>
                  </tr>
                </thead>
                <tbody>
                  {targetDateList?.detailsReport?.length > 0 &&

                    targetDateList?.detailsReport?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.disPointID}</td>
                          <td>{item.disName}</td>
                          <td>{item.prodCatagories}</td>
                          <td />
                          <td />
                          <td>{(item?.totalReport.targetQty)}</td>
                          <td>{(item?.totalReport.targetPrice)}</td>
                          <td>{(item?.totalReport.liftingQty)}</td>
                          <td>{(item?.totalReport.liftingPrice)}</td>
                          <td>{(item?.totalReport.targetQty)}</td>
                          <td>{(item?.totalReport.differentPrice)}</td>
                          <td>{(item?.totalReport.tarVsAccPers)}</td>

                        </tr>
                        {
                          item?.itemReport?.map((product, subindex) => (
                            <tr>
                              <td>{item.zoneName}</td>
                              <td>{item.divisionName}</td>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                              <td>{item.disPointID}</td>
                              <td>{item.disName}</td>
                              <td>{product.prodCatagories}</td>
                              <td>{product.prodID}</td>
                              <td>{product.prodName}</td>
                              <td>{parseFloat(product.targetQty).toFixed(2)}</td>
                              <td>{parseFloat(product.targetPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.liftingQty).toFixed(2)}</td>
                              <td>{parseFloat(product.liftingPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.differentQty).toFixed(2)}</td>
                              <td>{parseFloat(product.differentPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.tarVsAccPers).toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}
                </tbody>
              </table>
            </div>

            {!isLoading && targetDateList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>




        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default TargetVsAchDispointWise;
