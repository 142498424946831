import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form,Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  delteProductList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
  getUpdateProductList,
  productUpdateInputQuantity,
  salesUpdate,
  handleMemoInput,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { getItemValidationforStockProduct, getItemValidationforStockUpdateProduct } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";

const SecondarySalesEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, name, code } = useParams();
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const location = useLocation();
  const salesEditInfo = useSelector((state) => state.remoteSales.salesEditInfo);
  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled, setDisabled] = useState(false);
  const [currentDate,setCurrentDate] = useState(currentdate());
  const salesUpdateData = useSelector(
    (state) => state.secondary.salesUpdateData
  );
  console.log('salesUpdateData :>> ', salesUpdateData);
  const memoInput = useSelector(
    (state) => state.secondary.memoData
  );
  const [memoData, setMemoData] = useState({
    orderValue: 0,
    totalmemoQty: 0,
    totalSkuQty: 0,
    visitedOutlet: 0
  });

  // Redirect List Page after submitting data
  useEffect(() => {
    if (productUpdateStatus) {
      setTimeout(() => {history.go(0)}, 5000);
    }

  }, [productUpdateStatus]);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );
  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    // dispatch(getDistributorList());

    dispatch(getUpdateProductList(id, name));

    // dispatch(getItemCategory())
  }, []);

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
    dispatch(productUpdateInputQuantity(name, value));
  };


  const onSubmit = async (e) => {
    if (memoInput.visitedOutlet >=999) {
      alert("Visited Outlet must less than 1000");
      return false;
    }
    else if (memoInput.visitedOutlet == 0) {
      alert("Please Input T.Visited Outlet");
      return false;
    } else if (memoInput.orderValue == 0) {
      alert("Please Input T.Order value ");
      return false;
    }
    else if (memoInput.totalmemoQty == 0) {
      alert("Please Input T.Memo Qty");
      return false;
    }
    else if (memoInput.totalmemoQty >= 999) {
      alert("T.Memo Qty must less than 1000 ");
      return false;
    } else if (memoInput.totalSkuQty == 0) {
      alert("Please Input Number of T.Memo Line ");
      return false;
    }
    else if (memoInput.totalSkuQty > 999) {
      alert("T.Memo Line must less than 1000");
      return false;
    } 
    else {
      setDisabled(true);
      dispatch(salesUpdate(id,memoInput,name, salesUpdateData));
    }
  };

  // const onSubmit = async (e) => {
  //   setDisabled(true);
  //   dispatch(salesUpdate(id,memoInput,name, salesUpdateData));

  // };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];
  const handleMemoData = (value, name) => {
    dispatch(handleMemoInput(value, name));
  }
  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border p-3">
          <div className="mt-5">
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">{name}</strong>
                    <br />
                    {code}
                    <br />

                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group "></div>
        </div>
        <div className="container-fluid">
        <div className="form-group row">
        <div className="col-lg-3 mt-2">
            <label className="form-label">T.Visited Outlet</label>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Control
                type="number"
                placeholder="Enter Visited Outlet"
                name="visitedOutlet"
                className="fromStyle"
                onChange={(e) => handleMemoData(e.target.value, 'visitedOutlet')}
                value={memoInput.visitedOutlet}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-2">
            <label className="form-label">T.Order value</label>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Control
                type="number"
                placeholder="Enter Order value"
                name="orderValue"
                className="fromStyle"
                onChange={(e) => handleMemoData(e.target.value, 'orderValue')}
                value={memoInput.orderValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-2">
            <label className="form-label">T.Memo Qty</label>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Control
                type="number"
                placeholder="Enter Memo Qty"
                name="totalmemoQty"
                className="fromStyle"
                onChange={(e) => handleMemoData(e.target.value, 'totalmemoQty')}
                value={memoInput.totalmemoQty}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-2">
            <label className="form-label">Number of T.Memo Line</label>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Control
                type="number"
                placeholder="Enter sku Qty"
                name="totalSkuQty"
                className="fromStyle"
                onChange={(e) => handleMemoData(e.target.value, 'totalSkuQty')}
                value={memoInput.totalSkuQty}
              />
            </Form.Group>
          </div>
         
        </div>

        </div>
        





        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Update Stock</th>
                  {/* <th>Stock</th>
                  <th>Damage</th> */}
                  <th>Net Stock</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.upQty}</td>
                      {/* <td>{parseInt(item.prodStock)}</td>
                      <td>{isNaN(parseInt(item.prodDamage)) ? 0 : parseInt(item.prodDamage)}</td> */}
                      <td className={item.netStock < 0 ? 'redText' : ''}>{isNaN(parseInt(item.netStock)) ? 0 : parseInt(item.netStock)}</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.itemQuentityPic}
                            onChange={(e) =>{
                              dispatch(getItemValidationforStockUpdateProduct(id,name,item));
                              changeProductQuantity(item, e.target.value)
                            } 
                            }
                          />
                        </Form.Group>
                        <span><small className="validation-symbol"> {item.status == false ? "Sorry !! Stock is not avaiable" : ""} </small></span>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/remoteSales/list";
                  }}
                  type="button"
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SecondarySalesEdit;
