import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { divisionSalesReport, territorySalesReport, territorySalesReportBakemans, ZoneSalesReport } from '../../../information/_redux/actions/Report/ReportAction';
import { areaSalesReport } from './../../../information/_redux/actions/Report/ReportAction';
import { useState } from 'react';
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FaSearch} from 'react-icons/fa';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';

export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};



const TarVSacvZDATwiseTSMwiseBakemans = () => {

    const { register, control, setValue } = useForm();
    const reportData = useSelector((state) => state.target.zoneReports);
    const territoryReports = useSelector((state) => state.target.territoryReports);
    const territoryReportsLoading = useSelector((state) => state.target.territoryReportsLoading);
    const TotalTarget = useSelector((state) => state.target.TotalTarget);


    const totalDays = useSelector((state) => state.target.totalDays);
    const workingLeft = useSelector((state) => state.target.workingLeft);

    console.log('workingLeft :>> ', workingLeft);

    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectFilterData, setSelectFilterData] = useState(3);

    const [reportList, setReportList] = useState([
        {
            id: 3,
            type: 'Bakemans'
        }
    ]);

    const reportTypeDdl = () => {
        let options = [];
        if (reportList?.length > 0) {
            reportList.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.type,
                };

                options.push(itemData);
            });
        }
        return options;
    };



    const componentRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {

        const d = new Date();
        const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1); // First day of the current month
        const previousDay = new Date(d); // Copy the current date
        previousDay.setDate(d.getDate() - 1); // Subtract 1 day from the current date

        const firstD = formatDate(firstDayOfMonth);
        const currentD = formatDate(previousDay);

        dispatch(ZoneSalesReport(firstD, currentD, 3));
        dispatch(territorySalesReportBakemans(firstD, currentD, 3));
    }, [])

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);

    };

    const showReport = () => {
        dispatch(ZoneSalesReport(startDate, toDate, 3));
        dispatch(territorySalesReportBakemans(startDate, toDate, 3));
    }


    const needIms = ((TotalTarget / totalDays) * (workingLeft / TotalTarget)) * 100;
    console.log('needIms :>> ', needIms);


    return (
        <div className='card fullcardbg m-3'>
            {/* <div className="row mt-3 mr-2">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div class="btn-group btn-group-sm float-right" role="group">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="excelBtn ml-1"
                            table="table-to-xls"
                            filename={"TARGET VS SECONDARY ACH% (ZONE,DIVISION,REGION,TERRITORY)"}
                            sheet="tablexls"
                            buttonText={
                                <span style={{ display: 'flex', alignItems: 'center', background: 'blue', color: 'white', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <FaFileExcel style={{ marginRight: '5px' }} />
                                    XLS download
                                </span>
                            }
                        />
                        <button type="button" class="btn float-right ">
                            <PrintNew refvalue={componentRef} />
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="form-group row pl-4">
                <div className="form-group row pl-4">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Filter By</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={reportTypeDdl()} />}
                                rules={{ required: false }}
                                name="typeData"
                                register={register}
                                value={{ value: '7', label: 'Bakemans' }}
                                isClearable
                                isDisabled
                                onChange={(option) => {
                                    setSelectFilterData(option?.value)

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 mt-5 mb-5">
                        <Button size="sm" onClick={() => showReport()} className="btn btn-success">
                            <FaSearch style={{ marginRight: '5px' }} />Show Report
                        </Button>
                    </div>

                </div>

            </div>
            <hr></hr>
            <div className="row" ref={componentRef}>
                <div className="col-lg-12 mb-5">
                    <div className=" col-lg-12 text-center text-uppercase text-bold newtext">
                        Target vs Secondary Ach% (Territory)
                    </div>
                </div>

                <div className='col-lg-12'>
                   
                    {territoryReportsLoading && <NewLoader2 />}

                    <div className="table-responsive pl-5 pr-5 pb-5 " ref={componentRef}>
                        <table id="table-to-xls" className="printtablenew1">

                            {reportData?.length > 0 &&
                                <thead >
                                   <tr >
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS:&nbsp;&nbsp;{totalDays}</th>
                                        <th colSpan="3" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS PASS:&nbsp;&nbsp;{workingLeft}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>NEED IMS:&nbsp;&nbsp;{parseFloat(needIms).toFixed(2)}% </th>
                                    </tr>
                                </thead>
                            }
                            <tbody>

                                {territoryReports?.length > 0 &&
                                    < >
                                        <tr className="backcolor3">
                                            <th colSpan={11} className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>Territory Wise Data</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th>Territory</th>
                                         
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">Previous Month IMS</th>
                                            <th className="text-center">Current Month IMS</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(tar Vs IMS Ach%)</th>
                                        </tr>
                                    </>
                                }
                                {territoryReports?.length > 0 &&
                                    territoryReports?.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{index++ + 1}</td>
                                                <td>{item.zone_name}</td>
                                                
                                                <td>{(parseFloat(item.target).toFixed(2))}</td>
                                                <td>{(parseFloat(item.beforeMonth).toFixed(2))}</td>
                                                <td>{(parseFloat(item.sales).toFixed(2))}</td>
                                                <td>
                                                    <span style={{
                                                        color: ((item.performance)) >= (needIms) ?
                                                            ((item.performance) === '0.00' ? '#f1420d' : '#1c8b5d') :
                                                            '#f1420d'
                                                    }}>
                                                        {parseFloat((item.performance)).toFixed(2)}%
                                                    </span>
                                                </td>



                                                <td>{(parseFloat((item.performance * totalDays) / workingLeft).toFixed(2))}%</td>
                                                <td>{((item.soQty))}</td>
                                                <td>
                                                    <span style={{
                                                        color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                            ((parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)) === '0.00' ? '#f1420d' : '#1c8b5d') :
                                                            '#f1420d'
                                                    }}>
                                                        {(parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2))}%
                                                    </span>
                                                </td>
                                                <td>{((parseFloat((item.sales / item.soQty) / workingLeft)).toFixed(2))}</td>
                                                <td>{((item.rank))}</td>

                                            </tr>
                                        );
                                    })}

                            </tbody>



                        </table>
                    </div>


                </div>



            </div>
        </div>
    )
}

export default TarVSacvZDATwiseTSMwiseBakemans

