import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

const responses = {
  'hi': 'Hello! How can I assist you today?',
  'what is your name?': 'I am your friendly akijInsafbot!',
  'how can i contact support?': 'You can contact support at Mail: itsupport@akijinsaf.com Or SMS Whatsapp Group',
  'it head?': 'Nahid Newaz Chowdhury',
  'ims developers name?': 'Mohaimenur Rahman',
  'ims related issues?': 'Contact with Kashem Bhai & Parvez Bhai',
  'software related issues?': 'SMS Whatsapp Group : IT Support (ABL)',
  'network related issues?': 'You can contact support at Mail: itsupport@akijinsaf.com',
};

const initialSuggestions = [
  'hi',
  'what is your name?',
  'how can i contact support?',
  'it head?',
  'ims developers name?',
  'ims related issues?',
  'software related issues?',
  'network related issues?',
];

export function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const [isTyping, setIsTyping] = useState(false);

  // Simulate bot typing animation
  const simulateTyping = (response) => {
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [...prevMessages, { text: response, fromUser: false }]);
      setIsTyping(false);
    }, 500);
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (input.trim() === '') return;

    // Add user message
    setMessages([...messages, { text: input, fromUser: true }]);

    // Get bot response
    const lowerCaseInput = input.toLowerCase();
    const response = responses[lowerCaseInput] || 'Sorry, I don’t understand that.';

    // Simulate typing animation and then add bot response
    simulateTyping(response);

    // Clear input
    setInput('');
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSuggestions(suggestions.filter(s => s !== suggestion)); // Remove suggestion
    setInput(suggestion); // Set input to clicked suggestion

    // Add user message and simulate bot response
    setMessages([...messages, { text: suggestion, fromUser: true }]);
    const lowerCaseSuggestion = suggestion.toLowerCase();
    const response = responses[lowerCaseSuggestion] || 'Sorry, I don’t understand that.';
    simulateTyping(response);
  };

  // Toggle visibility
  const toggleChatbot = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
    <div style={{marginBottom: '20px'}}>
      {!isVisible ? (
        <div style={styles.logoContainer} onClick={toggleChatbot}>
          <img
            src={toAbsoluteUrl("/media/logos/chat.png")}
            alt="Chatbot Logo"
            style={styles.logo}
          />
        </div>
      ) : (
        <div style={styles.chatContainer}>
          {/* Header with logo, chatbot name, and close button */}
          <div style={styles.header}>
            <img
              src={toAbsoluteUrl("/media/logos/chat.png")}
              alt="Chatbot Logo"
              style={styles.headerLogo}
            />
            <div style={styles.headerTitle}>
              Akij Insaf bot <span style={styles.onlineStatus}>• Online</span>
            </div>
            <button onClick={toggleChatbot} style={styles.closeHeaderButton}>×</button>
          </div>

          <div style={styles.messagesContainer}>
            {messages.map((msg, index) => (
              <div key={index} style={msg.fromUser ? styles.userMessageContainer : styles.botMessageContainer}>
                <img
                  src={msg.fromUser ? toAbsoluteUrl("/media/logos/user.png") : toAbsoluteUrl("/media/logos/chat.png")}
                  alt={msg.fromUser ? "User Logo" : "Bot Logo"}
                  style={styles.messageLogo}
                />
                <div style={msg.fromUser ? styles.userMessage : styles.botMessage}>
                  {msg.text}
                </div>
              </div>
            ))}
            {isTyping && (
              <div style={styles.typingIndicator}>...</div>
            )}
          </div>
          {suggestions.length > 0 && (
            <div style={styles.suggestionsContainer}>
              {suggestions.map((suggestion, index) => (
                <button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={styles.suggestionButton}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}
          <div style={styles.inputContainer}>
            <input
              type="text"
              value={input}
              placeholder="Type Your Message Here"
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              style={styles.input}
            />
            <button onClick={handleSendMessage} style={styles.sendButton}>{"Send"}</button>
          </div>
        </div>
      )}
      </div>
    </>
  );
}

// Inline styles
const styles = {
  chatContainer: {
    position: 'fixed',
    bottom: '0',
    right: '10px',
    width: '400px',
    height: '500px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EEEEEE',
    marginBottom: '75px',
  },
  header: {
    backgroundColor: '#ffff',
    color: 'black',
    padding: '10px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLogo: {
    height: '44px',
    width: '44px',
  },
  headerTitle: {
    fontSize: '16px',
    flex: 1,
    textAlign: 'center',
  },
  onlineStatus: {
    color: '#28a745',
  },
  closeHeaderButton: {
    background: 'none',
    border: 'none',
    color: 'black',
    fontSize: '24px',
    cursor: 'pointer',
  },
  messagesContainer: {
    flex: 1,
    padding: '10px',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  userMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  botMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  userMessage: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '80%',
    wordWrap: 'break-word',
    marginLeft: '10px',
  },
  botMessage: {
    backgroundColor: '#ffff',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '80%',
    wordWrap: 'break-word',
    marginRight: '10px',
  },
  messageLogo: {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
  },
  inputContainer: {
    display: 'flex',
    borderTop: '1px solid #ccc',
    padding: '5px',
  },
  input: {
    flex: 1,
    padding: '5px',
    border: 'none',
    borderRadius: '5px',
  },
  sendButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  suggestionsContainer: {
    padding: '10px',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto', // Allow horizontal scrolling
    whiteSpace: 'nowrap', // Prevent wrapping
  },
  suggestionButton: {
    backgroundColor: '#7FFFD4',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
    textAlign: 'center',
    marginRight: '5px', // Space between buttons
  },
  typingIndicator: {
    alignSelf: 'flex-start',
    color: '#999',
    fontStyle: 'italic',
    padding: '10px',
  },
  logoContainer: {
    position: 'fixed',
    bottom: '65px',
    right: '10px',
    cursor: 'pointer',
    zIndex: 1000,
  },
  logo: {
    height: '60px',
    width: '60px',
  },
};
