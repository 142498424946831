import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ReportTest = () => {
  const [columns, setColumns] = useState({
    name: true,
    age: true,
    email: true,
    department: true,
    unit: true,
    time: true,
  });

  const [data] = useState([
    { name: 'John Doe', age: 28, email: 'john@example.com', department: 'HR', unit: 'Recruitment', time: '9 AM' },
    { name: 'Jane Smith', age: 32, email: 'jane@example.com', department: 'IT', unit: 'Development', time: '10 AM' },
    { name: 'Mike Johnson', age: 45, email: 'mike@example.com', department: 'Finance', unit: 'Accounting', time: '11 AM' },
  ]);

  const handleColumnChange = (e) => {
    setColumns({ ...columns, [e.target.name]: e.target.checked });
  };

  const downloadExcel = () => {
    const filteredData = data.map(row => {
      const filteredRow = {};
      for (const col in columns) {
        if (columns[col]) {
          filteredRow[col] = row[col];
        }
      }
      return filteredRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  const filteredData = data.map(row => {
    const filteredRow = {};
    for (const col in columns) {
      if (columns[col]) {
        filteredRow[col] = row[col];
      }
    }
    return filteredRow;
  });

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Report</title>');
    printWindow.document.write('<style>table {width: 100%; border-collapse: collapse;} th, td {padding: 10px; border: 1px solid #ddd; text-align: left;} th {background-color: #007bff; color: white;}</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<h2>Report Preview</h2>');
    printWindow.document.write('<table><thead><tr>');
    
    // Add colspan for Personal
    printWindow.document.write('<th colSpan="3">Personal</th>');
    Object.keys(columns).forEach(col => {
      if (col !== 'name' && col !== 'age' && col !== 'email' && columns[col]) {
        printWindow.document.write(`<th>${col}</th>`);
      }
    });
    
    printWindow.document.write('</tr><tr>');
    ['name', 'age', 'email'].forEach(col => {
      if (columns[col]) {
        printWindow.document.write(`<th>${col}</th>`);
      }
    });
    printWindow.document.write('</tr></thead><tbody>');

    filteredData.forEach(row => {
      printWindow.document.write('<tr>');
      Object.keys(columns).forEach(col => {
        if (columns[col]) {
          printWindow.document.write(`<td>${row[col]}</td>`);
        }
      });
      printWindow.document.write('</tr>');
    });
    printWindow.document.write('</tbody></table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '20px auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
    },
    heading: {
      textAlign: 'center',
      color: '#333',
      marginBottom: '20px',
    },
    columnSelection: {
      marginBottom: '20px',
    },
    columnCheckbox: {
      display: 'block',
      margin: '5px 0',
      fontSize: '16px',
    },
    downloadButton: {
      display: 'block',
      margin: '10px auto',
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
    printButton: {
      display: 'block',
      margin: '10px auto',
      padding: '10px 20px',
      backgroundColor: '#28a745',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
    tableHeader: {
      padding: '12px',
      border: '1px solid #ddd',
      textAlign: 'left',
      fontSize: '16px',
      backgroundColor: '#007bff',
      color: 'white',
      fontWeight: 'bold',
    },
    tableCell: {
      padding: '12px',
      border: '1px solid #ddd',
      textAlign: 'left',
      fontSize: '16px',
    },
    tableRowEven: {
      backgroundColor: '#f9f9f9',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Report Generator</h2>
      <div style={styles.columnSelection}>
        <h3>Select Columns</h3>
        {Object.keys(columns).map((col) => (
          <label key={col} style={styles.columnCheckbox}>
            <input
              type="checkbox"
              name={col}
              checked={columns[col]}
              onChange={handleColumnChange}
            />
            {col}
          </label>
        ))}
      </div>
      <button
        style={styles.downloadButton}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
        onClick={downloadExcel}
      >
        Download Excel
      </button>
      <button
        style={styles.printButton}
        onClick={handlePrint}
      >
        Print Report
      </button>

      <h3 style={styles.heading}>Report Preview</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            {/* Add colspan for Personal */}
            <th colSpan="3" style={styles.tableHeader}>Personal</th>
            {Object.keys(columns).map((col) => {
              if (col !== 'name' && col !== 'age' && col !== 'email' && columns[col]) {
                return <th key={col} style={styles.tableHeader}>{col}</th>;
              }
              return null;
            })}
          </tr>
          <tr>
            {['name', 'age', 'email'].map(col => (
              columns[col] && <th key={col} style={styles.tableHeader}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index} style={index % 2 === 0 ? styles.tableRowEven : null}>
              {Object.keys(columns).map((col) => columns[col] && (
                <td key={col} style={styles.tableCell}>{row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTest;
