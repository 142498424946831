import Axios from "axios";
import * as Types from "./SoDashboardType";


export const InputSoDashboard = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.CHANGE_SO_DASHBOARD_INPUT, payload: formData });
  };


export const GetSoDashboardData = (startDate,toDate) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.SO_DASHBOARD_DATA, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SoDashboard/GetSecondarySoTargetInfoForDashboard?Fdate=${startDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
        console.log('url :>> ', url);
      responseList.data = res.data.data;
      responseList.status = false;
    });
    dispatch({ type: Types.SO_DASHBOARD_DATA, payload: responseList });
    
  };
