import Axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const DemageQuantity = (item, index, product, subindex, value) => async (dispatch) => {

  const formData = {
    item: item,
    index: index,
    category: product,
    subindex: subindex,
    value: value
  };

  dispatch({ type: Types.CHANGE_DAMAGE_QUANTITY, payload: formData });
};

export const getDamageProduct = (DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageDemandProductItem?unitId=98&DistribCat=${DistribCat}`;
  console.log('damage list - url', url);
  await Axios.get(url).then((res) => {
    console.log('damage list - res', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGED_LIST, payload: responseList });
};

export const damageSubmit = (submitData, productList, selectZone, selectDivision, selectArea, selectTerritory,selectDamageType,startDate,toDate) => async (dispatch) => {

  let productListData = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  console.log('productList action:>> ', productList);
  console.log('productList action:>> ', productList);

  for (let i = 0; i < productList.length; i++) {
    if (productList[i].productStatus == true) {
          let productData = {
            itemId: 0,
            itemUOM: 0,
            itemCOAID: 0,
            itemCategoryId: 0,
            itemQuentity: 0,
            itemPrice: 0,
            itemVatPrice: 0,
          };

          productData.itemId = productList[i].productId;
          productData.itemPrice = productList[i].itemPrice;
          productData.itemCategoryId = selectDamageType;
          productData.itemQuentity = productList[i].itemQuentity;
          productListData.push(productData);
        }
    }

    console.log('productListData action:>> ', productListData);

  if (productListData.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageDemandProductEntry?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}&fromDate=${startDate}&ToDate=${toDate}`;
    console.log('postUrl :>> ', postUrl);
    await Axios
      .post(postUrl, productListData)
      .then(function (response) {

        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
          
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });

        }
      })
      .catch(function (error) {

        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
      });
  } catch (error) {

    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
  }
};

export const SattlementdamageSubmit = (submitData, productList, selectZone, selectDivision, selectArea, selectTerritory,selectDamageType,startDate,toDate) => async (dispatch) => {

  let productListData = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  console.log('productList action:>> ', productList);
  console.log('productList action:>> ', productList);

  for (let i = 0; i < productList.length; i++) {
    if (productList[i].productStatus == true) {
          let productData = {
            itemId: 0,
            itemUOM: 0,
            itemCOAID: 0,
            itemCategoryId: 0,
            itemQuentity: 0,
            itemPrice: 0,
            itemVatPrice: 0,
          };

          productData.itemId = productList[i].productId;
          productData.itemPrice = productList[i].itemPrice;
          productData.itemCategoryId = selectDamageType;
          productData.itemQuentity = productList[i].itemQuentity;
          productListData.push(productData);
        }
    }

    console.log('productListData action:>> ', productListData);

  if (productListData.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageSattlementEntry?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}&fromDate=${startDate}&ToDate=${toDate}`;
    console.log('postUrl :>> ', postUrl);
    await Axios
      .post(postUrl, productListData)
      .then(function (response) {

        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
          
        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });

        }
      })
      .catch(function (error) {

        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
      });
  } catch (error) {

    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.DAMAGE_SUBMIT, payload: responseList });
  }
};


export const clearFieldData = (item, index, product, subindex, value) => async (dispatch) => {

  const formData = {
    item: item,
    index: index,
    category: product,
    subindex: subindex,
    value: value
  };

  dispatch({ type: Types.CLEAR_FIELD_DATA, payload: formData });
};
// export const getDamageListForDamage = (ZoneID,Territory, salesTypeId) => async (dispatch) => {
//   let responseList = {
//     isLoading: true,
//     data: {},
//     status: true,
//   };
//   let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/Get_PendingSalesReportForDamage?ZoneID=${ZoneID}&Territory=${Territory}&DistribCat=${salesTypeId}`;

//   await Axios.get(url).then((res) => {
//     console.log('damage url :>> ', url);
//     console.log('damage res :>> ', res);

//     responseList.data = res.data.data;
//     responseList.status = false;
//   });
//   dispatch({ type: Types.GET_DAMAGE_LIST, payload: responseList });
// };

export const getDamageListForDamage = (ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/Get_PendingSalesReportForDamage?`;

  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (FromDate !== null) {

    url += `&FromDate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&ToDate=${ToDate}`
  }
  if (salesStatus !== null) {

    url += `&salesStatus=${salesStatus}`
  }
  if (DistribCat !== null) {

    url += `&DistribCat=${DistribCat}`
  }
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_LIST, payload: responseList });
};


export const GetSattlementDamageList = (ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetPendingSattlementList?`;

  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (FromDate !== null) {

    url += `&FromDate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&ToDate=${ToDate}`
  }
  if (salesStatus !== null) {

    url += `&salesStatus=${salesStatus}`
  }
  if (DistribCat !== null) {

    url += `&DistribCat=${DistribCat}`
  }
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_LIST, payload: responseList });
};




export const getUpdateDamageProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageProductListForUpdate?Inv=${orderId}`;
  await Axios.get(url).then((res) => {
    console.log('update url :>> ', url);
    console.log('update res :>> ', res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_DAMAGE_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const getUpdateSattlementDamageProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetSattlementDamageProductListForUpdate?Inv=${orderId}`;
  await Axios.get(url).then((res) => {
    console.log('update url :>> ', url);
    console.log('update res :>> ', res);

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_DAMAGE_UPDATE_DETAILS,
    payload: responseList,
  });
};




export const DemageUpdateQuantity = (item, index, product, subindex, value) => async (dispatch) => {

  const formData = {
    item: item,
    index: index,
    category: product,
    subindex: subindex,
    value: value
  };

  dispatch({ type: Types.CHANGE_DAMAGE_UPDATE_QUANTITY, payload: formData });
};
export const damageUpdate = (invoiceId, productList) => async (dispatch) => {

  let productListData = [];
  let quantityStatus = true;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  console.log('now productList', productList);

  for (let i = 0; i < productList.length; i++) {
    //if(productList[i].productStatus ==true){
    for (let j = 0; j < productList[j].details.length; j++) {
      if (productList[i].details[j].value > 0) {
        let productData = {
          itemId: 0,
          itemUOM: 0,
          itemCOAID: 0,
          itemCategoryId: 0,
          itemQuentity: 0,
          itemPrice: 0,
          itemVatPrice: 0,
        };
        productData.itemId = productList[i].productId;
        productData.itemPrice = productList[i].itemPrice;
        productData.itemCategoryId = productList[i].details[j].categoryId;
        productData.itemQuentity = productList[i].details[j].value;
        productListData.push(productData);
      }
    }
    //}
  }
  if (productListData.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  console.log('productListData :>> ', productListData);
  // return false;
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageOrderUpdate?SalesOrderInvID=${invoiceId}`;
    Axios
      .put(postUrl, productListData)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {
  }
  dispatch({ type: Types.DAMAGE_UPDATE, payload: responseList });
};


export const SattlementDamageUpdate = (invoiceId, productList) => async (dispatch) => {

  let productListData = [];
  let quantityStatus = true;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  console.log('now productList', productList);

  for (let i = 0; i < productList.length; i++) {
    //if(productList[i].productStatus ==true){
    for (let j = 0; j < productList[j].details.length; j++) {
      if (productList[i].details[j].value > 0) {
        let productData = {
          itemId: 0,
          itemUOM: 0,
          itemCOAID: 0,
          itemCategoryId: 0,
          itemQuentity: 0,
          itemPrice: 0,
          itemVatPrice: 0,
        };
        productData.itemId = productList[i].productId;
        productData.itemPrice = productList[i].itemPrice;
        productData.itemCategoryId = productList[i].details[j].categoryId;
        productData.itemQuentity = productList[i].details[j].value;
        productListData.push(productData);
      }
    }
    //}
  }
  if (productListData.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  console.log('productListData :>> ', productListData);
  // return false;
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementDamageOrderUpdate?SalesOrderInvID=${invoiceId}`;
    Axios
      .put(postUrl, productListData)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {
  }
  dispatch({ type: Types.DAMAGE_UPDATE, payload: responseList });
};

export const handleDamageApproved = (item,ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageSalesOrderApproved?SubSalesOrderInvID=${item.orderId}`;
    Axios
      .put(postUrl)
      .then(function (response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          // dispatch({ type: Types.APPROVED_ITEM, payload: false });
          dispatch(getDamageListForDamage(ZoneID,DivisionID,AreaID,Territory,FromDate,ToDate,salesStatus,DistribCat))

        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) { }
};

export const handleDamageApprovedAll = (AllData,ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/AllDamageApproved`;
    Axios
      .put(postUrl, AllData)
      .then(function (response) {
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          dispatch(getDamageListForDamage(ZoneID,DivisionID,AreaID,Territory,FromDate,ToDate,salesStatus,DistribCat))

        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) { }
};

export const damageDelete = (item,ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_DAMAGE_ITEM, payload: isLoading })
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageOrderDelete?OrderID=${item.orderId}`
  Axios.delete(url)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('error', data.message);
        dispatch({ type: Types.DELETE_DAMAGE_ITEM, payload: false });
        dispatch(getDamageListForDamage(ZoneID,DivisionID,AreaID,Territory,FromDate,ToDate,salesStatus,DistribCat));
      }
    })
}

export const SattlementDamageDelete = (item,ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_DAMAGE_ITEM, payload: isLoading })
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementDamageOrderDelete?OrderID=${item.orderId}`
  Axios.delete(url)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_DAMAGE_ITEM, payload: false });
        dispatch(GetSattlementDamageList(ZoneID,DivisionID,AreaID,Territory,FromDate,ToDate,salesStatus,DistribCat));
      }
    })
}

export const clearDamageList = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_LIST, payload: data });
};
export const getDamageProductListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageOrderDetailsReport?SalesOrderInvID=${orderId}`;
  await Axios.get(url).then((res) => {
    console.log('details action url :>> ', url);
    console.log('details action  res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_DAMAGE_PRODUCT_LIST_DETAILS,
    payload: responseList,
  });
};

export const GetSattlementDamageProductListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageSattlementOrderDetailsReport?SalesOrderInvID=${orderId}`;
  await Axios.get(url).then((res) => {
    console.log('details action url :>> ', url);
    console.log('details action  res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_DAMAGE_PRODUCT_LIST_DETAILS,
    payload: responseList,
  });
};



export const productSubmitStausRemoveForDamage = () => (dispatch) => {
  dispatch({ type: Types.PRODUCT_SUBMIT_STATUS_REMOVE_FOR_DAMAGE, payload: null });
};

export const getDistributorWiseDamageReport = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DistributorWiseDamageReport?`;

  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
 

  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_WISE_DAMAGE_REPORT, payload: responseList });
};
export const DamageTotalSummeryReport = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/TotalDamangeReportSummery?`;

  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
 

  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY, payload: responseList });
};
export const SattlementDamageSummeryReport = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementDamangeReportSummery?`;

  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
 

  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY, payload: responseList });
};
export const getDamageApprovedListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/DamageOrderApprovedDetailsReport?SalesOrderInvID=${orderId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_DAMAGE_APPROVED_LIST_DETAILS,
    payload: responseList,
  });
};

export const productDemageQuantityNew = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,

  };

  dispatch({ type: Types.CHANGE_DAMAGE_QUANTITY_NEW, payload: formData });
};
export const GetDamageType = (intCustomer,startDate,toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetDamageCategory?distribId=${intCustomer}&fromDate=${startDate}&ToDate=${toDate}`;
    await Axios.get(geturl)
      .then(function(response) {
        console.log('type response :>> ', response);
        responseList.data = response.data.data;
      })
      .catch(function(error) {});
    dispatch({ type: Types.GET_DAMAGE_TYPE, payload: responseList });
  } catch (error) {}
  return responseList;
};
export const clearFieldForDamage = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD_FOR_DAMAGE, payload: data });
};
export const GetSummerySheetForDamageCalculation = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetSummerySheetForDamageCalculation?`;

  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }

  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUMMERY_SHEET_REPORT, payload: responseList });
};
export const DamageSummeryReportCatWiseForY = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/TotalDamangeReportSummeryBySkuWise?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Category !== null) {
    url += `&categoryID=${Category}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
};


export const SattlementDamageSummeryReportCatWiseForY = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementTotalDamangeReportSummeryBySkuWiseY?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Category !== null) {
    url += `&categoryID=${Category}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
};

export const DamageSummeryReportCatWiseForN = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/TotalDamangeReportSummeryBySkuWiseForN?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Category !== null) {
    url += `&categoryID=${Category}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
};

export const SattlementDamageSummeryReportCatWiseForN = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementTotalDamangeReportSummeryBySkuWiseForN?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Category !== null) {
    url += `&categoryID=${Category}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE, payload: responseList });
};

export const DamageSummeryReportSKUWise = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_DAMAGE_REPORT_SUMMERY_SKU_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetSummeryDamageReportSkuWise?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_REPORT_SUMMERY_SKU_WISE, payload: responseList });
};


export const GetApprovedSummeryDamageReportSkuWise = (FromDate, ToDate,ZoneID, DivisionID, AreaID, Territory, Category) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  dispatch({ type: Types.GET_DAMAGE_APPROVED_REPORT_SUMMERY_SKU_WISE, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetApprovedSummeryDamageReportSkuWise?`;
console.log('url :>> ', url);
  if (FromDate !== null) {

    url += `&Fdate=${FromDate}`
  }
  if (ToDate !== null) {

    url += `&Tdate=${ToDate}`
  }
  if (ZoneID !== null) {

    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {

    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
 
  await Axios.get(url).then((res) => {
    console.log('damaged url :>> ', url);
    console.log('damaged res', res)

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DAMAGE_APPROVED_REPORT_SUMMERY_SKU_WISE, payload: responseList });
};


export const GetApprovedSummeryDamageReportSkuWiseExcel = async(Fdate,Tdate,ZoneID=0,DivisionID=0,AreaID=0,Territory=0) => {

  let responseList = {
    isProductLoading: true,
    data: {},
    status: true,
  };
   let  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/GetApprovedSummeryDamageReportSkuWise?Fdate=${Fdate}&Tdate=${Tdate}&ZoneID=${ZoneID}&DivisionID=${DivisionID}&AreaID=${AreaID}&Territory=${Territory}`;
  
   
  await Axios.get(url).then((res) => {
    console.log('damageres', res)
    
    responseList.data = res.data.data == null?[]:res.data.data;
    responseList.status = false;
    responseList.isProductLoading = false;
  });
  return responseList;
};
export const handleSattlementDamageApproved = (item,ZoneID, DivisionID, AreaID, Territory, FromDate, ToDate, salesStatus, DistribCat) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/Damage/SattlementDamageSingleApproved?SubSalesOrderInvID=${item.orderId}`;
    Axios
      .put(postUrl)
      .then(function (response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.message);
          // dispatch({ type: Types.APPROVED_ITEM, payload: false });
          dispatch(GetSattlementDamageList(ZoneID,DivisionID,AreaID,Territory,FromDate,ToDate,salesStatus,DistribCat))

        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) { }
};