import React from "react";
import SkuWiseTarget from "../../../information/components/Reports/SkuWiseTarget";



const SkuWiseTargetReportContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SkuWiseTarget/>
      </div>
    </div>
  );
};

export default SkuWiseTargetReportContainer;
