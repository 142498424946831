import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { numberFormatDecemal } from '../../../domains/CCO/utils/DateHelper';

const ImsFocusProduct = () => {
    const imsFocusSkuList = useSelector((state) => state.secondarySales.imsFocusSkuList);
    const isTopArray = Array.isArray(imsFocusSkuList);

    const [fontSize, setFontSize] = useState('12px');

    useEffect(() => {
        const handleResize = () => {
            setFontSize(window.innerWidth < 768 ? '10px' : '12px');
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderSkuCards = (skuList) => {
        return skuList.map((sku, index) => (
            <div
                key={sku.productId}
                style={{
                    backgroundColor: '#E6E6FA',
                    padding: '10px',
                    margin: '5px',
                    borderRadius: '10px',
                    flex: window.innerWidth < 768 ? '0 0 calc(100% - 10px)' : '0 0 calc(50% - 10px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: '120px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    position: 'relative',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.03)';
                    e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
                }}
            >
                <div style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#9393A0',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}>
                    {index + 1}
                </div>
                <div>
                    <strong>Product Name:</strong> {sku.productName} <br />
                    <strong>IMS Target:</strong> <span style={{ color: '#FF7F50' }}>{numberFormatDecemal(parseFloat(sku.imsTarget).toFixed(2))}</span><br />
                    <strong>Primary Value:</strong> <span style={{ color: '#FF7F50' }}>{numberFormatDecemal(parseFloat(sku.primaryValue).toFixed(2))}</span><br />
                    <strong>Secondary Value:</strong> <span style={{ color: '#FF7F50' }}>{numberFormatDecemal(parseFloat(sku.totalIms).toFixed(2))}</span><br />
                    <strong>Primary Achievement: </strong>
                    <span style={{ color: '#FF7F50' }}>{(parseFloat(sku.primaryAcv).toFixed(2))} %</span>
                    <div style={{
                        position: 'relative',
                        height: '20px',
                        backgroundColor: '#ccc',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        margin: '5px 0',
                    }}>
                        <div style={{
                            height: '100%',
                            width: `${sku.primaryAcv}%`,
                            backgroundColor: '#7d4b10',
                            transition: 'width 0.5s ease-in-out',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize,
                        }}>
                            {sku.primaryAcv} %
                        </div>
                    </div>

                    <strong>Secondary Achievement: </strong>
                    <span style={{ color: '#FF7F50' }}>{(parseFloat(sku.imsAcv).toFixed(2))} %</span>
                    <div style={{
                        position: 'relative',
                        height: '20px',
                        backgroundColor: '#ccc',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        margin: '5px 0',
                    }}>
                        <div style={{
                            height: '100%',
                            width: `${sku.imsAcv}%`,
                            backgroundColor: '#0b8794',
                            transition: 'width 0.5s ease-in-out',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize,
                        }}>
                            {sku.imsAcv} %
                        </div>
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <div className="row paddingremove" style={{
            backgroundColor: '#ffffff',
            display: 'flex',
            flexWrap: 'wrap',
        }}>
            <div className='col-lg-12 paddingremove' style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='text-center' style={{
                    backgroundColor: '#B7B7C3',
                    padding: '10px',
                    margin: '5px',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    width: '100%',
                }}>
                    IMS FOCUS SKU
                </div>
                {!isTopArray || imsFocusSkuList.length === 0 ? (
                    <p className='text-center p-1' style={{ color: '#666', width: '100%', fontSize }}>No data available.</p>
                ) : (
                    renderSkuCards(imsFocusSkuList)
                )}
            </div>
        </div>
    );
}

export default ImsFocusProduct;
