import React, { Component } from 'react';

const SimpleSgeoType = (props) => {

    const {distribType,sGeoType}=props;
    return (
        <>
            {
                <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="2" checked={sGeoType==2  ?true:false}  onChange={(e)=>props.getSalesValue('bread',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio2">NEW</label>
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off"   onChange={(e)=>props.getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">OLD</label>
                </div>
              </div>
              } 
        </>  
    )
}
export default SimpleSgeoType;