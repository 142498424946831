import React from "react";
import DistributorWiseProductSkuDetails from "../../../information/components/SecodarySales/DistributorWiseProductSkuDetails";



const DistributorWiseProductSkuDetailsContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <DistributorWiseProductSkuDetails />
      </div>
    </div>
  );
};

export default DistributorWiseProductSkuDetailsContainer;
