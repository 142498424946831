import React from "react";
import GiftAdd from "../../../information/components/RemoteSales/GiftAdd";



const GiftAddContainer = () => {
  return (
    <div className="container-fluid">
      
        <GiftAdd />
     
    </div>
  );
};

export default GiftAddContainer;
