import React from "react";
import SecondarySaleStock from "../../../information/components/SecodarySales/SecondarySaleStock";

const SecondarySalesStockContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySaleStock/>
      </div>
    </div>
  );
};
export default SecondarySalesStockContainer;
