import React, { useEffect, useState, useRef } from "react";
import Enumerable from 'linq';


const SampleTableList = () => {
  const[header,setHeader]=useState([]);
  const [productArray, setProductArray] = useState([
    {
    'areaid': 15,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 17,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 18,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 14,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 12,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 15,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Biscuit-Cream-Funtastic Ore cream Chocolate-Mini-24gm-Carton(48)",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
    {
    'areaid': 12,
    'areaname': "Bogura",
    'differentPrice': 132972.768,
    'differentQty': 697.8,
    'divisionid': 9,
    'divisionname': "Bogura",
    'liftingPrice': 2858.4,
    'liftingQty': 15,
    'prodCatID': 182836,
    'prodCatagories': "Biscuit",
    'prodCode': "214746",
    'prodID': 214746,
    'prodName': "Farid uddin",
    'prodPrice': 190.56,
    'tarVsAccPers': 2.1043771043771042,
    'targetPrice': 135831.168,
    'targetQty': 712.8,
    'zoneid': 1,
  },
]);

  useEffect(() => {
    intialData();
  }, [])

  const intialData=()=>{
    var zoneData = Enumerable.from(productArray)
     .groupBy(
          g => g.prodName,
          element => element,
          (key, items) => ({key, items: items.toArray()}
     )).toArray();
     setHeader(zoneData);

  }

  const tableRender=()=>{

    return(
      <div>
        <p>Hello</p>
      </div>
    )
     
    
    }
  
  return (
    <>
     <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
       <table className="table table-head-custom table-vertical-center  item-add-table">
         {tableRender()}
       </table>
        
     </div>
       
    </>
  );
};

export default SampleTableList;
