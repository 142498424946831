import React from 'react';

const FullScreenButton = () => {
    const handleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
                console.error("Error attempting to enable full-screen mode:", err);
            });
        } else {
            document.exitFullscreen().catch(err => {
                console.error("Error attempting to exit full-screen mode:", err);
            });
        }
    };

    const iconStyle = {
        color: '#B0E0E6',
        fontSize: '22px',
        cursor: 'pointer',
        transition: 'color 0.3s ease',
    };
    const hoverStyle = {
        color: 'gray',
    };

    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <i
            className="fas fa-expand mt-2 mr-3"
            onClick={handleFullScreen}
            style={{
                ...iconStyle,
                color: isHovered ? hoverStyle.color : iconStyle.color,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );
};

export default FullScreenButton;
