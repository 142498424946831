import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleModal from '../../../../master/components/Modal/SimpleModal';
import { GetWorkingDayList } from './DashboardCartAction';
import ModalCalender from './ModalCalender';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [modalShow, setModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const dispatch = useDispatch();
  const workingDayList = useSelector((state) => state.dashboardcart.workingDayList);
  const workingDayListCount = useSelector((state) => state.dashboardcart.workingDayListCount);

  useEffect(() => {
    const d = new Date();
    const currentD = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
    dispatch(GetWorkingDayList(currentD));
  }, [dispatch]);

  const highlightedDates = workingDayList && Array.isArray(workingDayList)
    ? workingDayList.map(item => item?.dateRa.split('T')[0])
    : [];

  const styles = {
    calendarCard: {
      backgroundColor: 'beige',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '320px',
      overflow: 'hidden',
      marginTop: '20px',
    },
    calendarHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      borderBottom: '1px solid #ddd',
      backgroundColor: 'rgb(249, 249, 249)',
    },
    calendarHeaderTitle: {
      margin: '0',
      fontSize: '18px',
      flex: 1,
      textAlign: 'left',
    },
    calendarHeaderButtonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    calendarHeaderButton: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      fontSize: '18px',
      marginLeft: '5px',
      transition: 'background-color 0.3s',
    },
    calendarBody: {
      padding: '10px',
    },
    calendarDays: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '5px',
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: 'beige',
    },
    day: {
      padding: '5px 0',
    },
    calendarDates: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '5px',
    },
    date: {
      textAlign: 'center',
      padding: '10px',
      cursor: 'pointer',
      borderRadius: '4px',
      transition: 'background-color 0.3s',
    },
    dateHighlight: {
      backgroundColor: '#A3E2BF',
      color: 'black',
    },
    dateNotWorking: {
      backgroundColor: 'red',
      color: 'white',
    },
    dateYesterday: {
      backgroundColor: 'blue',
      color: 'white',
    },
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const firstDayOfWeek = firstDay.getDay();
    const days = [];

    const emptyCells = (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1);

    for (let i = 0; i < emptyCells; i++) {
      days.push(<div key={`empty-${i}`} style={styles.day}></div>);
    }

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;

    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const isToday = day === new Date().getDate() && month === new Date().getMonth() && year === new Date().getFullYear();
      const isYesterday = dateString === yesterdayString;
      const isHighlighted = highlightedDates.includes(dateString);
      const isNotWorkingDay = !isHighlighted && !isToday;

      let dateStyle = styles.date;

      if (isToday) {
        dateStyle = isHighlighted ? { ...dateStyle, ...styles.dateHighlight } : dateStyle;
      } else if (isYesterday) {
        dateStyle = { ...dateStyle, ...styles.dateYesterday };
      } else if (isNotWorkingDay) {
        dateStyle = { ...dateStyle, ...styles.dateNotWorking };
      } else if (isHighlighted) {
        dateStyle = { ...dateStyle, ...styles.dateHighlight };
      }

      days.push(
        <div
          key={day}
          style={dateStyle}
          onClick={() => handleDateClick(day)}
          className={`calendar-date ${isToday ? 'today' : ''}`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const changeMonth = (offset) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + offset, 1);
      const formattedDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`;
      dispatch(GetWorkingDayList(formattedDate));
      return newDate;
    });
  };

  const handleDateClick = (day) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDate(date);
    setModalShow(true);
  };

  return (
    <div>
      <div className='text-center' style={{ textAlign: 'center', margin: '16px' }}>
        <span style={{ fontSize: '17px', color: '#C0C0C0', fontWeight: 600 }}>
          Total Working Day : {workingDayListCount}
        </span>
        <br />
      </div>

      <div style={styles.calendarCard}>
        <div style={styles.calendarHeader}>
          <h2 style={styles.calendarHeaderTitle}>
            {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
          </h2>
          <div style={styles.calendarHeaderButtonContainer}>
            <button onClick={() => changeMonth(-1)} style={styles.calendarHeaderButton}>&lt;</button>
            <button onClick={() => changeMonth(1)} style={styles.calendarHeaderButton}>&gt;</button>
          </div>
        </div>
        <div style={styles.calendarBody}>
          <div style={styles.calendarDays} className="mb-3">
            <div style={styles.day}>Mon</div>
            <div style={styles.day}>Tue</div>
            <div style={styles.day}>Wed</div>
            <div style={styles.day}>Thu</div>
            <div style={styles.day}>Fri</div>
            <div style={styles.day}>Sat</div>
            <div style={styles.day}>Sun</div>
          </div>
          <div style={styles.calendarDates}>
            {renderCalendar()}
          </div>
        </div>
      </div>

      <SimpleModal
        size="md"
        show={modalShow}
        handleClose={() => setModalShow(false)}
      >
        <ModalCalender handleClose={() => setModalShow(false)} selectedDate={selectedDate} />
      </SimpleModal>
    </div>
  );
};

export default Calendar;
