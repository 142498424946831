import React from "react";
import PrimaryLiftingAllZone from "../../../information/components/SecodarySales/PrimaryLiftingAllZone";

const PrimaryLiftingAllZoneContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <PrimaryLiftingAllZone />
      </div>
    </div>
  );
};
export default PrimaryLiftingAllZoneContainer;
