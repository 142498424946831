import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import ReportFooter from "../../Master/ReportFooter";
import { getSkuWiseTargetAction } from "../../_redux/actions/Report/ReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SkuWiseTarget = () => {

  const targetDateList = useSelector((state) => state.target.targetDate);
  const totalTarget = useSelector((state) => state.target.totalData);
  const isLoading = useSelector((state) => state.target.targetLoading);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  const componentRef = useRef();
  const { setValue  } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
 
    initialData();
  }, []);

  const initialData = async () => {
    
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [wordDate, setWordDate] = useState([]);


  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };

  const showValidation = () => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;

    if (conFromDate == null || conFromDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (conToDate == null || conToDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const handleListAction = () => {
    const validation = showValidation();
    if (validation) {
      let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
      let conToDate = skuDate.todate + 'T' + skuDate.toTime;
      dispatch(getSkuWiseTargetAction(conFromDate, conToDate, selectZone, selectDivision, selectArea));
    }
  }



  const style = {
    container: {
      'background': 'f7f7f7',
      // 'height':670,
      'overflowy': 'scroll'
    },
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">SKU WISE TARGET VS ACHIVEMENT(DELIVERY WISE) </h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='SKU WISE TARGET VS ACHIVEMENT(DELIVERY WISE)' />
            {/* <Form.Check
              type="checkbox"
              label="Time wise Data"
              onChange={(e) => handleCheckbox(e)}
            /> */}
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                // value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Deadweight"
                name="fromTime"
                value={`${skuDate.fromTime}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Todate"
                name="toTime"
                className="fromStyle"
                value={`${skuDate.toTime}`}
                onChange={(e) => handleChange(e, 'toTime')}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mt-0 ml-4">
            <label className="form-label">Select Zone</label>
            <Select
              options={zoneDropdownData()}
              value={defultZone}
              onChange={(option) => {
                divisionList(option?.value);
                setSelectZone(option?.value);
                setDefaultZone(option)
                setValue("divisionData", "");
                setValue("areaData", "");
                setValue("territory", "");
              }}
              defaultValue={defultZone}
            />
          </div>
          <div className="col-lg-2 mt-0">
            <label className="form-label">Select Division</label>
            <Select
              options={divisionDropdownData()}
              value={defultDivision}
              onChange={(option) => {
                getAreaData(option?.value);
                setSelectDivision(option?.value);
                setdefultDivision(option)
                setValue("areaData", "");
                setValue("territory", "");
              }}
              defaultValue={defultDivision}
            />
          </div>
          <div className="col-lg-2 mt-0">

            <label className="form-label">Select Region</label>
            <Select
              options={areaDropdownData()}
              value={defaultArea}
              onChange={(option) => {
                getTerritoryData(option?.value);
                setSelectArea(option?.value);
                setdefaultArea(option)
                setValue("territory", "");
              }}
              defaultValue={defaultArea}
            />
          </div>
          <div className="col-lg-2 mt-0 ml-4">
            <label className="form-label">Select Territory</label>
            <Select
              options={territoryDropdownData()}
              value={defultTerritory}
              onChange={(option) => {
                //getTerritoryData(option?.value);
                setSelectTerritory(option?.value);
                setdefultTerritory(option)
              }}
              defaultValue={defultTerritory}
            />
          </div>

          <div className="col-lg-3 ml-3">
            <button type="button" className="btn btn-success btn-sm mt-5" onClick={() => handleListAction()}>
              Show Report
            </button>

          </div>
        </div>
        {targetDateList.length > 0 &&
          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Cake</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.cookisTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.cookisLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Cookies & Biscuit</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisandBiscuitTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisandBiscuitLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.cookisandBiscuitTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.cookisandBiscuitLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Total</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.totalTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.totalLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.totalTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.totalLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="row" id="id" ref={inputField}>
          {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>
            <div
              className="stickyTable"
              style={{ height: targetDateList?.length > 0 ? 800 + "px" : 0 }}
            >
              {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} > */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVSION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">SKU CODE</th>
                    <th scope="col">CATEGORIES</th>
                    <th scope="col">SKU NAME</th>
                    <th scope="col">INVOICE VALUE</th>
                    <th scope="col">TARGET {wordDate} (ctn)</th>
                    <th scope="col">LIFTING(CTN) UPTO {wordDate}</th>
                    <th scope="col">DIFFERENCE(IN CTN)</th>
                    <th scope="col">TARGET {wordDate} IN TK</th>
                    <th scope="col">LIFTING {wordDate}</th>
                    <th scope="col">DIFFERENCE(IN TK)</th>
                    <th scope="col">TARGET VS ACH(IN Tk %)</th>
                  </tr>
                </thead>
                <tbody>
                  {targetDateList.length > 0 &&
                    targetDateList.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.zonename}</td>
                          <td>{item.divisionname}</td>
                          <td>{item.areaname}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.prodCode}</td>
                          <td>{`${item.prodCatagories}`}</td>
                          <td nowrap="nowrap">{item.prodName}</td>
                          <td>{parseFloat(item.prodPrice).toFixed(2)}</td>
                          <td>{parseFloat(item.targetQty).toFixed(2)}</td>
                          <td>{parseFloat(item.liftingQty).toFixed(2)}</td>
                          <td>{parseFloat(item.differentQty).toFixed(2)}</td>
                          <td>{parseFloat(item.targetPrice).toFixed(2)}</td>
                          <td>{parseFloat(item.liftingPrice).toFixed(2)}</td>
                          <td>{parseFloat(item.differentPrice).toFixed(2)}</td>
                          <td>{parseFloat(item.tarVsAccPers).toFixed(2)}%</td>
                        </tr>

                      </>

                    ))}
                  {
                    targetDateList.length > 0 &&
                    <ReportFooter />
                  }
                </tbody>
              </table>
            </div>

            {!isLoading && targetDateList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default SkuWiseTarget;
