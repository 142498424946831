import React from "react";
import TargetVsAchDispointWise from "../../../information/components/Reports/TargetVsAchDispointWise";



const TargetVsAchDispointWiseContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TargetVsAchDispointWise/>
      </div>
    </div>
  );
};
export default TargetVsAchDispointWiseContainer;