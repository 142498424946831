import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  cleanRemoteSalesData,
  EditDateGet,
  getSalesList,
  salesDelete,
  showApprovedReport,
} from "../../../_redux/actions/RemoteSales/RemoteSalesAction";
import {
  InputHandle,
} from "../../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getAreaListByDivisionEntry,
  getDivisionListByZone,
  getDivisionListByZoneEntry,
  getTerritory,
  getTerritoryEntry,
  getZoneList,
} from "../../../_redux/actions/RemoteSales/DistributorReportAction";
import { currentdate } from "../../../../../../domains/CCO/utils/DateHelper";
import SecondarySalesApproved from "./SecondarySalesApproved";
import SecondarySalesApprovedFilter from "./SecondarySalesApprovedFilter";
import { handleSubmitSoWiseApproved, showSecondaryApprovedReport } from "../../../_redux/actions/SecondarySales/SecondarySalesAction";
import SecondarySalesApprovedDetails from "./SecondarySalesApprovedDetails";
import { GetPermissionDropDown } from "../../Dashboard/DashboardCartAction";



const SecondarySalesApprovedList = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.itemList.approvedLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const salesApprovedList = useSelector((state) => state.secondarySales.secondaryApprovedList);
  console.log('salesApprovedList :>> ', salesApprovedList);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemDetailApproved, setItemDetailApproved] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [approvedData, setapproveData] = useState(null);
  const [selectItem, setSelectItem] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const ItemDetails = useSelector(
    (state) => state.secondarySales.secondaryApprovedDetails
  );

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cleanRemoteSalesData());
  }, [])

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {

    setItemifo(item);
    setItemID(item.distID);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const showReport = () => {
    let diInfo = {
      zoneId: selectZone,
      divisionId: selectDivision,
      areaId: selectArea,
      fromDate: startDate
    }
    setapproveData(diInfo);
    dispatch(showSecondaryApprovedReport(startDate, selectZone, selectDivision, selectArea, selectTerritory));
  }


  const itemApprovedDetail = (item) => {
    setItemID(item.distID);
    setSelectItem(item)
    setItemDetailApproved(true);
  }


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const approvedReport = (item) => {
    dispatch(handleSubmitSoWiseApproved(startDate, item, selectArea, selectTerritory,selectZone, selectDivision));
  }

  const ItemSingleApproved = (item) => {
    confirmAlert({
      title: "Confirm To Approved",
      message: `Are you sure to Confirm,Pleaes Confirm Your ALL SO Sales..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => approvedReport(item)
        },
        {
          label: "No",
        },
      ],
    });

    // let data =  dispatch(handleSubmitAction(item,approvedData,item.distID));
  }

  return (
    <>

      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        {/* <div className="container"> */}
        <SecondarySalesApprovedFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}

        /><hr></hr>

        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> From Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                className="fromStyle"
                onChange={handleChange}
                value={startDate}
              />
            </Form.Group>

          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label">Select Territory</label>
            <Select
              options={territoryDropdownData()}
              value={defultTerritory}
              isClearable
              onChange={(option) => {
                setSelectTerritory(option?.value);
                setdefultTerritory(option)
              }}
              defaultValue={defultTerritory}
            />
          </div>

          {/* <div className="col">
            <label className="form-label"> To Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Todate"
              name="todate"
              value={toDate}
              className="fromStyle"
              onChange={handleChangeTodate}
            />
          </div> */}
          <div className="col mt-6 mb-5">
            <button className="button-621" onClick={() => showReport()} >  Show Report</button>
            {/* <Button size="sm" onClick={()=>showReport()}>Show Report</Button> */}
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
              className="testTable"
              style={{ height: salesApprovedList?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table">
                {
                  salesApprovedList?.length > 0 &&

                  <thead>
                    <tr>
                      {/* <th scope="col">Department</th> */}
                      {/* <th scope="col">ORDER ID</th> */}

                      <th scope="col">SALES ID</th>

                      <th scope="col">SO ID</th>
                      <th scope="col">SO Name</th>

                      <th scope="col"> QUANTITY</th>
                      <th scope="col">ORDER Value</th>
                      <th scope="col"> TOTAL</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {salesApprovedList !== null &&
                    salesApprovedList?.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.salesId}</td>


                        <td>{item.soId}</td>
                        <td>{item.soName}</td>

                        <td>{item.quentity}</td>
                        <td>{item.orderQuentity}</td>
                        <td className="totalwidth">{parseFloat(item.productPrice).toFixed(2)}</td>
                        <td>
                          {" "}
                          {/* <Link onClick={() => itemDetail(item)} title="Details" style={{marginRight:5}}>
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        <Link onClick={() => itemApprovedDetail(item)} title="View">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link> */}
                          <Link onClick={() => ItemSingleApproved(item)} title="Approved">
                            <i className="fa fa-check editIcon item-list-icon"></i>
                          </Link>

                        </td>

                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && salesApprovedList == null && (
              <div className="alert alert-warning mt-5">
                Sorry ! Approved List Not Found in this Date.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SecondarySalesApproved
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={startDate}
          />
        </SimpleModal>

        <SimpleModal
          size="xl"
          show={itemDetailApproved}
          handleClose={() => setItemDetailApproved(false)}
          handleShow={() => setItemDetailApproved(true)}
          modalTitle={"Secondary Sales approved"}
        >
          <SecondarySalesApprovedDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={approvedData}
            selectitem={selectItem}

          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SecondarySalesApprovedList;
