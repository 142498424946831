import React from "react";
import StrikeRateOfSecondaryForSo from "./StrikeRateOfSecondaryForSo";

const StrikeRateOfSecondaryForSoContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <StrikeRateOfSecondaryForSo/>
      </div>
    </div>
  );
};

export default StrikeRateOfSecondaryForSoContainer;
