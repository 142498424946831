import React from "react";
import NewSecondarySalesEntry from "../../../information/components/SecodarySales/NewSecondarySalesEntry";
import SecondarySalesAdd from "../../../information/components/SecodarySales/SecondarySalesAdd";



const SecondarySalesAddContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        {/* < SecondarySalesAdd/> */}
        < NewSecondarySalesEntry/>
      </div>
    </div>
  );
};

export default SecondarySalesAddContainer;
