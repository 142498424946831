import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button,Col } from "react-bootstrap";
import {getZoneList} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, getSoReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { dateTimeConvertLongString } from "../../../../../domains/CCO/utils/DateHelper";

const SecondarySalesSoReport = () => {
  const demandlist = useSelector((state) => state.secondarySales.cumalative);
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const amount = useSelector((state) => state.secondarySales);

  const [zone, Setzone] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  const { } = useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const inputField = React.useRef(null);
  
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = (input) => {
    setToDate(input);
  };
 
  const handleDateFilter=(option)=>{
    handleChangeTodate(option);
  }
  const [selectGeoType,setSelectGeoType] = useState(2);
  
  const showReport =()=>{
    dispatch(getSoReport(startDate,toDate));

  }

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Secondary Sales So Report</h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              <DownloadDropdown />
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
            <SimpleSgeoType
             getSalesValue={getSalesValueType}
             sGeoType={selectGeoType}
             />
            </div>
          </div> */}
        </div>  
       
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)
                 

                }}

              />
            </Form.Group>
          </div>
          
            <div className="col mt-5">
            <Button size="sm" onClick={()=>showReport()}>Show Report</Button>
            </div>






        </div>
        <div>
          {demandlist.length > 0 &&
           
            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">
               
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <p className="balanceleft">Cake Quantity</p>
                      <p className="balanceleft">Cake Value</p>
                    </div>
                    <div className="widget__right">
                     
                        <div className="overlay-layer bg-transparent">
                          {/* <div className="spinner spinner-lg spinner-warning" /> */}
                        </div>
                        <div>
                          <h4 className="balanceleft"> {parseFloat(amount.cakeQty)}</h4>
                          <h4 className="balanceleft">৳ {parseFloat(amount.cakePrice).toFixed(2)}</h4>
                        </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left" >
                      <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" />
                      <p className="balanceleft">Biscuit Quantity</p>
                      <p className="balanceleft">Biscuit Value</p>
                      <p className="balanceleftmodified">Wafer Value</p>
                      <p className="balanceleftmodified">Wafer QTY</p>
                    
                    </div>
                    <div className="widget__right">
                    
                        <div className="overlay-layer bg-transparent">
                          <div className="spinner spinner-lg spinner-warning" />
                        </div>
                    
                        <div>
                          <h4 className="balanceleft">{parseFloat(amount.biscuteQty+amount.cookisQty)}</h4>
                          <h4 className="balanceleft">৳ {parseFloat(amount.biscutePrice+amount.cookisPrice).toFixed(2)}</h4>
                          <h4 className="balanceleftmodified">৳ {parseFloat(amount.wiferPrice).toFixed(2)}</h4>
                          <h4 className="balanceleftmodified"> {parseInt(amount.wiferQty)}</h4>
                         
                        </div>

                     
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <div className="achiveLayer">
                      <span>Biscuit Quantity:  {parseInt(amount.biscuteQty+amount.cookisQty)}</span>
                      <span>Biscuit Value:  ৳ {parseFloat(amount.biscutePrice+amount.cookisPrice).toFixed(2)}</span>
                      <span>Wafer Quantity:   {parseInt(amount.wiferQty)}</span>
                      <span>Wafer Value:  ৳ {parseFloat(amount.wiferPrice).toFixed(2)}</span>
                      <span>Snacks Quantity:   {parseInt(amount.snacksQty)}</span>
                      <span>Snacks Value:  ৳ {parseFloat(amount.snacksPrice).toFixed(2)}</span>
                    
                    </div>
                  </div>
                </div>
             </div>






                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="paid__widget three">
                    <div className="widget__left">
                     
                      <p className="balanceleft">Cookies Quantity</p>
                      <p className="balanceleft">Cookies Price</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">{parseFloat(amount.cookisQty)}</h4>
                      <h4 className="balanceleft">৳ {parseFloat(amount.cookisPrice).toFixed(2)}</h4>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <p className="balanceleft">Total Quantity</p>
                      <p className="balanceleft">Total Value</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">{parseInt(amount.totalQuantityCum)}</h4>
                      <h4 className="balanceleft">৳ {parseFloat(amount.totalPriceCum).toFixed(2)}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="stickyTable"
                style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
              >
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                 <th scope="col">ZONE NAME</th>
                 <th scope="col">DIVSION NAME</th>
                 {
                      selectGeoType==1?
                      <>
                       <th scope="col">AREA</th>
                      </>                     
                      :
                      <>
                      <th scope="col">REGION</th>
                      <th scope="col">TERRITORY</th>
                      </>
                    }
                  <th scope="col">JOINING DATE</th>
                  <th scope="col">SO ID</th>
                  <th scope="col">SO NAME</th>
                  <th scope="col">SO POINT ID</th>
                  <th scope="col">SO POINT</th>
                  <th scope="col">NUMBER OF MEMOlINE</th>
                  <th scope="col">TOTAL MEMO QTY</th>
                  <th scope="col">TOTAL VISITED OUTLET</th>
                  <th scope="col">TOTAL ORDER VALUE</th>
                  <th scope="col">BISCUIT QUNATITY</th>
                  <th scope="col">BISCUIT VALUE</th>
                  <th scope="col">CAKE QUNATITY</th>
                  <th scope="col">CAKE VALUE</th>
                  <th scope="col">COOKIES QUNATITY</th>
                  <th scope="col">COOKIES VALUE</th>
                  <th scope="col">WAFER QUNATITY</th>
                  <th scope="col">WAFER VALUE</th>
                  <th scope="col">SNACKS QUNATITY</th>
                  <th scope="col">SNACKS VALUE</th>
                  <th scope="col">TOTAL QUANTITY</th>
                  <th scope="col">TOTAL CTN(QTY)</th>
                  <th scope="col">TOTAL VALUE</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.zoneName}</td>
                      <td>{item.divisionName}</td>
                      {
                            selectGeoType==1?
                            <td>{item.areaName}</td>:
                            <>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                            </>
                          
                          }
                      <td>{dateTimeConvertLongString(item.joiningDate)}</td>
                      <td>{item.soId}</td>
                      <td>{`${item.soName}`}</td>
                      <td>{item.delpointId}</td>
                      <td>{item.delpointName}</td>
                      <td>{parseInt(item.memoLine)}</td>
                      <td>{parseInt(item.memoQty)}</td>
                      <td>{parseFloat(item.visitedOutlet)}</td>
                      <td>{parseFloat(item.orderValue)}</td>
                      <td>{item.biscuteQty}</td>
                      <td>{parseFloat(item.biscutePrice).toFixed(2)}</td>
                      <td>{item.cakeQty}</td>
                      <td>{parseFloat(item.cakePrice).toFixed(2)}</td>
                      <td>{item.cookisQty}</td>
                      <td>{parseFloat(item.cookisPrice).toFixed(2)}</td>
                      <td>{parseInt(item.wiferQty)}</td>
                      <td>{parseInt(item.wiferPrice)}</td>
                      <td>{parseInt(item.snacksQty)}</td>
                      <td>{parseInt(item.snacksPrice)}</td>
                      <td>{parseFloat(item.totalQuantity).toFixed(2)}</td>
                      <td>{parseFloat(item.totalQuantityCtn).toFixed(2)}</td>
                      <td>{parseFloat(item.totalPrice).toFixed(2)}</td>

                      {/* <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                      </td> */}
                    </tr>
                  ))}


              </tbody>
            </table>
            </div>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SecondarySalesSoReport;
