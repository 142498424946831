import React from "react";
import CustomerProductDeliveryList from "../../../information/components/RemoteSales/CustomerProductDeliveryList";




const DistributorProductDeliverListContainer = () => {
  return (
    <div className="container-fluid">
      <CustomerProductDeliveryList />
    </div>
  );
};

export default DistributorProductDeliverListContainer;
