import React from "react";
import ZoneList from "../../../information/components/Zone/ZoneList";

const ZoneListContainer = () => {
  return (
    <div className="container">
      <ZoneList />
    </div>
  );
};

export default ZoneListContainer;
