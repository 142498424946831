import React from "react";
import StrikeRateOfSecondary from "./StrikeRateOfSecondary";

const StrikeRateOfSecondaryContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <StrikeRateOfSecondary/>
      </div>
    </div>
  );
};

export default StrikeRateOfSecondaryContainer;
