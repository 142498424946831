import React from "react";
import SecondarySalesProductWiseReport from "../../../information/components/SecodarySales/SecondarySalesProductWiseReport";

const SecondarySalesReportsContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondarySalesProductWiseReport/>
      </div>
    </div>
  );
};

export default SecondarySalesReportsContainer;
