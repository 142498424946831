import React, { useEffect, useState, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputHandle } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DataTable from "react-data-table-component"
import { getDistributorListPermissionWise, sendSms } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import FilterComponent from "../FilterComponent";
import { showToast } from './../../../../../app/modules/master/utils/ToastHelper';
import { FastForward } from "@material-ui/icons";
import { FaSearch } from 'react-icons/fa';

const SmsSystem = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();

    const distibList = useSelector((state) => state.remoteSales.distibListforsms);
    const smsStatus = useSelector((state) => state.remoteSales.smsStatus);
    const isLoading = useSelector((state) => state.remoteSales.smsreportLoading);

    console.log('isLoading', isLoading)
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [smsText, setSmsText] = useState(null);
    const [selectSmsType, setSelectSmsType] = useState(null);
    console.log('mobileNumber', mobileNumber)

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }
    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const showReport = () => {
        setSelectedRows([]);

        dispatch(getDistributorListPermissionWise(selectZone, selectDivision, selectArea, selectTerritory, selectSmsType));
    }


    const smsSend = () => {

        if (showDiv1) {
            if (smsText == null) {
                showToast("error", "Please Type Your Message");
            } else if (selectedRows.length < 1) {
                showToast("error", "Please select At lease one Checkbox");
            } else {
                dispatch(sendSms(selectedRows, smsText, showDiv1, mobileNumber));
            }

        } else {
            dispatch(sendSms(selectedRows, smsText, showDiv1, mobileNumber));
        }
    }

    const sendType = [

        { value: "2", label: "NSM" },
        { value: "3", label: "DSM" },
        { value: "4", label: "RM" },
        { value: "5", label: "TSM" },
        { value: "222", label: "So" },
        { value: "1", label: "Distributor" },
    ];

    const handleCheckboxChange = (dmg) => {
        const updatedSelectedRows = selectedRows.some((selectedDmg) => selectedDmg.distribID === dmg.distribID)
            ? selectedRows.filter((selectedDmg) => selectedDmg.distribID !== dmg.distribID)
            : [...selectedRows, { distribID: dmg.distribID }];
        setSelectedRows(updatedSelectedRows);
    };

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        const allOrderIds = distibList.map((dmg) => ({ distribID: dmg.distribID }));
        setSelectedRows(selectAll ? [] : allOrderIds);
    };

    const [showDiv1, setShowDiv1] = useState(true);
    const [showDiv2, setShowDiv2] = useState(false);
    console.log('showDiv1', showDiv1)

    const handleClick = (div) => {
        if (div === 'div1') {
            setShowDiv1(true);
            setShowDiv2(false);
        } else {
            setShowDiv1(false);
            setShowDiv2(true);
        }
    };

    const checkboxStyle = {
        margin: '5px',
        cursor: 'pointer',
    };

    const labelStyle = {
        marginRight: '5px',
        fontWeight: 'bold',
        color: '#333',
    };

    return (
        <div>

            {
                <>

                    <div>
                        <label style={labelStyle}>
                            <input
                                type="checkbox"
                                checked={showDiv1}
                                onChange={() => handleClick('div1')}
                                style={checkboxStyle}
                            /> MULTIPLE
                        </label>
                        <label style={labelStyle}>
                            <input
                                type="checkbox"
                                checked={showDiv2}
                                onChange={() => handleClick('div2')}
                                style={checkboxStyle}
                            /> SINGLE
                        </label>


                    </div>


                    {
                        <>{

                            <div className="card p-3 dashboardCart ">
                                {
                                    showDiv1 && <>
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">SMS Type</label>
                                                    <RHFInput
                                                        className="formSelect pt-0 "
                                                        as={<Select options={sendType} />}
                                                        rules={{ required: false }}
                                                        name="smsType"
                                                        register={register}
                                                        placeholder="Select Sms type"
                                                        isClearable
                                                        onChange={(option) => {
                                                            setSelectSmsType(option?.value)
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-2">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">Select Zones</label>
                                                    <RHFInput
                                                        className="formSelect pt-0"
                                                        as={<Select options={zoneDropdownData()} />}
                                                        rules={{ required: false }}
                                                        name="zoneData"
                                                        register={register}
                                                        // value={selectZone}
                                                        value=""
                                                        isClearable
                                                        onChange={(option) => {
                                                            divisionList(option?.value);
                                                            setSelectZone(option?.value);
                                                            setValue("divisionData", "");
                                                            setValue("areaData", "");
                                                            setValue("territory", "");
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-2">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">Select Division</label>
                                                    <RHFInput
                                                        className="formSelect pt-0"
                                                        as={<Select options={divisionDropdownData()} />}
                                                        rules={{ required: false }}
                                                        name="divisionData"
                                                        register={register}
                                                        value=""
                                                        isClearable
                                                        // value={CertificateMasterInput.isActive}
                                                        onChange={(option) => {
                                                            // setType(option.value)
                                                            getAreaData(option?.value);
                                                            setSelectDivision(option?.value);
                                                            setValue("areaData", "");
                                                            setValue("territory", "");
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-2">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">Select Region</label>
                                                    <RHFInput
                                                        className="formSelect pt-0"
                                                        as={<Select options={areaDropdownData()} />}
                                                        rules={{ required: false }}
                                                        name="areaData"
                                                        register={register}
                                                        value=""
                                                        isClearable
                                                        // value={CertificateMasterInput.isActive}
                                                        onChange={(option) => {
                                                            // setType(option.value)
                                                            // dispatch(getDistributorList(option.value, salesType));
                                                            getTerritoryData(option?.value)
                                                            setSelectArea(option?.value);
                                                            setValue("territory", "");
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-2">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label">Select Territory</label>
                                                    <RHFInput
                                                        className="formSelect pt-0"
                                                        as={<Select options={territoryDropdownData()} />}
                                                        rules={{ required: false }}
                                                        name="territory"
                                                        register={register}
                                                        value=""
                                                        isClearable
                                                        // value={CertificateMasterInput.isActive}
                                                        onChange={(option) => {
                                                            setSelectTerritory(option?.value);
                                                        }}
                                                        setValue={setValue}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md mt-5 mb-5">
                                                <Button size="sm" onClick={() => showReport()} ><FaSearch /> Show</Button>
                                            </div>
                                        </div>
                                    </>
                                }

                                {showDiv2 && (
                                     <div className=" p-3 single-sms">
                                    <div className="col-lg-5 ">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <label className="form-label">Mobile Number :</label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Mobile Number"
                                                name="mobilenumber"
                                                style={{ backgroundColor: '#E6E6FA' }}
                                                value={mobileNumber}
                                                onChange={(e) => setMobileNumber(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    </div>
                                )}

                                <div className="card p-3 sms">
                                    <div className="form-group">
                                        <div className="col-lg-12 ">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <label className="form-label">SMS Field</label>
                                                <Form.Control as="textarea" rows="8"
                                                    autocomplete="off"
                                                    type="textarea"
                                                    placeholder="Type Text Here"
                                                    name="remarks"
                                                    // value={itemDataInputForNonBikeCarEntry?.remarks}
                                                    onChange={(e) => {
                                                        setSmsText(e.target.value)
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            <button type="button"
                                                className="btn btn-primary btn-sm text-center ml-4" onClick={() => smsSend()}>
                                                Send <FastForward />
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        }
                            {
                                showDiv1 && <>

                                    <div className="table-responsive mt-3">
                                        {isLoading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <>
                                                <table className="sms">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={handleSelectAllChange}
                                                                    checked={selectAll}
                                                                />
                                                            </th>
                                                            <th scope="col">SL</th>
                                                            <th scope="col">ZONE</th>
                                                            <th scope="col">DIVISION</th>
                                                            <th scope="col">REGION</th>
                                                            <th scope="col">TERRITORY</th>
                                                            <th scope="col">NAME</th>
                                                            <th scope="col">ENROLL</th>
                                                            <th scope="col">DIS-POINT ID</th>
                                                            <th scope="col">ADDRESS</th>
                                                            <th scope="col">CONTACT NUMBER</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {distibList.length > 0 ? (
                                                            distibList.map((item, index) => (
                                                                <tr key={item.distribID}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={() => handleCheckboxChange(item)}
                                                                            checked={selectedRows.some((dmg) => dmg.distribID === item.distribID)}
                                                                            aria-label={`Select ${item.distribName}`}
                                                                        />
                                                                    </td>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.zonename}</td>
                                                                    <td>{item.divisionname}</td>
                                                                    <td>{item.areaname}</td>
                                                                    <td>{item.territoryName}</td>
                                                                    <td>{item.distribName}</td>
                                                                    <td>{item.distribID}</td>
                                                                    <td>{item.disPointID}</td>
                                                                    <td>{item.address}</td>
                                                                    <td>{item.contactNo}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="11" className="text-center">
                                                                    <div>No Data Available.</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                </table>
                                            </>
                                        )}
                                    </div>
                                </>}
                        </>
                    }

                </>

            }
        </div>
    )
}

export default SmsSystem;