import React from "react";
import SkuWisePrimaryCumalativeReport from "../../../information/components/SecodarySales/SkuWisePrimaryCumalativeReport";


const SkuWisePrimaryCumalativeReportContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SkuWisePrimaryCumalativeReport />
      </div>
    </div>
  );
};
export default SkuWisePrimaryCumalativeReportContainer;