import axios from "axios";
import * as Types from "../../types/Types";



export const getPromotionalListDetails = (invID) => async (dispatch) => {
  let responseList = {
    promotionalLoading: true,
    data: {},
    status: true,
  };
  
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetPromotionalDemandListByGiftID?GiftID=${invID}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.promotionalLoading = false;
  });
  dispatch({ type: Types.GET_PROMOTIONAL_LIST_DETAILS, payload: responseList });
};


