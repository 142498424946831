import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
    getAreaListByDivision,
    getDivisionListByZone,
    getTerritory,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
    cleanSecondarySalesData,
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getSubDepoDelivery } from "../../_redux/actions/Delivery/DeliveryAction";


const SubDepoDelivery = () => {
    const isProductLoading = useSelector((state) => state.delivery.skuLoading);
    const productReport = useSelector((state) => state.delivery.soWiseProduct);
    const skuTotal = useSelector((state) => state.delivery.skuTotal);
    const skuQuantity = useSelector((state) => state.delivery.skuQuantity);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [type, setType] = useState(0);
    const componentRef = useRef();

    const [skuDate, setSkuDate] = useState({
        fromDelConfirmDate: null,
        fromDelConfirmTime: "00:00",
        toDelConfirmDate: null,
        toDelConfirmTime: "23:59",
        fromDelDate: null,
        toDelDate: null,
        fromDelFromTime: "00:00",
        fromDelToTime: "23:59",

    });

    const { register, setValue } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
       
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
       
    };

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZone(id);
        SetDivision(divisionData.data);
        setValue("divisionData", "");
    };
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivision(id);
        setArea(areaData.data);
    };

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    
    const inputField = React.useRef(null);

    const handleListAction = () => {
        let FromdelFromDateConv = skuDate.fromDelDate;
        let TodelFromDateConv = skuDate.toDelDate;

        dispatch(getSubDepoDelivery(selectZone, selectDivision, selectArea, selectTerritory, FromdelFromDateConv, TodelFromDateConv, type));

    }

    const handleChange = (e, name) => {
        const dateDetails = { ...skuDate };
        dateDetails[name] = e.target.value;
        setSkuDate(dateDetails);
    };

    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const getTerritoryData = async (id) => {
        let territoryData = await getTerritory(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };






    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-md-9">
                        <div className="mt-2 p-2">

                            <h3 className="mb-0 pb-0">SUB DEPO DELIVERY  </h3>



                        </div>
                    </div>
                    <div className="col-md-3">
                        <DownloadDropdown excelname='SUB DEPO DELIVERY' />
                    </div>

                </div>
                <hr></hr>
                <div className="form-group row">
                    

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Delivery From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDelDate"
                                value={`${skuDate.fromDelDate}`}
                                className="fromStyle"
                                onChange={(e) => handleChange(e, 'fromDelDate')}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Delivery To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="toDelDate"
                                value={`${skuDate.toDelDate}`}
                                className="fromStyle"
                                onChange={(e) => handleChange(e, 'toDelDate')}
                            />
                        </Form.Group>
                    </div>


                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Zone</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={zoneDropdownData()} />}
                                rules={{ required: false }}
                                name="zoneData"
                                register={register}
                                // value={selectZone}
                                value=""
                                onChange={(option) => {
                                    setSelectZone(option.value);
                                    divisionList(option.value);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("intDistributorId", "");
                                    // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Division</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={divisionDropdownData()} />}
                                rules={{ required: false }}
                                name="divisionData"
                                register={register}
                                value=""
                                // value={CertificateMasterInput.isActive}
                                onChange={(option) => {
                                    // setType(option.value)
                                    getAreaData(option.value);
                                    setSelectDivision(option.value);
                                    setValue("areaData", "");
                                    // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Region</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={areaDropdownData()} />}
                                rules={{ required: false }}
                                name="areaData"
                                register={register}
                                value=""

                                onChange={(option) => {


                                    setSelectArea(option.value); getTerritoryData(option.value)


                                    // handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>


                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Territory</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={territoryDropdownData()} />}
                                rules={{ required: false }}
                                name="territory"
                                register={register}
                                value=""
                                // value={CertificateMasterInput.isActive}
                                onChange={(option) => {
                                    setSelectTerritory(option.value);


                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-5">
                        <Button size="sm" onClick={() => handleListAction()}>Show Report</Button>
                    </div>

                </div>
                <div>
                    {productReport?.length > 0 &&

                        <div className="container-fluid mb-3 mt-3 containerFixed">
                            <div className="row">

                                <div className="col-xl-6 col-lg-4 col-md-6">
                                    <div className="paid__widget two">
                                        <div className="widget__left">


                                            <p className="balanceleft">Total Quantity</p>
                                        </div>
                                        <div className="widget__right">

                                            <div className="overlay-layer bg-transparent">
                                            </div>
                                            <div>
                                                <h4 className="balanceleft">৳ {parseInt(skuQuantity)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="paid__widget one">
                                        <div className="widget__left" >
                                            <p className="balanceleft">Total Value</p>
                                        </div>
                                        <div className="widget__right">
                                            <div>
                                                <h4 className="balanceleft">৳ {parseFloat(skuTotal).toFixed(2)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <div className="row" id="id" ref={inputField}>
                        {/* <table className="table mt-5 voyageTable table-responsive"> */}
                        <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
                            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
                            <div
                                className="stickyTable"
                                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
                            >
                                <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
                                    <thead>

                                        <tr>
                                            <th scope="col">ZONE</th>
                                            <th scope="col">DIVISION</th>
                                            <th scope="col">REGION</th>
                                            <th scope="col">TERRITORY</th>
                                            <th scope="col">DISTRIBUTOR ID</th>
                                            <th scope="col">DISTRIBUTOR NAME</th>
                                            <th scope="col">DISTRIBUTOR POINT</th>
                                            <th scope="col">PRODUCT ID</th>
                                            <th scope="col">PRODUCT NAME</th>
                                            <th scope="col">PRODUCT VALUE</th>
                                            <th scope="col">PRODUCT QUANTITY</th>
                                            <th scope="col">Total Value</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productReport?.length > 0 &&
                                            productReport?.map((item, index) => (
                                                <>
                                                    <tr className="alert bg-secondary">
                                                        {/* <td>{item.orderId}</td> */}
                                                        <td>{item.zoneName}</td>
                                                        <td>{item.divisionName}</td>
                                                        <td>{item.areaName}</td>
                                                        <td>{item.territoryName}</td>
                                                        <td>{item.distribID}</td>
                                                        <td>{item.distribName}</td>
                                                        <td>{item.disPointName}</td>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td>{item.totalQuentity}</td>

                                                        <td>{parseFloat(item.totalPrice).toFixed(2)}</td>
                                                    </tr>
                                                    {
                                                        item?.itemReport.map((product, subindex) => (
                                                            <tr>
                                                                <td>{item.zoneName}</td>
                                                                <td>{item.divisionName}</td>
                                                                <td>{item.areaName}</td>
                                                                <td>{item.territoryName}</td>
                                                                <td>{item.distribID}</td>
                                                                <td>{item.distribName}</td>
                                                                <td>{item.disPointName}</td>
                                                                <td>{product.productID}</td>
                                                                <td>{product.productName}</td>
                                                                <td>{parseFloat(product.productPrice).toFixed(2)}</td>
                                                                <td>{product.productQty}</td>
                                                                <td>{parseFloat(product.productTotalPrice).toFixed(2)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            ))}


                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default SubDepoDelivery