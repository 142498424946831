import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    getAreaListByDivision,
    getDivisionListByZone,
    getTerritory,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import { getSecondaryStockForExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import { getNewProductStockForDownloadExcel } from './../../_redux/actions/Delivery/DeliveryAction';
import NewLoadind from "../../../../master/spinner/NewLoadind";

const SecondarySaleStockNew = () => {

    const stockReport = useSelector((state) => state.delivery.stockReport);
    const isLoading = useSelector((state) => state.delivery.stockLoading);
    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [selectReportType, setSelectReportType] = useState(2);
    const [searchTerm, setSearchTerm] = useState("");

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortedColumn, setSortedColumn] = useState("disPointID");


    const [reportType, setReportType] = useState([
        {
            id: 1,
            name: "Distributor",
        },
        {
            id: 2,
            name: "Point Wise",
        },
    ]);
    const componentRef = useRef();

    const { register, setValue, } = useForm();

    const dispatch = useDispatch();
    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    };

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };
    const reportTypeDropdownData = () => {
        let options = [];
        if (reportType?.length > 0) {
            reportType.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.name,
                };

                options.push(itemData);
            });
        }

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZone(id, selectGeoType);
        SetDivision(divisionData.data);
        setValue("divisionData", "");
    };
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivision(id, selectGeoType);
        setArea(areaData.data);
    };

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };


    const handleListAction = () => {
        dispatch(getNewProductStockForDownloadExcel(
            startDate,
            selectZone,
            selectDivision,
            selectArea,
            selectTerritory,
            3));
    }


    const [selectGeoType, setSelectGeoType] = useState(2);

    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const hanleExcelClick = async () => {
        let excelClick = await getSecondaryStockForExcel(
            startDate,
            selectZone,
            selectDivision,
            selectArea,
            selectTerritory,
            3
        );
        const current = currentdate();
        // const worksheet = XLSX.utils.json_to_sheet(excelClick.data);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // XLSX.writeFile(workbook, "Product Stock(Secondary).xlsx");
        let Heading = [[
            'ZoneId',
            'ZoneName',
            'Divisionid',
            'DivisionName',
            'RegionID',
            'RegionName',
            'TerritoryId',
            'TerritoryName',
            'DisPointID',
            'DisPointName',
            'ProductID',
            'ProductName',
            'CategoryID',
            'CategoryName',
            'BrandName',
            'PackName',
            'Uom',
            'Price',
            'OpeningQty',
            'OpeningCtnQty',
            'OpeningValue',
            'LiftingQty',
            'LiftingCtnQty',
            'LiftingValue',
            'IMSPcsQty',
            'IMSCtnQty',
            'IMSValue',
            'ClosingQty',
            'ClosingCtnQty',
            'ClosingValue'

        ]];

        //Had to create a new workbook and then add the header
        const wb = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, Heading);

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
        const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
        XLSX.writeFile(wb, 'ProductStock' + " " + current + '.xlsx');
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritory(id, selectGeoType);
        setTerritory(territoryData.data);
    };

    const getSalesValueType = (name, value) => {
        setSalesType(value);
        setSelectGeoType(value);
    };

    //For Sorting
    const handleSort = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortedColumn(column);
            setSortOrder("asc");
            setSearchTerm(""); // Reset search term
        }
    };


    // Sorting logic
    const sortedList = useMemo(() => {
        if (!Array.isArray(stockReport)) {
            return [];
        }

        const filteredList = stockReport.filter(
            (item) =>
                !searchTerm ||
                String(item.disPointID).includes(searchTerm)
        );

        return filteredList.sort((a, b) => {
            if (sortOrder === "asc") {
                return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
            } else {
                return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
            }
        });
    }, [stockReport, searchTerm, sortOrder, sortedColumn]);

    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">

                <div className="row mt-5 bg-light p-2">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                            <span className="button-010">Product Stock(Secondary)</span>
                            {/* <h3 className="mb-0 pb-0">Product Stock(secondary)</h3> */}
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right">
                            <Button size="sm" onClick={() => hanleExcelClick()}>
                                Download
                            </Button>
                            <DownloadDropdown excelname='Product Stock(secondary)' />
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="mt-2 p-2 ml-4">
                            <SimpleSgeoType
                                getSalesValue={getSalesValueType}
                                sGeoType={selectGeoType}
                            />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Month</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Zone</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={zoneDropdownData()} />}
                                rules={{ required: false }}
                                name="zoneData"
                                register={register}
                                // value={selectZone}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectZone(option?.value);
                                    divisionList(option?.value);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                    // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Division</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={divisionDropdownData()} />}
                                rules={{ required: false }}
                                name="divisionData"
                                register={register}
                                value=""
                                isClearable
                                // value={CertificateMasterInput.isActive}
                                onChange={(option) => {
                                    // setType(option.value)
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                    // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Region</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={areaDropdownData()} />}
                                rules={{ required: false }}
                                name="areaData"
                                register={register}
                                value=""
                                isClearable
                                // value={CertificateMasterInput.isActive}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Territory</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={territoryDropdownData()} />}
                                rules={{ required: false }}
                                name="territory"
                                register={register}
                                value=""
                                isClearable
                                // value={CertificateMasterInput.isActive}
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Report Type</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={reportTypeDropdownData()} />}
                                rules={{ required: false }}
                                name="reportType"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectReportType(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 mt-5 mb-5 ">
                        <button className="button-621 mb-5" onClick={() => handleListAction()} >  Show Report</button>
                    </div>
                </div>


                <div className="row" id="id" ref={inputField}>
                    {sortedList?.length > 0 &&
                        <div className="searchBox pl-5">
                            <form className="form float-right">
                                <button>
                                    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <input
                                    className="input"
                                    placeholder="Search Dis Point ID"
                                    required=""
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    }
                    <div className="react-bootstrap-table table-responsive border-0 pl-5">
                        {isLoading && <NewLoadind />}
                        <div
                            className="stickyTable"
                            style={{ height: stockReport?.length > 0 ? 800 + "px" : 0 }}
                        >

                            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                                <thead>
                                    <tr className="bg-light">
                                        <th scope="col">ZONE</th>
                                        <th scope="col">DIVISION</th>
                                        <th scope="col">REGION</th>
                                        <th scope="col">TERRITORY</th>
                                        <th scope="col" onClick={() => handleSort("disPointID")}>
                                            DISTRIBUTOR POINT ID{" "}
                                            {sortedColumn === "disPointID" && (
                                                <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                                            )}
                                        </th>
                                        <th scope="col">DISTRIBUTOR POINT</th>
                                        <th scope="col">PRODUCT ID</th>
                                        <th scope="col">PRODUCT NAME</th>
                                        <th scope="col">PRODUCT CATEGORY</th>
                                        <th scope="col">BRAND NAME</th>
                                        <th scope="col">PACK NAME</th>
                                        <th scope="col">PRODUCT UOM</th>
                                        <th scope="col">OPENING(PCS)</th>
                                        <th scope="col">OPENING(CTN)</th>
                                        <th scope="col">OPENING(VALUE)</th>
                                        <th scope="col">LIFING (PCS)</th>
                                        <th scope="col">LIFING (CTN)</th>
                                        <th scope="col">LIFING (VALUE)</th>
                                        <th scope="col">IMS (PCS)</th>
                                        <th scope="col">IMS (CTN)</th>
                                        <th scope="col">IMS (VALUE)</th>
                                        <th scope="col">CLOSING (PCS)</th>
                                        <th scope="col">CLOSING(CTN)</th>
                                        <th scope="col">CLOSING(VALUE)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedList?.length > 0 &&
                                        sortedList?.map((item, index) => (
                                            <>
                                                <tr className="">
                                                    <td>{item.zoneName}</td>
                                                    <td>{item.divisionName}</td>
                                                    <td>{item.regionName}</td>
                                                    <td>{item.territoryName}</td>
                                                    <td>{item.disPointID}</td>
                                                    <td>{item.disPointName}</td>
                                                    <td>{item.productID}</td>
                                                    <td>{item.productName}</td>
                                                    <td>{item.prodCatName}</td>
                                                    <td>{item.brandName}</td>
                                                    <td>{item.packTypeName}</td>
                                                    <td>{item.productUom}</td>

                                                    <td>{parseInt(item.productOpeningPCQty)}</td>
                                                    <td>{parseInt(item.productOpeningCTQty)}</td>
                                                    <td>{parseInt(item.productOpeningPrice)}</td>

                                                    <td>{parseInt(item.productLiftingPCQty)}</td>
                                                    <td>{parseInt(item.productLiftingCTQty)}</td>
                                                    <td>{parseFloat(item.productLiftingPrice).toFixed(2)}</td>

                                                    <td>{parseInt(item.productIMS_PCQty)}</td>
                                                    <td>{parseInt(item.productIMS_CTQty)}</td>
                                                    <td>{parseFloat(item.productIMSPrice).toFixed(2)}</td>

                                                    <td>{parseInt(item.productClosingPCQty)}</td>
                                                    <td>{parseInt(item.productClosingCTQty)}</td>
                                                    <td>{parseFloat(item.productClosingPrice).toFixed(2)}</td>

                                                </tr>
                                            </>
                                        ))}
                                </tbody>
                            </table>


                        </div>
                        {!isLoading && sortedList.length === 0 && (
                            <div className="alert alert-warning mt-5">
                                Sorry ! List Not Found.
                            </div>
                        )}

                    </div>
                </div>

            </div>

        </>
    );
};

export default SecondarySaleStockNew;
