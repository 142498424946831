import axios from "axios";
import { showToast } from "../../../../../modules/master/utils/ToastHelper";
import * as Types from "../authTypes";



export const GetEmployeeInformation = (enroll) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetEmployeeInformation?Enroll=${enroll}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    showToast("error", res.data.data.message);
  });
  dispatch({ type: Types.GET_EMPLOYEE_INFORMATION, payload: responseList });
};

export const GetEmployeeInformationWithoutAdmin = (enroll) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetEmployeeInformationWithoutAdmin?Enroll=${enroll}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    showToast("error", res.data.data.message);
  });
  dispatch({ type: Types.GET_EMPLOYEE_INFORMATION, payload: responseList });
};

export const GetEmployeeInformationForAuthentication = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetEmployeeInformationForAuthentication`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    showToast("error", res.data.data.message);
  });
  dispatch({ type: Types.GET_EMPLOYEE_INFORMATION_AUTHENTICATION, payload: responseList });
};

export const GetAllMenuList = () => async (dispatch) => {
  let responseList = {
    data: [],
    isMenuLoading: true,
  };
  dispatch({ type: Types.GET_ALL_MENU_LIST, payload: responseList });

  try {
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/GetAllMenuList`;
    const res = await axios.get(url);
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isMenuLoading = false;

    if (res.data.data == null) {
      showToast("error", "Failed to fetch menu list");
    }

  } catch (error) {
    responseList.isMenuLoading = false;
    showToast("error", "An error occurred while fetching the menu list");
  }

  dispatch({ type: Types.GET_ALL_MENU_LIST, payload: responseList });
};

export const MenuPermissionSumbit = (enroll, data) => async (dispatch) => {
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/InsertMenuPermissionWithEnroll?Enroll=${enroll}`;
    
    // Using async/await for better error handling
    const response = await axios.post(postUrl, data);

    // Check response status code
    if (response.data.code === 200) {
      responseList.submitLoading = false;
      responseList.status = response.data.status;
      showToast("success", response.data.message);
    } else {
      responseList.status = false;
      responseList.submitLoading = false;
      showToast("error", response.data.message || "An unexpected error occurred.");
    }
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
    
  } catch (error) {
    responseList.status = false;
    responseList.submitLoading = false;
    
    // Handle errors from the request itself
    const errorMessage = error.response?.data?.message || 
      error.message || 
      "Something went wrong! Please try again later.";
    showToast("error", errorMessage);
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
  }
};


export const DeactivatedMenu = (menuid) => async (dispatch) => {
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/DeactivateMenuStatus?menuid=${menuid}`;
    
    const response = await axios.post(postUrl);

    if (response.data.code === 200) {
      responseList.submitLoading = false;
      responseList.status = response.data.status;
      showToast("success", response.data.message);
      dispatch(GetAllMenuList());
    } else {
      responseList.status = false;
      responseList.submitLoading = false;
      showToast("error", response.data.message || "An unexpected error occurred.");
    }
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
    
  } catch (error) {
    responseList.status = false;
    responseList.submitLoading = false;
  
    const errorMessage = error.response?.data?.message || 
      error.message || 
      "Something went wrong! Please try again later.";
    showToast("error", errorMessage);
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
  }
};
export const ActivateMenuStatus = (menuid) => async (dispatch) => {
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/ActivateMenuStatus?menuid=${menuid}`;
    
    const response = await axios.post(postUrl);

    if (response.data.code === 200) {
      responseList.submitLoading = false;
      responseList.status = response.data.status;
      showToast("success", response.data.message);
      dispatch(GetAllMenuList());
    } else {
      responseList.status = false;
      responseList.submitLoading = false;
      showToast("error", response.data.message || "An unexpected error occurred.");
    }
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
    
  } catch (error) {
    responseList.status = false;
    responseList.submitLoading = false;
  
    const errorMessage = error.response?.data?.message || 
      error.message || 
      "Something went wrong! Please try again later.";
    showToast("error", errorMessage);
    
    dispatch({ type: Types.MENU_PERMISSION_SUMBIT, payload: responseList });
  }
};


export const GetMenuListsByPermission = () => async (dispatch) => {
  let data = {
    menuList: [],
    isMenuLoading: true,
  }
  // dispatch({ type: Types.GET_MENU_LIST, payload: data });

  axios.get(`${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/ApplicationURLWEB`)
    .then(res => {
      data = {
        menuList: res.data.data,
        isMenuLoading: false,
      }
      dispatch({ type: Types.GET_MENU_LIST, payload: data });
    });

 






    // let menuList = [
    //   /**
    //       | Module : Hr Management
    //       */
    //   {
    //     moduleName: "HR",
    //     moduleRouteUrl: "/employee",
    //     moduleImageIcon: "/media/svg/icons/Code/hr.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Employee",
    //         subModuleRouteUrl: "/employee/employee-list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "All Employees",
    //             featureRouteUrl: "/employee/employee-list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "Employee Create",
    //             featureRouteUrl: "/employee/employee-add",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Employee Sign In/Out",
    //             featureRouteUrl: "/employee/employee-signing-list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "Employee CR Report",
    //             featureRouteUrl: "/employee/employee-cr-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //       {
    //         subModuleName: "Vessel",
    //         subModuleRouteUrl: "/vessel-list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Vessel List",
    //             featureRouteUrl: "/vessels/list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Create Vessel",
    //             featureRouteUrl: "/vessels/add",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Vessel Account",
    //             featureRouteUrl: "/vessels/vessel-account",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Vessel Items",
    //             featureRouteUrl: "/vessel-items/list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },

    //   {
    //     moduleName: "Expense",
    //     moduleRouteUrl: "/addition-deduction",
    //     moduleImageIcon: "/media/svg/icons/Code/expense.svg",
    //     subModules: [
    //       {
    //         subModuleName: "New Expense",
    //         subModuleRouteUrl: "/addition-deduction",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Manual",
    //             featureRouteUrl: "/addition-deduction",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Bulk",
    //             featureRouteUrl: "/addition-deduction/bulk",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Currency Conversion",
    //             featureRouteUrl: "/currency-conversion",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },

    //   {
    //     moduleName: "Certificate",
    //     moduleRouteUrl: "/certificates/list",
    //     moduleImageIcon: "/media/svg/icons/Code/certificates.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Detail",
    //         subModuleRouteUrl: "/certificates-main/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "New Certificate",
    //             featureRouteUrl: "/certificates-main/create",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "Certificate List",
    //             featureRouteUrl: "/certificates-main/list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //       {
    //         subModuleName: "Configure",
    //         subModuleRouteUrl: "/certificate/configure/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Master Certificate",
    //             featureRouteUrl: "/certificate/configure/master-certificate",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Categories",
    //             featureRouteUrl: "/certificate/configure/categories",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Issuing Authority",
    //             featureRouteUrl: "/certificate/configure/issuing-authority",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Types",
    //             featureRouteUrl: "/certificate/configure/types",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     moduleName: "Suppliers",
    //     moduleRouteUrl: "/partners",
    //     moduleImageIcon: "/media/svg/icons/Code/partner.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Suppliers Info",
    //         subModuleRouteUrl: "/partners/info",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "New Supplier",
    //             featureRouteUrl: "/partners/info",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Supplier List",
    //             featureRouteUrl: "/suppliers/list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },

    //   {
    //     moduleName: "Items",
    //     moduleRouteUrl: "/items",
    //     moduleImageIcon: "/media/svg/icons/Code/item.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Item Info",
    //         subModuleRouteUrl: "/items/info",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Item List",
    //             featureRouteUrl: "/items/list",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Item Add",
    //             featureRouteUrl: "/items/add",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },

    //   // {
    //   //   moduleName: "Procurement",
    //   //   moduleRouteUrl: "/vessels/list",
    //   //   moduleImageIcon: "/media/svg/icons/Code/Compiling.svg",
    //   //   subModules: [
    //   //     {
    //   //       subModuleName: "Procurement",
    //   //       subModuleRouteUrl: "/supply-chain/procurement",
    //   //       subModuleIcon: "menu-bullet menu-bullet-dot",
    //   //       features: [
    //   //         {
    //   //           featureName: "Purchase Requisition (Indent)",
    //   //           featureRouteUrl: "/supply-chain/procurement/purchase-requisition",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //         {
    //   //           featureName: "Cargo Booking",
    //   //           featureRouteUrl: "/supply-chain/procurement/demand-entry",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //         {
    //   //           featureName: "Pending Top Sheet",
    //   //           featureRouteUrl: "/supply-chain/procurement/top-sheet",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //         {
    //   //           featureName: "Cargo Pending Details",
    //   //           featureRouteUrl:
    //   //             "/supply-chain/procurement/cargo-pending-details",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //         {
    //   //           featureName: "Approve Top Sheet",
    //   //           featureRouteUrl: "/supply-chain/procurement/aprv-top-sheet",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //         {
    //   //           featureName: "Booking Update Detaills",
    //   //           featureRouteUrl:
    //   //             "/supply-chain/procurement/booking-unpdate-details",
    //   //           featureIcon: "menu-bullet menu-bullet-dot",
    //   //         },
    //   //       ],
    //   //     },
    //   //   ],
    //   // },

    //   {
    //     moduleName: "Voyage",
    //     moduleRouteUrl: "/voyage",
    //     moduleImageIcon: "/media/svg/icons/Code/voyage.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Voyage List",
    //         subModuleRouteUrl: "/voyage/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           // {
    //           //   featureName: "Voyage List",
    //           //   featureRouteUrl: "/voyage/list",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //           // {
    //           //   featureName: "Voyage Create",
    //           //   featureRouteUrl: "/voyage/create",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //         ],
    //       },
    //       {
    //         subModuleName: "Noon Report",
    //         subModuleRouteUrl: "/voyage/voyage-activity",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           // {
    //           //   featureName: "Noon Report List",
    //           //   featureRouteUrl: "/voyage/voyage-activity",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //           // {
    //           //   featureName: "Voyage Activity Create",
    //           //   featureRouteUrl: "/voyage/voyage-activity/create",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //         ],
    //       },
    //       {
    //         subModuleName: "Laytime Calculation",
    //         subModuleRouteUrl: "/voyage/laytime/laytimeinfo",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: []
    //       },
    //       {
    //         subModuleName: "Booking",
    //         subModuleRouteUrl: "/voyage/booking/bookinglist",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           // {
    //           //   featureName: "Booking List",
    //           //   featureRouteUrl: "/voyage/booking/bookinglist",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //           // {
    //           //   featureName: "Booking Entry",
    //           //   featureRouteUrl: "/voyage/booking/bookingentry",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //         ],
    //       },
    //     ],
    //   },

    //   //==========Purhchase===========
    //   {
    //     moduleName: "Purchase",
    //     moduleRouteUrl: "/purchase",
    //     moduleImageIcon: "/media/svg/icons/Code/purchase.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Purchase Request",
    //         subModuleRouteUrl: "/purchase/request/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName: "Purchase Approval",
    //         subModuleRouteUrl: "/purchase/approval/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName: "Purchase Order",
    //         subModuleRouteUrl: "/purchase/order/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName: "PO Approval",
    //         subModuleRouteUrl: "/purchase/order/approval/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName: "Supplier CS",
    //         subModuleRouteUrl: "/supplier/info",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName: "Quotation",
    //         subModuleRouteUrl: "/quotation/details",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //       {
    //         subModuleName:"Comparative Statement",
    //         subModuleRouteUrl: "/comparativestatement/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //     ],
    //   },

    //   /**
    //        | Module :Report
    //        */
    //   {
    //     moduleName: "Reports",
    //     moduleRouteUrl: "/report",
    //     moduleImageIcon: "/media/svg/icons/Code/report.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Voyage Report",
    //         subModuleRouteUrl: "/report/noon-report",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Noon Report",
    //             featureRouteUrl: "/report/noon-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },

    //       {
    //         subModuleName: "Wages Report",
    //         subModuleRouteUrl: "/report/salary-report",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "Salary Form Generate",
    //             featureRouteUrl: "/report/salary-report-form",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Salary Report",
    //             featureRouteUrl: "/report/salary-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "Salary Check Report",
    //             featureRouteUrl: "/report/salary-report-check",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "Salary Generate",
    //             featureRouteUrl: "/report/salary-generate",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },

    //           {
    //             featureName: "BEFTN Report",
    //             featureRouteUrl: "/report/beftn-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "FCBEFTN Report",
    //             featureRouteUrl: "/report/beftn-report-fc",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //       {
    //         subModuleName: "Certificate Report",
    //         subModuleRouteUrl: "/report/certificate-report",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           {
    //             featureName: "All Report",
    //             featureRouteUrl: "/report/all-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //           {
    //             featureName: "Day Report",
    //             featureRouteUrl: "/report/day-report",
    //             featureIcon: "menu-bullet menu-bullet-dot",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     moduleName: "Utility",
    //     moduleRouteUrl: "/utility",
    //     moduleImageIcon: "/media/svg/icons/Code/utility.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Loadable Calculator",
    //         subModuleRouteUrl: "/utility/calculator",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [
    //           // {
    //           //   featureName: "Loadable Calculator",
    //           //   featureRouteUrl: "/utility/calculator",
    //           //   featureIcon: "menu-bullet menu-bullet-dot",
    //           // },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     moduleName: "Role & Permission",
    //     moduleRouteUrl: "/role-permission",
    //     moduleImageIcon: "/media/svg/icons/Code/hr.svg",
    //     subModules: [
    //       {
    //         subModuleName: "Role",
    //         subModuleRouteUrl: "/role-permission/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },

    //       {
    //         subModuleName: "User",
    //         subModuleRouteUrl: "/user/list",
    //         subModuleIcon: "menu-bullet menu-bullet-dot",
    //         features: [],
    //       },
    //     ],
    //   },
    // ];

    // const vesselId = await getVesselId();
    // if (typeof vesselId !== undefined && vesselId !== null && vesselId !== "") {
    //   menuList = [
    //     {
    //       moduleName: "HR",
    //       moduleRouteUrl: "/employee",
    //       moduleImageIcon: "/media/svg/icons/Code/Compiling.svg",
    //       subModules: [
    //         {
    //           subModuleName: "Employee Management",
    //           subModuleRouteUrl: "/employee/employee-list",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "All Employees",
    //               featureRouteUrl: "/employee/employee-list",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       moduleName: "Expense",
    //       moduleRouteUrl: "/addition-deduction",
    //       moduleImageIcon: "/media/svg/icons/Code/Compiling.svg",
    //       subModules: [
    //         {
    //           subModuleName: "New Expense",
    //           subModuleRouteUrl: "/addition-deduction",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Manual",
    //               featureRouteUrl: "/addition-deduction",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //             {
    //               featureName: "Bulk",
    //               featureRouteUrl: "/addition-deduction/bulk",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       moduleName: "Vessel",
    //       moduleRouteUrl: "/vessels/list",
    //       moduleImageIcon: "/media/svg/icons/Code/Compiling.svg",
    //       subModules: [
    //         {
    //           subModuleName: "Vessel Items",
    //           subModuleRouteUrl: "/vessel-items/list",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Item List",
    //               featureRouteUrl: "/vessel-items/list",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       moduleName: "Reports",
    //       moduleRouteUrl: "/report",
    //       moduleImageIcon: "/media/svg/icons/Shopping/Gift.svg",
    //       subModules: [
    //         {
    //           subModuleName: "Noon Report",
    //           subModuleRouteUrl: "/report/noon-report",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Noon Report",
    //               featureRouteUrl: "/report/noon-report",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //           ],
    //         },

    //         {
    //           subModuleName: "Wages Report",
    //           subModuleRouteUrl: "/report/salary-report",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Salary Check Report",
    //               featureRouteUrl: "/report/salary-report-check",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //             {
    //               featureName: "BEFTN Report",
    //               featureRouteUrl: "/report/beftn-report",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //             {
    //               featureName: "FCBEFTN Report",
    //               featureRouteUrl: "/report/beftn-report-fc",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       moduleName: "Voyage",
    //       moduleRouteUrl: "/voyage",
    //       moduleImageIcon: "/media/svg/icons/Code/Compiling.svg",
    //       subModules: [
    //         {
    //           subModuleName: "Voyage",
    //           subModuleRouteUrl: "/voyage/list",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Voyage List",
    //               featureRouteUrl: "/voyage/list",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //             // {
    //             //   featureName: "Voyage Create",
    //             //   featureRouteUrl: "/voyage/create",
    //             //   featureIcon: "menu-bullet menu-bullet-dot",
    //             // },
    //           ],
    //         },
    //         {
    //           subModuleName: "Noon Report",
    //           subModuleRouteUrl: "/voyage/voyage-activity",
    //           subModuleIcon: "menu-bullet menu-bullet-dot",
    //           features: [
    //             {
    //               featureName: "Noon Report List",
    //               featureRouteUrl: "/voyage/voyage-activity",
    //               featureIcon: "menu-bullet menu-bullet-dot",
    //             },
    //             // {
    //             //   featureName: "Voyage Activity Create",
    //             //   featureRouteUrl: "/voyage/voyage-activity/create",
    //             //   featureIcon: "menu-bullet menu-bullet-dot",
    //             // },
    //           ],
    //         },
    //       ],
    //     },
    //   ];
    // }

    // dispatch({ type: Types.GET_MENU_LIST, payload: menuList });
  };

  /** Dummy Axios call for Example */
  export const DummyAxiosCall = () => async (dispatch) => {
    await axios.get(`https://testapi.com/?AccountId=1`).then((res) => {
      // dispatch({ type: Types.GET_EMPLOYEE_INFO, payload: res.data });
    });
    // dispatch({ type: Types.GET_BLOOD_GROUP_LIST, payload: "data" });
  };
