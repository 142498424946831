import * as Types from "../../types/SecondarySalesTypes";
import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import { GetImsTrackingSystem } from "./SecondaryAction";

export const checkboxStatusChange = (item,index) => async (dispatch) => {
    let data={
      item:item,
      index:index
    }
    let responseList = {
      isLoading: true,
      data: data,
      status: true,
    };
    dispatch({ type: Types.REMOTE_CHECKBOX_STATUS, payload: responseList });   
  };
  export const allCheckBoxSelect = () => async (dispatch) => {
     dispatch({ type: Types.REMOTE_CHECKBOX_ALL, payload: null });   
  };
  export const showRollbackProduct = (fromDate,toDate,selectArea,selectTerritory) => async (
    dispatch
  ) => {
    let responseList = {
      isLoading: true,
      data: [],
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetApprovedSSbyArea?AreaID=${selectArea}&TerritoryId=${selectTerritory}&Fdate=${fromDate}&Tdate=${toDate}`;
    await axios.get(url).then((res) => {
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.status = false;
     
    });
    dispatch({
      type: Types.GET_ROLL_BACK_LIST,
      payload: responseList,
    });
  };

  export const salesRoleback =  (salesArray,startDate,toDate,selectArea,selectTerritory) =>async (dispatch)=> {
 
    let responseList = {
      isLoading: true,
      data: {},
      status: false,
    };
    let newList =[];
    for (let i = 0; i < salesArray.length; i++) {
      let salesArr = {
        salesID: 0
      };
      if(salesArray[i].isPresent){
        salesArr.salesID=salesArray[i].salesID
        newList.push(salesArr);
      }
    }
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/RoleBackSecondarySales`;
    await axios.put(url,newList).then(res => {
      responseList.data = res.data.data;
      responseList.status = res.data.status;
      showToast('success', `${res.data.message}`);
     
      dispatch(showRollbackProduct(startDate,toDate,selectArea,selectTerritory));
      dispatch(GetImsTrackingSystem("secondarySales/approvedDelate","SalesRollBack","mac",selectTerritory));
    })
      .catch(function (error) {
        
      });
    return responseList;
  }