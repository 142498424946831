import React from "react";
import FundList from "../../../information/components/SecodarySales/FundList";





const FundListContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <FundList/>
      </div>
    </div>
  );
};

export default FundListContainer;