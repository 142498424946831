import React from "react";
import AreaList from "../information/components/AreaList";

const AreaListContainer = () => {
  return (
    <div className="container">

      <AreaList />

    </div>
  );
};

export default AreaListContainer;
