import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
    getAreaListByDivision,
    getDivisionListByZone,
    getTerritory,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
    cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import * as XLSX from "xlsx/xlsx.mjs";
import {  GetSummerySheetForDamageCalculation } from "../../_redux/actions/RemoteSales/DamageAction";

const SummerySheetReport = () => {

    const SummerysheetLoading = useSelector((state) => state.damage.SummerysheetLoading);
    const SummerysheetData = useSelector((state) => state.damage.SummerysheetData);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const componentRef = useRef();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();
    const dispatch = useDispatch();

    // items list with pagination
    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        dispatch(cleanSecondarySalesData());
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    };

    // delete issuing authority list

    const zoneDropdownData = () => {

        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZone(id, selectGeoType);
        SetDivision(divisionData.data);
        setValue("divisionData", "");
    };
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivision(id, selectGeoType);
        setArea(areaData.data);
    };

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };


    let ref = React.createRef();
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
        // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
    };
    const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
        dispatch(GetSummerySheetForDamageCalculation(fromDate, tDate, idZone, idDivision, idArea, idTerritory));
    }

    const getTerritoryData = async (id) => {
        let territoryData = await getTerritory(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const getSalesValueType = (name, value) => {
        setSelectGeoType(value);
    }


    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="mt-2 p-2">
                            <h3 className="mb-0 pb-0">Summery Sheet Report</h3>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right">
                            <DownloadDropdown excelname='Summery Sheet Report' refvalue={componentRef} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 ml-4">
                            <SimpleSgeoType
                                getSalesValue={getSalesValueType}
                                sGeoType={selectGeoType}
                            />
                        </div>
                    </div>

                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                className="fromStyle"
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Zone</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={zoneDropdownData()} />}
                                rules={{ required: false }}
                                name="zoneData"
                                register={register}
                                // value={selectZone}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectZone(option?.value);
                                    divisionList(option?.value);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Division</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={divisionDropdownData()} />}
                                rules={{ required: false }}
                                name="divisionData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Region</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={areaDropdownData()} />}
                                rules={{ required: false }}
                                name="areaData"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    getTerritoryData(option?.value)
                                    setSelectArea(option?.value);
                                    setValue("territory", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Territory</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={territoryDropdownData()} />}
                                rules={{ required: false }}
                                name="territory"
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 mt-5 mb-5">
                        <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}>Show Report</Button>
                    </div>

                </div>
                <div>
                    <div className="row" id="id" ref={inputField}>
                        {/* <table className="table mt-5 voyageTable table-responsive"> */}
                        {SummerysheetLoading && <LoadingSpinner text="Loading Items List..." />}
                        <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

                            {/* <div
                                className="stickyTable"
                                style={{ height: SummerysheetData?.length > 0 ? 600 + "px" : 0 }}
                            > */}
                            {/* <table className="table table-head-custom borderTableims" id="table-to-xls" ref={componentRef}> */}
                            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5" id="table-to-xls" ref={componentRef}>
                                {
                                    SummerysheetData?.length > 0 &&
                                    <thead className="bg-light">
                                        <tr>
                                            <th rowSpan="2" className="text-center">Zone</th>
                                            <th rowSpan="2" className="text-center">Division</th>
                                            <th rowSpan="2" className="text-center">Area</th>
                                            <th rowSpan="2" className="text-center">Territory</th>
                                            <th colSpan="1" className="text-center">Broken</th>
                                            <th colSpan="1" className="text-center">Date Expired</th>
                                            <th colSpan="1" className="text-center">Rat Cut</th>
                                            <th colSpan="3" className="text-center">Market Damage</th>
                                            <th colSpan="2" className="text-center">Quality Problem</th>
                                            <th colSpan="2" className="text-center">Grand Total</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Total</th>
                                            <th className="text-center">Total</th>
                                            <th className="text-center">Total</th>
                                            <th className="text-center">Total Damage</th>
                                            <th className="text-center">Lifting</th>
                                            <th className="text-center">%</th>
                                            <th className="text-center">Quality</th>
                                            <th className="text-center">%</th>
                                            <th className="text-center">Total</th>
                                            <th className="text-center">%</th>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {
                                        SummerysheetData?.length > 0 &&
                                        SummerysheetData?.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{item.zoneName}</td>
                                                    <td>{item.divisionName}</td>
                                                    <td>{item.areaName}</td>
                                                    <td>{item.territoryName}</td>
                                                    <td>{item.brokenValue}</td>
                                                    <td>{item.dateExpiredValue}</td>
                                                    <td>{item.ratCutValue}</td>
                                                    <td>{item.marketDamageValue}</td>
                                                    <td>{item.totalLifting}</td>
                                                    <td>{(((item.marketDamageValue)/(item.totalLifting))*100).toFixed(2)}%</td>
                                                    <td>{item.qualiProbleValue}</td>
                                                    <td>{(((item.qualiProbleValue)/(item.totalLifting))*100).toFixed(2)}%</td>
                                                    <td>{item.totalValue}</td>
                                                    <td>{(((item.totalValue)/(item.totalLifting))*100).toFixed(2)}%</td>
                                                </tr>
                                            </>
                                        ))}


                                </tbody>
                            </table>
                            {/* </div> */}
                            {!SummerysheetLoading && SummerysheetData.length === 0 && (
                                <div className="alert alert-warning mt-5">
                                    Sorry ! List Not Found.
                                </div>
                            )}
                        </div>
                    </div>

                </div>




            </div>
        </>
    );
};

export default SummerySheetReport;
