import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import ReportFooter from "../../Master/ReportFooter";
import { cleanTargetSalesData, getSkuDemandWiseTargetAction} from "../../_redux/actions/Report/ReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const SkuWiseDemandTarget = () => {
  
  const targetDateList = useSelector((state) => state.target.targetDate);
  const totalTarget = useSelector((state) => state.target.totalData);

  const isLoading = useSelector((state) => state.target.targetLoading);
  
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();
  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  const { register, setValue } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    dispatch(cleanTargetSalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    // setValue("divisionData", "");
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [wordDate, setWordDate] = useState([]);
  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea) => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    // dispatch(getSkuDemandWiseTargetAction(fromDate, tDate, idZone, idDivision, idArea));
    dispatch(getSkuDemandWiseTargetAction(conFromDate, conToDate, idZone, idDivision, idArea));
  }

  const style={
    container: {
        'background':'f7f7f7',
        // 'height':670,
        'overflowy': 'scroll'
    },
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

 

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Primary Lifting vs Achievement Confirm Date</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Primary Lifting vs Achievement Confirm Date' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Deadweight"
                name="fromTime"
                 value={`${skuDate.fromTime}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Todate"
                name="toTime"
                className="fromStyle"
                value={`${skuDate.toTime}`}
                onChange={(e) => handleChange(e, 'toTime')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  handleListAction(startDate, toDate, option?.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  handleListAction(startDate, toDate, selectZone, option?.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                 
                  setValue("territory", "");
                  handleListAction(startDate, toDate, selectZone, selectDivision, option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                    
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


        </div>
        <div className="col-lg-12 ">
            <button type="button" className="btn btn-success btn-sm mt-3 float-left" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectTerritory)}>
              Show Report
            </button>
          </div>
        {targetDateList.length > 0 &&
          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Cake</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisLiftingQty)}</span>
                      <span>Target Value:  {parseFloat(totalTarget.cookisTargetPrice)}</span>
                      <span>Lifting Value:  {parseFloat(totalTarget.cookisLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Cookies & Biscuit</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisandBiscuitTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisandBiscuitLiftingQty)}</span>
                      <span>Target Value:  {parseFloat(totalTarget.cookisandBiscuitTargetPrice)}</span>
                      <span>Lifting Value:  {parseFloat(totalTarget.cookisandBiscuitLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Total</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.totalTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.totalLiftingQty)}</span>
                      <span>Target Value:  {parseFloat(totalTarget.totalTargetPrice)}</span>
                      <span>Lifting Value:  {parseFloat(totalTarget.totalLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
       

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
           {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>
           
            <div
                className="stickyTable"
                style={{ height: targetDateList?.length > 0 ? 800 + "px" : 0 }}
              >
            {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} > */}
            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                 <th scope="col">ZONE</th>
                 <th scope="col">DIVSION</th>
                 <th scope="col">REGION</th>
                 <th scope="col">TERRITORY</th>
                  <th scope="col">SKU CODE</th>
                  <th scope="col">CATEGORIES</th>
                  <th scope="col">SKU NAME</th>
                  <th scope="col">INVOICE PRICE</th>
                  <th scope="col">TARGET</th>
                  <th scope="col">LIFTING UPTO {wordDate}</th>
                  <th scope="col">DIFFERENCE(IN CTN)</th>
                  <th scope="col">TARGET VS ACH(IN CTN %)</th>
                  <th scope="col">TARGET {wordDate} IN TK</th>
                  <th scope="col">LIFTING {wordDate}</th>
                  <th scope="col">DIFFERENCE(IN TK)</th>
                  {/* <th scope="col">TGT VS ACH (IN TK%)</th> */}
                </tr>
              </thead>
              <tbody>
                {targetDateList.length > 0 &&
                  targetDateList.map((item, index) => (
                      <>
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.zonename}</td>
                      <td>{item.divisionname}</td>
                      <td>{item.areaname}</td>
                      <td>{item.territoryName}</td>
                      {/* <td>{dateTimeConvertLongString(item.joiningDate)}</td> */}
                      <td>{item.prodCode}</td>
                      <td>{`${item.prodCatagories}`}</td>
                      <td nowrap="nowrap">{item.prodName}</td>
                      <td>{parseFloat(item.prodPrice).toFixed(2)}</td>
                      <td>{parseFloat(item.targetQty).toFixed(2)}</td>
                      <td>{parseInt(item.liftingQty)}</td>
                      <td>{parseInt(item.differentQty)}</td>
                      <td>{parseFloat(item.tarVsAccPers).toFixed(2)}</td>
                      <td>{parseFloat(item.targetPrice).toFixed(2)}</td>
                      <td>{parseFloat(item.liftingPrice).toFixed(2)}</td>
                      <td>{parseFloat(item.differentPrice).toFixed(2)}</td>

                      {/* <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                      </td> */}
                     
                    </tr>
                   
                    </>
                     
                  ))}
                  {
                    targetDateList.length > 0 &&
                    <ReportFooter/>
                  }
                   
               
              </tbody>
            </table>
            </div>
           
            {!isLoading && targetDateList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
           
          </div>
        </div>

       

       
      </div>
    </>
  );
};

export default SkuWiseDemandTarget;
