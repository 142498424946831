import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
    delteProductList,
    getDistributorList,
    getProductList,
    productSelecteditem,
    itemAddInput,
    productQuantity,
    InputHandle,
    salesSubmit,
    getCustomerBalance,
    cleanRemoteSalesData,
    giftSubmit,
    getDemandInvoiceListByCustomer,
    getCustomerListDetails,
    getPromotionalProducctItemList,
    GetNewCustomerBalanceByID,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { productSubmitStausRemove } from "../../_redux/actions/SecondarySales/SecondaryListAction";

const PromotionalItem = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
    const productCost = useSelector((state) => state.remoteSales.productCost);
    const fillterProductList = useSelector((state) => state.remoteSales.filterProduct);
    const productList = useSelector((state) => state.remoteSales.productList);
    const submitLoading = useSelector((state) => state.remoteSales.submitLoading);
    const availableBalance = useSelector((state) => state.remoteSales.availableBalance);
    const restBalance = useSelector((state) => state.remoteSales.restBalance);
    const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
    const countProduct = useSelector((state) => state.remoteSales.productQuantity);
    const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);
    const submitData = useSelector((state) => state.remoteSales.submitData);
    const statusProduct = useSelector((state) => state.remoteSales.productSubmitStatus);

    const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);

  console.log('NewCustomerBalance', NewCustomerBalance)
  

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [terriory, setTerritory] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [invoiceList, setInvoiceList] = useState([]);
    const [selectInvoice, setSelectInvoice] = useState(null);
    const [selectsaleTypes, setSelectsaleTypes] = useState(null);
    const [selectremarks, setSelectremarks] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState(null);
    const [itemtype, setItemType] = useState("E");

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(getPromotionalProducctItemList(itemtype));
        dispatch(cleanRemoteSalesData());
    }, [])

    // Redirect List Page after submitting data
    useEffect(() => {
        if (statusProduct) {
            dispatch(productSubmitStausRemove());
            setTimeout(() => { history.go(0) }, 3000);
        }
    }, [statusProduct]);

    const distributorList = useSelector(
        (state) => state.remoteSales.distributorList
    );
    const changeText = (name, value) => {
        dispatch(itemAddInput(name, value));
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        var customerList = await getCustomerListDetails();
        setCustomerList(customerList.data);
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }
    function productSelected(productItem) {
        dispatch(productSelecteditem(productItem));
    }
    const delteItem = (itemId) => {
        dispatch(delteProductList(itemId));
    };
    const changeProductQuantity = (name, value) => {
        dispatch(productQuantity(name, value));
    };

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }
    const getInvoicelist = async (customerId) => {

        let invoiceNumber = await getDemandInvoiceListByCustomer(customerId);
        setInvoiceList(invoiceNumber.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const distributorDropDownData = () => {
        let options = [];
        if (customerList?.length > 0) {
            customerList.forEach((item) => {
                let itemData = {
                    value: item,
                    label: item.distribName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const getSalesValueType = (name, value) => {
        setSalesType(value);
        setValue("intDistributorId", "");
        setValue("divisionData", "");
        setValue("areaData", "");
        //dispatch(getCustomerListDetails());
        dispatch(getPromotionalProducctItemList());
    }

    const onSubmit = async (e) => {
        if (selectZone == null) {
            alert("Please select Zone");
            return false;
        } else if (selectDivision == null) {
            alert("Please select Division");
            return false;
        } else if (selectArea == null) {
            alert("Please select Area");
            return false;
        } else if (selectTerritory == null) {
            alert("Please select Territory");
            return false;
        }
        // } else if (itemDataInput.intDistributorId == "") {
        //     alert("Please select Distribution");
        //     return false;
        // }
        setDisabled(true);
        dispatch(giftSubmit(NewCustomerBalance, productList, selectZone, selectDivision, selectArea,selectTerritory,selectInvoice,"E",selectremarks));
    };

    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3 ">
                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <h3 className="mb-0 pb-0">Event Item</h3>
                            </div>
                        </div>
                    </div>
                    <hr></hr>

                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Zones</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={zoneDropdownData()} />}
                                    rules={{ required: false }}
                                    name="zoneData"
                                    register={register}
                                    // value={selectZone}
                                    value=""
                                    onChange={(option) => {

                                        divisionList(option.value);
                                        setSelectZone(option.value);
                                        setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                        //dispatch(getCustomerBalance(393737));
                                        dispatch(GetNewCustomerBalanceByID(393737));

                                    }}
                                    setValue={setValue}
                                />

                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Division</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={divisionDropdownData()} />}
                                    rules={{ required: false }}
                                    name="divisionData"
                                    register={register}
                                    value=""
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        // setType(option.value)
                                        getAreaData(option.value);
                                        setSelectDivision(option.value);
                                        setValue("areaData", "");
                                        setValue("intDistributorId", "");
                                        setValue("territory", "");

                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Region</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={areaDropdownData()} />}
                                    rules={{ required: false }}
                                    name="areaData"
                                    register={register}
                                    value=""
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        // setType(option.value)

                                        getTerritoryData(option.value)
                                        setSelectArea(option.value);

                                        setValue("intDistributorId", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>


                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Territory</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={territoryDropdownData()} />}
                                    rules={{ required: false }}
                                    name="territory"
                                    register={register}
                                    value=""
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        setSelectTerritory(option.value);
                                        dispatch(getDistributorList(option.value, salesType));

                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        {/* <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Distributor</label>
                                <RHFInput

                                    as={<Select options={distributorDropDownData()} />}
                                    rules={{ required: false }}
                                    name="intDistributorId"
                                    register={register}
                                    value=""
                                    // value={itemDataInput.intDistributorId}
                                    onChange={(option) => {
                                        inputChangeHandle("distributorName", option.label);
                                        inputChangeHandle("intDistributorId", option.value);
                                        getInvoicelist(option.value?.distribID)
                                        dispatch(getCustomerBalance(option.value?.distribID));
                                        setSelectCustomer(option)

                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div> */}
                        <div className="col-lg-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label">Remarks</label>
                                        <Form.Control as="textarea" rows="2"
                                            autocomplete="off"
                                            type="textarea"
                                            placeholder="Type Remarks"
                                            name="Remarks"
                                            onChange={(e) => {
                                                setSelectremarks(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </div>

                    </div>

                </div>
                <div className="container-fluid mb-3 containerFixed">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="paid__widget one">
                                <div className="widget__left" >
                                    {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                                    <p className="balanceleft">Available Balance</p>
                                    <p className="balanceleft">Rest Balance</p>
                                </div>
                                <div className="widget__right">
                                    {balanceLoaderNew ? (
                                        <div className="overlay-layer bg-transparent">
                                            <div className="spinner spinner-lg spinner-warning" />
                                        </div>
                                    ) : (
                                        <div>
                                            <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                                            <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="paid__widget two">
                                <div className="widget__left">
                                    <p>Credit Limit</p>
                                </div>
                                <div className="widget__right">
                                    {balanceLoaderNew ? (
                                        <div className="overlay-layer bg-transparent">
                                            <div className="spinner spinner-lg spinner-warning" />
                                        </div>
                                    ) : (
                                        <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="paid__widget three">
                                <div className="widget__left">

                                    <p>Total Product Value</p>
                                </div>
                                <div className="widget__right">
                                    <h4>{productCost.toFixed(2)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="paid__widget four">
                                <div className="widget__left">
                                    <p>Total Quantity(Pieces)</p>
                                </div>
                                <div className="widget__right">
                                    <h4>{countProduct}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
                    {productList.length > 0 ? (
                        <div className="react-bootstrap-table table-responsive mt-8">
                            <table className="table table table-head-custom table-vertical-center">
                                <thead>
                                    <th>SL</th>
                                    <th>Item ID</th>
                                    <th>Item Name</th>
                                    <th>Stock</th>
                                    <th>Quantity</th>

                                </thead>
                                <tbody>
                                    {productList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{++index}</td>
                                            <td>{item.itemID}</td>
                                            <td>{item.itemName}</td>
                                            <td>{item.stockQty}</td>
                                            <td>
                                                <Form.Group>
                                                    <Form.Control
                                                        autocomplete="off"
                                                        className="formHeight"
                                                        type="number"
                                                        placeholder="Quantity"
                                                        name="itemName"
                                                        value={item.quantity}
                                                        onChange={(e) =>
                                                            changeProductQuantity(item, e.target.value)
                                                        }
                                                    />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-lg-12 mt-5">
                                <button
                                    type="submit"
                                    disabled={disabled}
                                    className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                                >
                                    Submit
                                </button>

                            </div>
                        </div>
                    ) : null}
                </div>
            </form>
        </>
    );
};

export default PromotionalItem;
