import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Card } from "react-bootstrap";
import { PasswordUpdate } from "../../../../../app/modules/Auth/action/profileAction";
import { showToast } from "../../../../master/utils/ToastHelper";




const ProfileScreen = () => {

    const [userInput, setUserInput] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [error, setError] = useState("");
    const [sucess, setSucess] = useState("");

    const handleInputChange = (name, value) => {
        let oldData = { ...userInput };
        oldData[name] = value;
        setUserInput(oldData);
    }
    const handleSubmit = async () => {
        let oldData = { ...userInput };
        if (oldData.oldPassword == "") {
            setError("Please Type Old Password");
            return false;
        }else if(oldData.password == ""){
            setError("Please Type New  Password");
            return false;
        }else if(oldData.confirmPassword == ""){
            setError("Please Type Confirm  Password");
            return false;
        }else if(oldData.password !== oldData.confirmPassword){
            setError("Password Does not Match");
            return false;
        }else{
            setError("");
           let passwordConfirm =  await PasswordUpdate(userInput);

           setError(`${passwordConfirm.data.data}`);
        //    return false;
           if(passwordConfirm.data.code == 200){
            showToast('success', `${passwordConfirm.data.data}`);
            const data = localStorage.removeItem("userData");
            setSucess(passwordConfirm.data); 
            setTimeout(() => { 
                window.location.href = "/auth/login";  
            }, 2000); 
           }

        }

    }
    return (
        <div className="container">
            <div className="row justify-content-center">

                <div className="col-md-6">

                    <div className="profileScreen card">
                        {
                            error !== "" &&
                            <div class="alert alert-danger" role="alert">
                                {error}
                            </div>
                        }
                       
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Old Password <span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Password" onChange={(e) => handleInputChange('oldPassword', e.target.value)} />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">New Password <span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Password" onChange={(e) => handleInputChange('password', e.target.value)} />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Confirm Password <span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Confirm Password" onChange={(e) => handleInputChange('confirmPassword', e.target.value)} />
                        </div>
                        <Button type="button" class="btn btn-primary" onClick={() => handleSubmit()}>Change Password</Button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ProfileScreen;
