import React, { useEffect, useState } from "react";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import 'react-pivottable/pivottable.css';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { getCumalativeReport } from "../../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";

const AiReportDelivery = ({ currentPage, setCurrentPage }) => {
  const { register, setValue } = useForm();
  const dispatch = useDispatch();
  const [pivotState, setPivotState] = useState(null);
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  const Plot = createPlotlyComponent(window.Plotly);
  const demandlist = useSelector((state) => state.secondarySales.cumalative);
  console.log('distributor demandlist :>> ', demandlist);

  const [pivotScheme,setPivotScheme]=useState({
    hideConstructor: true,
    rows: [
      "Distributor"
     
    ],
    cols: ["soName"],
        aggregatorName: "Sum",
        vals: ["totalPrice"],
        // sorters: {
        //   "тип финансирования": sortAs([
        //     "всего млн. руб.",
        //     "фед. бюджет млн. руб.",
        //     "внебюджетные средства млн. руб."
        //   ])
        // }
  })


  useEffect(() => {
    dispatch(getCumalativeReport('2023-02-01', '2023-02-10', 1, 0, 0,0,2));
  }, [])
  


  const [data, setData] = useState([

    {
      Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      //...
    },
    {
        Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      //...
    },
    {
        Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      //...
    },
    {
        Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      //...
    },
    {
        Distributor: 'Abdullah',
      product: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor: 'Abdullah',
      product2: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value2: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6',
      Distributor1: 'Abdullah',
      product1: 'Biscuit-Cream-Funtastic Ore Cream Pineapple-Mini-25gm-Carton(48)',
      Value1: '1.6'
      //...
    },
  ]);




  return (
    <>
      
      {
        demandlist.length > 0 &&
        <table className="borderTableims">
            <PivotTableUI
            data={demandlist}
            onChange={s => setPivotState(s)}
            {...pivotState}
        />
        </table>
        
      }
     
    </>
  );
};

export default AiReportDelivery;
