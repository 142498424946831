import React, { useEffect, useState } from 'react';

const SecondarySalesPivotPage = () => {


    const [daynamicRow,setDaynamicRow]=useState([
        {
            id:1
        }
    ])

    const [distribInfo, setDistribInfo] = useState([
        {
            'distID': 401032,
            'distribCode': "7401032",
            'distribName': "Ashish Store - Joydebpur,Gazipur,Dhaka North",
            'productPrice': 1145.6674,
            'quentity': 127,
            'p_SOItemquentities': [
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                },
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                },
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                }
            ]

        },
        {
            'distID': 401032,
            'distribCode': "7401032",
            'distribName': "Ashish Store - Joydebpur,Gazipur,Dhaka North",
            'productPrice': 1145.6674,
            'quentity': 127,
            'p_SOItemquentities': [
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                },
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                },
                {
                    'approvedQuentityPic': 300,
                    'itemPrice': 3.9167,
                    'itemQuentityPic': 300,
                    'itemTotalPrice': 1175.01
                }
            ]

        },
    ]);



    useEffect(() => {
        
    }, []);


    return (
        <div className="invoice-content item-add-table">
            <table className="table table-responsive">
                <table
                    class="table table-bordered bg-white"
                    style={{ width: "100%" }}
                >
                    <thead>
                        {
                            daynamicRow.map((item,index)=>(
                                
                            <tr>
                            <th>Item Id</th>
                            <th>Item Name</th>
                            <th>Order Quntity</th>
                            <th>Price</th>
                            {
                                 daynamicRow.map((sub,subIndex)=>( 
                                     <tr>
                                        <th className='child'>Item Id</th>
                                        <th className='child'>Item Name</th>
                                        <th className='child'>Order Quntity</th>
                                        <th className='child'>Price</th>
                                    </tr>  
                                 ))
                            }
                            </tr>  

                            ))
                            
                        }
                        
                    </thead>
                    <tbody>
                        {
                            distribInfo.map((item,index)=>(
                            <tr>
                                <td>{item.distID}</td>
                                <td>{item.distribCode}</td>
                                <td>{item.distribName}</td>
                                <td>{item.quentity}</td>
                                {
                                    item.p_SOItemquentities.map((sub,subIndex)=>(
                                    
                                    <tr className='classRow'>
                                     <td className='child'>{sub.approvedQuentityPic}</td>
                                        <td className='child'>{sub.itemPrice}</td>
                                        <td className='child'>{sub.itemQuentityPic}</td>
                                        <td className='child'>{sub.itemQuentityPic}</td>
                                        <td className='child'>{sub.itemQuentityPic}</td>
                                    </tr>
                                    ))
                                }
                            </tr>

                            ))
                        }
                        
                       
                        



                    </tbody>
                </table>
            </table>
            {/* <div className="invoice-price">
          <div className="invoice-price-right-text">
            <span mt-5>Quntity</span> <span>{totalQuantity}</span>
          </div>
        </div> */}
        </div>
    )
}
export default SecondarySalesPivotPage;