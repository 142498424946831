import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import faker from 'faker';
import LoadingSpinner from '../../../../master/spinner/LoadingSpinner';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

export function CumulativeRevenueLast12Months() {

    const dispatch = useDispatch();
    const reportData = useSelector((state) => state.dashboardcart.reportData);
    const ablData = useSelector((state) => state.dashboardcart.ablData);
    const ablprice = useSelector((state) => state.dashboardcart.cumulativeRev);
    const abldate = useSelector((state) => state.dashboardcart.abldate);
    const isLoading = useSelector((state) => state.dashboardcart.monthtomonthLoading);
    const cumSecondarydaryDiff = useSelector((state) => state.dashboardcart.cumSecondarydaryDiff);
    const cumulativeRev = useSelector((state) => state.dashboardcart.cumulativeRev);
    console.log('cumulativeRev', cumulativeRev)

    const verticleData = cumulativeRev;
    const horizontalData = abldate;

    let nf = new Intl.NumberFormat('en-BD');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: true,
            }
        },
        scales: {
            y: {
              title: {
                display: true,
                text: 'Millions',
                font: {
                  size: 12,
                },
              },
            },
          },
    };

    const labels = horizontalData;

    const data = {
        labels,
        datasets: [
            // {
            //     type: 'line',
            //     label: [reportData.lineName]+' Growth',
            //     borderColor: 'rgb(255, 99, 132)',
            //     borderWidth: 2,
            //     fill: false,
            //     data: cumSecondarydaryDiff,
            //     yAxisID: 'y1',
            //     datalabels: {
            //         display: true,
            //         color: '#544949',
            //         font: {
            //             weight: 'bold',
            //             size: 12
            //         },
            //     }
            //     },
            {
                type: 'bar',
                label: [reportData.lineName]+' Cumulative Revenue',
                backgroundColor: '#0b8793',
                data: verticleData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'y',
                datalabels: {
                    display: true,
                    color: 'white',
                    align: 'bottom',
                    anchor: 'end',
                    rotation: 270,
                    formatter: function (value, context) {
                        return 'BDT ' + nf.format(value) ;
                    }
                }
            },
        ],

    };

    return (

        <div className="card mb-0 ">
            <span className="spineercolor mt-5 mb-5">{isLoading && <LoadingSpinner />}</span>
            <>
                {!isLoading && (
                    <>
                        <div className='text-center'><h5>Cumulative Revenue Last 12 Months - At lac</h5></div>
                        <Chart options={options} data={data} height={137}  plugins={[ChartDataLabels]} />
                    </>
                )}
            </>
        </div>


    );
}
