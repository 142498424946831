import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from '../../../../master/spinner/LoadingSpinner';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartLocationWIseSales = () => {
    const donutData = useSelector(
        (state) => state.dashboardcart.donutData
    );
    console.log('donutData :>> ', donutData);
    const zoneDataForDonut = useSelector((state) => state.dashboardcart.zoneDataForDonut);
    const totalForDonut = useSelector((state) => state.dashboardcart.totalForDonut);
    console.log('totalForDonut', totalForDonut)
    const reportData = useSelector((state) => state.dashboardcart.reportData);
    const locationTotalQty = useSelector((state) => state.dashboardcart.locationTotalQty);
    const donutLoading = useSelector((state) => state.dashboardcart.donutLoading);
    const Measurement = reportData.measurement;
    
    const data = {
        labels: zoneDataForDonut,
        datasets: [
            {
                data: donutData,
                backgroundColor: [
                    "#0b8793",
                    "#00C9FF",
                    "#fcb045",
                    "#ff9068",
                    "#4e6af5",
                    "#e86193",
                    "#c973ff",
                    "#927694",
                ],
                borderColor: [
                    "#0b8793",
                    "#00C9FF",
                    "#fcb045",
                    "#ff9068",
                    "#4e6af5",
                    "#e86193",
                    "#c973ff",
                    "#927694",
                ],
                borderWidth: 1,
                labels: zoneDataForDonut,
            },
        ],
    };
    const options = {
        responsive: true,
        chart: {
            width: 50,
        },
        plugins: {
            legend: {
                position: "bottom",
                display: false,
                
            },
            datalabels: {
                display: true,
                color: "white",
                formatter: (value, ctx) => {
                    let dataset = ctx.chart.data.datasets[ctx.datasetIndex];
                    let totalForDonut = dataset.data.reduce((a, b) => a + b, 0);
                    let percentage = ((value / totalForDonut) * 100).toFixed(2) + "%";
                    return `${dataset.labels[ctx.dataIndex]}\n${value}  L \n(${percentage})`;
                },
            },
        },

        // scales: {
        //   y: {
        //     title: {
        //       display: true,
        //       text: "Millions",
        //       font: {
        //         size: 12,
        //       },
        //     },
        //   },
        // },
        responsive: [
            {
                breakpoint: 780,
                options: {
                    chart: {
                        width: 100,
                    },
                },
            },
            {
                breakpoint: 400,
                options: {
                    chart: {
                        width: 50,
                    },
                },
            },
        ],
    };
    return (
        <div className="mb-0 card">
            <span className="spineercolor mt-5 mb-5">
                {donutLoading && <LoadingSpinner />}
            </span>
            <>
                {!donutLoading && (
                    <>
                        <div className="locatioChartHead">
                            <div className="text-center">
                                <h5>Location Wise Sales - At lac</h5>
                            </div>
                        </div>
                        <div className="cuswidth3">
                            <div className='locationMother mb-6'>
                                <div className="location locationPosition">
                                    <h6>
                                        Total: {Measurement == 1 ? totalForDonut + "L" : locationTotalQty + "L"}
                                    </h6>
                                    <h6>100%</h6>
                                </div>
                                <Doughnut options={options} data={data} plugins={[ChartDataLabels]} />
                            </div>
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export default DoughnutChartLocationWIseSales;

