import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {getAreaListByDivision,getDivisionListByZone,getTerritory,getZoneList,} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import { DamageSummeryReportSKUWise } from "../../_redux/actions/RemoteSales/DamageAction";

const DamageSummaryReportSKUwise = () => {
    const { register, setValue } = useForm();

    const dispatch = useDispatch();
  const damageData = useSelector((state) => state.damage.dDataSKUWise);
  const damageSummaryLoading = useSelector((state) => state.damage.dDataSKUWiseLoading);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };
        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    dispatch(DamageSummeryReportSKUWise(fromDate, tDate, idZone, idDivision, idArea, idTerritory));
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSelectGeoType(value);
  }

  const catdata = [
    { value: "1", label: "Cake" },
    { value: "2", label: "Biscuit" },
];

  
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
            <span className="button-010">Damage Summary Report SKU Wise</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <DownloadDropdown excelname='Damage Summary Report SKU Wise' refvalue={componentRef} />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-md-3 mt-5 mb-5">
          <button className="button-621" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)} >Show Report</button>
            {/* <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectCategory)}>Show Report</Button> */}
          </div>

        </div>
        <div>
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {damageSummaryLoading && <LoadingSpinner text="Loading List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              <div
                className="stickyTable"
                style={{ height: damageData?.length > 0 ? 600 + "px" : 0 }}
              >
                {/* <table className="table table-head-custom borderTableims" id="table-to-xls" ref={componentRef}> */}
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead className="bg-light" >

                    <tr>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">PRODUCT CATEGORY</th>
                      <th scope="col">PACK NAME</th>
                      <th scope="col">QUALITY PROBLEM QTY</th>
                      <th scope="col">QUALITY PROBLE VALUE</th>
                      <th scope="col">MARKET DAMAGE QTY</th>
                      <th scope="col">MARKET DAMAGE VALUE</th>
                      <th scope="col">EMPPTY QTY</th>
                      <th scope="col">EMPPTY VALUE</th>
                      <th scope="col">AIRLESS QTY</th>
                      <th scope="col">AIR LESS VALUE</th>
                      <th scope="col">DATE MISSING QTY</th>
                      <th scope="col">DATE MISSING VALUE</th>
                      <th scope="col">RAT CUT QTY</th>
                      <th scope="col">RAT CUT VALUE</th>
                      <th scope="col">BROKEN QTY</th>
                      <th scope="col">BROKEN VALUE</th>
                      <th scope="col">DATE EXPIRED QTY</th>
                      <th scope="col">DATE EXPIRED VALUE</th>
                      <th scope="col">TOTAL QTY</th>
                      <th scope="col">TOTAL VALUE</th>
                      <th scope="col">TOTAL DELIVERY QTY</th>
                      <th scope="col">TOTAL DELIVERY value</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {
                      damageData?.length > 0 &&
                      damageData?.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.productId}</td>
                            <td>{item.productName}</td>
                            <td>{item.productCategory}</td>
                            <td>{item.packName}</td>
                            <td>{item.qualiProblemQty}</td>
                            
                            <td>{parseFloat(item.qualiProbleValue).toFixed(2)}</td>
                            <td>{item.marketDamageQty}</td>
                            <td>{parseFloat(item.marketDamageValue).toFixed(2)}</td>
                            <td>{item.empptyQtyQty}</td>
                            <td>{parseFloat(item.empptyValue).toFixed(2)}</td>
                            <td>{item.airLessQty}</td>
                            <td>{parseFloat(item.airLessValue).toFixed(2)}</td>
                            <td>{item.dateMissingQty}</td>
                            <td>{parseFloat(item.dateMissingValue).toFixed(2)}</td>
                            <td>{item.ratCutQty}</td>
                            <td>{parseFloat(item.ratCutValue).toFixed(2)}</td>
                            <td>{item.brokenQty}</td>
                            <td>{parseFloat(item.brokenValue).toFixed(2)}</td>
                            <td>{item.dateExpiredQty}</td>
                            <td>{parseFloat(item.dateExpiredValue).toFixed(2)}</td>
                            <td>{item.totalQty}</td>
                            <td>{parseFloat(item.totalValue).toFixed(2)}</td>
                            <td>{item.totalLifting==""?0:item.totalLiftingQty}</td>
                            <td>{item.totalLifting==""?0:item.totalLifting}</td>
                            

                          </tr>

                        </>
                      ))}


                  </tbody>
                </table>
              </div>
              {!damageSummaryLoading && damageData.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! List Not Found.
                </div>
              )}
            </div>
          </div>

        </div>




      </div>
    </>
  );
};

export default DamageSummaryReportSKUwise;
