import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.GET_SEARCH_DATA_INPUT, payload: formData });
};
export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const submitStatusRemove = () => (dispatch) => {
  dispatch({ type: Types.ZONE_SUBMIT_STATUS_REMOVE, payload: null });
};
export const productSubmitStausRemove = () => (dispatch) => {
  dispatch({ type: Types.PRODUCT_SUBMIT_STATUS_REMOVE, payload: null });
};
export const handleSubmit = (inputData) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/ZoneEntry?ZoneName=${inputData.zoneName}`;
    axios
      .post(postUrl)
      .then(function (response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.messege);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({
    type: Types.ZONE_SAVE,
    payload: responseList,
  });
};
export const getDistributorList = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&AreaID=${areaId}&DistribCat=${salesTypeId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const EditDateGet = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_SECONDARY_SALES_LIST_EDIT_DATA, payload: item });
};

export const getCustomerBalance = (customerId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};

export const getProductList = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnit?unitId=98&DistribCat=${salesType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};

export const getSalesList = (fromDate,zoneId=0,divisionId=0, areaId=0,territoryId=0) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondarysalesofSObyArea?`;
  if (zoneId !== null) {
    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&SDate=${fromDate}`
  }

  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SECONDARY_SALES_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const getSalesListDemo = (areaid, fromDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/GetSecondarysalesofSObyAreaTemp?AreaID=${areaid}&SDate=${fromDate}`;

  await axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SECONDARY_SALES_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const showApprovedReport = (fromDate, todate, areaId, salesTypeId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReport?FromDate=${fromDate}&ToDate=${todate}&AreaID=${areaId}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_SALES_REPORT, payload: responseList });
};
export const getCustomerProductDelivery = (areaid, fromDate, toDate) => async (dispatch) => {

  let responseList = {
    isPProductLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReport?AreaID=${areaid}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isPProductLoading = false
  });
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
};
export const getSalesListDetails = (soPoint, salesDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondarysalesofSODetailsbyAreabydate?SOPoint=${soPoint}&SSDate=${salesDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_ORDER_DETAILS, payload: responseList });
};
export const getSalesListDetailsDemo = (soEnroll, salesDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondarysalesofSODetailsbyAreabydateDemo?SOEnroll=${soEnroll}&SSDate=${salesDate}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_ORDER_DETAILS, payload: responseList });
};
export const getSalesApprovedDetails = (invoiceId) => async (dispatch) => {
  let responseList = {
    approvedLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReportDetails?InvoiceID=${invoiceId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.approvedLoading = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_DETAILS, payload: responseList });
};
export const getDistributorDeliveryDetails = (customerId, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
};
export const checkgetDistributorDeliveryDetails = async (customerId, fromDate, toDate) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });

};

export const getUpdateProductList = (soEnroll, EntryDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    dataProd:[],
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetItemforSSUpdatebyASM?SOPoint=${soEnroll}&EntryDAte=${EntryDate}`;
  await axios.get(url).then((res) => {
   
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SECONDARY_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};
export const handleMemoInput = (value,name) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.HANDLE_MEMO_INPUT, payload: formData });
};

export const getFebUpdateProductList = (soEnroll, EntryDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetFebItemforSSUpdatebyASM?SOPoint=${soEnroll}&EntryDAte=${EntryDate}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SECONDARY_SALES_ORDER_FEB_UPDATE_PRODUCT,
    payload: responseList,
  });
};

export const productSelecteditem = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_SELECTED, payload: item });
};
export const delteProductList = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_DELETE, payload: item });
};
export const productQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_QUANTITY, payload: formData });
};
export const productUpdateInputQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_UPDATE_QUANTITY, payload: formData });
};

export const productFebUpdateInputQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_UPDATE_QUANTITY_FEB, payload: formData });
};


export const salesSubmit = (submitData, productList, selectZone, selectDivision, selectArea) => async (dispatch) => {
  // return false;

  let newList = [];

  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });



  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
// fund collection
export const fundSubmit = (submitData, selectZone, selectDivision, selectArea,selectTerritory,fundDate,fundAmount,fundDetails) => async (dispatch) => {
  // return false;

  let { zoneID, divisionID, areaID, territoryID, fundDate, fundAmount } = fundDetails

  let newList = [];
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}/api/RemoteOrder/InsertFundCollection?ZoneID=${zoneID}&DivisionID=${divisionID}&AreaID=${areaID}&TerritoryId=${territoryID}&fundDate=${fundDate}&fundAmount=${fundAmount}`;

    axios
      .post(postUrl, newList)
      .then(function (response) {

        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.FUND_SUBMIT, payload: responseList });
};
// fund collection


export const salesUpdate = (soPoint,memoData, date, productList) => async (dispatch) => {

  const {memoId=0,orderValue=0,totalSkuQty=0,totalmemoQty=0,visitedOutlet=0} = memoData;

  let newList = [];
 
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemID: 0,
      itemName: '',
      intUomId: 0,
      prodTrdprice: 0,
      prodTrdpricebypic: 0,
      itemUOMqty: 0,
      itemQuentityPic: 0,
      upQty:0,
    };
    const quantity = productList[i].quantity;
    if (productList[i].itemQuentityPic > 0) {
      productData.itemID = productList[i].itemID;
      productData.itemName = productList[i].itemName;
      productData.intUomId = productList[i].intUomId;
      productData.prodTrdprice = productList[i].prodTrdprice;
      productData.prodTrdpricebypic = productList[i].prodTrdpricebypic;
      productData.itemUOMqty = parseInt(productList[i].itemUOMqty);
      productData.upQty = parseInt(productList[i].upQty);
      productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      newList.push(productData);
    }

  }
  if (newList.length == 0) {
    showToast("error", "Please type at least One quantity");
    return false;
  }
  
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/SecondarySalesUpdatebyASM?SOPoint=${soPoint}&memoId=${memoId}&orderValue=${orderValue}&totalmemoQty=${totalmemoQty}&totalSkuQty=${totalSkuQty}&visitedOutlet=${visitedOutlet}&&SSDate=${date}`;
 
    axios
      .put(postUrl, newList)
      .then(function (response) {
       

        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};

export const salesFebUpdate = (soPoint, date, productList) => async (dispatch) => {
  let newList = [];
  
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemID: 0,
      itemName: '',
      intUomId: 0,
      prodTrdprice: 0,
      prodTrdpricebypic: 0,
      itemUOMqty: 0,
      itemQuentityPic: 0
    };
    const quantity = productList[i].quantity;
    if (productList[i].itemQuentityPic > 0) {
      productData.itemID = productList[i].itemID;
      productData.itemName = productList[i].itemName;
      productData.intUomId = productList[i].intUomId;
      productData.prodTrdprice = productList[i].prodTrdprice;
      productData.prodTrdpricebypic = productList[i].prodTrdpricebypic;
      productData.itemUOMqty = parseInt(productList[i].itemUOMqty);
      productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      newList.push(productData);
    }

  }
  if (newList.length == 0) {
    showToast("error", "Please type at least One quantity");
    return false;
  }



  // const { intCustomer, monCustBalance, coaid, intsalesofficeid } = submitData;
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/SecondaryFebSalesUpdatebyASM?SOPoint=${soPoint}&SSDate=${date}`;
    axios
      .put(postUrl, newList)
      .then(function (response) {

        //   responseList.data = response.data;

        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};

export const salesDelete = (item, areaId) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_SECONDARY_SALES_TITEM, payload: isLoading })
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/SecondarySalesDeletebyASM?SOPoint=${item.soPointID}&SSDate=${item.sDate}`
  axios.delete(url)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SECONDARY_SALES_TITEM, payload: false });
        dispatch(getSalesList(areaId, item.sDate))
      }
    })
}
export const FebsalesDelete = (item, areaId) => (dispatch) => {
  let isLoading = true;
  dispatch({ type: Types.DELETE_SECONDARY_SALES_TITEM, payload: isLoading })
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/TempSecondarySalesDeletebyASM?SOPoint=${item.soPointID}&SSDate=${item.sDate}`
  axios.delete(url)
    .then((res) => {
      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SECONDARY_SALES_TITEM, payload: false });
        dispatch(getSalesList(areaId, item.sDate))
      }
    })
}

export const cleanRemoteSalesData = (id) => (dispatch) => {
  dispatch({ type: Types.CLEAR_REMOTE_SALES_DATA, payload: null });
}
