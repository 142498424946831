import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { dateTimeConvertLongString } from '../../../../master/PrintDwopdown/DateHelper';


export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};


const SOQtyRegionActiveInactiveView = (itemDetailnIfo) => {

    const territoryActiveInactive = useSelector((state) => state.target.territoryActiveInactive);
    console.log('territoryActiveInactive :>> ', territoryActiveInactive);


    return (
        <div className='card fullcardbg m-3' style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
        }}>
            <div className='p-3 text-center'>
                <span><strong>SO QTY DETAILS</strong></span><br></br>
                <span><strong>REGION NAME :</strong> {itemDetailnIfo?.itemDetailnIfo?.zone_name}</span>, <span><strong>SHOW QTY :</strong> {itemDetailnIfo?.itemDetailnIfo?.soQty}</span>
                <hr></hr>
            </div>
            <div className="row mt-7">

                <div className='col-lg-6 '>
                    <div className="table-responsive pl-5 pr-5 pb-5 ">

                        <table id="table-to-xls" className="printtablenew1 text-right float-right ">
                            {/* <span className='text-right float-right '>Active: {territoryActiveInactive?.soDateWiseListToDateNull?.length || 0}</span> */}
                            <tbody>
                                <>
                                    <tr className="backcolor3">
                                        <th colSpan="3" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>Active: {territoryActiveInactive?.soDateWiseListToDateNull?.length || 0}</th>
                                    </tr>
                                    {territoryActiveInactive?.soDateWiseListToDateNull?.length > 0 && <>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            {/* <th>SO POINT ID</th> */}
                                            <th>SO POINT</th>
                                            <th>EMP ID</th>
                                            <th>FROM DATE</th>
                                            <th>TO DATE</th>

                                        </tr></>}


                                    {territoryActiveInactive?.soDateWiseListToDateNull?.length > 0 &&
                                        territoryActiveInactive.soDateWiseListToDateNull
                                            .sort((a, b) => b.rank - a.rank)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        {/* <td>{item.delPointId}</td> */}
                                                        <td>{item.delPointName}</td>
                                                        <td>{item.empId}</td>

                                                        <td>{dateTimeConvertLongString(item.fromDate)}</td>
                                                        <td>{dateTimeConvertLongString(item.toDate)}</td>

                                                    </tr>
                                                );
                                            })}
                                </>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className="table-responsive pl-5 pr-5 pb-5">
                        {/* <span>InActive: {territoryActiveInactive?.soDateWiseListToDateNotNull?.length || 0}</span> */}
                        <table id="table-to-xls" className="printtablenew1">
                            <tbody>
                                <>
                                    <tr className="backcolor3">
                                        <th colSpan="3" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>InActive: {territoryActiveInactive?.soDateWiseListToDateNotNull?.length || 0}</th>
                                    </tr>
                                    {territoryActiveInactive?.soDateWiseListToDateNotNull?.length > 0 && <>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            {/* <th>SO POINT ID</th> */}
                                            <th>SO POINT</th>
                                            <th>EMP ID</th>
                                            <th>FROM DATE</th>
                                            <th>TO DATE</th>

                                        </tr></>}


                                    {territoryActiveInactive?.soDateWiseListToDateNotNull?.length > 0 &&
                                        territoryActiveInactive.soDateWiseListToDateNotNull
                                            .sort((a, b) => b.rank - a.rank)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        {/* <td>{item.delPointId}</td> */}
                                                        <td>{item.delPointName}</td>
                                                        <td>{item.empId}</td>
                                                        <td>{dateTimeConvertLongString(item.fromDate)}</td>
                                                        <td>{dateTimeConvertLongString(item.toDate)}</td>

                                                    </tr>
                                                );
                                            })}
                                </>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SOQtyRegionActiveInactiveView

