import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from "react-chartjs-2";
import faker from 'faker';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export function NorthSouthChart() {


  useEffect(() => {
  }, []);


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,
        text: 'Per day target',
      },
      grid: {
        position: 'front'
      }
    },
  };



  const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

  const data = {
    labels,
    datasets: [
      {
        label: 'বিক্রয়',
        data: [400,200,300,700,300,100,300,2000],
        backgroundColor: '#FFFFFF',
        borderColor: '#733b09',
        pointBorder: '#FFFFFF',
        tension: '0.2',
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: 'টার্গেট',
        //data: labels.map(() => faker.datatype.number({ min: dashboardInfo?.totalPrimaryTargetValue/31 , max: dashboardInfo?.targetQty/31 })),
        data: labels.map(() => faker.datatype.number({ min:1000, max: 1000 })),
        backgroundColor: '#44546a',
        borderColor: '#44546a',
        pointBorder: '#44546a',
        borderDash: [5, 5],
        pointRadius: 0,
        pointStyle: 'circle',
      },
    ],

  };
  return (

    <div className="">
            <Line options={options} data={data} height={230} width={770} />
    </div>


  );
}
