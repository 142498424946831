
export const GetDdl = (data,value,name) => {
    let options = [];
    if (data) {
      data.forEach((item) => {
        let itemData = {
          value: item[value],
          label: item[name],
        };
        options.push(itemData);
      });
    }
    return options;
  };