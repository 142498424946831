import React, { useEffect, useState } from "react";


const CustomPagination = (props) => {

    const {data,itemsPerPage}=props;
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = data + itemsPerPage;
    let count = data.length / itemsPerPage;
    console.log('data.slice(itemOffset, endOffset', data.slice(itemOffset, endOffset));
    
    // setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [data,itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (<></>
    // <ReactPaginate
    //   nextLabel="next >"
    //   onPageChange={handlePageClick}
    //   pageRangeDisplayed={5}
    //   pageCount={pageCount}
    //   previousLabel="< previous"
    //   pageClassName="page-item"
    //   pageLinkClassName="page-link"
    //   previousClassName="page-item"
    //   previousLinkClassName="page-link"
    //   nextClassName="page-item"
    //   nextLinkClassName="page-link"
    //   breakLabel="..."
    //   breakClassName="page-item"
    //   breakLinkClassName="page-link"
    //   containerClassName="pagination"
    //   activeClassName="active"
    //   renderOnZeroPageCount={null}
    // />
  );
};
export default CustomPagination;
