import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DistributorReportsFilterBr from "../../../information/components/DistributorReports/DistributorReportsFilterBr";
import DistributorReportBrDataTable from "./DistributorReportBrDataTable";


const DistributorReportsBrContainer = () => {
  const dispatch = useDispatch();

  return (
   <>
              <DistributorReportsFilterBr />
              <DistributorReportBrDataTable />
           
      </> 
  );
};

export default DistributorReportsBrContainer;
