import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { cleanTargetSalesData, getAsmAchivementReport } from "../../_redux/actions/Report/ReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaSearch } from "react-icons/fa";

const AsmWiseSecondaryTarget = () => {

  const targetDateList = useSelector((state) => state.target.targetDate);
  const totalTarget = useSelector((state) => state.target.totalData);

  const isProductLoading = useSelector((state) => state.target.targetLoading);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  const componentRef = useRef();
  const {  setValue } = useForm();
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cleanTargetSalesData());
    initialData();
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const initialData = async () => {
    
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }
  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };

  const showValidation = () => {
    let conFromDate = skuDate.fromDate;
    let conToDate = skuDate.todate;
    if (conFromDate == null || conFromDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (conToDate == null || conToDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const handleListAction = () => {
    const validation = showValidation();
    if (validation) {

      let conFromDate = skuDate.fromDate;
      let conToDate = skuDate.todate;
      dispatch(getAsmAchivementReport(conFromDate, conToDate, selectZone, selectDivision, selectArea, selectTerritory));
    }
  }


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Target Vs Acheivement(TSM Wise)</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Target Vs Acheivement(TSM Wise)' />
            {/* <Form.Check
              type="checkbox"
              label="Time wise Data"
              onChange={(e) => handleCheckbox(e)}
            /> */}
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                // value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0 ">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  //getTerritoryData(option?.value);
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>


          <div className="col-md-3 mb-4">
            <button type="button" className="btn btn-success btn-sm mt-4" onClick={() => handleListAction()}>
              <FaSearch />  Show Report
            </button>
          </div>
        </div>
        {/* {targetDateList.length > 0 &&
          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Cake</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseInt(totalTarget.cookisTargetQty)}</span>
                      <span>Secondary Quantity:  {parseInt(totalTarget.cookisLiftingQty)}</span>
                      <span>Target value:  {parseFloat(totalTarget.cookisTargetPrice).toFixed(2)}</span>
                      <span>Secondary value:  {parseFloat(totalTarget.cookisLiftingPrice).toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Cookies & Biscuit</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseInt(totalTarget.cookisandBiscuitTargetQty)}</span>
                      <span>Secondary Quantity:  {parseInt(totalTarget.cookisandBiscuitLiftingQty)}</span>
                      <span>Target value:  {parseFloat(totalTarget.cookisandBiscuitTargetPrice).toFixed(2)}</span>
                      <span>Secondary value:  {parseFloat(totalTarget.cookisandBiscuitLiftingPrice).toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Total</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseInt(totalTarget.totalTargetQty)}</span>
                      <span>Secondary Quantity:  {parseInt(totalTarget.totalLiftingQty)}</span>
                      <span>Target value:  {parseFloat(totalTarget.totalTargetPrice).toFixed(2)}</span>
                      <span>Secondary value:  {parseFloat(totalTarget.totalLiftingPrice).toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } */}

        {/* <div className="card-body" >
          <div className="container-fluid p-0 text-center">
            <div className="table-responsive bg-light">
              <div>
                <table className="table table-head-custom table-vertical-center item-add-table borderTableims" id="table-pdf" ref={componentRef}>
                  <tbody>
                    {targetDateList.length > 0 &&
                      <tr>
                        <td>
                          BISCUIT: {formatValueWithCommas(parseInt(totalTarget.biscuitTargetPrice))} <br></br>
                          COOKIES: {formatValueWithCommas(parseInt(totalTarget.cookisTargetPrice))} <br></br>
                          CAKE: {formatValueWithCommas(parseInt(totalTarget.cakeTargetPrice))} <br></br>
                          WAFER: {formatValueWithCommas(parseInt(totalTarget.waferTargetPrice))} <br></br>
                          CUMULATIVE TARGET: {" "}
                          {formatValueWithCommas((
                            parseInt(totalTarget.biscuitTargetPrice) +
                            parseInt(totalTarget.cookisTargetPrice) +
                            parseInt(totalTarget.cakeTargetPrice) +
                            parseInt(totalTarget.waferTargetPrice)
                          ))} <br></br>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}

        {targetDateList?.length > 0 &&

          <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
            <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
              {/* Target Section */}
              <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>TARGET</h5>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseInt(totalTarget.biscuitTargetPrice))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseInt(totalTarget.cookisTargetPrice))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.cakeTargetPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.waferTargetPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.snacksTargetPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.chanachurTargetPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Target Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>
                    {numberFormatDecemal((
                      parseInt(totalTarget.biscuitTargetPrice) +
                      parseInt(totalTarget.cookisTargetPrice) +
                      parseFloat(totalTarget.cakeTargetPrice) +
                      parseFloat(totalTarget.waferTargetPrice) +
                      parseFloat(totalTarget.snacksTargetPrice) +
                      parseFloat(totalTarget.chanachurTargetPrice)
                    ).toFixed(2))}
                  </span>
                </div>
              </div>

              {/* IMS Section */}
              <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>IMS</h5>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseInt(totalTarget.biscuitLiftingPrice))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseInt(totalTarget.cookisLiftingPrice))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.cakeLiftingPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.waferLiftingPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.snacksLiftingPrice + totalTarget.chanachurLiftingPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{numberFormatDecemal(parseFloat(totalTarget.chanachurLiftingPrice).toFixed(2))}</span>
                </div>
                <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total IMS Value</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>
                    {numberFormatDecemal((
                      parseInt(totalTarget.biscuitLiftingPrice) +
                      parseInt(totalTarget.cookisLiftingPrice) +
                      parseFloat(totalTarget.cakeLiftingPrice) +
                      parseFloat(totalTarget.waferLiftingPrice) +
                      parseFloat(totalTarget.snacksLiftingPrice) +
                      parseFloat(totalTarget.chanachurLiftingPrice)
                    ).toFixed(2))}
                  </span>
                </div>
              </div>

              {/* Achievement Section */}
              <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#fff3cd", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#ffc107", fontSize: "14px", lineHeight: "10px" }}>ACHIEVEMENT</h5>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{parseInt(((totalTarget.biscuitLiftingPrice) / (totalTarget.biscuitTargetPrice)) * 100)}%</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{parseInt(((totalTarget.cookisLiftingPrice) / (totalTarget.cookisTargetPrice)) * 100)}%</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{parseInt(((totalTarget.cakeLiftingPrice) / (totalTarget.cakeTargetPrice)) * 100)}%</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>{parseInt(((totalTarget.waferLiftingPrice) / (totalTarget.waferTargetPrice)) * 100)}%</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>
                    {(() => {
                      const percentage = parseInt(((totalTarget.snacksLiftingPrice) / (totalTarget.snacksTargetPrice)) * 100);
                      return isNaN(percentage) ? 0 + "%" : `${percentage}%`;
                    })()}
                  </span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>
                    {(() => {
                      const percentage = parseInt(((totalTarget.chanachurLiftingPrice) / (totalTarget.chanachurTargetPrice)) * 100);
                      return isNaN(percentage) ? 0 + "%" : `${percentage}%`;
                    })()}
                  </span>
                </div>
                <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Achievement</span>
                  <span style={{ float: "right", fontSize: "13px" , color: '#050f26', fontWeight: '500'}}>
                    {(
                      (
                        parseInt(totalTarget.biscuitLiftingPrice) +
                        parseInt(totalTarget.cookisLiftingPrice) +
                        parseFloat(totalTarget.cakeLiftingPrice) +
                        parseFloat(totalTarget.waferLiftingPrice) +
                        parseFloat(totalTarget.snacksLiftingPrice) +
                        parseFloat(totalTarget.chanachurLiftingPrice)
                      ) /
                      (
                        parseInt(totalTarget.biscuitTargetPrice) +
                        parseInt(totalTarget.cookisTargetPrice) +
                        parseFloat(totalTarget.cakeTargetPrice) +
                        parseFloat(totalTarget.waferTargetPrice) +
                        parseFloat(totalTarget.snacksTargetPrice) +
                        parseFloat(totalTarget.chanachurTargetPrice)
                      ) * 100
                    ).toFixed(2)}%
                  </span>
                </div>
              </div>
            </div>
          </div>




        }


        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

            <div
              className="stickyTable"
              style={{ height: targetDateList?.length > 0 ? 800 + "px" : 0 }}
            >
              {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>

                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">SKU CODE</th>
                    <th scope="col">SKU NAME</th>
                    <th scope="col">PRODUCT CATEGORIES</th>
                    <th scope="col">TARGET(CTN)</th>
                    <th scope="col">TARGET(IN TK)</th>
                    <th scope="col">SECONDARY(CTN)</th>
                    <th scope="col">SECONDARY(IN TK)</th>
                    <th scope="col">DIFFERENCE(IN CTN)</th>
                    <th scope="col">DIFFERENCE(IN TK)</th>
                    <th scope="col">TARGET VS ACH(IN TK %)</th>


                  </tr>
                </thead>
                <tbody>
                  {targetDateList?.length > 0 &&
                    targetDateList?.map((item, index) => (
                      <>
                        <tr className="alert" style={{ background: '#EAEAEA' }}>
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td />
                          <td />
                          <td />
                          <td>{Math.round(item?.totalReport.targetQty)}</td>
                          <td>{Math.round(item?.totalReport.targetPrice)}</td>
                          <td>{Math.round(item?.totalReport.liftingQty)}</td>
                          <td>{Math.round(item?.totalReport.liftingPrice)}</td>
                          <td>{Math.round(item?.totalReport.differentQty)}</td>
                          <td>{Math.round(item?.totalReport.differentPrice)}</td>
                          <td>{Math.round(item?.totalReport.tarVsAccPers)}</td>

                        </tr>
                        {
                          item?.itemReport.map((product, subindex) => (
                            <tr>
                              <td>{item.zoneName}</td>
                              <td>{item.divisionName}</td>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                              <td>{product.prodCode}</td>
                              <td>{product.prodName}</td>
                              <td>{product.prodCatagories}</td>
                              <td>{Math.round(product.targetQty)}</td>
                              <td>{Math.round(product.targetPrice)}</td>
                              <td>{Math.round(product.liftingQty)}</td>
                              <td>{Math.round(product.liftingPrice)}</td>
                              <td>{Math.round(product.differentQty)}</td>
                              <td>{Math.round(product.differentPrice)}</td>
                              <td>{Math.round(product.tarVsAccPers)}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}


                </tbody>
              </table>
            </div>
            {/* {!isProductLoading && targetDateList?targetDateList[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
          </div>
        </div>




      </div>
    </>
  );
};

export default AsmWiseSecondaryTarget;
