import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import { InsertIMSFocudProduct } from '../../_redux/actions/SecondarySales/FundAction';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';

const InsertFocusProduct = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    // Handle file selection (from file input or drag-and-drop)
    const handleFileChange = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    };

    // Parse the Excel file and prepare the data
    const excelDateToJSDate = (serial) => {
        const epoch = new Date(Date.UTC(1899, 11, 30)); // Excel's "zero" date (31st Dec 1899)
        return new Date(epoch.getTime() + serial * 86400000); // Add the serial number as days
    };

    const parseExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            const sheet = workbook.Sheets['Sheet1'];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const products = jsonData.slice(1).map((row) => {
                let month = row[0];
                if (typeof month === 'number') {
                    month = excelDateToJSDate(month);
                }

                return {
                    month: month.toISOString(),
                    productId: row[1],
                };
            });

            uploadProducts(products);
        };

        reader.readAsBinaryString(file);
    };

    const uploadProducts = async (products) => {
        setIsLoading(true); // Set loading state before dispatching the action
        try {
            // Dispatching the action and awaiting the response
            const response = await dispatch(InsertIMSFocudProduct(products));

            // Check if the response contains a warning or error
            if (response.status === false && response.code === 400) {
                setMessage(response.data); // Show warning (e.g., duplicate products)
            } else if (response.status === false && response.code === 201) {
                setMessage('Focus List not created due to some other error.');
            } else if (response.status === true) {
                setMessage('Focus List inserted successfully!');
            }
        } catch (error) {
            setMessage('An error occurred while uploading the file.');
        } finally {
            setIsLoading(false); // Reset the loading state after the response is received
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (file) {
            parseExcel(file); // Trigger the parsing and upload
        } else {
            setMessage('Please select an Excel file to upload.');
        }
    };

    // Handle drag and drop (using react-dropzone)
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileChange,
        accept: '.xlsx, .xls',
        multiple: false,
    });

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <span style={styles.headerText}>Upload Focus Products</span>
            </div>

            <form onSubmit={handleSubmit} style={styles.form}>
                {/* Drag-and-Drop Section */}
                <div {...getRootProps()} style={styles.dropzone}>
                    <input {...getInputProps()} />
                    <svg
                        fill="#007bff"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={styles.dropzoneIcon}
                    >
                        <path
                            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 18L8 14H10V10H14V14H16L12 18Z"
                        ></path>
                    </svg>
                    <span style={styles.dropzoneText}>
                        {file ? file.name : 'Drag & Drop Excel File or Click to Select'}
                    </span>
                </div>

                <button
                    type="submit"
                    disabled={isLoading || !file}
                    style={styles.upload}
                >
                       <svg style={styles.icon} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15"
                        stroke="#fff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                </svg>
                    {isLoading ? (
                        <span style={styles.loadingText}>Uploading...</span>
                    ) : (
                        ' Upload Excel'
                    )}
                </button>
            </form>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        width: '100%',
        maxWidth: '650px',
        margin: '0 auto',
        padding: '30px',
        textAlign: 'center',
        borderRadius: '12px',
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
    },
    header: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#fff',
        marginBottom: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #007bff, #00c6ff)',
        padding: '20px',
        borderRadius: '10px',

    },
    icon: {
        width: '32px',
        height: '32px',
        marginRight: '15px',
    },
    headerText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#fff',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
    },
    dropzone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        border: '2px dashed #007bff',
        borderRadius: '12px',
        backgroundColor: 'rgba(0, 123, 255, 0.1)',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        textAlign: 'center',
    },
    dropzoneIcon: {
        width: '40px',
        height: '40px',
        marginBottom: '15px',
    },
    dropzoneText: {
        fontSize: '18px',
        color: '#007bff',
        fontWeight: '500',
        textAlign: 'center',
    },
    uploadBtn: {
        padding: '14px 24px',
        fontSize: '18px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        width: '100%',
        boxSizing: 'border-box',
    },
    loading: {
        backgroundColor: '#ccc',
        cursor: 'not-allowed',
    },
    loadingText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        marginTop: '20px',
        fontSize: '16px',
        color: 'red',
    },
    upload: {
        fontSize: '20px',
        fontWeight: '700',
        color: '#fff',
        marginBottom: '30px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #007bff, #00c6ff)',
        padding: '17px',
        borderRadius: '10px',
        border: 'none'
    },
};

export default InsertFocusProduct;
