import * as Types from "./SoDashboardType";
const initialState = {
    soStatus: false,
    sodashboardinfo: [],
    targetListValue: {
        soTotalValue: 0,
        soTargetQty: 0
    },
    secondaryList: [],
    soDashboardData: {
        measurement: 1,
    }
};
const SoDashboardReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.SO_DASHBOARD_DATA:
            return {
                ...state,
                sodashboardinfo: action.payload.data,
                targetList: action.payload.data?.targetList,
                secondaryList: action.payload.data?.secondaryList,
                soStatus: action.payload.status
            }

        case Types.SO_DASHBOARD_DATA:
            const cloneSoTopCard = { ...state.targetListValue };

            cloneSoTopCard.soTotalValue = action.payload.data.targetList?.soTotalValue
            return {
                ...state,
                targetListValue: cloneSoTopCard,
                soStatus: action.payload.status
            };
        case Types.CHANGE_SO_DASHBOARD_INPUT:
            const soDashboardData = { ...state.soDashboardData };
            soDashboardData[action.payload.name] = action.payload.value;
            return {
                ...state,
                soDashboardData
            };

        default:
            break;
    }
    return newState;
};

export default SoDashboardReducer;
