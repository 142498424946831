import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Col } from "react-bootstrap";
import LoadingSpinner from "../../../modules/master/spinner/LoadingSpinner";
import { NorthSouthChart } from "../../../modules/Sales/information/components/Chart/NorthSouthChart";
import { GetSoDashboardData, InputSoDashboard } from "../../../modules/Sales/information/components/Dashboard/SoDashboardAction";


const SoTopBarCard = () => {
    const { register, setValue } = useForm();

    const dispatch = useDispatch();
    const targetListValue = useSelector((state) => state.sodash.targetListValue);
    const soDashboardData = useSelector((state) => state.sodash.soDashboardData);
    const isLoading = useSelector((state) => state.sodash.soStatus);
    const Measurement = soDashboardData.measurement;
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const initialData = async () => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";
        dispatch(GetSoDashboardData(firstD,currentD));
    }

    useEffect(() => {
        initialData();
    }, []);
    let nf = new Intl.NumberFormat('en-BD');

    const handleChangeStartDate = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };

    const handleChange = (name, value) => {
        dispatch(InputSoDashboard(name, value));
    };

    const options = [
        { value: "1", label: "Value" },
        { value: "2", label: "Volume" },
    ];

    return (

        <div className="container-fluid mb-6 containerFixed">

            <div className="row">
                
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div class="top_card">
                    <div className="form-group row">
                    <div className="col-lg-4 text-left">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control cusheight"
                                onChange={handleChangeStartDate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-4 text-left">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control cusheight "
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-4 text-left">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Measurement</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={options} />}
                                rules={{ required: false }}
                                name="measurement "
                                register={register}
                                value=""
                                isClearable
                                onChange={(option) => {
                                    handleChange('measurement', option?.value);
                                    dispatch(GetSoDashboardData(startDate, toDate))
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>


                </div>
                        <h4>সেকেন্ডারি</h4>
                        <div class="columns1">
                            <div class="column1 column1color">
                                <h5>বিক্রয়</h5>
                                <p>0</p>
                            </div>
                            <div class="column1 column2color">
                                <h5>অর্জিত</h5>
                                <p>0</p>
                            </div>
                            <div class="column1 column3color">
                                <h5> দৈনিক চাহিদা </h5>
                                <p>0</p>
                            </div>
                        </div>
                        <div class="column1 top_card1">
                            {/* <RadialBarChart /> */}
                            <NorthSouthChart />
                        </div>
                        <div class="columns1">
                            <div class="column1 top_card1">
                                <div className="topCard_img">
                                    <img src="/media/svg/icons/Code/topcard1.svg" alt="iMarine" />
                                    <h6>টার্গেট</h6>
                                    <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                                    <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && (parseInt(Measurement == 1 ? targetListValue?.soTotalValue : targetListValue?.soTargetQty)).toLocaleString("en-IN")}</h4></span>
                                </div>
                            </div>
                            <div class="column1 top_card1">
                                <div className="topCard_img">
                                    <img src="/media/svg/icons/Code/topcard1.svg" alt="iMarine" />
                                    <h6>টার্গেট</h6>
                                    <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                                    <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && (parseInt(Measurement == 1 ? targetListValue?.soTotalValue : targetListValue?.soTargetQty)).toLocaleString("en-IN")}</h4></span>
                                </div>
                            </div>
                            <div class="column1 top_card1">
                                <div className="topCard_img">
                                    <img src="/media/svg/icons/Code/target-objective-svgrepo-com.svg" alt="iMarine" />
                                    <h6>টার্গেট</h6>
                                    <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                                    <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && (parseInt(Measurement == 1 ? targetListValue?.soTotalValue : targetListValue?.soTargetQty)).toLocaleString("en-IN")}</h4></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-3">
                    For Blinking Card- carddemo
                    <div className="top_card">
                        <div className="topCard_img">
                            <img src="/media/svg/icons/Code/topcard2.svg" alt="iMarine" />
                            <h6>Delivery</h6>
                            <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                            <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && parseInt(topCard?.delivery).toLocaleString("en-IN")}</h4></span>

                        </div>
                    </div>

                </div> */}

                {/* <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="top_card">
                        <div className="topCard_img">
                            <img src="/media/svg/icons/Code/topcard2.svg" alt="iMarine" />
                            <h6>Delivery</h6>
                            <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                            <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && parseInt(topCard?.delivery).toLocaleString("en-IN")}</h4></span>

                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-3">
                    <div className="top_card">
                        <div className="topCard_img">
                            <img src="/media/svg/icons/Code/topcard1.svg" alt="iMarine" />
                            <h6>Secondary Sales </h6>
                            <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                            <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && parseInt(topCard?.demand).toLocaleString("en-IN")}</h4></span>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-3">
                    <div className="top_card">
                        <div className="topCard_img">
                            <img src="/media/svg/icons/Code/target-objective-svgrepo-com.svg" alt="iMarine" />
                            <h6>Target</h6>
                            <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                            <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && parseInt(topCard?.delivery).toLocaleString("en-IN")}</h4></span>

                        </div>
                    </div>
                </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-3">
                    <div className="top_card">
                        <div className="topCard_img">
                            <img src="/media/svg/icons/Code/target-objective-svgrepo-com.svg" alt="iMarine" />
                            <h6>Target</h6>
                            <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
                            <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading && parseInt(topCard?.delivery).toLocaleString("en-IN")}</h4></span>

                        </div>
                    </div>
                </div> */}

                {/* <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img src="/media/svg/icons/Code/topcard3.svg" alt="iMarine" />
              <h6>IMS</h6>
              <span className="spineercolor">{isLoading&&<LoadingSpinner />}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.ims).toLocaleString("en-IN")}</h4></span>
            </div>
          </div>
        </div> */}
                {/* <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img
                src="/media/svg/icons/Code/target-objective-svgrepo-com.svg"
                alt="iMarine"
              />
              <h6>IMS Target</h6>
              <span className="spineercolor">{isLoading&&<LoadingSpinner />}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.target).toLocaleString("en-IN")}</h4></span>
            </div>
          </div>
        </div> */}
            </div>
            {/* <div class="carddemo">
                <h2>Example Card</h2>
                <div class="columns1">
                    <div class="column2">
                        <h3>Column 1</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend ipsum eget justo pulvinar, in ullamcorper turpis efficitur.</p>
                    </div>
                    <div class="column2">
                        <h3>Column 2</h3>
                        <p>Proin ac quam eget eros ultricies bibendum. Praesent non risus quis felis tincidunt lacinia. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                    </div>
                    <div class="column2">
                        <h3>Column 3</h3>
                        <p>Donec blandit libero ac mauris convallis sagittis. Nullam quis mi ut libero dictum vulputate eu ut velit. In nec massa ac enim interdum elementum.</p>
                    </div>
                </div>
            </div> */}



        </div>
    );
};
export default SoTopBarCard;
