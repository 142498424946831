import { indexOf } from "lodash";
import * as Types from "../../types/Types";

const initialState = {
  isLoading: false,
  zoneSubmitStatus: false,
  productUpdateStatus: false,
  febProStatus: false,
  restBalance: 0,
  salesDetails: [],
  productQuantity: 0,
  totalSalesDetailsAmount: 0,
  totalDetQuantity: null,
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  salesUpdateData: [],
  submitData: {
    intCustomer: 0,
    monCustBalance: 0,
    coaid: 0,
    intsalesofficeid: 0,
    DistribPointID: 0,
    DelPointID: 0,
  },
  balanceLoader: false,
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
  productCost: 0,
  productList: [],
  filterProduct: [],
  isLoggedIn: false,
  selectedProduct: [],
  availableBalance: 0,
  creditLimit: 0,
  demandlist: [],
  productDelivery: [],
  customerDeliveryProd: [],
  isPProductLoading: false,
  isDeDetails: false,
  salesEditInfo: null,
  submitLoading: false,
  salesApprovedList: [],
  salesApprovedDetails: [],
  approvedLoading: false,
};
const SecondarySalesFebReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_SALES_PRODUCT_LIST_FOR_TEMP:
      return {
        ...state,
        productList: action.payload.data,
      };
    case Types.GET_SECONDARY_SALES_ORDER_FEB_UPDATE_PRODUCT:
      return {
        ...state,
        salesUpdateData: action.payload.data,
      };
    case Types.CHANGE_PRODUCT_QUANTITY_DEMO:
      let quantityvalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const product = state.productList.slice();
      const avaiblebalance = state.availableBalance;
      let sumItem = 0;
      let restBalance = 0;
      let rest;
      let productQuantity = 0;
      for (let i = 0; i < product.length; i++) {
        if (product[i].itemID === action.payload.item.itemID) {
          product[i].itemQuentityPic = quantityvalue;
          product[i].totalPrice =
            product[i].itemQuentityPic * product[i].prodTrdpricebypic;
        }
        sumItem += product[i].itemQuentityPic == "" ? 0 : product[i].itemQuentityPic * product[i].prodTrdpricebypic;
        restBalance = avaiblebalance - sumItem;
        productQuantity += parseFloat(product[i].itemQuentityPic == null ? 0 : product[i].itemQuentityPic);
      }
      return {
        ...state,
        productList: product,
        productCost: sumItem,
        productQuantity: productQuantity,
        restBalance,
      };

    case Types.CHANGE_PRODUCT_UPDATE_QUANTITY_FEB:
      let quantityUpdatevalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const productUpdate = state.salesUpdateData.slice();

      let sumItemUpdate = 0;
      for (let i = 0; i < productUpdate.length; i++) {
        if (productUpdate[i].itemID === action.payload.item.itemID) {
          productUpdate[i].itemQuentityPic = quantityUpdatevalue;
        }
        sumItemUpdate +=
          productUpdate[i].itemQuentityPic * productUpdate[i].prodTrdpricebypic;
      }
      return {
        ...state,
        salesUpdateData: productUpdate,
      };
    case Types.CLEAR_REMOTE_SALES_DATA_FEB:
      
      const productClear = state.productList.slice();
      for (let i = 0; i < productClear.length; i++) {
        productClear[i].itemQuentityPic = "";
      }

      return {
        ...state,
        availableBalance: 0,
        restBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        salesApprovedList: [],
        demandlist: [],
        productList: [],
        febProStatus: false
      };
    case Types.SALES_PRODUCT_SUBMIT_FEB:
      return {
        ...state,
        submitLoading: false,
        febProStatus:action.payload.status,
        availableBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
      };
    default:
      break;
  }
  return newState;
};

export default SecondarySalesFebReducer;


