import * as Types from "../../types/Types";
import { GetDdl } from "../../../../../../Global/GlobalDdl";

const initialState = {

  productList: [],
  restBalance: 0,
  productSubmitStatus: false,
  productUpdateStatus: false,
  damageSubmitStatus: false,
  SummerysheetLoading: false,
  productSubDetails: [],
  damageList: [],
  damageUpdateData: [],
  productListUpdate: [],
  damagedetailslist: [],
  distributordamagedata: [],
  damageApprovedListDetails: [],
  damageproductList: [],
  damageType: [],
  productQuantity: 0,
  productCost: 0,
  damagedetailslist: [],
  distributordamagedata: [],
  damageApprovedListDetails: [],
  damageproductList: [],
  damageType: [],
  damageSummeryData: [],
  SummerysheetData: [],
  damageSummaryLoading: false,
  dSummaryCatWiseLoading: false,
  dSummeryDataCatWise: [],
  dDataSKUWiseLoading: false,
  dDataSKUWise: [],
  damageApprovedDataSKUWise: [],
  damageApprovedDataSKUWiseLoading: false,

};
const DamageReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {


    case Types.GET_DAMAGED_LIST:

      const productData = [];
      for (let i = 0; i < action.payload.data?.length; i++) {
        let dataSet = {
          damagesl: action.payload.data[i].damagesl,
          itemPrice: action.payload.data[i].itemPrice,
          itemQuentity: null,
          productId: action.payload.data[i].productId,
          productName: action.payload.data[i].productName,
          productStatus: false
        }
        productData.push(dataSet);

      }

      return {
        ...state,
        productList: productData,
      };
    case Types.CHANGE_DAMAGE_QUANTITY:

      // const product = state.productList.slice();
      // for (let i = 0; i < product.length; i++) {
      //   if (product[i].productId == action.payload.item.productId) {
      //     product[i].productStatus = true;
      //     product[i].itemPrice = action.payload.item.itemPrice;
      //     for (let j = 0; j < product[j].details.length; j++) {
      //       const element = product[i].details[j];
      //       if (product[i].details[j].categoryId == action.payload.category.categoryId) {
      //         product[i].details[j].value = parseInt(action.payload.value)
      //       }
      //     }

      //   }
      // }
      return {
        ...state,
        // productList: product,
      };
    case Types.DAMAGE_SUBMIT:
      return {
        ...state,
        submitLoading: false,
        productSubmitStatus: action.payload.status,
        productQuantity: 0,
        productCost: 0,
      };
    case Types.CLEAR_FIELD_DATA:
      const productClean = state.productList.slice();
      for (let i = 0; i < productClean.length; i++) {
        for (let j = 0; j < productClean[j].details.length; j++) {
          const element = productClean[i].details[j];
          productClean[i].details[j].value = ""
        }
      }
      return {
        ...state,
        productList: productClean,
        productQuantity: 0,
        productCost: 0,
      };

    case Types.GET_DAMAGE_LIST:
      return {
        ...state,
        damageList: action.payload.data,
      };

    case Types.GET_DAMAGE_UPDATE_DETAILS:
      return {
        ...state,
        damageUpdateData: action.payload.data,
      };
    case Types.DAMAGE_UPDATE:
      return {
        ...state,
        productUpdateStatus: true,
      };
    case Types.CHANGE_DAMAGE_UPDATE_QUANTITY:

      const productUpdate = state.damageUpdateData.slice();
      for (let i = 0; i < productUpdate.length; i++) {
        if (productUpdate[i].productId == action.payload.item.productId) {
          productUpdate[i].productStatus = true;
          productUpdate[i].itemPrice = action.payload.item.itemPrice;
          for (let j = 0; j < productUpdate[j].details.length; j++) {
            const element = productUpdate[i].details[j];
            if (productUpdate[i].details[j].categoryId == action.payload.category.categoryId) {
              productUpdate[i].details[j].value = parseInt(action.payload.value)
            }
          }
        }
      }
      return {
        ...state,
        damageUpdateData: productUpdate,
      };

    case Types.DELETE_DAMAGE_ITEM:
      return {
        ...state,
      };
    case Types.APPROVED_ITEM:
      return {
        ...state,
      };
    case Types.CLEAR_LIST:
      return {
        ...state,
        productList: [],
        productSubDetails: [],
        damageList: [],
        damageUpdateData: [],
        productListUpdate: [],
      };
    case Types.GET_DAMAGE_PRODUCT_LIST_DETAILS:
      return {
        ...state,
        damagedetailslist: action.payload.data,
      };
    case Types.GET_DAMAGE_APPROVED_LIST_DETAILS:
      return {
        ...state,
        damageApprovedListDetails: action.payload.data,
      };
    case Types.PRODUCT_SUBMIT_STATUS_REMOVE_FOR_DAMAGE:
      return {
        ...state,
        productSubmitStatus: false,
      };
    case Types.GET_DISTRIBUTOR_WISE_DAMAGE_REPORT:
      return {
        ...state,
        distributordamagedata: action.payload.data,
      };
    case Types.CHANGE_DAMAGE_QUANTITY_NEW:
      const product = state.productList.slice();
      let productQuantity = 0;
      let sumItem = 0;
      let sumD = [];
      let quantityvalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      for (let i = 0; i < product.length; i++) {
        if (product[i].productId == action.payload.item.productId) {
          product[i].productStatus = true;
          product[i].itemPrice = action.payload.item.itemPrice;
          product[i].itemQuentity = quantityvalue > 0 ? quantityvalue : null;
        }
        productQuantity += product[i].itemQuentity;
        sumItem += product[i].itemQuentity * product[i].itemPrice;
      }
      return {
        ...state,
        productList: product,
        productQuantity: productQuantity,
        productCost: sumItem,
      };

    case Types.GET_DISTRIBUTOR_WISE_DAMAGE_REPORT:
      return {
        ...state,
        distributordamagedata: action.payload.data,
      };
    case Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY:
      return {
        ...state,
        damageSummeryData: action.payload.data,
        damageSummaryLoading: action.payload.status,
      };
    case Types.GET_TOTAL_DAMAGE_REPORT_SUMMERY_CAT_WISE:
      console.log('action.payload', action.payload)
      return {
        ...state,
        dSummeryDataCatWise: action.payload.data,
        dSummaryCatWiseLoading: action.payload.status,
      };
    case Types.GET_DAMAGE_REPORT_SUMMERY_SKU_WISE:
      console.log('action.payload', action.payload)
      return {
        ...state,
        dDataSKUWise: action.payload.data,
        dDataSKUWiseLoading: action.payload.status,
      };


    // case Types.GET_DAMAGE_APPROVED_REPORT_SUMMERY_SKU_WISE:
    //   console.log('action.payload', action.payload);

    //   let structuredData = [];

    //   if (Array.isArray(action.payload.data)) {
    //     structuredData = action.payload.data.reduce((acc, item) => {
    //       const existingProduct = acc.find(product => product.productId === item.productId);

    //       if (existingProduct) {
    //         existingProduct.damageCategories.push({
    //           damageName: item.damageName,
    //           disPointID: item.disPointID,
    //           damageQty: item.damageQty,
    //           damageValue: item.damageValue,
    //         });
    //       } else {
    //         acc.push({
    //           productId: item.productId,
    //           productName: item.productName,
    //           zoneName: item.zoneName,
    //           divisionName: item.divisionName,
    //           areaName: item.areaName,
    //           territoryName: item.territoryName,

    //           totalLiftingQty: item.totalLiftingQty,
    //           totalLifting: item.totalLifting,
    //           damageCategories: [{
    //             distribID: item.distribID,
    //             distribName: item.distribName,
    //             damageName: item.damageName,
    //             disPointID: item.disPointID,
    //             damageQty: item.damageQty,
    //             damageValue: item.damageValue,
    //           }],
    //         });
    //       }

    //       return acc;
    //     }, []);

    //     // Calculate totals for each product
    //     structuredData.forEach(product => {
    //       product.totalQty = product.damageCategories.reduce((total, category) => total + category.damageQty, 0);
    //       product.totalValue = product.damageCategories.reduce((total, category) => total + (category.damageQty * category.damageValue), 0);
    //     });
    //   }

    //   return {
    //     ...state,
    //     damageApprovedDataSKUWise: structuredData,
    //     damageApprovedDataSKUWiseLoading: action.payload.status,
    //   };



    case Types.GET_SUMMERY_SHEET_REPORT:
      return {
        ...state,
        SummerysheetLoading: action.payload.status,
        SummerysheetData: action.payload.data,
      };
    case Types.GET_DAMAGE_APPROVED_REPORT_SUMMERY_SKU_WISE:
      return {
        ...state,
        damageSummaryLoading: action.payload.status,
        damageApprovedDataSKUWise: action.payload.data,
      };
    // case Types.CHANGE_DAMAGE_QUANTITY_NEW:
    //   const product = state.productList.slice();
    //   console.log('action.payload :>> ', action.payload);
    //   for (let i = 0; i < product.length; i++) {
    //     if (product[i].productId == action.payload.item.productId) {
    //       product[i].productStatus = true;
    //       product[i].itemPrice = action.payload.item.itemPrice;
    //       product[i].itemQuentity = action.payload.value;
    //     }
    //   }
    //   return {
    //     ...state,
    //     productList: product,
    //   };

    case Types.GET_DAMAGE_TYPE:

      return {
        ...state,
        damageType: GetDdl(action.payload.data, "categoryId", "categoryName"),
      };
    // case Types.CLEAR_FIELD_FOR_DAMAGE:
    //   const changedvalue = {
    //     ...state.productList,
    //   };
    //   changedvalue.value= 0
    //   return {
    //     ...state,
    //     productList:changedvalue,
    //   };


    case Types.CLEAR_FIELD_FOR_DAMAGE:
      const changedvalue = state.productList.slice();
      for (let i = 0; i < changedvalue.length; i++) {
        changedvalue[i].itemQuentity = ""

      }
      return {
        ...state,
        productList: changedvalue,
        damageSubmitStatus: true,
      };

    default:
      break;
  }
  return newState;
};

export default DamageReducer;

