import React, { Component } from 'react';
import Axios from 'axios';

export const PasswordUpdate = async (inputData) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: false,
    };
   
    let serverData={
        oldPassword: inputData.oldPassword,
        newPassword: inputData.password,
    }
    try {
      let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/UserPasswordRecet`;
      await Axios
        .put(geturl,serverData)
        .then(function(response) {
           
          responseList.data = response.data;
        })
        .catch(function(error) {
          
        });
    } catch (error) {
     
    }
    return responseList;
  };