import React from "react";
import SecondaryList from "../../../information/components/SecodarySales/SecondaryList";




const SecondaryListContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <SecondaryList/>
      </div>
    </div>
  );
};

export default SecondaryListContainer;
