import React from "react";
import SecondarySalesApprovedList from "../../../../information/components/RemoteSales/secondarySalesList/SecondarySalesApprovedList";

const SecondarySalesListContainer = () => {
  return (
    <div className="container-fluid">
      <SecondarySalesApprovedList />
    </div>
  );
};

export default SecondarySalesListContainer;
