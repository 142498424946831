import axios from "axios";
import * as Types from "../../types/DashboardTypes";

export const GetBradeBurnTopCard = (ReportType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_TOPCARD_DATA, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetIMSTARGETAChivement?ReportType=${ReportType}`;
  await axios.get(url).then((res) => {
    console.log('bread burn res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TOPCARD_DATA_FOR_BREAD_BURN, payload: responseList });
};

export const GetAllProductForBreadBurn = (ReportType) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.GET_ALL_PRODUCT_DATA_FOR_BREAD_BURN, payload: responseList });
    try {
      let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSecondarySalesProductCategory?ReportType=${ReportType}`;
    await axios.get(url).then((res) => {
      responseList.data = res.data.data;
      responseList.status = false;
    });

    } catch (error) {
      // responseList.status = false;
    }
    dispatch({ type: Types.GET_ALL_PRODUCT_DATA_FOR_BREAD_BURN, payload: responseList });
    
  };
