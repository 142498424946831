import React,{useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import LoadingSpinner from "../../../modules/master/spinner/LoadingSpinner";
import { GetTopCard } from "../../../modules/Sales/information/_redux/actions/Dashboard/DashboardAction";


const DashboardTopBar = () => {
  const dispatch = useDispatch();
  const topCard = useSelector((state) => state.dashboard.topcardValue);

  const isLoading = useSelector((state) => state.dashboard.toCardLoading);


  useEffect(() => {
   dispatch(GetTopCard());
    
  }, []);
  let nf = new Intl.NumberFormat('en-BD');

  return (
   
    <div className="container-fluid mb-6 containerFixed">
         <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img src="/media/svg/icons/Code/topcard1.svg" alt="iMarine" />
              <h6>Demand </h6>
             
              <span className="spineercolor">{isLoading&&<LoadingSpinner/>}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.demand).toLocaleString("en-IN")}</h4></span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img src="/media/svg/icons/Code/topcard2.svg" alt="iMarine" />
              <h6>Delivery</h6>
              <span className="spineercolor">{isLoading&&<LoadingSpinner/>}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.delivery).toLocaleString("en-IN")}</h4></span>
           
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img src="/media/svg/icons/Code/topcard3.svg" alt="iMarine" />
              <h6>IMS</h6>
              <span className="spineercolor">{isLoading&&<LoadingSpinner />}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.ims).toLocaleString("en-IN")}</h4></span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="top_card">
            <div className="topCard_img">
              <img
                src="/media/svg/icons/Code/target-objective-svgrepo-com.svg"
                alt="iMarine"
              />
              <h6>IMS Target</h6>
              <span className="spineercolor">{isLoading&&<LoadingSpinner />}</span>
              <span><h4 className="dashboardTopCardDataColor mt-2">{!isLoading&&parseInt(topCard?.target).toLocaleString("en-IN")}</h4></span>
            </div>
          </div>
        </div>
      </div>

    
     
    </div>
  );
};
export default DashboardTopBar;
