import React, { useState, useEffect } from 'react';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    return `${hours}:${minutes}:${seconds} ${period}`;
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#282c34',
    color: '#61dafb',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  };

  const clockStyle = {
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '4px',
    border: '1px solid #61dafb',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  };

  return (
    <div style={containerStyle}>
      <div style={clockStyle}>
        {formatTime(time)}
      </div>
    </div>
  );
};

export default Clock;
