import React from "react";
import TradeOfferOrderAdd from "../../../information/components/RemoteSales/TradeOfferOrderAdd";



const TradeOfferContainer = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <TradeOfferOrderAdd/>
      </div>
    </div>
  );
};
export default TradeOfferContainer;
