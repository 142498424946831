import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { GetAllProduct } from "../../_redux/actions/Dashboard/DashboardAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const CircleChart = () => {
  const dispatch = useDispatch();
  const topCard = useSelector((state) => state.dashboard.topcardValue);
 
  const isLoading = useSelector((state) => state.dashboard.toCardLoading);

  useEffect(() => {
    dispatch(GetAllProduct());
  }, []);
  const options = {

    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }

        },
        track: {
          background: '#EFEAED',

        },

      },

    },
    labels: ["Acheivement"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        // gradientToColors: ["#2ed02f"],
        colorStops: [
          {
            offset: 30,
            color: "#42b30b",
            opacity: 1
          },
          {
            offset: 50,
            color: "#FAD375",
            opacity: 1
          },
          
          {
            offset: 100,
            color: " #EB656F",
            opacity: 1
          }
        ]

      }


    },


  };

  const series = [parseInt(topCard?.achivement)];

  return (
    <div className="acheivement_chart_card">
      <span className="spineercolor">{isLoading && <LoadingSpinner />}</span>
      <div className=" text-center">

        <>
          {!isLoading && (
            <>
              <div id="chart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="radialBar"
                  height="220"

                />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default CircleChart;
