import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";


const ReportFooter = () => {

    const totalTarget = useSelector((state) => state.target.totalData);


    return (
        <>
                  <tr className='tblFooterback'>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td >
                        
                    <strong class="blue">Cookies</strong>
                    </td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cookisTargetQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisLiftingQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisDifferentQty).toFixed(2)}</td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cookisTargetPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisLiftingPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisDifferentPrice).toFixed(2)}</td> 
                </tr>
                <tr className='tblFooterback'>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td> <strong class="blue">Biscuit</strong></td>
                    <td></td>
                    <td>{parseFloat(totalTarget.biscuitTargetQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.biscuitLiftingQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.biscuitDifferentQty).toFixed(2)}</td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cookisTargetPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisLiftingPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisDifferentPrice).toFixed(2)}</td> 
                </tr>
                <tr className='tblFooterback'>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td> <strong class="blue">Cake</strong></td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cakeTargetQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cakeLiftingQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cakeDifferentQty).toFixed(2)}</td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cakeTargetPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cakeLiftingPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cakeDifferentPrice).toFixed(2)}</td> 
                </tr>
                <tr className='tblFooterback'>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td> <strong class="blue">Total(Cookies & Biscuit)</strong></td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitTargetQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitLiftingQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitDifferentQty).toFixed(2)}</td>
                    <td></td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitTargetPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitLiftingPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.cookisandBiscuitDifferentPrice).toFixed(2)}</td> 
                </tr>
                <tr className='tblFooterback'>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td> <strong class="blue">Grand Total</strong></td>
                    <td></td>
                    <td>{parseFloat(totalTarget.totalTargetQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.totalLiftingQty).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.totalDifferentQty).toFixed(2)}</td>
                    <td></td>
                    <td>{parseFloat(totalTarget.totalTargetPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.totalLiftingPrice).toFixed(2)}</td>
                    <td>{parseFloat(totalTarget.totalDifferentPrice).toFixed(2)}</td> 
                </tr>
              
            

        </>
    )
}
export default ReportFooter;