import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";

import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import { Dropdown, Card } from "react-bootstrap";
import Pdf from "react-to-pdf";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { dateTimeConvertLongString, generateStringDateFromDate, getDateFromUtcDate } from "../../../../../domains/CCO/utils/DateHelper";
import ReportFooter from "../../Master/ReportFooter";
import { cleanTargetSalesData, getSkuDemandWiseTargetAction, getSkuWiseTargetAction, updateLockTimeData } from "../../_redux/actions/Report/ReportAction";

const ApplicationLock = () => {

  let ref = React.createRef();
  const inputField = React.useRef(null);
  const componentRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const updateLockTime = () => {
    const updateLock = updateLockTimeData(startDate,toDate);
  }

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5 bg-light">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Application Lock</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Application Lock' />
          </div>
        </div>
        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div className="form-group row">
              <div className="col-lg-3 col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Date</label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    value={startDate}
                    className="fromStyle"
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3 col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label"> To Date</label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Todate"
                    name="todate"
                    value={toDate}
                    className="form-control cusheight "
                    onChange={handleChangeTodate}
                  />
                </Form.Group>
              </div>
              <div className="col mt-5">
                <Button size="sm" onClick={() => updateLockTime()}>Update</Button>

              </div>
            </div>
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} >
              <thead>
                <tr>
                  <th scope="col">Lock</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>



          </div>
        </div>



      </div>
    </>
  );
};

export default ApplicationLock;
