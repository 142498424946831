import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
    getAreaListByDivision,
    getAreaListByDivisionEntry,
    getDivisionListByZone,
    getDivisionListByZoneEntry,
    getTerritory,
    getTerritoryEntry,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { InputHandle } from "../../_redux/actions/Delivery/DeliveryAction";
import {
    getDistributorList, StockStatusUpdate,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";

const DeliveryConfirmStatus = () => {
    const { register, handleSubmit, errors, setValue, setFocus } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    const deliveryStatusInput = useSelector((state) => state.delivery.deliveryStatusInput);
    const SalesGroupdistributorList = useSelector((state) => state.remoteSales.SalesGroupdistributorList);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(1);
    const [distribType, setDistribType] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const componentRef = useRef();

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        initialData();
    }, []);

    useEffect(() => {
        if (defultTerritory?.value) {
            dispatch(getDistributorList(defultTerritory?.value, salesType));
            inputChangeHandle("intDistributorId", 0);
        }
    }, [defultTerritory]);

    const initialData = async () => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }


    const zoneDropdownData = () => {

        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
      }
      const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
      }
      const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
      }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemID, setItemID] = useState(null);
    const [itemifo, setItemifo] = useState(null);
    const [paginateData, setPaginateData] = useState([]);

    const itemDetail = (item) => {
        setItemifo(item);
        setItemID(item.orderId);
        setItemDetailShow(true);
    };
    let ref = React.createRef();
    const inputField = React.useRef(null);

    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const handleTerritoryChange = (option) => {
        setSelectTerritory(option?.value);
        setdefultTerritory(option);
        setValue("intDistributorId", 0);
        dispatch(getDistributorList(option?.value, salesType));
        inputChangeHandle("intDistributorId", 0);
    };

    const showValidation = () => {
        if (selectZone == 0) {
            alert("Please Select Zone");
            return false;
        }
        else if (selectDivision == 0) {
            alert("Please select Division");
            return false;
        }
        else if (selectArea == 0) {
            alert("Please select Region");
            return false;
        }
        else if (selectTerritory == 0) {
            alert("Please select Territory");
            return false;
        }
        else if (deliveryStatusInput.intDistributorId == 0) {
            alert("Please select Distributor");
            return false;
        }
        else if (deliveryStatusInput?.delivery_code == 0) {
            alert("Please Type Challan No");
            return false;
        }
        else {
            return true;
        }
    }

    const onSubmit = (e) => {
        const validation = showValidation();
        if (validation) {
            dispatch(StockStatusUpdate(deliveryStatusInput?.delivery_code, deliveryStatusInput?.intDistributorId));
        }
    }


    useEffect(() => {
        setValue("intDistributorId", 0);
    }, [selectTerritory]);

    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <span className="button-010">Delivery Confirm Status</span>
                            </div>
                        </div>
                        {/* <SimpleSalesType
                            distribType={distribType}
                            salesType={salesType}
                            getSalesValue={getSalesValueType}
                        /> */}
                    </div>

                    <hr></hr>
                    <div className="form-group row">

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Zone</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={zoneDropdownData()}
                                    value={defultZone}
                                    onChange={(option) => {
                                        divisionList(option?.value);
                                        setSelectZone(option?.value);
                                        setDefaultZone(option);
                                        setdefultDivision(null);
                                        setdefaultArea(null);
                                        setdefultTerritory(null);
                                        setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("territory", "");

                                    }}
                                    defaultValue={defultZone}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Division</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={divisionDropdownData()}
                                    value={defultDivision}
                                    onChange={(option) => {
                                        getAreaData(option?.value);
                                        setSelectDivision(option?.value);
                                        setdefultDivision(option);
                                        setdefaultArea(null);
                                        setdefultTerritory(null);
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                    }}
                                    defaultValue={defultDivision}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Region</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={areaDropdownData()}
                                    value={defaultArea}
                                    onChange={(option) => {
                                        getTerritoryData(option?.value);
                                        setSelectArea(option?.value);
                                        setdefaultArea(option);
                                        setdefultTerritory(null);
                                        setValue("territory", "");
                                    }}
                                    defaultValue={defaultArea}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 mt-0">
                            <label className="form-label ml-4">Select Territory</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Select
                                    options={territoryDropdownData()}
                                    value={defultTerritory}
                                    onChange={handleTerritoryChange}
                                    defaultValue={defultTerritory}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">

                                <label className="form-label">Select Distributor</label>
                                <RHFInput

                                    as={<Select options={SalesGroupdistributorList} />}
                                    rules={{ required: false }}
                                    name="intDistributorId"
                                    register={register}
                                    value={deliveryStatusInput.intDistributorId}
                                    onChange={(option) => {
                                        inputChangeHandle("intDistributorId", option.value?.intcusid);
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>

                        </div>
                        <div className="col-lg-3">
                            <Form.Group controlId="formGridState">
                                <label className="form-label">CHALLAN NO</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="form-control reffcss "
                                    type="text"
                                    placeholder="Type DO Code"
                                    name="delivery_code"
                                    value={deliveryStatusInput?.delivery_code}
                                    onChange={(e) => {
                                        inputChangeHandle("delivery_code", e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 mt-7 mb-5">
                            <button
                                type="submit"
                                className="btn btn-primary 
                                    btn-sm text-center 
                                    custome-addnew-btn item-add-save mb-5"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default DeliveryConfirmStatus;
