import React from "react";
import RemoteSalesList from "../../../information/components/RemoteSales/RemoteSalesList";



const RemoteSalesListContainer = () => {
  return (
    <div className="container-fluid">
      <RemoteSalesList />
    </div>
  );
};

export default RemoteSalesListContainer;
